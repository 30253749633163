import { MappingProfile } from '@automapper/core';
import { createMetadataForDetails } from '../detailsMapping/DetailsModel/DetailsModelMetadata';
import { createMetadataForOperationalMetadata } from '../detailsMapping/operational-metadata-model';
import { createMetadataForTabs } from '../detailsMapping/Tabs/tabs-metadata';
import { createMetadataForQualityLosses } from '../detailsMapping/UnitKpi/QualityLosses/quality-losses-metadata';
import { createMetadataForUnitKPI } from '../detailsMapping/UnitKpi/UnitKpiMetadata';
import { createMetadataForOpDataModel } from '../operational-data-model/operational-data-model-metadata';

export const opDataMetadataProfile: MappingProfile = (mapper) => {
    createMetadataForOpDataModel();
    createMetadataForOperationalMetadata();
    createMetadataForUnitKPI();
    createMetadataForQualityLosses();
    createMetadataForTabs();
    createMetadataForDetails();
};
