import { EventInfoFilterModel } from '@/models/event-info';

export interface EventInfoListQuery {
    urlParams: string;
    body: any;
}
export class EventInfoQueryBuilder {
    buildQueryForEventInfoList(filter: EventInfoFilterModel): EventInfoListQuery {
        let queryString = '?';
        const requestBody: any = {};

        for (const field in filter) {
            if (filter[field]) {
                if (Array.isArray(filter[field])) {
                    requestBody[field] = filter[field];
                } else {
                    queryString = queryString.concat(`${field}=${filter[field]}&`);
                }
            }
        }

        if (queryString.endsWith('&') || queryString.endsWith('?')) {
            queryString = queryString.slice(0, -1)
        }

        return { urlParams: queryString, body: requestBody };
    }
}