import { convertUsing, mapFrom, Mapper } from '@automapper/core';
import { CamelToPascalCase, numberConverter, PascalToCamelCase } from '../../common';
import { OperationalDataDetailsDTO, OperationalDataDetailsEditDTO, OperationalDataUnitChpDto, OperationalDataUnitComponentsDto, OperationalDataUnitKpiDTO, OperationalDataUnitKpiEditDTO, OperationalDataUnitMaterialDTO, OperationalDataUnitThermalDTO, OperationalDataUnitUnavailabilityDTO, OperationalMetadataDTO } from '@/models/operational-data/operational-data-DTO';
import { ChpModel, ComponentsModel, MaterialModel, OpDataUnitKPI, OperationalDataDetails, OperationalMetadata, ThermalGenerationModel, UnavailabilityModel } from '@/models/operational-data/operational-data-details';

export const mapDetails = (mapper: Mapper): void => {
    mapper.createMap<OperationalDataDetails, OperationalDataDetailsDTO>(
        'OperationalDataDetails',
        'OperationalDataDetailsDTO',
        { namingConventions: PascalToCamelCase }
    )
        .forMember(
            d => d.operationalMetadataDto,
            mapFrom(s => mapper.map<OperationalMetadata, OperationalMetadataDTO>(
                s.Metadata!, 'OperationalMetadataDTO', 'OperationalMetadata')
            ))
        .forMember(
            d => d.operationalDataUnitKpiDto,
            mapFrom(s => mapper.map<OpDataUnitKPI, OperationalDataUnitKpiDTO>(
                s.UnitKPI!, 'OperationalDataUnitKpiDTO', 'OpDataUnitKPI')
            ))
        .forMember(
            d => d.operationalDataUnitThermalListDto,
            mapFrom(s => mapper.mapArray<ThermalGenerationModel, OperationalDataUnitThermalDTO>(
                s.ThermalList!, 'OperationalDataUnitThermalDTO', 'ThermalGenerationModel')
            ))
        .forMember(
            d => d.operationalDataUnitMaterialListDto,
            mapFrom(s => mapper.mapArray<MaterialModel, OperationalDataUnitMaterialDTO>(
                s.MaterialList!, 'OperationalDataUnitMaterialDTO', 'MaterialModel')
            ))
        .forMember(
            d => d.operationalDataUnitUnavailabilityListDto,
            mapFrom(s => mapper.mapArray<UnavailabilityModel, OperationalDataUnitUnavailabilityDTO>(
                s.UnavailabilityList!, 'OperationalDataUnitUnavailabilityDTO', 'UnavailabilityModel')
            ))
        .forMember(
            d => d.operationalDataComponentKpiListDto,
            mapFrom(s => mapper.mapArray<ComponentsModel, OperationalDataUnitComponentsDto>(
                s.ComponentsList!, 'OperationalDataUnitComponentsDto', 'ComponentsModel')
            ))
        .forMember(
            d => d.operationalDataUnitChpDto,
            mapFrom(s => mapper.map<ChpModel, OperationalDataUnitChpDto>(
                s.CHP!, 'OperationalDataUnitChpDto', 'ChpModel')
            ))
        .forMember(d => d.operationalDataUnitKpiDto.valueInternal, mapFrom(s => s.QualityLossesPct?.valueInternal))
        .forMember(d => d.operationalDataUnitKpiDto.commentInternal, mapFrom(s => s.QualityLossesPct?.commentInternal))
        .forMember(d => d.operationalDataUnitKpiDto.valueExternal, mapFrom(s => s.QualityLossesPct?.valueExternal))
        .forMember(d => d.operationalDataUnitKpiDto.commentExternal, mapFrom(s => s.QualityLossesPct?.commentExternal))
        .forMember(d => d.operationalDataUnitKpiDto.valueOther, mapFrom(s => s.QualityLossesPct?.valueOther))
        .forMember(d => d.operationalDataUnitKpiDto.commentOther, mapFrom(s => s.QualityLossesPct?.commentOther))

        .forMember(d => d.operationalDataUnitKpiDto.waterActual, mapFrom(s => s.QualityLossesTemp?.waterActual))
        .forMember(d => d.operationalDataUnitKpiDto.waterForecast, mapFrom(s => s.QualityLossesTemp?.waterForecast))
        .forMember(d => d.operationalDataUnitKpiDto.airActual, mapFrom(s => s.QualityLossesTemp?.airActual))
        .forMember(d => d.operationalDataUnitKpiDto.airForecast, mapFrom(s => s.QualityLossesTemp?.airForecast));

    mapper
        .createMap<OperationalDataDetailsDTO, OperationalDataDetails>(
            'OperationalDataDetailsDTO', 'OperationalDataDetails', { namingConventions: CamelToPascalCase })
        .forMember(d => d.Metadata, mapFrom(s => mapper.map<OperationalMetadataDTO, OperationalMetadata>(
            s.operationalMetadataDto, 'OperationalMetadata', 'OperationalMetadataDTO')
        ))
        .forMember(d => d.UnitKPI, mapFrom(s => mapper.map<OperationalDataUnitKpiDTO, OpDataUnitKPI>(
            s.operationalDataUnitKpiDto, 'OpDataUnitKPI', 'OperationalDataUnitKpiDTO')
        ))
        .forMember(d => d.ThermalList, mapFrom(s => mapper.mapArray<OperationalDataUnitThermalDTO, ThermalGenerationModel>(
            s.operationalDataUnitThermalListDto, 'ThermalGenerationModel', 'OperationalDataUnitThermalDTO')
        ))
        .forMember(d => d.MaterialList, mapFrom(s => mapper.mapArray<OperationalDataUnitMaterialDTO, MaterialModel>(
            s.operationalDataUnitMaterialListDto, 'MaterialModel', 'OperationalDataUnitMaterialDTO')
        ))
        .forMember(d => d.UnavailabilityList, mapFrom(s => mapper.mapArray<OperationalDataUnitUnavailabilityDTO, UnavailabilityModel>(
            s.operationalDataUnitUnavailabilityListDto, 'UnavailabilityModel', 'OperationalDataUnitUnavailabilityDTO')
        ))
        .forMember(d => d.ComponentsList, mapFrom(s => mapper.mapArray<OperationalDataUnitComponentsDto, ComponentsModel>(
            s.operationalDataComponentKpiListDto, 'ComponentsModel', 'OperationalDataUnitComponentsDto')
        ))
        .forMember(d => d.CHP, mapFrom(s => mapper.map<OperationalDataUnitChpDto, ChpModel>(
            s.operationalDataUnitChpDto, 'ChpModel', 'OperationalDataUnitChpDto')
        ))
        .forMember(d => d.QualityLossesPct?.valueInternal, convertUsing(numberConverter, (s: OperationalDataDetailsDTO) => s.operationalDataUnitKpiDto.valueInternal))
        .forMember(d => d.QualityLossesPct?.commentInternal, mapFrom(s => s.operationalDataUnitKpiDto.commentInternal))
        .forMember(d => d.QualityLossesPct?.valueExternal, convertUsing(numberConverter, (s: OperationalDataDetailsDTO) => s.operationalDataUnitKpiDto.valueExternal))
        .forMember(d => d.QualityLossesPct?.commentExternal, mapFrom(s => s.operationalDataUnitKpiDto.commentExternal))
        .forMember(d => d.QualityLossesPct?.valueOther, convertUsing(numberConverter, (s: OperationalDataDetailsDTO) => s.operationalDataUnitKpiDto.valueOther))
        .forMember(d => d.QualityLossesPct?.commentOther, mapFrom(s => s.operationalDataUnitKpiDto.commentOther))
        .forMember(d => d.QualityLossesTemp?.waterActual, convertUsing(numberConverter, (s: OperationalDataDetailsDTO) => s.operationalDataUnitKpiDto.waterActual))
        .forMember(d => d.QualityLossesTemp?.waterForecast, convertUsing(numberConverter, (s: OperationalDataDetailsDTO) => s.operationalDataUnitKpiDto.waterForecast))
        .forMember(d => d.QualityLossesTemp?.airActual, convertUsing(numberConverter, (s: OperationalDataDetailsDTO) => s.operationalDataUnitKpiDto.airActual))
        .forMember(d => d.QualityLossesTemp?.airForecast, convertUsing(numberConverter, (s: OperationalDataDetailsDTO) => s.operationalDataUnitKpiDto.airForecast))

    mapper.createMap<OperationalDataDetails, OperationalDataDetailsEditDTO>(
        'OperationalDataDetails', 'OperationalDataDetailsEditDTO', { namingConventions: PascalToCamelCase })
        .forMember(d => d.operationalDataUnitKpiDto, mapFrom(s => mapper.map<OpDataUnitKPI, OperationalDataUnitKpiEditDTO>(
            s.UnitKPI!, 'OperationalDataUnitKpiEditDTO', 'OpDataUnitKPI')
        ))
        .forMember(d => d.operationalDataUnitThermalListDto, mapFrom(s => mapper.mapArray<ThermalGenerationModel, OperationalDataUnitThermalDTO>(
            s.ThermalList!, 'OperationalDataUnitThermalDTO', 'ThermalGenerationModel')
        ))
        .forMember(d => d.operationalDataUnitMaterialListDto, mapFrom(s => mapper.mapArray<MaterialModel, OperationalDataUnitMaterialDTO>(
            s.MaterialList!, 'OperationalDataUnitMaterialDTO', 'MaterialModel')
        ))
        .forMember(d => d.operationalDataUnitUnavailabilityListDto, mapFrom(s => mapper.mapArray<UnavailabilityModel, OperationalDataUnitUnavailabilityDTO>(
            s.UnavailabilityList!, 'OperationalDataUnitUnavailabilityDTO', 'UnavailabilityModel')
        ))
        .forMember(d => d.operationalDataComponentKpiListDto, mapFrom(s => mapper.mapArray<ComponentsModel, OperationalDataUnitComponentsDto>(
            s.ComponentsList!, 'OperationalDataUnitComponentsDto', 'ComponentsModel')
        ))
        .forMember(d => d.operationalDataUnitChpDto, mapFrom(s => mapper.map<ChpModel, OperationalDataUnitChpDto>(
            s.CHP!, 'OperationalDataUnitChpDto', 'ChpModel')
        ))
        .afterMap((s, d) => {
            if (d.operationalDataUnitKpiDto) {
                d.operationalDataUnitKpiDto.valueInternal = s.QualityLossesPct?.valueInternal;
                d.operationalDataUnitKpiDto.commentInternal = s.QualityLossesPct ? s.QualityLossesPct.commentInternal : '';
                d.operationalDataUnitKpiDto.valueExternal = s.QualityLossesPct?.valueExternal;
                d.operationalDataUnitKpiDto.commentExternal = s.QualityLossesPct ? s.QualityLossesPct?.commentExternal : '';
                d.operationalDataUnitKpiDto.valueOther = s.QualityLossesPct?.valueOther;
                d.operationalDataUnitKpiDto.commentOther = s.QualityLossesPct ? s.QualityLossesPct?.commentOther : '';
                d.operationalDataUnitKpiDto.waterActual = s.QualityLossesTemp?.waterActual;
                d.operationalDataUnitKpiDto.waterForecast = s.QualityLossesTemp?.waterForecast;
                d.operationalDataUnitKpiDto.airActual = s.QualityLossesTemp?.airActual;
                d.operationalDataUnitKpiDto.airForecast = s.QualityLossesTemp?.airForecast;
            }
        });
}