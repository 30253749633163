const checkedPermissionsNewAndPendingState = {
    'SitePerformanceEngineer': [
        'UseButtonSplitEvent',
        'UseButtonSave',
        'UseButtonSubmit',
        'UseButtonDeleteSplit'
    ],
    'Admin': [
        'UseButtonSplitEvent',
        'UseButtonSave',
        'UseButtonSubmit',
        'UseButtonDeleteSplit'
    ]
}

const checkedPermissionsInReviewState = {
    'CentralFunctionsEngineer': [
        'UseButtonSplitEvent',
        'UseButtonSave',
        'UseButtonFinal',
        'UseButtonRequestInfo',
        'UseButtonDeleteSplit'
    ],
    'Admin': [
        'UseButtonSplitEvent',
        'UseButtonSave',
        'UseButtonFinal',
        'UseButtonRequestInfo',
        'UseButtonDeleteSplit'
    ]
}

const checkedPermissionsFinalState = {
    'Admin': [
        'UseButtonReOpen'
    ]
}

const checkPermissionsForOperationalDataDraftState = {
    'SitePerformanceEngineer': [
        'UseButtonSave'
    ],
    'Admin': [
        'UseButtonSave'
    ]
}

const checkedPermissionsForOperationalDataNewAndPendingState = {
    'SitePerformanceEngineer': [
        'UseButtonSave',
        'UseButtonSubmit'
    ],
    'Admin': [
        'UseButtonSave',
        'UseButtonSubmit'
    ]
}

const checkedPermissionsForOperationalDataInReviewState = {
    'CentralFunctionsEngineer': [
        'UseButtonSave',
        'UseButtonFinal',
        'UseButtonRequestInfo',
    ],
    'Admin': [
        'UseButtonSave',
        'UseButtonFinal',
        'UseButtonRequestInfo',
    ]
}

export enum PermissionEnum {
    New = 741,
    PendingInfo = 742,
    InReview = 743,
    Locked = 745,
    Final = 744,
    AwaitingCompletion = 747,
    NewForOperationalData = 751,
    DraftForOperationalData = 752,
    InReviewForOperationalData = 753,
    PendingInfoForOperationalData = 754,
    FinalForOperationalData = 755,
    ManualEventsUpload = 756
}

export type PermissionMatrix = Partial<Record<keyof typeof PermissionEnum, Record<string, Array<string>>>>;

/**
 * User with no access rights for a lifecycle will not be listed in the lifecycle
 * This is a special treatment for roles that mostly have read only access to REACT.
 */
export const staticPermissionMatrix: PermissionMatrix = {
    'New': checkedPermissionsNewAndPendingState,
    'PendingInfo': checkedPermissionsNewAndPendingState,
    'InReview': checkedPermissionsInReviewState,
    'Locked': {},
    'AwaitingCompletion': checkedPermissionsFinalState, // same permissions as 'Final'
    'Final': checkedPermissionsFinalState,
    'DraftForOperationalData': checkPermissionsForOperationalDataDraftState,
    'NewForOperationalData': checkedPermissionsForOperationalDataNewAndPendingState,
    'PendingInfoForOperationalData': checkedPermissionsForOperationalDataNewAndPendingState,
    'InReviewForOperationalData': checkedPermissionsForOperationalDataInReviewState,
    'FinalForOperationalData': checkedPermissionsFinalState, // Has to be changed, if permissions between event info and operational data differ.
    'ManualEventsUpload': checkedPermissionsFinalState
}
