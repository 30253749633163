import { render, staticRenderFns } from "./components-block.vue?vue&type=template&id=6f333fc0&scoped=true"
import script from "./components-block.ts?vue&type=script&lang=js&external"
export * from "./components-block.ts?vue&type=script&lang=js&external"
import style0 from "./components-block.vue?vue&type=style&index=0&id=6f333fc0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f333fc0",
  null
  
)

export default component.exports