import { isEqual } from 'lodash';

export function eraseUnchangedProperties<T>(original: T, changed: T, exludedProperties: Set<(keyof T)>): void {
    let propertyKey: keyof T;
    for(propertyKey in changed)
    {
        if(exludedProperties.has(propertyKey))
            continue;

        if (isEqual(original[propertyKey], changed[propertyKey]))
            changed[propertyKey] = null as any;
    }
}