import { OpDataUnitKPI, ThermalHeatSums } from '@/models/operational-data/operational-data-details';
import store from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import CustomInput from '../../../utils/custom-input/custom-input.vue';
import { OperationalDataGettersNames } from '@/store/modules/operational-data/operational-data-getters';
import { ModeSettings } from '@/utils/mode-settings';
import { cloneDeep } from 'lodash';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
@Component({
    components: {
        CustomInput
    }
})
export default class UnitGeneration extends Vue {
    min = 0;
    required = true;
    precision = ModeSettings.InputNumberPrecision;

    get isReaderUser(): boolean {
        const opData = store.state.operationalData.activeStatus;
        return (opData && opData.reportStatusSid) ?
            !store.getters['userAccessManagement/useButtonSave'](opData.reportStatusSid) : true;
    }

    get activeUnitKPIModel(): OpDataUnitKPI {
        return cloneDeep(store.getters[OperationalDataGettersNames.GetActiveUnitKPI]);
    }

    get thermalHeatSums(): ThermalHeatSums {
        return cloneDeep(store.getters[OperationalDataGettersNames.GetThermalHeatSums]);
    }

    onInputChange(value: number, propertyPath: string): void {
        store.commit(OperationalDataMutationsNames.SetUnitKpiProperty, {propertyPath: propertyPath, value});
    }
}
