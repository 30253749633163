import { Component } from 'vue-property-decorator';
import CustomInput from '@/components/utils/custom-input/custom-input.vue';
import { cloneDeep } from 'lodash';
import store from '@/store';
import { ChpHeat, ChpModel, ChpPower } from '@/models/operational-data/operational-data-details';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
import { CustomInputValidationState } from '../../op-data-comand-buttons/custom-inputs-validator';
import { TabsValidationUpdateModel } from '@/models/operational-data/operational-data';
import BaseCustomInputValidation from '@/components/utils/base-custom-input-validation/base-custom-input-validation';

export const AvailableUnitsForChp = [9055, 9007, 9042, 9043, 9048, 9024, 9027, 9028];

export interface CHPTabItem {
    propertyPath: (keyof ChpModel | keyof ChpHeat | keyof ChpPower)[];
    title: string;
    value: number | undefined;
    units: string;
}

@Component({
    components: {
        CustomInput
    }
})
export default class CHPTab extends BaseCustomInputValidation {
    chpPrecision = 3;
    items: CHPTabItem[] = [];

    created():void {
        this.onValidationStateChange = this.onCustomInputsValidationStateChange;
    }

    onCustomInputsValidationStateChange(inputs: CustomInputValidationState[]): void {
        const tabValidationUpdateModel: TabsValidationUpdateModel = {
            key: 'isChpTabValid',
            isValid: inputs.every(i => i.isValid)
        }

        store.commit(OperationalDataMutationsNames.SetTabValidationState, tabValidationUpdateModel);
    }

    get isReadOnly(): boolean {
        const opData = store.state.operationalData.activeStatus;
        return (opData && opData.reportStatusSid) ?
            !store.getters['userAccessManagement/useButtonSave'](opData.reportStatusSid) : true;
    }
    
    mounted(): void {
        if (!store.state.operationalData.activeCHP)
            store.commit(OperationalDataMutationsNames.InitActiveChpProperty);
        
        const activeChp = cloneDeep(store.state.operationalData.activeCHP) as ChpModel;
        this.items = [
            { propertyPath: ['Power', 'GeneralOverall'], title: 'operationalData.entryView.tabs.chpTab.NetPowerGenerationOverall', value: activeChp.Power.GeneralOverall, units: 'MWh' },
            { propertyPath: ['Power', 'RectifiedGeneralOverall'], title: 'operationalData.entryView.tabs.chpTab.RectifiedNetPowerGenerationOverall', value: activeChp.Power.RectifiedGeneralOverall, units: 'MWh' },
            { propertyPath: ['Power', 'General'], title: 'operationalData.entryView.tabs.chpTab.CHPNetPowerGeneration', value: activeChp.Power.General, units: 'MWh' },
            { propertyPath: ['Power', 'GenFedGrid'], title: 'operationalData.entryView.tabs.chpTab.NetPowerGenerationFedIntoTheGrid', value: activeChp.Power.GenFedGrid, units: 'MWh' },
            { propertyPath: ['PrimaryEnergySavings'], title: 'operationalData.entryView.tabs.chpTab.PrimaryEnergySavings', value: activeChp.PrimaryEnergySavings, units: '%' },
            { propertyPath: ['Heat', 'GeneralOverall'], title: 'NetHeatGenerationOverall', value: activeChp.Heat.GeneralOverall, units: 'MWh' },
            { propertyPath: ['Heat', 'General'], title: 'operationalData.entryView.tabs.chpTab.CHPNetHeatGeneration', value: activeChp.Heat.General, units: 'MWh' },
            { propertyPath: ['Heat', 'Fuel'], title: 'operationalData.entryView.tabs.chpTab.CHPFuelHeat', value: activeChp.Heat.Fuel, units: 'GJ' },
            { propertyPath: ['Heat', 'AdjustedFuel'], title: 'operationalData.entryView.tabs.chpTab.AdjustedFuelHeat', value: activeChp.Heat.AdjustedFuel, units: 'GJ' },
            { propertyPath: ['Heat', 'InternalConsumption'], title: 'operationalData.entryView.tabs.chpTab.InternalHeatConsumption', value: activeChp.Heat.InternalConsumption, units: 'GJ' },
        ];

        this.$nextTick(() => this.validateCustomInputs(this));
    }

    onInputChange(item: CHPTabItem):void {
        store.commit(OperationalDataMutationsNames.SetActiveChpProperty, item);
    }
}
