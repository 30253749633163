import { ApiResponse, ApiEnumerationResponse, EnumAttributes, ApiHierarchiesResponse, EventInfoList, EventInfoModel, HierarchyAttributes, ApiResponseList, SplitEventModel, EventHistoryModel } from '@/models';
import { EventInfoFilterModel, EventInfoStatusModel, EventVgbClassificationList } from '@/models/event-info';
import { MainSystem, SubSystem } from '@/models/hierarchies';
import { EventInfoQueryBuilder } from './helpers/event-info-query-builder';
import { UploadValidationResult, UploadValidationResultDto } from '@/models/manual-upload-validation-model';
import { ModeServiceBase } from '@/services/base-services';
import { OpDataQueryBuilder } from '@/services/helpers/op-data-query-builder';
import {
    ActiveOperationalDataFilters
} from '@/components/operational-data/op-data-filter/utils/ActiveOperationalDataFilters';
import { PagedList } from '@/models/base-models/paged-list';
import { OperationalDataModel } from '@/models/operational-data/operational-data';
import { OperationalDataDonutStatusCountDTO, OperationalDataDTO } from '@/models/operational-data/operational-data-DTO';
import { mapper } from '@/mappers/mapper';
import store from '@/store';
import { OperationalDataGettersNames } from '@/store/modules/operational-data/operational-data-getters';
import { EnumService } from '@/services/enum-service';
import { EnumerationsService } from '@/services/enumerations-service';
import { HierarchiesService } from '@/services/hierarchies-service';
import { MainSystemsService } from '@/services/main-systems-service';
import { SubSystemsService } from '@/services/sub-systems-service';
import { SplitEventService } from '@/services/split-event-service';
import { AuditHistoryService } from '@/services/audit-history-service';
import { ParallelEventService } from '@/services/parallel-event-service';

export enum EnumInfoMasterData {
    VgbConditionBefore = 810,
    VgbTimeFrame = 820,
    VgbEventType = 840,
    VgbMainCons = 860,
    VgbExternalInfluenceDescriptionSid = 870
}

export enum EnumStatusSid {
    New = 741,
    PendingInfo = 742,
    InReview = 743,
    Final = 744
}

class EventInfoListService extends ModeServiceBase {
    private readonly queryBuilder: EventInfoQueryBuilder;

    constructor(queryBuilder: EventInfoQueryBuilder) {
        super('event-info-list');
        this.queryBuilder = queryBuilder;
    }

    public async getAllEventInfos(filter: EventInfoFilterModel): Promise<ApiResponse<EventInfoList>> {
        const query = this.queryBuilder.buildQueryForEventInfoList(filter);
        return (await this.api.post(undefined, query.body, `${query.urlParams}`)).data;
    }

    public async getEventInfoList(machineSid = -1): Promise<ApiResponse<EventInfoList>> {
        // Send request
        const request = machineSid !== -1 ? `?MachineSid=${machineSid}` : undefined
        return (await this.api.post(undefined, undefined, `${request}`)).data;
    }
}

class EventInfoStatusCountService extends ModeServiceBase {
    private readonly queryBuilder: EventInfoQueryBuilder;

    constructor(queryBuilder: EventInfoQueryBuilder) {
        super('event-info-status-count');
        this.queryBuilder = queryBuilder;
    }

    public async getDoughnutfilterStatus(filter: EventInfoFilterModel): Promise<ApiResponseList<EventInfoStatusModel>> {
        const query = this.queryBuilder.buildQueryForEventInfoList(filter);
        return (await this.api.post(undefined, query.body, `${query.urlParams}`)).data.result;
    }
}

/**
 * @name EventInfoService
 * @description this class is used to query the backend server for EventInfo related data.
 */
export class EventInfoService extends ModeServiceBase {
    private readonly queryBuilder = new EventInfoQueryBuilder();
    private readonly eventInfoListService = new EventInfoListService(this.queryBuilder);
    private readonly eventInfoStatusCountService = new EventInfoStatusCountService(this.queryBuilder);
    private readonly enumService = new EnumService();
    private readonly enumerationsService = new EnumerationsService();
    private readonly hierarchiesService = new HierarchiesService();
    private readonly mainSystemsService = new MainSystemsService();
    private readonly subSystemsService = new SubSystemsService();
    private readonly splitEventService = new SplitEventService();
    private readonly auditHistoryService = new AuditHistoryService();
    private readonly parallelEventService = new ParallelEventService();

    constructor() {
        super('event-info');
    }

    public async getAllEventInfos(filter: EventInfoFilterModel): Promise<ApiResponse<EventInfoList>> {
        return this.eventInfoListService.getAllEventInfos(filter);
    }

    public async getEventInfoList(machineSid = -1): Promise<ApiResponse<EventInfoList>> {
        return this.eventInfoListService.getEventInfoList(machineSid);
    }

    //Parsing in the different Enums for the VGB-Classifications for all available Sid (?)
    public async getVgbClassificationById(enumSid: number): Promise<ApiResponse<EventVgbClassificationList>> {
        return this.enumService.getVgbClassificationById(enumSid);
    }

    /**
     * @name getEventInfo
     * @description This method calls an event info from the backend.
     * @param {number} eventInfoId  The event info id
    */
    public async getEventInfo(eventInfoId: number): Promise<ApiResponse<EventInfoModel>> {
        try {
            return (await this.api.get(undefined, `${eventInfoId}`)).data;
        }
        catch (err) {
            throw new Error(err as any);
        }
    }

    // TODO write function querying:
    // - event ID
    // - unavailable capacity
    // - start / end date
    // - other events that happened during this time at the same unit

    /**
     * @name setEventInfo
     * @description This method submits an event info to the backend.
     * @param {EventInfoModel} eventInfo  The event info.
    */
    // TODO setEventInfo is not working, putEventInfo is working
    public async setEventInfo(eventInfo: EventInfoModel): Promise<ApiResponse<EventInfoModel>> {
        return (await this.api.put(undefined, eventInfo)).data;
    }

    public async putEventInfo(event: EventInfoModel): Promise<ApiResponse<EventInfoModel>> {
        return (await this.api.put(undefined, event)).data;
    }

    /**
     * @name getEnumAttributes
     * @description This method queries from the backend a list of enums with a specific enum type id.
     * @param {number} enumTypeId  The type id of the enum.
     * @returns A List of enum attributes.
    */
    public async getEnumAttributes(enumTypeId: number): Promise<ApiResponse<ApiEnumerationResponse<EnumAttributes>>> {
        return this.enumerationsService.getEnumAttributes(enumTypeId);
    }

    /**
     * @name getHierarchyAttributes
     * @description This method queries from the backend a list of hierarchy attributes.
     * @returns A List of hierarchy attributes.
    */
    public async getHierarchyAttributes(): Promise<ApiResponse<ApiHierarchiesResponse<HierarchyAttributes>>> {
        return this.hierarchiesService.getHierarchyAttributes();
    }

    /**
     * @name getMainSystems
     * @description This method queries from the backend a list of main systems.
     * @param {number} machineId  The id of the unit.
     * @param {string} functionalLocation The functional location.
     * @returns A List of main systems.
    */
    public async getMainSystems(machineId: number, functionalLocation: string): Promise<ApiResponse<ApiResponseList<MainSystem>>> {
        return this.mainSystemsService.getMainSystems(machineId, functionalLocation);
    }

    /**
     * @name getSubSystems
     * @description This method queries from the backend a list of sub systems.
     * @param {number} machineId  The id of the unit.
     * @param {string} functionalLocation The functional location.
     * @param {string} mainSystemId  The id of the main system.
     * @returns A List of sub systems.
    */
    public async getSubSystems(machineId: number, functionalLocation: string, mainSystemId: string): Promise<ApiResponse<ApiResponseList<SubSystem>>> {
        return this.subSystemsService.getSubSystems(machineId, functionalLocation, mainSystemId);
    }

    /**
     * @name postSplitEvent
     * @description This method orders the backend to perform a split of an event.
     * @param {number} eventId  The id of the event for which a split has to be performed.
     * @param {string[]} splitDates The intermediate dates for the splitted events.
     * @returns The new event info which contains the splitted events.
    */
    public async postSplitEvent(eventId: number, splitDate: string): Promise<ApiResponse<EventInfoModel>> {
        return this.splitEventService.postSplitEvent(eventId, splitDate);
    }

    /**
     * @name getSplitEvents
     * @description This method queries from the backend a list of split events.
     * @param {number} eventId  The id of the parent event.
     * @returns The event splits.
    */
    public async getSplitEvents(eventId: number): Promise<ApiResponse<ApiResponseList<SplitEventModel>>> {
        return this.splitEventService.getSplitEvents(eventId);
    }

    public async getSplitEventById(parentId: number, childId: number): Promise<ApiResponse<SplitEventModel>> {
        return this.splitEventService.getSplitEventById(parentId, childId);
    }

    public async putSplitEvent(requestbody: SplitEventModel): Promise<ApiResponse<SplitEventModel>> {
        return this.splitEventService.putSplitEvent(requestbody);
    }

    public async deleteAllSplitEvents(eventId: number): Promise<ApiResponseList<SplitEventModel>> {
        return this.splitEventService.deleteAllSplitEvents(eventId);
    }

    /**
     * @name getEventHistory
     * @description This method queries from the backend a list of data changes of the event.
     * @param {number} eventId  The id of the parent event.
     * @returns The event history.
    */
    public async getEventHistory(eventId: number): Promise<ApiResponse<ApiResponseList<EventHistoryModel>>> {
        return this.auditHistoryService.getEventHistory(eventId);
    }

    /**
     * @name getParallelEvents
     * @description This method queries the backend for a list of events happening in parallel with a specific event
     * @param {number} eventId The id of the primary event
     * @param {string} UtcStartTime The timestamp of the event UTC Start Time
     * @param {string} UtcEndTime The timestamp of the event UTC End Time
     * @returns Events happening in parallel with the supplied eventId
     */
    public async getParallelEvents(eventId: number, UtcStartTime: string, UtcEndTime: string): Promise<ApiResponse<EventInfoModel[]>> {
        return this.parallelEventService.getParallelEvents(eventId, UtcStartTime, UtcEndTime);
    }

    public async getDoughnutfilterStatus(filter: EventInfoFilterModel): Promise<ApiResponseList<EventInfoStatusModel>> {
        return this.eventInfoStatusCountService.getDoughnutfilterStatus(filter);
    }

    public async uploadEventsFile(file: File, finalize = false): Promise<ApiResponse<UploadValidationResult | null>> {
        const formData = new FormData();
        formData.append('File', file);
        formData.append('Finalize', finalize.toString());
        return await this.api.post(undefined, formData, undefined, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                const validationResultDto = response.data.result as UploadValidationResultDto;
                return {
                    message: null,
                    statusCode: response.data.statusCode,
                    result: {
                        successCount: validationResultDto.successfullyMappedCount,
                        parallelCount: validationResultDto.parallelEventsCount,
                        duplicatedEntries: validationResultDto.duplicateIdentifiers,
                        incorrectEntries: validationResultDto.wrongMappedIdentifiers
                    }
                };
            })
            .catch((error) => {
                return {
                    message: error.response.data.message,
                    statusCode: error.response.data.statusCode,
                    result: null
                };
            });
    }

    // TODO query both EventId and SplitEventId, EventId has EventInfo, SplitEventId is the float value for a split event
}
