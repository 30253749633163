import { render, staticRenderFns } from "./breadcrumb-item.vue?vue&type=template&id=294090f2&scoped=true"
import script from "./breadcrumb-item.ts?vue&type=script&lang=js&external"
export * from "./breadcrumb-item.ts?vue&type=script&lang=js&external"
import style0 from "./breadcrumb-item.less?vue&type=style&index=0&id=294090f2&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "294090f2",
  null
  
)

export default component.exports