import BgTooltip from '@/components/event/event-table/custom-tooltips/bgTooltip.vue';
import Vue from 'vue';
import { VendorOptions, RowData } from './contracts/table-data';
import { AgGridVue } from 'ag-grid-vue';

const EventTable = Vue.extend({
    name: 'EventTable',
    components: {
        AgGridVue,
        BgTooltip
    },
    props: {
        vendorOptions: {
            type: Object as () => VendorOptions,
            default: {},
            required: true,
        },
        tableName: {
            type: String,
            required: true,
        }
    },
    data(): {
        gridApi: any;
        columnApi: any;
        minRowHeight: number;
        currentRowHeight: number;
        tooltipShowDelay: number;
        animateRows: boolean;
        defaultColDef: {
            editable: boolean;
            sortable: boolean;
            resizable: boolean;
            filter: boolean;
            flex: number;
            minWidth: number;
            minHeight: number;
            unSortIcon: boolean;    //show sort icons even if no sort selected?
            tooltipComponent: any;
        };
        } {
        return {
            gridApi: null,
            columnApi: null,
            minRowHeight: 0,
            currentRowHeight: 0,
            tooltipShowDelay: 0,
            animateRows: true,
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
                filter: true,
                flex: 1,
                minWidth: 100,
                minHeight: 50,
                unSortIcon: true,
                tooltipComponent: BgTooltip
            },
        }
    },
    computed: {
    },
    methods: {
        onGridReady(params: any): void {
            this.gridApi = params.api;
            this.gridApi.showLoadingOverlay();
            this.columnApi = params.columnApi;
            this.minRowHeight = params.api.getSizesForCurrentTheme().rowHeight;
            this.currentRowHeight = this.minRowHeight;
            params.api.sizeColumnsToFit();
            this.gridApi.hideOverlay();
        },
        onFirstDataRendered(params: any): void {
            this.onGridSizeChanged(params);
        },
        getSelectedRows(): Array<RowData>{
            const selectedNodes = this.gridApi.getSelectedNodes();
            return selectedNodes.map((node: any) => node.data);
        },
        onRowSelected(event: any): void {
            this.$emit('eventSelected', event.node.data);
        },
        onPaginationChange(newPage: any): void {
            this.$emit('paginationChanged', newPage);
        },
        onCellDoubleClicked(cellDoubleClickedEvent: any): void {
            this.autoSizeColumns(true, cellDoubleClickedEvent.column.colId);
        },
        onGridSizeChanged(params: any): void {

            const bodyViewportList = document.querySelectorAll(`div[eventTableName=${this.tableName}] .ag-body-viewport`);

            if (!bodyViewportList || bodyViewportList.length === 0) {
                return;
            }

            if (bodyViewportList.length > 1) {
                console.warn(`More than one viewport found for the event table.
                              This can lead to a wrong rendering behaviour.
                              Choose a unique name for the table to fix this issue.
                              The currently used name is ${this.tableName}`);
            }

            const bodyViewport = bodyViewportList[0];

            const gridHeight = bodyViewport.clientHeight;
            const renderedRowCount = params.api.getDisplayedRowCount();

            if (renderedRowCount * this.minRowHeight >= gridHeight) {
                if (this.currentRowHeight !== this.minRowHeight) {
                    this.currentRowHeight = this.minRowHeight;
                    //params.api.resetRowHeights();  // Do we need this at all? Doesn't work with autoHeight=true
                }
            } else {
                this.currentRowHeight = Math.floor(gridHeight / renderedRowCount);
                //params.api.resetRowHeights();
            }
        },
        onSortChanged(sortChangedEvent: any): void {
            this.$emit('sortChanged', sortChangedEvent);
        },
        autoSizeColumns(skipHeaders: boolean, ...columnIds: string[]): void {
            this.columnApi.autoSizeColumns(columnIds, skipHeaders);
        },
    }
})

export default EventTable;
