import { render, staticRenderFns } from "./op-data-entry-details-tabs.vue?vue&type=template&id=3bc348dd&scoped=true"
import script from "./op-data-entry-details-tabs.ts?vue&type=script&lang=js&external"
export * from "./op-data-entry-details-tabs.ts?vue&type=script&lang=js&external"
import style0 from "./op-data-entry-details-tabs.vue?vue&type=style&index=0&id=3bc348dd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bc348dd",
  null
  
)

export default component.exports