import { ActiveOperationalDataFilters } from '@/components/operational-data/op-data-filter/utils/ActiveOperationalDataFilters';
import { mapper } from '@/mappers/mapper';
import { ApiResponse, ApiResponseList } from '@/models';
import { PagedList } from '@/models/base-models/paged-list';
import { OperationalDataModel } from '@/models/operational-data/operational-data';
import { MaterialModel, OperationalDataDetails, ThermalGenerationModel } from '@/models/operational-data/operational-data-details';
import { OperationalDataDetailsDeleteDTO, OperationalDataDetailsDTO, OperationalDataDetailsEditDTO, OperationalDataDonutStatusCountDTO, OperationalDataDTO, OperationalDataEnumDTO, OperationalDataStatusDTO } from '@/models/operational-data/operational-data-DTO';
import store from '@/store';
import { OperationalDataGettersNames } from '@/store/modules/operational-data/operational-data-getters';
import { OpDataQueryBuilder } from './helpers/op-data-query-builder';
import { ModeServiceBase } from '@/services/base-services';
import { EnumService } from '@/services/enum-service';

class OperationalDataListService extends ModeServiceBase {
    private readonly queryBuilder = new OpDataQueryBuilder();

    constructor() {
        super('operational-data-list');
    }

    public async getOperationalData(activeFilters?: ActiveOperationalDataFilters): Promise<ApiResponse<PagedList<OperationalDataModel>>> {
        const query = this.queryBuilder.buildQueryForOpDataList(activeFilters);
        const response = await this.api.post(undefined, query.body, query.urlParams);
        const opDataList: OperationalDataModel[] = [];
        (response.data.result.items as OperationalDataDTO[]).forEach(opDataDTO => {
            const opDataModel = mapper.map<OperationalDataDTO, OperationalDataModel>(opDataDTO, 'OperationalDataModel', 'OperationalDataDTO');
            opDataModel.Status.Name = store.getters[OperationalDataGettersNames.GetStatusNameById](opDataModel.Status.Id)
            opDataList.push(opDataModel);
        });
        response.data.result.items = opDataList;

        return response.data;
    }
}

class OperationalDataStatusCountService extends ModeServiceBase {
    private readonly queryBuilder = new OpDataQueryBuilder();

    constructor() {
        super('operational-data-status-count');
    }

    public async getOperationalDataDoughnutStatus(activeFilters?: ActiveOperationalDataFilters): Promise<ApiResponse<ApiResponseList<OperationalDataDonutStatusCountDTO>>> {
        const query = this.queryBuilder.buildQueryForOpDataList(activeFilters);
        const response = await this.api.post(undefined, query.body, query.urlParams);
        return response.data;
    }
}

class OperationalDataStatusService extends ModeServiceBase {
    private readonly queryBuilder = new OpDataQueryBuilder();

    constructor() {
        super('operational-data-status');
    }

    public async getStatus(unitId: number, date: string): Promise<ApiResponse<OperationalDataStatusDTO>> {
        try {
            const test = await this.api.get(undefined, `${unitId}/${date}`);
            return test.data;
        }
        catch (err) {
            throw new Error(err as any);
        }
    }

    public async setStatus(model: OperationalDataStatusDTO): Promise<ApiResponse<OperationalDataStatusDTO>> {
        try {
            return (await this.api.put(undefined, model)).data;
        }
        catch (err) {
            throw new Error(err as any);
        }
    }
}

export class OperationalDataService extends ModeServiceBase {
    private readonly queryBuilder = new OpDataQueryBuilder();
    private readonly operationalDataListService = new OperationalDataListService();
    private readonly operationalDataStatusCountService = new OperationalDataStatusCountService();
    private readonly operationalDataStatusService = new OperationalDataStatusService();
    private readonly enumService = new EnumService();

    constructor() {
        super('operational-data');
    }

    public async getOperationalData(activeFilters?: ActiveOperationalDataFilters): Promise<ApiResponse<PagedList<OperationalDataModel>>> {
        return this.operationalDataListService.getOperationalData(activeFilters);
    }

    public async getOperationalDataDoughnutStatus(activeFilters?: ActiveOperationalDataFilters): Promise<ApiResponse<ApiResponseList<OperationalDataDonutStatusCountDTO>>> {
        return this.operationalDataStatusCountService.getOperationalDataDoughnutStatus(activeFilters);
    }

    public async getOperationalDataEntry(unitId: number, date: string): Promise<ApiResponse<OperationalDataDetails>> {
        try {
            const response = (await this.api.get(undefined, `${unitId}/${date}`));
            if (response.status === 204) {
                throw new Error(`There is no data for operational data details ${unitId} ${date}`);
            }

            const detailsModel = mapper.map<OperationalDataDetailsDTO, OperationalDataDetails>(
                response.data.result, 'OperationalDataDetails', 'OperationalDataDetailsDTO'
            ) as OperationalDataDetails;

            return {
                message: response.data.message,
                statusCode: response.data.statusCode,
                result: detailsModel
            };
        }
        catch (err) {
            throw new Error(err as any);
        }
    }

    public async setOperationalDataEntry(unitId: number, reportMonth: string, model: OperationalDataDetails): Promise<ApiResponse<OperationalDataDetails>> {
        try {
            const editModel = mapper.map<OperationalDataDetails, OperationalDataDetailsEditDTO>(
                model, 'OperationalDataDetailsEditDTO', 'OperationalDataDetails'
            );
            editModel.machineSid = unitId;
            editModel.reportMonth = reportMonth;

            const response = (await this.api.put(undefined, editModel)).data as ApiResponse<OperationalDataDetailsDTO>;
            const updatedModel = mapper.map<OperationalDataDetailsDTO, OperationalDataDetails>(
                response.result, 'OperationalDataDetails', 'OperationalDataDetailsDTO'
            ) as OperationalDataDetails;

            return {
                message: response.message,
                statusCode: response.statusCode,
                result: updatedModel
            };
        }
        catch (err) {
            throw new Error(err as any);
        }
    }

    //TODO: Finalize!
    public async getOptions(): Promise<ApiResponse<any>> {
        try {
            return (await this.api.get(undefined)).data;
        }
        catch (err) {
            throw new Error(err as any);
        }
    }

    public async getStatus(unitId: number, date: string): Promise<ApiResponse<OperationalDataStatusDTO>> {
        return this.operationalDataStatusService.getStatus(unitId, date);
    }

    public async setStatus(model: OperationalDataStatusDTO): Promise<ApiResponse<OperationalDataStatusDTO>> {
        return this.operationalDataStatusService.setStatus(model);
    }

    public async deleteOperationalDataEntry(model: ThermalGenerationModel | MaterialModel): Promise<ApiResponse<OperationalDataDetailsDeleteDTO>> {
        const query = this.queryBuilder.buildQueryForOpDataDelete(model);
        try {
            return (await this.api.delete(undefined, `${query.urlParams}`, { data: query.body })).data;
        }
        catch (err) {
            throw new Error(err as any);
        }
    }

    public async getEnum(sid: number): Promise<ApiResponse<OperationalDataEnumDTO>> {
        return this.enumService.getEnum(sid);
    }

}
