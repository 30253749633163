import { ModeServiceBase } from '@/services/base-services';
import {
    ApiResponse,
    EventInfoModel,
} from '@/models';

export class ParallelEventService extends ModeServiceBase {
    constructor() {
        super('parallel-event-list');
    }

    /**
     * @name getParallelEvents
     * @description This method queries the backend for a list of events happening in parallel with a specific event
     * @param {number} eventId The id of the primary event
     * @param {string} UtcStartTime The timestamp of the event UTC Start Time
     * @param {string} UtcEndTime The timestamp of the event UTC End Time
     * @returns Events happening in parallel with the supplied eventId
     */
    public async getParallelEvents(eventId: number, UtcStartTime: string, UtcEndTime: string): Promise<ApiResponse<EventInfoModel[]>> {
        return (await this.api.get(`UtcStartTime=${UtcStartTime}&UtcEndTime=${UtcEndTime}`, `${eventId}`)).data;
    }
}
