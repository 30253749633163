import { render, staticRenderFns } from "./op-data-comand-buttons.vue?vue&type=template&id=58bc2036&scoped=true"
import script from "./op-data-comand-buttons.ts?vue&type=script&lang=js&external"
export * from "./op-data-comand-buttons.ts?vue&type=script&lang=js&external"
import style0 from "./op-data-comand-buttons.less?vue&type=style&index=0&id=58bc2036&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58bc2036",
  null
  
)

export default component.exports