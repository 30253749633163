import { render, staticRenderFns } from "./vgb-tab.vue?vue&type=template&id=23d8506f"
import script from "./vgb-tab.ts?vue&type=script&lang=js&external"
export * from "./vgb-tab.ts?vue&type=script&lang=js&external"
import style0 from "./vgb-tab.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports