import { Component, Vue, Prop, Model, Ref } from 'vue-property-decorator';

const NaNValidationRule = (value: number | undefined, propertyName: string): boolean => {
    const isNaN = Number.isNaN(value);
    if (isNaN)
        console.error(`CustomInput error - ${propertyName} prop validation failed - received NaN but expected Number`);

    return !isNaN;
}

@Component
export default class CustomInput extends Vue {
    private defaultStep = 1;
    @Ref() htmlInput!: HTMLInputElement;

    @Prop({ default: false }) required!: boolean;
    @Prop({ type: Number, validator: value => NaNValidationRule(value as any, 'min') }) min!: number | undefined;
    @Prop({ type: Number, validator: value => NaNValidationRule(value as any, 'max') }) max!: number | undefined;
    @Prop({ type: Number, validator: value => NaNValidationRule(value as any, 'precision') }) precision!: number | undefined;
    @Prop() inputClass!: any;
    @Prop({ default: false }) isDisabled!: boolean;

    @Model('change', { type: Number, validator: (value: number | undefined) => NaNValidationRule(value, 'customInputValue') }) readonly customInputValue!: number | undefined;

    get step(): number {
        return this.prepareStepFromPrecision();
    }
    private prepareStepFromPrecision(): number {
        if (this.precision === undefined)
            return this.defaultStep;

        let step = '0.';
        for (let i = 0; i < this.precision; i++)
            step += i === this.precision - 1 ? '1' : '0';

        return Number(step);
    }

    private edited = false;
    get inputValue(): number | undefined | '' {
        if (this.customInputValue === undefined)
            return this.customInputValue;

        return this.precision && !this.edited ? parseFloat(this.customInputValue.toFixed(this.precision)) : this.customInputValue;

    }
    set inputValue(value: number | undefined | '') {
        this.edited = true;
        this.$emit('change', value === '' ? undefined : value);
    }

    get dynamicClasses(): any {
        return {
            'pui-form-input-field--disabled': this.isDisabled,
            ...this.inputClass
        };
    }

    isValid(): boolean {
        return this.htmlInput.checkValidity();
    }
}