import Vue from 'vue'
import { ColumnDef, VendorOptions } from '../../components/event/event-table/contracts/table-data'
import EventTable from '@/components/event/event-table/event-table.vue'
import { EventBus } from '@/utils'
import { EventInfoFilterModel, SplitEventModel } from '@/models'
import { EventInfoTableModel } from '@/models/event-info'
import { eventInfoService } from '@/store/modules/event-management';
import { EventHeaderEnumerationsService } from '@/services/event-header-enumerations-service';
import moment from 'moment'
import store from '@/store'
import { LoadingPanel } from '@/store';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations'
import { Route } from 'vue-router'
import NotFoundPage from '@/components/not-found-page/not-found-page.vue'
import { TranslationMap } from '@/components/event/event-history/translationmap'
import { EventInfoStatusColor, EventInfoStatusSid, EventLifecycleColor } from '@/utils/evt-mgmt-utils'
import { ManualEventsUploader } from './ManualEventsUploader'
import { formatWithAutoLocale } from '@/utils/date-time-utils';

const InfoTableView = Vue.extend({
    name: 'InfoTableView',
    components: {
        EventTable,
        NotFoundPage
    },
    props: {},
    beforeRouteLeave(to: Route, from: Route, next: any) {
        if (this.$store.state.sidebar.renderingInProgress)
            return;

        next();
    },
    data(): {
        totalEvents: number;
        vendorOptions: VendorOptions;
        enumService: EventHeaderEnumerationsService;
        eventTableName: string;
        isLoading: boolean;
        filterValues: EventInfoFilterModel;
        pagination: {
            totalPages: number;
            rangeOfPages: number;
        };
        dateFormat: string;
        isUserRoleAssigned: boolean;
        manualUpload: ManualEventsUploader;
        canDisplayManualUploadLink: boolean;
        uploadItemName: string;
        } {
        return {
            totalEvents: 0,
            vendorOptions: {
                data: [],
                rowSelection: 'single',
                columnDefs: [],
                paginationPageSize: 20
            },
            enumService: new EventHeaderEnumerationsService(),
            eventTableName: 'InfoViewEventTable',
            isLoading: false,
            filterValues: {
                EventStart: undefined,
                EventEnd: undefined,
                areaSids: [],
                plantSids: [],
                machineSids: [],
                lifecycleStatus: [],
                eventInfoPlusStatus: [],
                page: 1,
                size: 20
            },
            pagination: {
                totalPages: 1,
                rangeOfPages: 3,
            },
            dateFormat: 'DD.MM.YYYY HH:mm',
            isUserRoleAssigned: false,
            manualUpload: new ManualEventsUploader(),
            canDisplayManualUploadLink: false,
            uploadItemName: ''
        }
    },
    created(): void {
        this.isUserRoleAssigned = (store.getters['userAccessManagement/getUserRole']);
        if (this.isUserRoleAssigned) {
            this.vendorOptions.columnDefs = this.defaultColumnDefs();
            EventBus.$on(EventBus.VIEWS.INFO.FILTER_CHANGED, (filters: EventInfoFilterModel) => {
                store.dispatch('eventManager/getDoughnutFilterItems', { obj: filters })
                    .then(() => this.onFilterChange(filters));
            })
            EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => {
                this.vendorOptions.columnDefs = this.defaultColumnDefs();
                this.onLanguageChanged()
            })
        }
    },
    async mounted(): Promise<void> {
        const userRole = store.getters['userAccessManagement/getUserRole'];
        this.canDisplayManualUploadLink = userRole === 'Admin' || userRole === 'CentralFunctionsEngineer';

        this.filterValues = JSON.parse(window.sessionStorage.getItem('eventInfoFilterState') || '{}');

        if (this.isUserRoleAssigned) {
            store.commit(OperationalDataMutationsNames.SetDoughnutLoadingPanel, true);
            store.commit('sidebar/SET_RENDERING_STATE', true);
            await this.onFilterChange(this.filterValues);
            await this.$store.dispatch('sidebar/setCurrentSidebar', {
                shouldShowSidebar: true,
                currentSidebarComponent: 'EventInfoFilter',
                sidebarComponentProperties: {
                    presetfilterValues: this.filterValues,
                    vendorOptions: this.vendorOptions
                },
            });
        }
    },
    computed: {
        currentPagination: {
            get(): number {
                return store.getters['eventManager/getEventFilter']?.page || 1;
            },
            set(_: number): void {
                // NO-OP
            }
        }
    },
    methods: {
        async onFileLoaded(event: any): Promise<void> {
            store.commit('loading');
            await this.manualUpload.uploadFile(event.target.files.item(0));
            store.commit('loading');
            this.uploadItemName = event.target.files.item(0).name;
            (this.$refs[this.manualUpload.htmlDomRefKey] as any).open();
        },
        async finalizeUpload(): Promise<void> {
            this.manualUpload.finalizeUpload();
            this.onCancel();
            await this.onFilterChange(this.filterValues);
        },
        onCancel():void {
            this.manualUpload.clearValidationResults();
            (this.$refs[this.manualUpload.htmlDomRefKey] as any).close();
        },
        onLanguageChanged(): void {
            this.vendorOptions.data.forEach((element, index) => {
                this.vendorOptions.data[index].col7 = [this.$t(TranslationMap.sid[element.col7[1]]), element.col7[1]]
                this.vendorOptions.data[index].col9 = [this.$t(TranslationMap.sid[element.col9[1]]), element.col9[1]]
            });

            this.manualUpload.refreshTranslations();
        },
        onPageChanged(currentPage: number): void {
            this.filterValues.page = currentPage;
            this.$store.dispatch('eventManager/setEventFilter', { obj: this.filterValues });
            window.sessionStorage.setItem('eventInfoFilterState', JSON.stringify(this.filterValues));
            this.onFilterChange(this.filterValues);
        },
        async onFilterChange(filters: EventInfoFilterModel): Promise<void> {
            let filteredEvents: EventInfoTableModel[];
            try {
                this.setLoading(true);
                this.filterValues = filters;
                filteredEvents = await this.getFilteredEvents(filters);
                if (filteredEvents) {
                    const numberOfParentEvents = filteredEvents.length
                    await filteredEvents.forEach(async (event, index) => {
                        const children = event.splitEventCollection || []
                        children.forEach((child: SplitEventModel) => {
                            filteredEvents.push(this.formatChildDataForTable(child, event))
                        });
                        if (index === numberOfParentEvents - 1) {
                            this.constructTable(filteredEvents);
                            this.setLoading(false);
                        }
                    });
                }
            } catch (e) {
                console.error('Error during change of filter:', e);
                this.setLoading(false);
            }
        },
        constructTable(filteredEvents: EventInfoTableModel[]): void {
            this.vendorOptions.data = [];
            const notAvailable = '-';

            filteredEvents.forEach((event: EventInfoTableModel) => {
                // create a string with a list of parallel events (parent or split event)
                // TODO: replace eventId by eventIdentifier to keep consistancy with REACT
                let parallelEvents = ''
                event.parallelEventDtoCollection?.forEach(element => {
                    element.splitEventId ? parallelEvents = parallelEvents + (element.eventIdentifier) + '.' + element.splitEventId + ', ' : parallelEvents = parallelEvents + (element.eventIdentifier) + ', '
                });
                parallelEvents = parallelEvents.slice(0, -2) // remove last comma
                this.vendorOptions.data.push({
                    'col1': (event.eventId) ? [event.eventId, event.eventIdentifier] : notAvailable,
                    'col2': (event.eventUnitName) ? event.eventUnitName : notAvailable,
                    'col3': (event.eventTitle != '') ? event.eventTitle : notAvailable,
                    'col4': (event.utcStartTime) ? formatWithAutoLocale({ datetime: event.utcStartTime, utc: true, format: 'L HH:mm'}) : notAvailable,
                    'col5': (event.utcEndTime) ? formatWithAutoLocale({ datetime: event.utcEndTime, utc: true, format: 'L HH:mm'}) : notAvailable,
                    'col6': (event.unAvailCapacity || event.unAvailCapacity == 0) ? Number(event.unAvailCapacity.toFixed(3)) : notAvailable,
                    'col7': (event.enumEventInfoStatusName != '') ? [this.$t(TranslationMap.sid[event.enumEventInfoStatusSid]), event.enumEventInfoStatusSid] : notAvailable,
                    'col8': (parallelEvents != '') ? parallelEvents : notAvailable,
                    'col9': (event.enumEventLifecycleStatusSid) ? [this.$t(TranslationMap.sid[event.enumEventLifecycleStatusSid]), event.enumEventLifecycleStatusSid] : notAvailable
                });
            });
        },
        async getFilteredEvents(filter: EventInfoFilterModel): Promise<EventInfoTableModel[]> {
            const res = (await eventInfoService.getAllEventInfos(filter)).result;
            this.pagination.totalPages = res.totalPages;
            this.totalEvents = res.totalCount;
            if (res.totalCount === 0) {
                this.pagination.totalPages = 1
                return [
                    {
                        'eventId': 0,
                        'eventIdentifier': '',
                        'eventUnitName': '',
                        'eventTitle': '',
                        'utcStartTime': '',
                        'utcEndTime': '',
                        'unAvailCapacity': 0,
                        'enumEventInfoStatusName': '',
                        'enumEventInfoStatusSid': 0,
                        'enumEventLifecycleStatusName': '',
                        'enumEventLifecycleStatusSid': 0,
                        'parallelEventDtoCollection': [],
                        'splitEventCollection': []
                    }
                ]
            }

            return res.items;
        },
        formatChildDataForTable(child: SplitEventModel, parent: EventInfoTableModel): EventInfoTableModel {
            /*
                transform the child event object to an EventInfoModel object
                note: only relevent data to show at the table is set,
                unused values that are required by the EventInfoModel are set to default values
            */
            /*
                TODO: check if child can have other data than parent event for
                - eventTitle
                - enumEventInfoStatusName
            */
            return ({
                'eventId': Number(child.parentId + '.' + child.splitEventId),
                'eventIdentifier': parent.eventIdentifier + '.' + child.splitEventId,
                'eventUnitName': parent.eventUnitName,
                'eventTitle': parent.eventTitle,
                'utcStartTime': child.utcStartTime,
                'utcEndTime': child.utcEndTime,
                'unAvailCapacity': child.unAvailCapacity,
                'enumEventInfoStatusName': parent.enumEventInfoStatusName,
                'enumEventInfoStatusSid': parent.enumEventInfoStatusSid,
                'enumEventLifecycleStatusName': parent.enumEventLifecycleStatusName,
                'enumEventLifecycleStatusSid': parent.enumEventLifecycleStatusSid,
                'parallelEventDtoCollection': [],
                'splitEventCollection': []
            })
        },
        setLoading(loading: boolean): void {
            this.isLoading = loading;
            store.commit('toggleLoadingPanelVisibility', LoadingPanel.EventInfoTable);
        },
        defaultColumnDefs(): Array<ColumnDef> {
            return [
                {
                    headerName: this.$t('info.id').toString(), field: 'col1', minWidth: 100, sort: 'desc', sortIndex: 1,
                    cellRenderer: function (params: any): string {
                        if (!params.value[1]) return '-';
                        if (params.value[1] && params.value[1].includes('.'))
                            return '<a href="#/eventinfo/' + params.value[0] + '" style="color: #ffa100" target="_self">' + params.value[1] + '</a>';
                        return '<a href="#/eventinfo/' + params.value[0] + '" target="_self">' + params.value[1] + '</a>';
                    },
                    cellStyle: function (params: any): any {
                        if (params.value[1] && params.value[1].includes('.'))

                            return { fontStyle: 'italic' };
                        return null;
                    }
                },
                { headerName: this.$t('unit').toString(), field: 'col2', minWidth: 100 },
                { headerName: this.$t('info.title').toString(), field: 'col3', minWidth: 100 },
                { headerName: this.$t('info.start').toString(), field: 'col4', minWidth: 100, comparator: (date1: string, date2: string): number => this.dateComparator(date1, date2), sort: 'desc', sortIndex: 0 },
                { headerName: this.$t('info.end').toString(), field: 'col5', minWidth: 100, comparator: (date1: string, date2: string): number => this.dateComparator(date1, date2) },
                { headerName: this.$t('info.unavailableCapacity').toString(), field: 'col6', minWidth: 100 },
                {
                    headerName: this.$t('info.status').toString(), field: 'col7', minWidth: 100,
                    cellRenderer: function (params: any): string {
                        return params.value[0];
                    },
                    cellStyle: function (params: any): any {
                        if (params.value)
                            return { fontWeight: 'bold', color: (EventInfoStatusColor as any)[EventInfoStatusSid[params.value[1]]] };
                        return null;
                    }
                },
                { headerName: this.$t('info.parallelEvent').toString(), field: 'col8', minWidth: 100 },
                {
                    headerName: this.$t('info.lifecycle').toString(), field: 'col9', minWidth: 100,
                    cellRenderer: function (params: any): string {
                        return params.value[0]
                    },
                    cellStyle: function (params: any): any {
                        if (params.value)
                            return { fontWeight: 'bold', color: (EventLifecycleColor as any)[params.value[1]] }
                        return null;
                    }
                },
            ]
        },
        dateComparator(date1: string, date2: string): number {
            if (date1 === null && date2 === null) {
                return 0;
            }
            if (date1 === null) {
                return -1;
            }
            if (date2 === null) {
                return 1;
            }
            return moment(date1, this.dateFormat).diff(moment(date2, this.dateFormat), 'minutes');
        }
    }
})

export default InfoTableView;
