<template>
    <div
        v-if="errorData !== undefined"
        style="height: 100%; display: flex; justify-content: center; align-items: center;"
    >
        <NotFoundPage
            :error-response="errorData.errorResponse"
            :page-reload-timeout="errorData.pageReloadTimeout"
            :button-label="errorData.buttonLabel"
            :button-link="errorData.buttonLink"
            :button-is-visible="errorData.buttonIsVisible"
        />
    </div>
    <div
        id="app"
        v-else-if="signedIn && loaded && arePermissionsSet"
    >
        <loader />
        <WaitingModal />
        <pui-application-layout>
            <template #header>
                <header-wrapper />
            </template>
            <template
                #left
                v-if="currentSidebar.shouldShowSidebar && !$route.meta.hideNav"
            >
                <nav style="height: 100%;">
                    <pui-side-panel
                        :open.sync="leftPanelIsOpen"
                        title=""
                        width="38rem"
                        id="globalLeftSidePanel"
                        style="z-index: 1;"
                        :floating-trigger="false"
                        trigger-position="right"
                    >
                        <filter-panel
                            :component="currentSidebar.currentSidebarComponent"
                            :component-properties="currentSidebar.sidebarComponentProperties"
                        />
                    </pui-side-panel>
                </nav>
            </template>
            <template #main>
                <main style="display: grid; height: 100%; place-items: center;">
                    <router-view :key="$route.fullPath" />
                </main>
            </template>
        </pui-application-layout>
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import HeaderWrapper from '@/components/header/header.vue';
import Snackbar from '@/components/snackbar/snackbar.vue';
import Loader from '@/components/loader/loader.vue';
import { Sidebar } from './store/modules/contracts/Sidebar';
import FilterPanel from './components/filter-panel/filter-panel.vue';
import SidebarMultiOption from './components/sidebar-multi-option/sidebar-multi-option.vue';
import WaitingModal from './components/waiting-modal/waiting-modal.vue';
import './main.less';
import { EventBus, LANGUAGES, LocalStorageHelper } from './utils';
import { UserService } from './services/user-service';
import NotFoundPage from '@/components/not-found-page/not-found-page.vue';
import store from '@/store';
import { ModeErrorModel } from '@/models';

@Component({
    name: 'app',
    components: {
        HeaderWrapper,
        snackbar: Snackbar,
        loader: Loader,
        SidebarMultiOption,
        WaitingModal,
        FilterPanel,
        NotFoundPage
    },
})
export default class App extends Vue {
    private signedIn = true;
    private leftPanelIsOpen = true;
    private loaded = false;
    private arePermissionsSet = false;

    get currentSidebar(): Sidebar {
        return store.getters['sidebar/getCurrentSidebar'];
    }

    private async created(): Promise<void> {
        await (this as any).$sdk?.$authenticator?.loadAuthModule();
        await (this as any).$sdk?.$authenticator?.attemptSsoSilent('loginRedirect');
        this.signedIn = await (this as any).$sdk?.$authenticator?.isAuthenticated() as boolean;
        if (!this.signedIn) {
            await this.$sdk.$authenticator?.login('loginRedirect');
        }

        await (this as any).$sdk.$http?.$generic.request.api.setBaseUrl(process.env.VUE_APP_API_BASE_URL);
        await (this as any).$sdk.$http?.$generic.request.api.setTokenCallback(async () => await (this as any).$sdk.$authenticator?.getToken(process.env.VUE_APP_MODE_SCOPE));

        EventBus.$on(EventBus.GLOBAL.NETWORK_ERROR, () => {
            store.commit('setErrorData', {
                errorResponse: this.$t('genericErrorMessageWithCounter').toString(),
                pageReloadTimeout: 45,
                buttonIsVisible: false
            });
        })

        if (this.signedIn) {
            const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);
            this.arePermissionsSet = await (this as any).$sdk?.$permissions?.setPermissions(useCaseId);

            try {
                this.loadLanguages();
                await this.setRolesAndPermissions();
                this.loaded = true;
            }
            catch {
                this.loaded = true;
                store.commit('setErrorData', {
                    errorResponse: this.$t('loginErrorMessage').toString(),
                    buttonLabel: '',
                    buttonLink: '',
                    buttonIsVisible: false
                });
            }
        }
    }

    get errorData(): ModeErrorModel | undefined {
        return store.state.errorData;
    }

    private async setRolesAndPermissions(): Promise<void>{
        const userService = new UserService();
        const profile = LocalStorageHelper.getProfile();
        await store.dispatch('userAccessManagement/setUserRoleAndPermissions', { service: userService, userId: profile.id })
        store.commit('userAccessManagement/SET_USER_ID', profile.id);
    }

    // Returns the language from languageOptions that corresponds to the language set in the user's browser.
    private determineDefaultLanguage(): string {
        LocalStorageHelper.getLanguageOptions().find((el: string) => {
            if (el === navigator.language) {
                return el;
            }
        });
        return LocalStorageHelper.getLanguageOptions()[0];
    }

    private loadLanguages(): void {
        LocalStorageHelper.setLanguageOptions(Object.keys(LANGUAGES));
        const selectedLanguage = LocalStorageHelper.getSelectedLanguage() ?? this.determineDefaultLanguage();

        if (selectedLanguage) {
            this.selectLanguage(selectedLanguage);
        }
    }

    private selectLanguage(selectedLanguage: string): void {
        LocalStorageHelper.setSelectedLanguage(selectedLanguage);
        this.$i18n.locale = LANGUAGES[selectedLanguage];
        EventBus.$emit(EventBus.GLOBAL.LANGUAGE_CHANGED);
    }
}
</script>

<style lang="less">
    div#app ul.pui-context-menu__content {
        z-index: 999999;
    }
</style>
