import Vue from 'vue';

const BreadcrumbItem = Vue.extend({
    name: 'BreadcrumbItem',
    props: {
        label: {
            type: String,
            required: true
        },
        href: {
            type: String,
            required: true
        },
        isFirst: {
            type: Boolean,
            required: false,
            default: false
        },
        isActive: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    methods: {
        onLinkClicked(): void {
            if (!this.isActive) {
                this.$emit('active-route-changed', {
                    href: this.href
                });
            }
        }
    }
});

export default BreadcrumbItem;
