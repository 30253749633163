import { EventInfoModel, SplitEventModel } from '@/models';
import store from '@/store';
import { eventInfoService } from '@/store/modules/event-management';
import { EventBus, LocalStorageHelper } from '@/utils';
import { EventInfoStatus, EventInfoStatusColor, EventInfoStatusSid, toLocalizedIsoString } from '@/utils/evt-mgmt-utils';
import moment from 'moment';
import Vue, { VueConstructor } from 'vue';
import EventSplit from '../event-split/event-split.vue';
import AdditionalInfoTab from './tabs/additional-info-tab/additional-info-tab.vue';
import EventInfoSystemsTab from './tabs/systems-tab/systems-tab.vue';
import EventInfoVgbTab from './tabs/vgb-tab/vgb-tab.vue';
import IndividualEventVgbTab from './tabs/individual-event/individual-event.vue';
import { SingleEvent } from './types';
import { VGBType } from '@/models/vgb-enum';
import { mapGetters } from 'vuex';
import { IncidentPenaltyName } from '@/models/event-info';
import { formatWithAutoLocale } from '@/utils/date-time-utils';

const MILLISECONDS_PER_MINUTE = 60000;

interface MyView {
    $pui: {
        toast: Function;
    };
}

const EventInfo = (Vue as VueConstructor<Vue & MyView>).extend({
    name: 'EventInfo',
    components: {
        EventInfoVgbTab,
        EventInfoSystemsTab,
        AdditionalInfoTab,
        EventSplit,
        IndividualEventVgbTab
    },
    props: {},
    data(): {
        routeId: string;
        eventIdentifier: string;
        tabs: string[];
        translateIndicator: boolean;
        isDominantEvent: boolean | undefined;
        tractionPower: number;
        eventInfo?: EventInfoModel;
        splitEvents?: SplitEventModel[];
        parallelEvents?: { items: [] };
        utcStartTime: string;
        utcEndTime: string;
        dateFormat: string;
        dataDateFormat: string;
        durationHours: number;
        unAvailEnergy: number;
        utcStartTimeForEventSplit: string;
        showStartTimeDatePicker: boolean;
        showEndTimeDatePicker: boolean;
        singleEvent?: SingleEvent;
        isDominantInfo: boolean;
        previousLocalStartTime: string;
        previousLocalEndTime: string;
        unAvailCapElement: HTMLElement | null;
        mainAndSubSystemTabRef: string;
        additionalInfoTabRef: string;
        individualEventTabRef: string;
        eventInfoVgbTabRef: string;
        submitDialogErrorMessage: string;
        eventInfoSnapshot: {
            isDominantEvent?: boolean;
            budgetYear?: number;
            tractionPower?: number;
            systemState?: {
                eventSapHierarchicalSystem?: string;
                eventSapMainSystem?: string;
                eventSapSubSystem?: string;
            };
            additionalInfoState?: {
                eventDescription?: string;
                isViaTrip?: boolean;
                preventativeMeasure?: string;
                reviewFollowUp?: string;
                rootCauseAnalysis?: string;
            };
            vgbState?: {
                enumVgbConditionBeforeSid?: number;
                enumVgbTimeframeSid?: number;
                enumVgbEventTypeSid?: number;
                enumVgbMainConsequenceSid?: number;
                enumVgbEventCauseSid?: number;
                vgbClassificationFlag?: {
                    text: any;
                    value: any;
                };
            };
            individualEventState?: {
                isAssetReliabilityRelevant: boolean;
                incidentPenalty?: number;
                incidentPenaltyName?: IncidentPenaltyName;
                incidentPenaltyThreshold?: number | IncidentPenaltyName.NotApplicable;
                mWhLossMWCapacityThreshold?: number;
            };
        };
        } {
        return {
            routeId: '',
            eventIdentifier: '',
            tabs: [],
            translateIndicator: true,
            isDominantEvent: false,
            tractionPower: 0,
            eventInfo: undefined,
            splitEvents: undefined,
            parallelEvents: undefined,
            utcStartTime: '',
            utcEndTime: '',
            dateFormat: 'L HH:mm',
            dataDateFormat: 'yyyy-MM-DDTHH:mm',
            durationHours: 0,
            unAvailEnergy: 0,
            utcStartTimeForEventSplit: '',
            showStartTimeDatePicker: false,
            showEndTimeDatePicker: false,
            singleEvent: {
                availableCapacity: undefined,
                unAvailCapacity: undefined,
                unAvailCapacityVModel: undefined,
                unAvailEnergy: undefined
            },
            isDominantInfo: false,
            previousLocalStartTime: '',
            previousLocalEndTime: '',
            unAvailCapElement: null,
            mainAndSubSystemTabRef: 'mainAndSubSystemTabRef',
            additionalInfoTabRef: 'additionalInfoTabRef',
            individualEventTabRef: 'individualEventTabRef',
            eventInfoVgbTabRef: 'eventInfoVgbTabRef',
            submitDialogErrorMessage: '',
            eventInfoSnapshot: {},
        }
    },
    watch: {
        splitEvents: {
            handler(newSplitEvents): void {
                this.$store.dispatch('eventManager/setCurrentSplitEvents', { obj: newSplitEvents });
            },
            deep: true
        },
        'isUnsavedChanges': function (): void {
            this.$store.commit('eventManager/SET_IS_UNSAVED_CHANGES', this.isUnsavedChanges);
        }
    },
    beforeDestroy() {
        this.$store.commit('eventManager/SET_INDIVIDUAL_EVENT_INFO', undefined);
        this.$store.dispatch('eventManager/setcurrentAdditionalInformationTab', {obj: undefined});
        if (!this.singleEvent) return;
        if (this.isUnsavedChanges) {
            this.$store.commit('eventManager/SET_SINGLE_PARENT_EVENT_PLACEHOLDER_CAP', {
                unAvailCap: this.singleEvent.unAvailCapacity
            });
            this.singleEvent.unAvailCapacityVModel = undefined;
        }
    },
    async mounted(): Promise<void> {
        this.unAvailCapElement = document.getElementById('singleEventUnAvailCapacity');
        this.unAvailCapElement?.addEventListener('focusout', () => this.onFocusoutHandler());
        this.createEventInfoSnapshot();
    },
    destroyed(): void {
        this.unAvailCapElement?.removeEventListener('focusout', () => this.onFocusoutHandler());
    },
    async created(): Promise<void> {
        this.routeId = this.$route.params.id;
        this.tabs = this.translateIndicatorTabHeaders();
        // eslint-disable-next-line sonarjs/no-duplicate-string
        this.eventInfo = this.$store.getters['eventManager/getSingleParentEvent']();
        this.splitEvents = this.$store.getters['eventManager/getAllChildEvents']();
        this.parallelEvents = this.$store.getters['eventManager/getCurrentParallelEvents']();
        this.eventInfo?.isTractionPower ? this.tractionPower = 1 : this.tractionPower = 0;

        if (this.isParentEvent === true) {
            this.useEventInfoAsCurrentState();
            this.eventIdentifier = this.eventInfo?.eventHeaderDto.eventIdentifier || '';
        }
        else {
            this.useSplitEventAsCurrentState();
            this.eventIdentifier = this.eventInfo?.eventHeaderDto.eventIdentifier + '.' + this.routeId.split('.')[1] || '';
        }
        this.setSingleEventValues();

        EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => {
            this.tabs = this.translateIndicatorTabHeaders();
            this.translateIndicator = !this.translateIndicator;
        });
    },
    methods: {
        toLocalizedIsoString: toLocalizedIsoString,
        useEventInfoAsCurrentState(): void {
            if (this.eventInfo) {
                this.utcStartTime = this.eventInfo.utcStartTime;
                this.utcEndTime = this.eventInfo.utcEndTime;
                this.durationHours = this.eventInfo.durationHours;
                this.unAvailEnergy = this.eventInfo.unAvailEnergy;
                this.isDominantEvent = this.eventInfo.isDominant;

                if (this.splitEvents && this.splitEvents.length > 0) {
                    const lastSplitEvent = this.splitEvents[this.splitEvents.length - 1];
                    this.utcStartTimeForEventSplit = lastSplitEvent.utcStartTime;
                }
                else {
                    this.utcStartTimeForEventSplit = this.utcStartTime;
                }

                store.commit('eventManager/SET_CURRENT_SYSTEMS_STATE', {
                    'eventSapHierarchicalSystem': this.eventInfo?.eventSapHierarchicalSystem,
                    'eventSapMainSystem': this.eventInfo?.eventSapMainSystem,
                    'eventSapSubSystem': this.eventInfo?.eventSapSubSystem
                });
            }
        },
        useSplitEventAsCurrentState(): void {
            const splitEventId = Number(this.$route.params.id.split('.')[1]);

            if (this.splitEvents && this.splitEvents.length) {
                const splitEvent = this.splitEvents.find((splitEvent: SplitEventModel) => splitEvent.splitEventId === splitEventId);
                if (splitEvent) {
                    this.utcStartTime = splitEvent.utcStartTime;
                    this.utcEndTime = splitEvent.utcEndTime;
                    this.durationHours = splitEvent.durationHours;
                    this.unAvailEnergy = splitEvent.unAvailEnergy || NaN;
                    this.isDominantEvent = this.splitEvents[Number(this.$route.params.id.split('.')[1]) - 1].isDominant;

                    store.commit('eventManager/SET_CURRENT_SYSTEMS_STATE', {
                        'eventSapHierarchicalSystem': splitEvent?.eventSapHierarchicalSystem,
                        'eventSapMainSystem': splitEvent?.eventSapMainSystem,
                        'eventSapSubSystem': splitEvent?.eventSapSubSystem
                    });
                }
            }
        },
        translateIndicatorTabHeaders(): string[] {
            if (this.isParentEvent) {
                return [
                    'VGB',
                    this.$t('mainAndSubSystem').toString().toUpperCase(),
                    this.$t('additionalInfo').toString().toUpperCase(),
                    this.$t('individualEvent').toString().toUpperCase(),
                ]
            }
            else {
                return [
                    'VGB',
                    this.$t('mainAndSubSystem').toString().toUpperCase(),
                    this.$t('individualEvent').toString().toUpperCase(),
                ]
            }
        },
        setNumberFormat(input: number): string {
            return (input || input === 0) ? Number(input.toFixed(3)).toString() : 'n/a';
        },
        setDateFormat(dateString: string): string {
            return formatWithAutoLocale({ utc: true, datetime: dateString, format: this.dateFormat });
        },
        setTractionPower(): void {
            if (this.eventInfo) {
                this.eventInfo.isTractionPower = Boolean(this.tractionPower);
            }
        },
        filterSessionStorage(passedValue: any): any {
            if (passedValue != undefined) {
                return Object.fromEntries(Object.entries(passedValue).map(([key, value]) => [key, (value === '' || value === undefined) ? null : value]));
            } else {
                return {}
            }
        },
        budgetYearToObject(): Record<string, any> {
            let budgetYear = {};
            if (this.eventInfo?.budgetYear != undefined || this.eventInfo?.budgetYear != 0) {
                budgetYear = { 'budgetYear': this.eventInfo?.budgetYear };
            } else {
                budgetYear = {};
            }
            return budgetYear;
        },
        /* eslint-disable sonarjs/cognitive-complexity */
        getMissingParentEventFormInputs(): string {
            let invalidFields: string[] = [];

            // VGB tab errors
            const vgbTabRef = (this.$refs[this.eventInfoVgbTabRef] as [typeof EventInfoVgbTab])[0];
            if (vgbTabRef) {
                if (!vgbTabRef.isEnumVgbConditionBeforeSidValid) {
                    invalidFields.push('missingStateBefore');
                }
                if (!vgbTabRef.isEnumVgbTimeframeSidValid) {
                    invalidFields.push('missingTimeLimits');
                }
                if (!vgbTabRef.isEnumVgbEventTypeSidValid) {
                    invalidFields.push('missingTypeOfEvent');
                }
                if (!vgbTabRef.isEnumVgbMainConsequenceSidValid) {
                    invalidFields.push('missingMainEffect');
                }
            }

            // Main & Sub-System tab errors
            const mainAndSubSystemTabRef = (this.$refs[this.mainAndSubSystemTabRef] as [typeof EventInfoSystemsTab])[0];
            if (mainAndSubSystemTabRef && !mainAndSubSystemTabRef.isFunctionalLocationValid) {
                invalidFields.push('missingFunctionalLocation');
            }

            // Additional info tab errors
            const additionalInfoTabRef = (this.$refs[this.additionalInfoTabRef] as [typeof AdditionalInfoTab])[0];
            if (additionalInfoTabRef && !additionalInfoTabRef.isEventDescriptionValid) {
                invalidFields.push('missingEventDescription');
            }

            // ARA information tab errors
            const araInformationTabRef = (this.$refs[this.individualEventTabRef] as [typeof IndividualEventVgbTab])[0];
            if (araInformationTabRef && !araInformationTabRef.isIncidentPenaltyValid) {
                invalidFields.push('missingIncidentPenalty');
            }

            // translating error keys
            invalidFields = invalidFields.map(key => this.$t(`vgbClassificationRequirements.${key}`).toString());

            if (invalidFields.length === 1) {
                // single error field message
                return this
                    .$t('vgbClassificationRequirements.singleFieldMissing', { field: invalidFields[0] })
                    .toString();
            } else if (invalidFields.length > 1) {
                // multiple error fields message
                return this
                    .$t('vgbClassificationRequirements.multipleFieldsMissing', {
                        fieldsList: [...invalidFields].slice(0, -1).join(', '),
                        lastField: invalidFields[invalidFields.length - 1],
                    })
                    .toString();
            }
            return '';
        },
        /* eslint-enable sonarjs/cognitive-complexity */
        async saveEvent(): Promise<void> {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            const vgbState = this.$store.getters['eventManager/getVgbTab'];
            // eslint-disable-next-line sonarjs/no-duplicate-string
            const systemState = this.$store.getters['eventManager/getSystemsTab'];
            // eslint-disable-next-line sonarjs/no-duplicate-string
            const additionalInfoState = this.$store.getters['eventManager/getCurrentAdditionalInformationTab'];
            // eslint-disable-next-line sonarjs/no-duplicate-string
            const individualEventState = this.$store.getters['eventManager/getIndividualEventInfo'];

            //Separate pillLabel value from rest of the Object
            const pillLabel = vgbState.vgbClassificationFlag.value;

            //Filter the sessionStorage-Objects for empty/undefined values and assign them to the changedEvent-Object
            const vgbFiltered = this.filterSessionStorage(vgbState);
            delete vgbFiltered.vgbClassificationFlag;
            const systemStateFiltered = this.filterSessionStorage(systemState);
            const additionalInfoFiltered = this.filterSessionStorage(additionalInfoState);
            const individualEventFiltered = this.filterSessionStorage(individualEventState);

            //get all SingleEventValues
            const singleEventValues = this.aggregateSingleEventValues();

            if (this.isParentEvent) {
                let changedEvent = {} as EventInfoModel;
                changedEvent = Object.assign(changedEvent,
                    vgbFiltered,
                    systemStateFiltered,
                    additionalInfoFiltered,
                    singleEventValues,
                    individualEventFiltered,
                    { 'isDominant': this.isDominantEvent },
                    { 'isTractionPower': Boolean(this.tractionPower) },
                    { 'eventId': Number(this.$route.params.id) },
                    { 'vgbClassificationFlag': pillLabel },
                    this.budgetYearToObject()
                );

                this.$store.dispatch('eventManager/updateParentEventObject', { getters: this.$store.getters, obj: changedEvent });
                const isApiCallSuccessful = await this.$store.dispatch('eventManager/saveParentEventObject', { getters: this.$store.getters });

                if(isApiCallSuccessful) {
                    this.popToastSuccess();
                    this.createEventInfoSnapshot(); // Refreshes the snapshot with the current state.
                }
                else
                    this.popToastError();
            }
            else {
                let changedEvent = {} as SplitEventModel;
                changedEvent = Object.assign(changedEvent,
                    vgbFiltered,
                    systemStateFiltered,
                    additionalInfoFiltered,
                    individualEventFiltered,
                    { 'isDominant': this.isDominantEvent },
                    { 'isTractionPower': Boolean(this.tractionPower) },
                    { 'parentId': Number(this.$route.params.id.split('.')[0]) },
                    { 'splitEventId': Number(this.$route.params.id.split('.')[1]) },
                    { 'utcStartTime': this.utcStartTime },
                    { 'utcEndTime': this.utcEndTime },
                    { 'vgbClassificationFlag': pillLabel },
                    this.budgetYearToObject()
                );
                try {
                    this.$store.dispatch('eventManager/updateChildEventObject', { getters: this.$store.getters, obj: changedEvent });
                    await this.$store.dispatch('eventManager/saveChildEventObject', { getters: this.$store.getters });
                    this.createEventInfoSnapshot();
                    this.popToastSuccess();
                } catch {
                    this.popToastError();
                }
            }
        },
        async deleteSplitEvents(): Promise<void> {
            (this.$refs['confirmDeletionModalRef'] as any).close();
            try {
                this.$store.dispatch('eventManager/deleteAllSplitEvents', {eventId: this.routeId})
                this.$emit('deleteBreadcrumb');
                this.splitEvents = [];
                this.popToastSuccess();
                this.$emit('updateChart');

            } catch {
                this.popToastError();
            }
        },

        // TODO - make .d.ts file for TS to recognize pebble imoprt
        popToastSuccess(): void {
            this.$pui.toast({
                title: this.$t('eventSubmittingModal.successTitle').toString(),
                copy: this.$t('eventSubmittingModal.successText').toString()
            })
        },
        popToastError(): void {
            this.$pui.toast({
                title: this.$t('eventSubmittingModal.errorTitle').toString(),
                copy: this.$t('eventSubmittingModal.errorText').toString()
            })
        },
        popToastPUTSplitError(): void {
            this.$pui.toast({
                title: this.$t('eventSplittingModal.outsideRangeErrorTitle').toString(),
                copy: this.$t('eventSplittingModal.outsideRangeErrorText').toString()
            })
        },
        openSubmitDialog(): void {
            if (this.isParentEvent) {
                this.submitDialogErrorMessage = this.getMissingParentEventFormInputs();
            } else {
                this.submitDialogErrorMessage = '';
            }
        },
        async saveEventChanges(): Promise<void> {
            if (this.isParentEvent) {
                const errorMessage = this.getMissingParentEventFormInputs();
                if (errorMessage) {
                    this.$pui.toast({
                        type: 'warning',
                        title: this.$t('vgbClassificationRequirements.title'),
                        copy: errorMessage,
                    })
                }
            }

            this.$store.commit('loading');
            if (this.checkValiditySaveEvent()) {
                await this.saveEvent();
                await this.updateSingleEventValues();
                this.$emit('updateChart');
                if (!this.singleEvent) return;
                this.singleEvent.unAvailCapacityVModel = undefined;
            }
            this.$store.commit('loading');
        },
        checkValiditySaveEvent(): boolean {
            //Validation for Parent Events
            if (this.isParentEvent) {
                if (this.isValueOutsideREACTRange) {
                    this.popToastError();
                    return false
                }
                else {
                    return true
                }
            }
            //Validation for Child Events
            else {
                return true
            }
        },
        /**
         * After saving: get the updated parentEvent, update the chart and get the new singleEvent Values
         */
        async updateSingleEventValues(): Promise<void> {
            if (this.isParentEvent) {
                this.eventInfo = this.$store.getters['eventManager/getSingleParentEvent']();
                this.setSingleEventValues();
            }
        },
        async performEventSplit(event: { splitDate: string }): Promise<void> {
            if (this.eventInfo) {
                try {
                    (await eventInfoService.postSplitEvent(this.eventInfo.eventId, event.splitDate));
                    const splitEventId = (this.splitEvents) ? this.splitEvents?.length + 1 : 1;
                    this.$router.push({ path: `/eventinfo/${this.eventInfo.eventId}.${splitEventId}` });
                } catch (error) {
                    console.log(error);
                }
            }
        },
        /**
         * method fired, when submit button is clicked
         * updates the status @param newStatusSid in the parent event and saves it to the backend
         */
        async saveAndChangeStatus(newStatusSid: number): Promise<void> {
            // TODO: Save message in PopToast - change accordingly
            this.$store.commit('loading');
            const getter = this.$store.getters;
            const oldEnumStatusSid = this.$store.getters['eventManager/getSingleParentEventStatusSid'];

            try{
                await this.$store.dispatch('eventManager/changeStatus', { getters: getter, newEnumSid: newStatusSid });
                await this.saveEvent();
                this.$store.commit('eventManager/RESET_STATUS');
            } catch (e) {
                console.log('Error submitting changes.', e)
            }

            this.$store.commit('loading');
            this.eventInfo = this.$store.getters['eventManager/getSingleParentEvent']();
        },
        aggregateSingleEventValues(): Record<string, any> {
            const changedEvent = {};
            Object.assign(changedEvent,
                { 'unAvailCapacity': this.singleEventUnAvailableCapacity },
                { 'availCapacity': Number(this.singleEventAvailableCapacity) },
                { 'unAvailEnergy': Number(this.singleEventUnavailableEnergy) }
            )
            return changedEvent;
        },
        async setSingleEventValues(): Promise<void> {
            if (!this.eventInfo || !this.singleEvent) return;
            if (isNaN(this.getCurrentSplitEventId)) {
                this.setSingleEventUnavailableCapacity();
                this.setSingleEventAvailableCapacity();
                this.setSingleEventUnavailableEnergy();
            } else {
                this.getCurrentSplitEventValues();
            }
        },
        setSingleEventUnavailableCapacity(): void {
            if (!this.singleEvent) return;
            this.singleEvent.unAvailCapacity = this.eventInfo?.unAvailCapacity;
        },
        setSingleEventAvailableCapacity(): void {
            if (!this.singleEvent || !this.eventInfo) return;
            if (this.eventInfo.isManualCapacityChange) {
                this.singleEvent.availableCapacity = (Number(this.eventInfo.nominalCapacityUnit) - Number(this.singleEvent.unAvailCapacity));
            } else {
                this.singleEvent.availableCapacity = this.eventInfo.availCapacity;
            }
        },
        setSingleEventUnavailableEnergy(): void {
            if (!this.singleEvent || !this.eventInfo) return;
            if (this.eventInfo.isManualCapacityChange) {
                this.singleEvent.unAvailEnergy = (Number(this.eventInfo?.unAvailCapacity) * Number(this.eventInfo?.durationHours));
            } else {
                this.singleEvent.unAvailEnergy = this.eventInfo.unAvailEnergy;
            }
        },
        getCurrentSplitEventValues(): void {
            this.splitEvents?.forEach((splitEvent: SplitEventModel) => {
                if (splitEvent.splitEventId === this.getCurrentSplitEventId) {
                    this.setSplitEventUnavailableCapacity(splitEvent);
                    this.setSplitEventAvailableCapacity(splitEvent);
                    this.setSplitEventUnavailableEnergy(splitEvent);
                }
            })
        },
        setSplitEventUnavailableCapacity(splitEvent: SplitEventModel): void {
            if (!this.singleEvent) return;
            this.singleEvent.unAvailCapacity = splitEvent.unAvailCapacity;
        },
        setSplitEventAvailableCapacity(splitEvent: SplitEventModel): void {
            if (!this.singleEvent) return;
            this.singleEvent.availableCapacity = splitEvent.availCapacity;
        },
        setSplitEventUnavailableEnergy(splitEvent: SplitEventModel): void {
            if (!this.singleEvent) return;
            this.singleEvent.unAvailEnergy = splitEvent.unAvailEnergy;
        },
        onFocusoutHandler(): void {
            this.$store.commit('eventManager/SET_SINGLE_PARENT_EVENT_PLACEHOLDER_CAP', {
                unAvailCap: this.singleEvent?.unAvailCapacityVModel
            });
            this.$emit('updateChart');
        },
        clickOnStartTime(): void {
            this.previousLocalStartTime = this.localStartTime;
            this.showStartTimeDatePicker = true;
        },
        resetLocalStartTime(): void {
            this.localStartTime = this.previousLocalStartTime;
            this.showStartTimeDatePicker = false;
        },
        applyLocalStartTime(): void {
            const selectableRange = this.getLocalStartTimeRange;
            if (selectableRange.start && selectableRange.end) {
                const selectableStart = new Date(selectableRange.start);
                const selectableEnd = new Date(selectableRange.end);
                const selectedStart = new Date(this.localStartTime);
                if (selectedStart <= selectableStart || selectedStart >= selectableEnd) {
                    this.localStartTime = this.previousLocalStartTime;
                }
            }
            this.showStartTimeDatePicker = false;
        },
        clickOnEndTime(): void {
            this.previousLocalEndTime = this.localEndTime;
            this.showEndTimeDatePicker = true;
        },
        resetLocalEndTime(): void {
            this.localEndTime = this.previousLocalEndTime;
            this.showEndTimeDatePicker = false;
        },
        applyLocalEndTime(): void {
            const selectableRange = this.getLocalEndTimeRange;
            if (selectableRange.start && selectableRange.end) {
                const selectableStart = new Date(selectableRange.start);
                const selectableEnd = new Date(selectableRange.end);
                const selectedEnd = new Date(this.localEndTime);
                if (selectedEnd <= selectableStart || selectedEnd >= selectableEnd) {
                    this.localEndTime = this.previousLocalEndTime;
                }
            }
            this.showEndTimeDatePicker = false;
        },
        async createEventInfoSnapshot(): Promise<void> {
            const systemState = await this.$store.getters['eventManager/getSystemsTab'];
            const additionalInfoState = await this.$store.getters['eventManager/getCurrentAdditionalInformationTab'];
            const vgbState = await this.$store.getters['eventManager/getVgbTab'];
            const individualEventState = await this.$store.getters['eventManager/getIndividualEventInfo'];
            this.eventInfoSnapshot = {
                isDominantEvent: this.isDominantEvent,
                budgetYear: this.eventInfo?.budgetYear,
                tractionPower: this.tractionPower,
                systemState: {
                    eventSapHierarchicalSystem: systemState?.eventSapHierarchicalSystem,
                    eventSapMainSystem: systemState?.eventSapMainSystem,
                    eventSapSubSystem: systemState?.eventSapSubSystem
                },
                additionalInfoState: {
                    eventDescription: additionalInfoState?.eventDescription,
                    isViaTrip: additionalInfoState?.isViaTrip,
                    preventativeMeasure: additionalInfoState?.preventativeMeasure,
                    reviewFollowUp: additionalInfoState?.reviewFollowUp,
                    rootCauseAnalysis: additionalInfoState?.rootCauseAnalysis
                },
                vgbState: {
                    enumVgbConditionBeforeSid: vgbState?.enumVgbConditionBeforeSid,
                    enumVgbTimeframeSid: vgbState?.enumVgbTimeframeSid,
                    enumVgbEventTypeSid: vgbState?.enumVgbEventTypeSid,
                    enumVgbMainConsequenceSid: vgbState?.enumVgbMainConsequenceSid,
                    enumVgbEventCauseSid: vgbState?.enumVgbEventCauseSid,
                    vgbClassificationFlag: {
                        text: vgbState?.vgbClassificationFlag?.text?? '-',
                        value: vgbState?.vgbClassificationFlag?.value?? '-'
                    }
                },
                individualEventState: {
                    isAssetReliabilityRelevant: individualEventState?.isAssetReliabilityRelevant,
                    incidentPenalty: individualEventState?.incidentPenalty,
                    incidentPenaltyName: individualEventState?.incidentPenaltyName,
                    incidentPenaltyThreshold: individualEventState?.incidentPenaltyThreshold,
                    mWhLossMWCapacityThreshold: individualEventState?.mWhLossMWCapacityThreshold
                }
            };
        },
        closeSubmitDialog(): void {
            (this.$refs['submitModalRef'] as any).close();
        },

        confirmSubmit(): void {
            this.saveAndChangeStatus(743);
            this.closeSubmitDialog();
        }
    },
    computed: {
        ...mapGetters({
            currentVgtTabData: 'eventManager/getVgbTab',
            currentAraInformationTabData: 'eventManager/getIndividualEventInfo',
        }),
        // local times to be shown in date picker. Changes apply to utc times
        localStartTime: {
            get: function (): any {
                return moment.utc(this.utcStartTime).local().format(this.dataDateFormat);
            },
            set: function (newValue: any): void {
                this.utcStartTime = moment(newValue).utc().format(this.dataDateFormat) + ':00';
            }
        },
        localEndTime: {
            get: function (): any {
                return moment.utc(this.utcEndTime).local().format(this.dataDateFormat);
            },
            set: function (newValue: any): void {
                this.utcEndTime = moment(newValue).utc().format(this.dataDateFormat) + ':00';
            }
        },
        singleEventUnAvailableCapacity(): number {
            if (this.singleEvent?.unAvailCapacityVModel !== undefined && this.singleEvent?.unAvailCapacityVModel !== '') {
                return (Number(this.singleEvent.unAvailCapacityVModel))
            } else {
                return (Number(this.singleEvent?.unAvailCapacity))
            }
        },
        singleEventAvailableCapacity(): number {
            if (isNaN(this.getCurrentSplitEventId)) {
                if (this.singleEvent?.unAvailCapacityVModel !== undefined && this.singleEvent?.unAvailCapacityVModel !== '') {
                    return (Number(this.eventInfo?.nominalCapacityUnit) - Number(this.singleEvent?.unAvailCapacityVModel));
                } else {
                    if (this.eventInfo?.isManualCapacityChange) {
                        return (Number(this.eventInfo?.nominalCapacityUnit) - Number(this.eventInfo?.unAvailCapacity));
                    } else {
                        return (Number(this.eventInfo?.availCapacity));
                    }
                }
            } else {
                return (this.splitEvents as SplitEventModel[])[this.getCurrentSplitEventId - 1].availCapacity;
            }
        },
        singleEventUnavailableEnergy(): number {
            if (isNaN(this.getCurrentSplitEventId)) {
                if (this.singleEvent?.unAvailCapacityVModel !== undefined && this.singleEvent.unAvailCapacityVModel !== '') {
                    return (Number(this.singleEvent.unAvailCapacityVModel) * Number(this.eventInfo?.durationHours));
                } else {
                    if (this.eventInfo?.isManualCapacityChange) {
                        return (Number(this.singleEvent?.unAvailCapacity) * Number(this.eventInfo.durationHours));
                    } else {
                        return (this.eventInfo as EventInfoModel).unAvailEnergy;
                    }
                }
            } else {
                return Number((this.splitEvents as SplitEventModel[])[this.getCurrentSplitEventId - 1].unAvailEnergy);
            }
        },
        isParentEvent(): boolean {
            return !this.routeId.includes('.');
        },
        isFirstSplit(): boolean {
            return (this.routeId.includes('.') && this.$route.params.id.split('.')[1] === '1');
        },
        isLastSplit(): boolean {
            return (this.routeId.includes('.') && Number(this.$route.params.id.split('.')[1]) === this.splitEvents?.length);
        },
        getLocalStartTimeRange(): { start?: string; end?: string } {
            if (this.splitEvents && this.splitEvents.length) {
                const index = Number(this.$route.params.id.split('.')[1]) - 1; // The index of the current split event.
                return {
                    start: moment.utc(this.splitEvents[index - 1].utcStartTime).local().add(1, 'minutes').format(this.dataDateFormat), // The start time of the previous split event.
                    end: moment.utc(this.splitEvents[index].utcEndTime).local().format(this.dataDateFormat) // The end time of the current split event.
                };
            }
            return {
                start: this.eventInfo?.utcStartTime,
                end: this.eventInfo?.utcEndTime
            };
        },
        getLocalEndTimeRange(): { start?: string; end?: string } {
            if (this.splitEvents && this.splitEvents.length) {
                const index = Number(this.$route.params.id.split('.')[1]); // The index of the next split event.
                return {
                    start: moment.utc(this.splitEvents[index - 1].utcStartTime).local().format(this.dataDateFormat), // The start time of the current split event.
                    end: moment.utc(this.splitEvents[index].utcEndTime).local().subtract(1, 'minutes').format(this.dataDateFormat) // The end time of the next split event.
                };
            }
            return {
                start: this.eventInfo?.utcStartTime,
                end: this.eventInfo?.utcEndTime
            };
        },
        getNextSplitEventId(): number {
            return (this.splitEvents != undefined) ? this.splitEvents?.length + 1 : 1;
        },
        getCurrentSplitEventId(): number {
            return Number(this.$route.params.id.split('.')[1]);
        },
        eventStatus(): string[] {
            const temp = this.$store.state.eventManager.singleParentEvent.enumEventInfoStatusSid
            const label = this.$t((EventInfoStatus as any)[EventInfoStatusSid[temp]]);
            const color = (EventInfoStatusColor as any)[EventInfoStatusSid[temp]];
            return ([label, color])
        },
        // calculate years for budget year selection (start date +- 3 years)
        yearSelection(): any[] {
            const years = [];
            if (this.eventInfo && this.eventInfo.utcStartTime) {
                const startYear = Number(this.eventInfo.utcStartTime.slice(0, 4)) - 3;
                const endYear = Number(this.eventInfo.utcStartTime.slice(0, 4)) + 3;
                for (let i = startYear; i <= endYear; i++) {
                    years.push({
                        label: i.toString(),
                        // secondaryLabel: 'Year in which the Planned Unavailability was budgeted for. Applicable only for Planned Unavailability Budgeted during MTP',
                        value: i,
                    })
                }
                years.push({label: '-', value: null}); // Option to delete selcted budget year value.
            }
            return (years)
        },
        isDisplaySaveButton(): boolean {
            return (this.eventInfo && this.eventInfo.enumEventInfoStatusSid) ?
                this.$store.getters['userAccessManagement/useButtonSave'](this.eventInfo.enumEventInfoStatusSid) : false;
        },
        isDisplaySubmitButton(): boolean {
            return (this.eventInfo && this.eventInfo.enumEventInfoStatusSid) ?
                this.$store.getters['userAccessManagement/useButtonSubmit'](this.eventInfo.enumEventInfoStatusSid) : false;
        },
        isDisplaySplitEventButton(): boolean {
            return (this.eventInfo && this.eventInfo.enumEventInfoStatusSid) ?
                this.$store.getters['userAccessManagement/useButtonSplit'](this.eventInfo.enumEventInfoStatusSid) : false;
        },
        isDisplayFinalButton(): boolean {
            return (this.eventInfo && this.eventInfo.enumEventInfoStatusSid) ?
                this.$store.getters['userAccessManagement/useButtonFinal'](this.eventInfo.enumEventInfoStatusSid) : false;
        },
        isDisplayReOpenButton(): boolean {
            return (this.eventInfo && this.eventInfo.enumEventInfoStatusSid) ?
                this.$store.getters['userAccessManagement/useButtonReOpen'](this.eventInfo.enumEventInfoStatusSid) : false;
        },
        isDisplayDeleteSplitButton(): boolean {
            if(this.splitEvents && this.splitEvents.length !== 0){
                return (this.eventInfo) ?
                    this.$store.getters['userAccessManagement/useButtonDeleteSplit'](this.eventInfo.enumEventInfoStatusSid) : false;
            }
            else{
                return false;
            }
        },
        isDisplayRequestInfoButton(): boolean {
            return (this.eventInfo && this.eventInfo.enumEventInfoStatusSid) ?
                this.$store.getters['userAccessManagement/useButtonRequestInfo'](this.eventInfo.enumEventInfoStatusSid) : false;
        },
        isValueOutsideREACTRange(): string {
            let msg = '';
            if (this.singleEvent?.unAvailCapacityVModel !== undefined && +this.singleEvent?.unAvailCapacityVModel < 0) {
                msg = this.$t('unavailnegativeErrorMessage').toString();
            } else if (
                this.singleEvent?.unAvailCapacityVModel !== undefined &&
                this.eventInfo?.eventHeaderDto.unAvailCapacityUnit !== undefined &&
                +this.singleEvent?.unAvailCapacityVModel > +this.eventInfo?.eventHeaderDto.unAvailCapacityUnit
            ) {
                msg = this.$t('unavailhigherErrorMessage').toString();
            }
            return msg;
        },
        isSingleEventDisabled(): boolean {
            return ((this.childEventsAreAvailable || !this.parallelEventsAreAvailable) || !this.isParentEvent);
        },
        isUnsavedChanges(): boolean {
            if (this.isParentEvent && this.hasAdditionalInfoStateChanges) return true; // Only the parent event has an additional tab.
            return (
                this.hasEventInfoStateChanges ||
                this.hasSystemStateChanges ||
                this.hasVgbStateChanges ||
                this.hasIndividualEventStateChanges
            );
        },
        hasIndividualEventStateChanges(): boolean {
            const individualEventState = this.$store.getters['eventManager/getIndividualEventInfo'];
            return (
                individualEventState?.isAssetReliabilityRelevant !== this.eventInfoSnapshot.individualEventState?.isAssetReliabilityRelevant ||
                individualEventState?.incidentPenalty !== this.eventInfoSnapshot.individualEventState?.incidentPenalty
            );
        },
        hasEventInfoStateChanges(): boolean {
            return (
                this.singleEvent?.unAvailCapacityVModel !== undefined &&
                this.singleEvent?.unAvailCapacityVModel !== '' &&
                this.singleEvent?.unAvailCapacityVModel !== (this.eventInfo?.unAvailCapacity)?.toString() ||
                this.eventInfoSnapshot.budgetYear !== this.eventInfo?.budgetYear ||
                this.eventInfoSnapshot.tractionPower !== this.tractionPower ||
                this.eventInfoSnapshot.isDominantEvent !== this.isDominantEvent
            );
        },
        hasSystemStateChanges(): boolean {
            const systemState = this.$store.getters['eventManager/getSystemsTab'];
            if (systemState)
                return (
                    this.eventInfoSnapshot.systemState?.eventSapHierarchicalSystem !== systemState.eventSapHierarchicalSystem ||
                    this.eventInfoSnapshot.systemState?.eventSapMainSystem !== systemState.eventSapMainSystem ||
                    this.eventInfoSnapshot.systemState?.eventSapSubSystem !== systemState.eventSapSubSystem
                );
            return false;
        },
        hasAdditionalInfoStateChanges(): boolean {
            const additionalInfoState = this.$store.getters['eventManager/getCurrentAdditionalInformationTab'];
            if (additionalInfoState)
                return (
                    !(this.eventInfoSnapshot.additionalInfoState?.eventDescription === undefined && additionalInfoState.eventDescription === '') && this.eventInfoSnapshot.additionalInfoState?.eventDescription !== additionalInfoState.eventDescription ||
                    !(this.eventInfoSnapshot.additionalInfoState?.isViaTrip === undefined && additionalInfoState.isViaTrip === false) && this.eventInfoSnapshot.additionalInfoState?.isViaTrip !== additionalInfoState.isViaTrip ||
                    !(this.eventInfoSnapshot.additionalInfoState?.preventativeMeasure === undefined && additionalInfoState.preventativeMeasure === '') && this.eventInfoSnapshot.additionalInfoState?.preventativeMeasure !== additionalInfoState.preventativeMeasure ||
                    !(this.eventInfoSnapshot.additionalInfoState?.reviewFollowUp === undefined && additionalInfoState.reviewFollowUp === '') && this.eventInfoSnapshot.additionalInfoState?.reviewFollowUp !== additionalInfoState.reviewFollowUp ||
                    !(this.eventInfoSnapshot.additionalInfoState?.rootCauseAnalysis === undefined && additionalInfoState.rootCauseAnalysis === '') && this.eventInfoSnapshot.additionalInfoState?.rootCauseAnalysis !== additionalInfoState.rootCauseAnalysis
                );
            return false;
        },
        hasVgbStateChanges(): boolean {
            const vgbState = this.$store.getters['eventManager/getVgbTab'];
            if (vgbState)
                return (
                    this.eventInfoSnapshot.vgbState?.enumVgbConditionBeforeSid !== vgbState.enumVgbConditionBeforeSid ||
                    this.eventInfoSnapshot.vgbState?.enumVgbTimeframeSid !== vgbState.enumVgbTimeframeSid ||
                    !(vgbState.enumVgbEventTypeSid === 0 && this.eventInfoSnapshot.vgbState?.enumVgbEventTypeSid === undefined) && this.eventInfoSnapshot.vgbState?.enumVgbEventTypeSid !== vgbState.enumVgbEventTypeSid ||
                    !(vgbState.enumVgbMainConsequenceSid === 0 && this.eventInfoSnapshot.vgbState?.enumVgbMainConsequenceSid === undefined) && this.eventInfoSnapshot.vgbState?.enumVgbMainConsequenceSid !== vgbState.enumVgbMainConsequenceSid ||
                    !(vgbState.enumVgbEventCauseSid === 0 && this.eventInfoSnapshot.vgbState?.enumVgbEventCauseSid === undefined) && this.eventInfoSnapshot.vgbState?.enumVgbEventCauseSid !== vgbState.enumVgbEventCauseSid ||
                    this.eventInfoSnapshot.vgbState?.vgbClassificationFlag?.text !== vgbState.vgbClassificationFlag.text ||
                    this.eventInfoSnapshot.vgbState?.vgbClassificationFlag?.value !== vgbState.vgbClassificationFlag.value
                );
            return false;
        },
        hasparllelunavailCheck(): boolean {
            
            const parallelEvents = this.$store.getters['eventManager/getCurrentParallelEvents']();
            if (parallelEvents && parallelEvents.items.length > 0) {
                let sumOfParallelEvents = 0;
                parallelEvents.items.forEach((elementsas: any) => {
                    if (elementsas.unAvailCapacity !== undefined && elementsas.unAvailCapacity !== '') {
                        sumOfParallelEvents += Number(elementsas.unAvailCapacity);
                    }

                });
                if (typeof this.eventInfo?.unAvailCapacity !== 'undefined' && this.eventInfo?.unAvailCapacity !== null) {
                    sumOfParallelEvents += Number(this.eventInfo?.unAvailCapacity);
                }
                if ((Number(this.eventInfo?.nominalCapacityUnit) < sumOfParallelEvents)) {

                    return true;
                }

            }
            return false;
            
            
        },
        childEventsAreAvailable(): boolean {
            return (this.splitEvents && this.splitEvents?.length > 0) ? true : false;
        },
        parallelEventsAreAvailable(): boolean {
            return (this.parallelEvents && this.parallelEvents?.items.length > 0) ? true : false;
        },
        isDominantEnabled(): boolean {
            return (this.parallelEventsAreAvailable && !this.splitEvents?.length) || (this.parallelEventsAreAvailable && this.childEventsAreAvailable && !isNaN(Number(this.$route.params.id.split('.')[1])));
        },
        isReadOnly(): boolean {
            return (this.eventInfo && this.eventInfo.enumEventInfoStatusSid) ?
                !this.$store.getters['userAccessManagement/useButtonSave'](this.eventInfo.enumEventInfoStatusSid) : true;
        },
        isNotResultPUB(): boolean {
            const vgbState = this.$store.getters['eventManager/getVgbTab'];
            return (vgbState) ? vgbState.enumVgbTimeframeSid !== 830 : false;
        },
        hasRequiredFields(): boolean {
            if (!this.currentVgtTabData || !this.isParentEvent) return false;
            const classificationsWithMandatoryFlags = [VGBType.BU.toString(), VGBType.OE.toString(), '-'];
            return classificationsWithMandatoryFlags.includes(this.currentVgtTabData.vgbClassificationFlag.value);
        }
    }
})

export default EventInfo;
