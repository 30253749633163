import { ChpModel, ComponentsModel, MaterialModel, OperationalDataEnumModel, ThermalGenerationModel, UnavailabilityModel } from '@/models/operational-data/operational-data-details';
import { EnumItemDTO, OperationalDataUnitChpDto, OperationalDataUnitComponentsDto, OperationalDataUnitMaterialDTO, OperationalDataUnitThermalDTO, OperationalDataUnitUnavailabilityDTO } from '@/models/operational-data/operational-data-DTO';
import { mapFrom, Mapper } from '@automapper/core';

export const mapTabs = (mapper: Mapper): void => {
    mapper.createMap<ThermalGenerationModel, OperationalDataUnitThermalDTO>('ThermalGenerationModel', 'OperationalDataUnitThermalDTO');
    mapper.createMap<OperationalDataUnitThermalDTO, ThermalGenerationModel>('OperationalDataUnitThermalDTO', 'ThermalGenerationModel');

    mapper.createMap<MaterialModel, OperationalDataUnitMaterialDTO>('MaterialModel', 'OperationalDataUnitMaterialDTO')
    mapper.createMap<OperationalDataUnitMaterialDTO, MaterialModel>('OperationalDataUnitMaterialDTO', 'MaterialModel')

    mapper.createMap<UnavailabilityModel, OperationalDataUnitUnavailabilityDTO>('UnavailabilityModel', 'OperationalDataUnitUnavailabilityDTO')
    mapper.createMap<OperationalDataUnitUnavailabilityDTO, UnavailabilityModel>('OperationalDataUnitUnavailabilityDTO', 'UnavailabilityModel')
        .forMember(d => d.valuePct, mapFrom(s => s.valuePct * 100))

    mapper.createMap<ComponentsModel, OperationalDataUnitComponentsDto>('ComponentsModel', 'OperationalDataUnitComponentsDto');
    mapper.createMap<OperationalDataUnitComponentsDto, ComponentsModel>('OperationalDataUnitComponentsDto', 'ComponentsModel');

    mapper.createMap<ChpModel, OperationalDataUnitChpDto>('ChpModel', 'OperationalDataUnitChpDto')
        .forMember(d => d.chpNetHeatGen, mapFrom(s => s.Heat.General))
        .forMember(d => d.chpNetHeatGen, mapFrom(s => s.Heat.General))
        .forMember(d => d.netHeatGenOverall, mapFrom(s => s.Heat.GeneralOverall))
        .forMember(d => d.internalHeatConsumption, mapFrom(s => s.Heat.InternalConsumption))
        .forMember(d => d.chpFuelHeat, mapFrom(s => s.Heat.Fuel))
        .forMember(d => d.adjustedFuelHeat, mapFrom(s => s.Heat.AdjustedFuel))
        .forMember(d => d.primaryEnergySavings, mapFrom(s => s.PrimaryEnergySavings))
        .forMember(d => d.chpNetPowerGen, mapFrom(s => s.Power.General))
        .forMember(d => d.netPowerGenOverall, mapFrom(s => s.Power.GeneralOverall))
        .forMember(d => d.rectifiedNetPowerGenOverall, mapFrom(s => s.Power.RectifiedGeneralOverall))
        .forMember(d => d.netPowerGenFedGrid, mapFrom(s => s.Power.GenFedGrid))

    mapper.createMap<OperationalDataUnitChpDto, ChpModel>('OperationalDataUnitChpDto', 'ChpModel')
        .forMember(d => d.Heat.General, mapFrom(s => s.chpNetHeatGen))
        .forMember(d => d.Heat.GeneralOverall, mapFrom(s => s.netHeatGenOverall))
        .forMember(d => d.Heat.InternalConsumption, mapFrom(s => s.internalHeatConsumption))
        .forMember(d => d.Heat.Fuel, mapFrom(s => s.chpFuelHeat))
        .forMember(d => d.Heat.AdjustedFuel, mapFrom(s => s.adjustedFuelHeat))
        .forMember(d => d.PrimaryEnergySavings, mapFrom(s => s.primaryEnergySavings))
        .forMember(d => d.Power.General, mapFrom(s => s.chpNetPowerGen))
        .forMember(d => d.Power.GeneralOverall, mapFrom(s => s.netPowerGenOverall))
        .forMember(d => d.Power.RectifiedGeneralOverall, mapFrom(s => s.rectifiedNetPowerGenOverall))
        .forMember(d => d.Power.GenFedGrid, mapFrom(s => s.netPowerGenFedGrid))


    mapper.createMap<EnumItemDTO, OperationalDataEnumModel>('EnumItemDTO', 'OperationalDataEnumModel')
        .forMember(d => d.label, mapFrom(s => s.name))
        .forMember(d => d.value, mapFrom(s => s.sid));
}
