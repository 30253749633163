import BaseCustomInputValidation from '@/components/utils/base-custom-input-validation/base-custom-input-validation';
import { TabsValidationUpdateModel } from '@/models/operational-data/operational-data';
import { ComponentsModel } from '@/models/operational-data/operational-data-details';
import store from '@/store';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
import { cloneDeep } from 'lodash';
import { Component } from 'vue-property-decorator';
import { CustomInputValidationState } from '../../op-data-comand-buttons/custom-inputs-validator';
import ComponentsBlock from './components-block.vue';

@Component({
    components: {
        ComponentsBlock
    }
})
export default class ComponentsTab extends BaseCustomInputValidation {
    data: ComponentsModel[] = [];
    gasTurbines: ComponentsModel[] = [];
    steamTurbines: ComponentsModel[] = [];
    boilers: ComponentsModel[] = [];

    created(): void {
        const temp = cloneDeep(store.state.operationalData.activeComponentsModels)
        this.data = (temp ? temp : [])

        this.gasTurbines = this.data.filter(sourceBlock => sourceBlock.sourceBlockName.includes('GT'));
        this.steamTurbines = this.data.filter(sourceBlock => sourceBlock.sourceBlockName.includes('ST'));
        this.boilers = this.data.filter(sourceBlock => sourceBlock.sourceBlockName.includes('BO'));
        this.onValidationStateChange = this.onCustomInputsValidationStateChange;
        this.$nextTick(() => this.validateCustomInputs(this));
    }

    onCustomInputsValidationStateChange(inputs: CustomInputValidationState[]): void {
        const tabValidationUpdateModel: TabsValidationUpdateModel = {
            key: 'isComponentTabValid',
            isValid: inputs.every(i => i.isValid)
        }

        store.commit(OperationalDataMutationsNames.SetTabValidationState, tabValidationUpdateModel);
    }
}
