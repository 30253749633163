import { Component, Prop, Vue } from 'vue-property-decorator';
import EventInfoFilter from '../event/event-info-filter/event-info-filter.vue';
import OpDataFilter from '../operational-data/op-data-filter/op-data-filter.vue';

@Component({
    components:{
        EventInfoFilter,
        OpDataFilter
    }
})
export default class FilterPanel extends Vue {
    @Prop({required:true}) component!: string;
    @Prop() componentProperties!: any;
}