import { Mapper, MappingProfile} from '@automapper/core';
import { createMetadataMap } from '@automapper/pojos';
import { DonutStatusCount } from '../../../models/operational-data/operational-data';
import { OperationalDataDonutStatusCountDTO } from '../../../models/operational-data/operational-data-DTO';

const mapOpDataDonutStatus = (mapper: Mapper): void => {
    mapper.createMap<OperationalDataDonutStatusCountDTO, DonutStatusCount>('OperationalDataDonutStatusCountDTO', 'DonutStatusCount')
};

const createMetadataForDonutStatus = (): void => {
    createMetadataMap<DonutStatusCount>('DonutStatusCount', {
        reportStatusSid: Number,
        recordCount: Number,
    });
    createMetadataMap<OperationalDataDonutStatusCountDTO>('OperationalDataDonutStatusCountDTO', {
        reportStatusSid: Number,
        recordCount: Number,
    })
};

export const opDataStatusesProfile: MappingProfile = (mapper) => {
    createMetadataForDonutStatus();
    mapOpDataDonutStatus(mapper);
};
