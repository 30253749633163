import { ModeServiceBase } from '@/services/base-services';
import {
    ApiResponse,
    ApiResponseList,
    MainSystem
} from '@/models';
import { EventInfoQueryBuilder } from './helpers/event-info-query-builder';

export class MainSystemsService extends ModeServiceBase {
    private queryBuilder = new EventInfoQueryBuilder();

    constructor() {
        super('main-systems');
    }

    /**
     * @name getMainSystems
     * @description This method queries from the backend a list of main systems.
     * @param {number} machineId  The id of the unit.
     * @param {string} functionalLocation The functional location.
     * @returns A List of main systems.
     */
    public async getMainSystems(machineId: number, functionalLocation: string): Promise<ApiResponse<ApiResponseList<MainSystem>>> {
        const queryString = this.queryBuilder.buildQueryForEventInfoList({ unitId: machineId, functionalLocation });
        return (await this.api.get(undefined, queryString.urlParams)).data;
    }
}
