import { ModeServiceBase } from '@/services/base-services';
import {
    ApiResponse,
    ApiResponseList,
    EventInfoModel,
    SplitEventModel
} from '@/models';

class SplitEventListService extends ModeServiceBase {
    constructor() {
        super('split-event-list');
    }

    /**
     * @name getSplitEvents
     * @description This method queries from the backend a list of split events.
     * @param {number} eventId  The id of the parent event.
     * @returns The event splits.
     */
    public async getSplitEvents(eventId: number): Promise<ApiResponse<ApiResponseList<SplitEventModel>>> {
        return (await this.api.get(undefined, `${eventId}`)).data;
    }
}

export class SplitEventService extends ModeServiceBase {
    private readonly splitEventListService = new SplitEventListService();

    constructor() {
        super('split-event');
    }

    /**
     * @name postSplitEvent
     * @description This method orders the backend to perform a split of an event.
     * @param {number} eventId  The id of the event for which a split has to be performed.
     * @param {string[]} splitDates The intermediate dates for the splitted events.
     * @returns The new event info which contains the splitted events.
     */
    public async postSplitEvent(eventId: number, splitDate: string): Promise<ApiResponse<EventInfoModel>> {
        return (await this.api.post(undefined, { parentId: eventId, utcSplitTime: splitDate })).data;
    }

    /**
     * @name getSplitEvents
     * @description This method queries from the backend a list of split events.
     * @param {number} eventId  The id of the parent event.
     * @returns The event splits.
     */
    public async getSplitEvents(eventId: number): Promise<ApiResponse<ApiResponseList<SplitEventModel>>> {
        return this.splitEventListService.getSplitEvents(eventId);
    }

    public async getSplitEventById(parentId: number, childId: number): Promise<ApiResponse<SplitEventModel>> {
        return (await this.api.get(undefined, `${parentId}/${childId}`)).data;
    }

    public async putSplitEvent(requestbody: SplitEventModel): Promise<ApiResponse<SplitEventModel>> {
        return (await this.api.put(undefined, requestbody)).data;
    }

    public async deleteAllSplitEvents(eventId: number): Promise<ApiResponseList<SplitEventModel>> {
        return (await this.api.delete(`${eventId}`)).data;
    }
}
