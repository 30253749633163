import store from '@/store';
import { OperationalDataActionsNames } from '@/store/modules/operational-data/operational-data-actions';
import { OperationalDataGettersNames } from '@/store/modules/operational-data/operational-data-getters';
import { EventBus } from '@/utils';
import { DoughnutFilter, DoughnutItem } from '@/utils/doughnut-filter-utils/doughnut-filter';
import { OperationalDataStatusColor } from '@/utils/mode-statuses-colors';
import { cloneDeep } from 'lodash';
import OpDataContext from '../op-data-filter';

export class OpDataDoughnutHelper {
    static doughnutChartElementId = 'doughnutOpsDataChart';

    private opDataContext!: OpDataContext;

    constructor(opDataContext: OpDataContext) {
        this.opDataContext = opDataContext;
        this.initDoughnutFilter();
        EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => {
            this.doughnutFilter?.setTitle(this.title);
            this.refreshItems()
        });
    }

    doughnutFilter: DoughnutFilter | null = null
    statuses: DoughnutItem[] = [];

    private get title(): string {
        return this.opDataContext.$t('doughnutTitle.eventInfoText') as string;
    }

    refreshItems(): void {
        this.statuses = this.prepareStatusesForDoughnutFilter();
        this.doughnutFilter?.setItems(this.statuses);
    }

    initDoughnutFilter(): void {
        this.statuses = this.prepareStatusesForDoughnutFilter();
        this.doughnutFilter = new DoughnutFilter(
            this.statuses,
            OpDataDoughnutHelper.doughnutChartElementId,
            this.title
        );

        this.doughnutFilter.chart.on('click', (params: any) => {
            this.updateStatusFilters(params.data);
        });
    }

    private prepareStatusesForDoughnutFilter(): DoughnutItem[] {
        const statuses: DoughnutItem[] = [];
        store.state.operationalData.opDataDoughnutData.forEach(item => {
            statuses.push({
                id: item.reportStatusSid,
                value: item.recordCount,
                name: this.opDataContext.$t('operationalData.filters.statuses.' + item.reportStatusSid) as string,
                itemStyle: { color: OperationalDataStatusColor.get(item.reportStatusSid) as any }
            });
        })

        return statuses;
    }

    private updateStatusFilters(doughnutItem: DoughnutItem): void {
        const opDataContext = this.opDataContext;
        const statusId = doughnutItem.id;
        if(!statusId)
            return;

        if (!opDataContext.activeFilters.statuses?.includes(statusId))
            opDataContext.activeFilters.statuses?.push(statusId);
        else {
            const statusIndex = opDataContext.activeFilters.statuses.findIndex(item => item === doughnutItem.id);
            opDataContext.activeFilters.statuses?.splice(statusIndex, 1);
        }
    }

}
