import { AutomapperMetadata } from '@/mappers/mapper';
import { ChpHeat, ChpModel, ChpPower, ComponentsModel, MaterialModel, OperationalDataEnumModel, ThermalGenerationModel, UnavailabilityModel } from '@/models/operational-data/operational-data-details';
import { BaseOpDataTabModelDTO, EnumItemDTO, OpDataEntryIdentifierDTO, OperationalDataUnitChpDto, OperationalDataUnitComponentsDto, OperationalDataUnitMaterialDTO, OperationalDataUnitThermalDTO, OperationalDataUnitUnavailabilityDTO } from '@/models/operational-data/operational-data-DTO';
import { createMetadataMap } from '@automapper/pojos';

export const createMetadataForTabs = (): void => {
    const opDataIndentifierDtoMetadata: AutomapperMetadata<OpDataEntryIdentifierDTO> = {
        machineSid: Number,
        reportMonth: String
    }
    createMetadataMap<OpDataEntryIdentifierDTO>('OpDataEntryIdentifierDTO', opDataIndentifierDtoMetadata);

    const baseOpDataModelDtoMetadata : AutomapperMetadata<BaseOpDataTabModelDTO> = {
        ...opDataIndentifierDtoMetadata,
        sourceBlockCounter: Number
    }
    createMetadataMap<BaseOpDataTabModelDTO>('BaseOpDataTabModelDTO', baseOpDataModelDtoMetadata);

    const unavailabilityDtoMetadata: AutomapperMetadata<OperationalDataUnitUnavailabilityDTO> = {
        ...opDataIndentifierDtoMetadata,
        unavailabilityType: String,
        valueMwh: Number,
        valuePct: Number,
    }
    createMetadataMap<OperationalDataUnitUnavailabilityDTO>('OperationalDataUnitUnavailabilityDTO', unavailabilityDtoMetadata);
    createMetadataMap<UnavailabilityModel>('UnavailabilityModel', 'OperationalDataUnitUnavailabilityDTO');

    const thermalDtoMetadata : AutomapperMetadata<OperationalDataUnitThermalDTO> = {
        ...baseOpDataModelDtoMetadata,
        sourceBlockNameSid: Number,
        grossHeatGenerationVolume: Number,
        netHeatGenerationVolume: Number,
        powerFactor: Number,
        electricEquivalentVolume: Number,
    }
    createMetadataMap<OperationalDataUnitThermalDTO>('OperationalDataUnitThermalDTO', thermalDtoMetadata);
    createMetadataMap<ThermalGenerationModel>('ThermalGenerationModel', 'OperationalDataUnitThermalDTO');

    const materialDtoMetadata : AutomapperMetadata<OperationalDataUnitMaterialDTO> = {
        ...baseOpDataModelDtoMetadata,
        sourceBlockNameSid: Number,
        stock: Number,
        additions: Number,
        disposal: Number,
        materialType: String,
    }
    createMetadataMap<OperationalDataUnitMaterialDTO>('OperationalDataUnitMaterialDTO', materialDtoMetadata);
    createMetadataMap<MaterialModel>('MaterialModel', 'OperationalDataUnitMaterialDTO');

    const componetsDtoMetadata: AutomapperMetadata<OperationalDataUnitComponentsDto> = {
        ...baseOpDataModelDtoMetadata,
        sourceBlockName: String,
        grossCapacity: Number,
        netCapacity: Number,
        grossGenVolumeMwh: Number,
        netGenVolumeMwh: Number,
        operationHours: Number,
        generatorOpHours: Number,
        hotStarts: Number,
        warmStarts: Number,
        coldStarts: Number,
        goodStarts: Number,
        failedStarts: Number,
        equivalentOpHours: Number,
    };
    createMetadataMap<OperationalDataUnitComponentsDto>('OperationalDataUnitComponentsDto', componetsDtoMetadata);
    createMetadataMap<ComponentsModel>('ComponentsModel', 'OperationalDataUnitComponentsDto');

    const chpHeatMetadata: AutomapperMetadata<ChpHeat> = {
        General: Number,
        GeneralOverall: Number,
        InternalConsumption: Number,
        Fuel: Number,
        AdjustedFuel: Number,
    }
    createMetadataMap<ChpHeat>('ChpHeat', chpHeatMetadata);
    
    const chpPowerMetadata: AutomapperMetadata<ChpPower> = {
        General: Number,
        GeneralOverall: Number,
        RectifiedGeneralOverall: Number,
        GenFedGrid: Number,
    }
    createMetadataMap<ChpPower>('ChpPower', chpPowerMetadata);

    const chpModelMetadata: AutomapperMetadata<ChpModel> = {
        ...opDataIndentifierDtoMetadata,
        Heat: 'ChpHeat',
        PrimaryEnergySavings: Number,
        Power: 'ChpPower'
    }
    createMetadataMap<ChpModel>('ChpModel', chpModelMetadata);
    
    const chpModelDtoMetadata: AutomapperMetadata<OperationalDataUnitChpDto> = {
        ...opDataIndentifierDtoMetadata,
        chpNetHeatGen: Number,
        netHeatGenOverall: Number,
        internalHeatConsumption: Number,
        chpFuelHeat: Number,
        adjustedFuelHeat: Number,
        primaryEnergySavings: Number,
        chpNetPowerGen: Number,
        netPowerGenOverall: Number,
        rectifiedNetPowerGenOverall: Number,
        netPowerGenFedGrid: Number,
    }
    createMetadataMap<OperationalDataUnitChpDto>('OperationalDataUnitChpDto', chpModelDtoMetadata);

    const enumItemDtoMetadata : AutomapperMetadata<Partial<EnumItemDTO>> = {
        sid: Number,
        name: String
    }
    createMetadataMap<EnumItemDTO>('EnumItemDTO', enumItemDtoMetadata);

    const operationalDataEnumMetadata : AutomapperMetadata<OperationalDataEnumModel> = {
        label: String,
        value: Number
    }
    createMetadataMap<OperationalDataEnumModel>('OperationalDataEnumModel', operationalDataEnumMetadata);
}