import { ModeServiceBase } from '@/services/base-services';
import {
    ApiResponse,
    ApiResponseList,
    SubSystem
} from '@/models';
import { EventInfoQueryBuilder } from './helpers/event-info-query-builder';

export class SubSystemsService extends ModeServiceBase {
    private queryBuilder = new EventInfoQueryBuilder();
    constructor() {
        super('sub-systems');
    }

    /**
     * @name getSubSystems
     * @description This method queries from the backend a list of sub systems.
     * @param {number} machineId  The id of the unit.
     * @param {string} functionalLocation The functional location.
     * @param {string} mainSystemId  The id of the main system.
     * @returns A List of sub systems.
     */
    public async getSubSystems(machineId: number, functionalLocation: string, mainSystemId: string): Promise<ApiResponse<ApiResponseList<SubSystem>>> {
        const queryString = this.queryBuilder.buildQueryForEventInfoList({ unitId: machineId, functionalLocation, mainSystemId });
        return (await this.api.get(undefined, queryString.urlParams)).data;
    }
}
