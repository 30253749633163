import * as echarts from 'echarts';
import { EChartsOption, LegendComponentOption, SeriesOption } from 'echarts';
import { DefaultDoughnutOptions } from './default-doughnut-filter-options';

export interface DoughnutItem {
    id?: number;
    value: number;
    name: string;
    itemStyle: { color: string };
}

export class DoughnutFilter {
    private items: DoughnutItem[] = [];
    private renderElementId!: string;
    private options: EChartsOption = DefaultDoughnutOptions;

    constructor(items: DoughnutItem[], renderElementId: string, title: string) {
        this.items = items;
        this.renderElementId = renderElementId;
        this.updateOptions(title);
        this.initChart();
    }

    chart!: echarts.ECharts;

    setTitle(title: string): void {
        this.createLabelFormatter(title);
        this.chart.setOption(this.options);
    }

    setItems(items: DoughnutItem[]): void {
        (this.options.series as SeriesOption).data = this.items = items;
        this.chart.setOption(this.options);
    }

    private updateOptions(title: string): void {
        if (title)
            this.createLabelFormatter(title);

        (this.options.series as SeriesOption).data = this.items;
        (this.options.legend as LegendComponentOption).formatter = this.legendFormatter.bind(this);
    }

    private createLabelFormatter(title: string): void {
        (this.options.series as any).label.formatter = (): string => {
            return `${title}`;
        };
    }

    private initChart(): void {
        const renderElement = document.getElementById(this.renderElementId);
        if (!renderElement)
            throw `Cannot find element ${this.renderElementId} on the page.`;

        this.chart = echarts.init(renderElement);
        this.chart.setOption(this.options);
    }

    private legendFormatter(name: string): string {
        const statusEntry = this.items.find(item => item.name === name);
        return (statusEntry ? `${this.appendZeroToNumber(statusEntry.value)} ` : '') + name;
    }

    private appendZeroToNumber = (number: number): string => number < 10 ? '0' + number : number.toString();


}