import store, { StateWithModules, LoadingPanel } from '@/store';
import { OperationalDataActionsNames } from '@/store/modules/operational-data/operational-data-actions';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ActiveOperationalDataFilters } from './utils/ActiveOperationalDataFilters';
import { FiltersOptionsProvider } from './utils/FiltersOptionsProvider';
import { OpDataDoughnutHelper } from './utils/OpDataDoughnutHelper';
import { PuiMultiselectOpenCloseEventEmitter } from './utils/PuiMultiselectOpenCloseEventEmitter';
import { cloneDeep } from 'lodash';
import { OperationalDataGettersNames } from '@/store/modules/operational-data/operational-data-getters';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
import { OperationalDataException } from '@/utils/exceptions/OperationalDataException';
import { OperationalDataFiltersOptions } from '@/models/operational-data/operational-data';

@Component
export default class OpDataFilter extends Vue {
    activeFilters: ActiveOperationalDataFilters = new ActiveOperationalDataFilters();
    filtersOptionsProvider: FiltersOptionsProvider | null = null;
    unwatchTableDataChange!: Function;
    unwatchFilteredTableDataChange!: Function;
    doughnutHelper: OpDataDoughnutHelper | undefined = undefined;
    doughnutChartElementId = OpDataDoughnutHelper.doughnutChartElementId;
    startCalendar = false;
    endCalendar = false;
    statusesDropdownRefreshKey = 0;
    multiselectEventEmitter = new PuiMultiselectOpenCloseEventEmitter();
    apiRequestsLock = false;
    multiselectIds = new Map<string, string>([
        ['country', 'op-data-country-multiselect'],
        ['site', 'op-data-site-multiselect'],
        ['unit', 'op-data-unit-multiselect'],
        ['status', 'op-data-status-multiselect']
    ]);
    countryOptionsRefreshKey = false;

    @Watch('startCalendar')
    onStartCalendarVisibilityChange(isVisible: boolean):void {
        if(isVisible && this.endCalendar)
            this.endCalendar = false;
    }

    @Watch('endCalendar')
    onEndCalendarVisibilityChange(isVisible: boolean):void {
        if(isVisible && this.startCalendar)
            this.startCalendar = false;
    }

    get isLoading(): boolean {
        return store.state.operationalData.doughnutLoadingPanel;
    }

    get filtersOptions(): OperationalDataFiltersOptions {
        const filtersOptions = cloneDeep(store.state.operationalData.filtersOptions);
        if (!filtersOptions)
            throw new OperationalDataException('filtersOptions not existing in the store but is expected to be there');

        return filtersOptions;
    }

    isDateReseted = false;

    onStartDateReset(): void {
        this.activeFilters.resetDateFilterToPreviousValue('startDate');
        this.startCalendar = false;
        this.isDateReseted = true;
    }

    onEndDateReset(): void {
        this.activeFilters.resetDateFilterToPreviousValue('endDate');
        this.endCalendar = false;
        this.isDateReseted = true;
    }

    onDatePickerFilterChange(): void {
        if (!this.activeFilters.isAnyChange())
            return;

        this.$nextTick(() => {
            if (this.isDateReseted) {
                this.isDateReseted = false;
                return;
            }

            this.onFilterChange();
        });
    }

    onFilterChange(): void {
        if (this.apiRequestsLock)
            return;


        this.activeFilters.formatDatesFilters();
        this.activeFilters.updateDependentSelections(this.filtersOptions);
        this.filtersOptionsProvider?.updateOptionsOnFilterChange(this.activeFilters);
        this.activeFilters.createSnapshot();
        store.commit(OperationalDataMutationsNames.SetActiveFilters, this.activeFilters);
        this.sendRequestsOnFilterChange();
    }

    sendRequestsOnFilterChange(): void {
        store.dispatch(OperationalDataActionsNames.OnFilterChange, this.activeFilters.activeFilters);
        store.dispatch(OperationalDataActionsNames.LoadOperationalDataDoughnutStatus, this.activeFilters.activeFilters)
            .then(() => this.onDoughnutStatusesLoaded());
    }

    private onDoughnutStatusesLoaded(): void {
        if (!this.doughnutHelper) {
            console.error(new OperationalDataException('Can not refresh doughnut and statuses because doughnutHelper is not existing'))
            return;
        }

        this.doughnutHelper.refreshItems();
        this.filtersOptionsProvider?.setStatusesFromDoughnutItems(this.doughnutHelper.statuses)
    }

    mounted(): void {
        if (!store.state.operationalData.tableData.length)
            this.watchTableInit();
        else {
            this.watchFilteredTableDataChange();
            this.initSidebarWithData();
        }
    }

    beforeDestroy(): void {
        if (this.unwatchFilteredTableDataChange)
            this.unwatchFilteredTableDataChange();
    }

    private watchTableInit(): void {
        this.unwatchTableDataChange = store.watch((state: StateWithModules) => state.operationalData.tableData, () => {
            this.initSidebarWithData();
            this.watchFilteredTableDataChange();
            this.unwatchTableDataChange();
        });
    }

    private watchFilteredTableDataChange(): void {
        this.unwatchFilteredTableDataChange = store.watch(
            (state: StateWithModules) =>
                state.operationalData.filteredTableData,
            () => this.doughnutHelper?.refreshItems()
        );
    }

    private async initSidebarWithData(): Promise<void> {
        if (!store.state.operationalData.filtersOptions)
            await store.dispatch(OperationalDataActionsNames.LoadAvailableFiltersOptions);

        if (!store.state.operationalData.opDataDoughnutData.length)
            await store.dispatch(OperationalDataActionsNames.LoadOperationalDataDoughnutStatus);

        this.filtersOptionsProvider = new FiltersOptionsProvider(this, this.filtersOptions);
        this.doughnutHelper = new OpDataDoughnutHelper(this)
        this.filtersOptionsProvider.setStatusesFromDoughnutItems(this.doughnutHelper.statuses);
        this.$nextTick(() => this.setupMultiselectObservers());
        const filtersInStore = cloneDeep(store.getters[OperationalDataGettersNames.GetActiveFilters]) as ActiveOperationalDataFilters | null;
        if (filtersInStore)
            this.activeFilters.activeFilters = filtersInStore.activeFilters;
    }

    private setupMultiselectObservers(): void {
        this.multiselectIds.forEach(id => {
            this.multiselectEventEmitter.subscribe(id);
            this.multiselectEventEmitter.onOpen(id, () => (this.apiRequestsLock = true));
            this.multiselectEventEmitter.onClose(id, () => {
                this.apiRequestsLock = false;
                if (this.activeFilters.isAnyChange())
                    this.onFilterChange();
            });
        });
    }

    onClearFilters(): void {
        this.apiRequestsLock = true;
        this.activeFilters = new ActiveOperationalDataFilters();
        this.filtersOptionsProvider = new FiltersOptionsProvider(this, this.filtersOptions);
        this.$nextTick(() => {
            this.apiRequestsLock = false;
            this.onFilterChange();
        })
    }

    get isTableLoading(): boolean {
        return store.getters.isLoadingPanelVisible(LoadingPanel.OperationalDataTable);
    }

    get isFilterEmpty(): boolean {
        if (!this.activeFilters.startDate &&
            !this.activeFilters.endDate &&
            this.activeFilters.countries.length === 0 &&
            this.activeFilters.sites.length === 0 &&
            this.activeFilters.units?.length === 0 &&
            this.activeFilters.statuses?.length === 0
        ) return true;
        return false;
    }
}
