import { MaterialModel, OperationalDataEnumModel } from '@/models/operational-data/operational-data-details';
import store from '@/store';
import { OperationalDataGettersNames } from '@/store/modules/operational-data/operational-data-getters';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
import { cloneDeep } from 'lodash';
import { Component, Prop, Watch } from 'vue-property-decorator';
import CustomInput from '@/components/utils/custom-input/custom-input.vue';
import { OperationalDataActionsNames } from '@/store/modules/operational-data/operational-data-actions';
import { SourceBlockNamesValidator } from '../tabs-utils/SourceBlockNamesValidator';
import { CustomInputValidationState } from '../../op-data-comand-buttons/custom-inputs-validator';
import { TabsValidationUpdateModel } from '@/models/operational-data/operational-data';
import BaseCustomInputValidation from '@/components/utils/base-custom-input-validation/base-custom-input-validation';

export interface BuildingBlockTabValidation {
    allSourceBlockNamesValid: boolean;
    allBlockCustomInputsValid: boolean;
}
export interface MaterialBuildingBlockModel {
    sourceBlockCounter: number | undefined;
    sourceBlockNameSid: number | undefined;
    stock: number | undefined;
    additions: number | undefined;
    disposal: number | undefined;
}
@Component({
    components: {
        CustomInput
    },
})

export default class MaterialsTab extends BaseCustomInputValidation {
    @Prop({ required: true }) materialType!: string;

    readonly maxBuildingBlocksCount = 10;
    precision = 3;
    min = 0;
    data: MaterialBuildingBlockModel[] = [];
    flagMaterials = false;
    rowText: string[] = []
    sourceBlockNamesValidator!: SourceBlockNamesValidator;
    options: OperationalDataEnumModel[] = [];
    buildingBlocksValidationState: BuildingBlockTabValidation = {
        allSourceBlockNamesValid: true,
        allBlockCustomInputsValid: true
    }

    @Watch('buildingBlocksValidationState', { deep: true })
    onBuildingBlockInputsValidationStateChange(validationState: BuildingBlockTabValidation): void {
        const tabValidationUpdateModel: TabsValidationUpdateModel = {
            key: this.materialType === 'MA' ? 'isMaterialTabValid' : 'isWasteMaterialTabValid',
            isValid: validationState.allSourceBlockNamesValid && validationState.allBlockCustomInputsValid
        }

        store.commit(OperationalDataMutationsNames.SetTabValidationState, tabValidationUpdateModel);
    }

    created(): void {
        this.getMaterialOptions();
        {
            this.onValidationStateChange = (inputs: CustomInputValidationState[]): void => {
                this.buildingBlocksValidationState.allBlockCustomInputsValid = inputs.every(i => i.isValid);
            };

            const materials = cloneDeep(store.state.operationalData.activeMaterialsModels).filter(item => item.materialType === this.materialType);
            this.data = materials.map(material => this.prepareBuildingBlockFromMaterialModel(material));

            if (this.data.length == 0)
                this.addColumn();
            else
                this.$nextTick(() => this.validateCustomInputs(this));
        }
    }

    private prepareBuildingBlockFromMaterialModel(materialModel: MaterialModel): MaterialBuildingBlockModel {
        const getNumberInputValue = (value: number): number | undefined => !materialModel.sourceBlockNameSid && !value ? undefined : value;

        return {
            sourceBlockCounter: materialModel.sourceBlockCounter,
            sourceBlockNameSid: materialModel.sourceBlockNameSid,
            additions: getNumberInputValue(materialModel.additions),
            stock: getNumberInputValue(materialModel.stock),
            disposal: getNumberInputValue(materialModel.disposal)
        }
    }

    mounted(): void {
        this.initializeTranslations();
        this.sourceBlockNamesValidator = new SourceBlockNamesValidator();
    }

    private initializeTranslations(): void {
        this.rowText = [
            '',
            this.$t('operationalData.entryView.tabs.materials').toString(),
            this.$t('operationalData.entryView.tabs.materialsTab.stock').toString(),
            this.$t('operationalData.entryView.tabs.materialsTab.additions').toString(),
            this.$t('operationalData.entryView.tabs.materialsTab.disposal').toString(),
        ];
    }

    get isReadOnly(): boolean {
        const opData = store.state.operationalData.activeStatus;
        return (opData && opData.reportStatusSid) ?
            !store.getters['userAccessManagement/useButtonSave'](opData.reportStatusSid) : true;
    }

    isValidName(buildingBlock: MaterialBuildingBlockModel): boolean {
        const isValid = this.sourceBlockNamesValidator.validate(
            buildingBlock,
            this.data,
            ['stock', 'disposal', 'additions']
        );

        if (this.sourceBlockNamesValidator.allInputsValidated)
            this.buildingBlocksValidationState.allSourceBlockNamesValid = this.sourceBlockNamesValidator.areAllValid;

        return isValid;
    }

    deleteColumn(column: number): void {
        this.queryColumnDeletion(column, store.state.operationalData.activeMaterialsModels)
        this.data.splice(column, 1);
        this.closeConfirmDeletionPopup(column);
        this.$nextTick(() => this.validateCustomInputs(this));
        this.flagMaterials = !this.flagMaterials;
    }
    queryColumnDeletion(column: number, activeMaterialList: MaterialModel[]): void {
        if (activeMaterialList[column].sourceBlockCounter !== undefined) {
            store.dispatch(OperationalDataActionsNames.DeleteOperationalDataEntry, activeMaterialList[column]);
        }
        store.commit(OperationalDataMutationsNames.DeleteActiveMaterialModelColumn, column);
    }

    closeConfirmDeletionPopup(column: number): void {
        (this.$refs['confirmDeletionModalRef'] as any)[column - 1].close();
    }

    openConfirmDeletionPopup(column: number): void {
        (this.$refs['confirmDeletionModalRef'] as any)[column - 1].open();
    }

    addColumn(): void {
        this.data.push({
            sourceBlockCounter: undefined,
            sourceBlockNameSid: this.data[0] ? this.data[0].sourceBlockNameSid : undefined,
            stock: undefined,
            additions: undefined,
            disposal: undefined
        })

        const materialModels = this.mapBuildingBlocksToMaterialModels();
        store.commit(OperationalDataMutationsNames.SetActiveMaterialModels, materialModels);

        this.$nextTick(() => this.validateCustomInputs(this));
    }

    atInputChange(): void {
        const materialModels = this.mapBuildingBlocksToMaterialModels();
        store.commit(OperationalDataMutationsNames.SetActiveMaterialModels, materialModels);
    }

    mapBuildingBlocksToMaterialModels(): MaterialModel[] {
        const unitKPI = cloneDeep(store.getters[OperationalDataGettersNames.GetActiveUnitKPI]);
        return this.data.map(buildingBlock => {
            return {
                machineSid: unitKPI.Unit.Id,
                reportMonth: unitKPI.ReportMonth,
                sourceBlockNameSid: buildingBlock.sourceBlockNameSid,
                sourceBlockCounter: buildingBlock.sourceBlockCounter,
                additions: buildingBlock.additions ?? 0,
                stock: buildingBlock.stock ?? 0,
                disposal: buildingBlock.disposal ?? 0,
                materialType: this.materialType
            }
        });
    }

    getMaterialOptions(): void {
        const temp = store.getters[OperationalDataGettersNames.GetDropDownOptions];
        this.options = this.materialType === 'MA' ? temp.materialOptions : temp.wasteMaterialOptions;
        this.translateLabels();
    }

    translateLabels(): void {
        this.options.forEach(element => {
            element.label = this.$t(`operationalData.entryView.tabs.dropDownOptions.${element.value}`).toString()
        })
    }
}
