import { Color } from './types';

export const colors: Color = {
    'PUB': '#0059A4', // dark blue
    'PUN': '#43A047', // dark green
    'BU': '#E6252E', // red darker
    'OM': '#363636', // dark grey darker
    'OE': '#d3651d', // darker orange
    'EI': '#FFDD99', // amber light
    'ND': '#00BFA5', // teal lighter
    'Electric Net Generation Volume': '#4CAF50', // medium green
    'Standby': '#BFCBD1' // light grey
}
