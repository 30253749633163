import { ModeServiceBase } from '@/services/base-services';
import {
    ApiHierarchiesResponse,
    ApiResponse,
    HierarchyAttributes
} from '@/models';

export class HierarchiesService extends ModeServiceBase {
    constructor() {
        super('hierarchies');
    }

    /**
     * @name getHierarchyAttributes
     * @description This method queries from the backend a list of hierarchy attributes.
     * @returns A List of hierarchy attributes.
     */
    public async getHierarchyAttributes(): Promise<ApiResponse<ApiHierarchiesResponse<HierarchyAttributes>>> {
        return (await this.api.get()).data;
    }
}
