import { SplitEventModel } from '@/models'
import { CurrentVgbTabModel, EventInfoModel, EventVgbClassificationModel } from '@/models/event-info'
import store from '@/store'
import { EventBus } from '@/utils'
import Vue from 'vue'
import { TranslationMap } from './translationmap'

const EventInfoVgbTab = Vue.extend({
    name: 'EventInfoVgbTab',
    components: {},
    props: {
    },
    data(): {
        languageKey: boolean;
        isLoading: boolean;
        filterOptions: {
            enumVgbConditionBeforeSid: Record<string, any>;
            enumVgbTimeframeSid: Record<string, any>;
            enumVgbEventTypeSid: Record<string, any>;
            enumVgbMainConsequenceSid: Record<string, any>;
            enumVgbExternalInfluenceDescriptionSid: Record<string, any>;
        };
        triggerBool: boolean;
        currentVgbStateObject: {
            enumVgbConditionBeforeSid: number | undefined;
            enumVgbTimeframeSid: number | undefined;
            enumVgbEventTypeSid: number | undefined;
            enumVgbMainConsequenceSid: number | undefined;
            enumVgbExternalInfluenceDescriptionSid: number | undefined;
            vgbClassificationFlag: {
                text: string;
                value: string;
            };
        };
        disableDropdownFields: boolean;
        eventId: string;
        } {
        return {
            languageKey: false,
            isLoading: false,
            filterOptions: {
                enumVgbConditionBeforeSid: [],
                enumVgbTimeframeSid: [],
                enumVgbEventTypeSid: [],
                enumVgbMainConsequenceSid: [],
                enumVgbExternalInfluenceDescriptionSid: []
            },
            triggerBool: true,
            currentVgbStateObject: {
                enumVgbConditionBeforeSid: 0,
                enumVgbTimeframeSid: 0,
                enumVgbEventTypeSid: 0,
                enumVgbMainConsequenceSid: 0,
                enumVgbExternalInfluenceDescriptionSid: 0,
                vgbClassificationFlag: {
                    text: '-',
                    value: '-'
                },
            },
            disableDropdownFields: true,
            eventId: '',
        }
    },
    created(): void {
        this.isLoading = true;
        this.parseDropdownData();
        this.isLoading = false;

        this.eventId = this.$route.params.id;
        const currentState = this.$store.getters['eventManager/getVgbTab'];

        if (currentState){
            this.currentVgbStateObject = currentState;
            this.calculatePillState(this.currentVgbStateObject.enumVgbConditionBeforeSid!, this.currentVgbStateObject.enumVgbTimeframeSid!);
        } else {
            this.loadEventData();
        }

        EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => {
            this.parseDropdownData();
            this.parsePill();
            this.languageKey = !this.languageKey;
        });
    },
    computed: {
        isParentEvent(): boolean {
            return !this.eventId.includes('.')
        },
        isReadOnly(): boolean {
            const eventInfo = store.getters['eventManager/getSingleParentEvent']();
            return (eventInfo && eventInfo.enumEventInfoStatusSid) ?
                !store.getters['userAccessManagement/useButtonSave'](eventInfo.enumEventInfoStatusSid) : true;
        },
        isEnumVgbConditionBeforeSidValid(): boolean {
            if (!this.isParentEvent) return true
            return !!this.currentVgbStateObject.enumVgbConditionBeforeSid;
        },
        isEnumVgbTimeframeSidValid(): boolean {
            if (!this.isParentEvent) return true
            return !!this.currentVgbStateObject.enumVgbTimeframeSid;
        },
        isEnumVgbEventTypeSidValid(): boolean {
            if (!this.isParentEvent) return true
            return !!this.currentVgbStateObject.enumVgbEventTypeSid;
        },
        isEnumVgbMainConsequenceSidValid(): boolean {
            if (!this.isParentEvent) return true
            return !!this.currentVgbStateObject.enumVgbMainConsequenceSid;
        },
        externalInfluenceDescriptionFieldDisabled(): boolean {
            return this.isReadOnly || this.currentVgbStateObject.vgbClassificationFlag.value !== 'Ext';
        },
        isClearAllBtnDisabled(): boolean {
            return this.isReadOnly || this.disableDropdownFields
        }
    },
    watch: {
        currentVgbStateObject:{
            handler(newStateObj): void{
                this.$store.dispatch('eventManager/setVgbState', {obj: newStateObj})
            },
            deep: true
        },
    },
    methods: {
        onSelectionChange(): void {
            this.parseDropdownData();
            this.resetInputValues();
            this.recalculatingTimeFrame();

            this.calculateNewPillState();
        },
        clearAllInputs(): void {
            this.currentVgbStateObject = {
                enumVgbConditionBeforeSid: 0,
                enumVgbTimeframeSid: 0,
                enumVgbEventTypeSid: 0,
                enumVgbMainConsequenceSid: 0,
                enumVgbExternalInfluenceDescriptionSid: 0,
                vgbClassificationFlag: {
                    text: '-',
                    value: '-'
                },
            }
        },
        async loadEventData(): Promise<void>{
            let data = {} as EventInfoModel | SplitEventModel;

            if (this.isParentEvent){
                data = this.$store.getters['eventManager/getSingleParentEvent']();
            } else {
                data = this.$store.getters['eventManager/getSingleChildEvent']();
            }

            this.currentVgbStateObject.enumVgbConditionBeforeSid = data.enumVgbConditionBeforeSid;
            this.currentVgbStateObject.enumVgbExternalInfluenceDescriptionSid = data.enumVgbExternalInfluenceDescriptionSid;
            this.currentVgbStateObject.enumVgbEventTypeSid = data.enumVgbEventTypeSid;
            this.currentVgbStateObject.enumVgbMainConsequenceSid = data.enumVgbMainConsequenceSid;
            this.currentVgbStateObject.enumVgbTimeframeSid = data.enumVgbTimeframeSid;
            this.disableDropdownFields = false;
            this.calculatePillState(this.currentVgbStateObject.enumVgbConditionBeforeSid!, this.currentVgbStateObject.enumVgbTimeframeSid!);
            this.$store.dispatch('eventManager/setVgbState', this.currentVgbStateObject as CurrentVgbTabModel);
        },
        /**
         * Setting the data for the vgb-dropdown fields
         * retrieving enums from the store
         * saving the translated value into filterOptions
         */
        parseDropdownData(): void {
            const temp = this.$store.getters['eventManager/getAllVgbEnums']();
            this.filterOptions.enumVgbConditionBeforeSid = temp.enumVgbConditionBeforeSid.map((value: EventVgbClassificationModel) => ({label: this.$t(TranslationMap.ConditionBefore[value.sid]), value: value.sid}));
            this.filterOptions.enumVgbExternalInfluenceDescriptionSid = temp.enumVgbExternalInfluenceDescriptionSid.map((value: EventVgbClassificationModel) => ({label: this.$t(TranslationMap.ExternalInfluenceDescription[value.sid]), value: value.sid}));
            this.filterOptions.enumVgbEventTypeSid = temp.enumVgbEventTypeSid.map((value: EventVgbClassificationModel) => ({label: this.$t(TranslationMap.EventType[value.sid]), value: value.sid}));
            this.filterOptions.enumVgbMainConsequenceSid = temp.enumVgbMainConsequenceSid.map((value: EventVgbClassificationModel) => ({label: this.$t(TranslationMap.MainConsequences[value.sid]), value: value.sid}));
            this.filterOptions.enumVgbTimeframeSid = temp.enumVgbTimeframeSid.map((value: EventVgbClassificationModel) => ({label: this.$t(TranslationMap.TimeFrame[value.sid]), value: value.sid}));
        },
        /**
         * Helper function for iterating and comparing the passed @param value with the available filterOptions
         * for reducing the selectable options
         * @returns an Array of all selectable options
         */
        filterTimeLimits(value: number[]): any[] {
            const arr = [];
            for (const element in this.filterOptions.enumVgbTimeframeSid) {
                for (let i = 0; i < value.length; i++) {
                    if (this.filterOptions.enumVgbTimeframeSid[element].value == value[i]) {
                        const label = this.$t(TranslationMap.TimeFrame[value[i]])
                        arr.push({ label: label, value: value[i] });
                    }
                }
            }

            return arr;
        },
        filterEventType(value: number[]): any[] {
            const arr = [];
            for (const element in this.filterOptions.enumVgbEventTypeSid) {
                for (let i = 0; i < value.length; i++) {
                    if (this.filterOptions.enumVgbEventTypeSid[element].value == value[i]) {
                        const label = this.$t(TranslationMap.EventType[value[i]])
                        arr.push({ label: label, value: value[i] });
                    }
                }
            }

            return arr;
        },
        filterMainCons(value: number[]): any[] {
            const arr = [];
            for (const element in this.filterOptions.enumVgbMainConsequenceSid) {
                for (let i = 0; i < value.length; i++) {
                    if (this.filterOptions.enumVgbMainConsequenceSid[element].value == value[i]) {
                        arr.push({ label: this.$t(TranslationMap.MainConsequences[value[i]]), value: value[i] });
                    }
                }
            }
            return arr;
        },
        /**
         * Helper functions
         */
        recalculatingTimeFrame(): void {
            if(this.currentVgbStateObject.enumVgbConditionBeforeSid !== 817){
                const temp = this.filterTimeLimits([821,822,823,824,826,827,828,829,830,832]);
                while(this.filterOptions.enumVgbTimeframeSid.length > 0){
                    this.filterOptions.enumVgbTimeframeSid.pop();
                }
                temp.forEach(item => this.filterOptions.enumVgbTimeframeSid.push(item));
            }
        },
        resetInputValues(): void{
            this.disableDropdownFields = false;
            this.currentVgbStateObject.enumVgbMainConsequenceSid = 0;
            this.currentVgbStateObject.enumVgbEventTypeSid = 0;
            this.currentVgbStateObject.enumVgbExternalInfluenceDescriptionSid = 0;
        },
        parsePill(): void {
            this.currentVgbStateObject.vgbClassificationFlag.text = this.$t(TranslationMap.FlagTitle[this.currentVgbStateObject.vgbClassificationFlag.value]).toString();
        },
        /**
         * Calculates and sets the Flag for the vgb-classification
         * Values based on enumeration index
         * @param cond
         * @param time
         */
        calculatePillState(cond: number, time: number): void{
            if(cond !== undefined || time !== undefined ){
                //Set Pill Label for Outage Extension
                if (cond == 817 && (time == 825 || time == 831)) {
                    this.currentVgbStateObject.vgbClassificationFlag.text = this.$t(TranslationMap.FlagTitle.OE).toString();
                    this.currentVgbStateObject.vgbClassificationFlag.value = 'OE';
                }
                //Set Pill Label for Planned Event Budgeted
                else if (time == 830) {
                    this.currentVgbStateObject.vgbClassificationFlag.text = this.$t(TranslationMap.FlagTitle.PUB).toString();
                    this.currentVgbStateObject.vgbClassificationFlag.value = 'PUB';
                }
                //Set Pill Label for Planned Event Nominated
                else if (time == 829) {
                    this.currentVgbStateObject.vgbClassificationFlag.text = this.$t(TranslationMap.FlagTitle.PUN).toString();
                    this.currentVgbStateObject.vgbClassificationFlag.value = 'PUN';
                }
                //Set Pill Label for Breakdown Event
                else if ([821, 822, 823, 824, 826, 827].includes(time)) {
                    this.currentVgbStateObject.vgbClassificationFlag.text = this.$t(TranslationMap.FlagTitle.BU).toString();
                    this.currentVgbStateObject.vgbClassificationFlag.value = 'BU';
                }
                //Set Pill Label for Opportunity Maintenance
                else if (time == 828) {
                    this.currentVgbStateObject.vgbClassificationFlag.text = this.$t(TranslationMap.FlagTitle.OM).toString();
                    this.currentVgbStateObject.vgbClassificationFlag.value = 'OM';
                }
                //Set Pill Label for External Influence
                else if (time === 832) {
                    this.currentVgbStateObject.vgbClassificationFlag.text = this.$t(TranslationMap.FlagTitle.EXT).toString();
                    this.currentVgbStateObject.vgbClassificationFlag.value = 'Ext';
                }
            } else {
                this.currentVgbStateObject.vgbClassificationFlag.text = '-';
                this.currentVgbStateObject.vgbClassificationFlag.value = '-';
            }
        },
        retrieveSystemsSession(): any{
            const systemsSession = this.$store.getters['eventManager/systemsTabCurrentState']
            const systemOptions = this.$store.getters['eventManager/systemsTabSelectionCurrentState']

            if(systemsSession != undefined && systemOptions != undefined){

                for(const element in systemOptions.mainSystems){
                    if(systemOptions.mainSystems[element].value == systemsSession.eventSapMainSystem){
                        return {label: systemOptions.mainSystems[element].label, value: systemOptions.mainSystems[element].value}
                    }
                }
            }
            else{
                console.log('no Session Object found')
            }
        },
        /* eslint-disable sonarjs/cognitive-complexity */
        calculateNewPillState(): void {
            if (this.currentVgbStateObject.enumVgbConditionBeforeSid !== null && this.currentVgbStateObject.enumVgbTimeframeSid !== null) {

                const cond = Number(this.currentVgbStateObject.enumVgbConditionBeforeSid);
                const time = Number(this.currentVgbStateObject.enumVgbTimeframeSid);

                //Set Options for Flag Outcome OE
                if (cond == 817 && (time == 825 || time == 831)) {
                    this.filterOptions.enumVgbEventTypeSid = this.filterEventType([847, 849]);
                    this.filterOptions.enumVgbMainConsequenceSid = this.filterMainCons([866, 868]);
                    this.disableDropdownFields = false;
                }
                //Set Options for Flag Outcome PUB or Outcome PUN
                else if (time == 830 || time == 829) {
                    this.filterOptions.enumVgbEventTypeSid = this.filterEventType([845, 846, 847, 849]);
                    this.filterOptions.enumVgbMainConsequenceSid = this.filterMainCons([866, 868]);
                    this.disableDropdownFields = false;
                }
                //Set Options for BU
                else if ([821, 822, 823, 824, 826, 827].includes(time)) {
                    this.filterOptions.enumVgbEventTypeSid = this.filterEventType([841,842,843,844,848,862]);
                    this.filterOptions.enumVgbMainConsequenceSid = this.filterMainCons([865,866,868]);
                    this.disableDropdownFields = false;
                }
                //Set Options for OM
                else if (time == 828) {
                    this.filterOptions.enumVgbEventTypeSid = this.filterEventType([845, 847]);
                    this.filterOptions.enumVgbMainConsequenceSid = this.filterMainCons([865,866,868]);
                    this.disableDropdownFields = false;
                }
                //Set Options for External Influence
                else if (time === 832) {
                    this.filterOptions.enumVgbEventTypeSid = this.filterEventType([852, 853, 854, 855, 856,857,858,859 ]);
                    this.filterOptions.enumVgbMainConsequenceSid = this.filterMainCons([865,866,868]);
                    this.disableDropdownFields = false;
                }

                this.calculatePillState(cond, time);
            }
        }
    },
})

export default EventInfoVgbTab;
