import Vue from 'vue';
import App from './App.vue';
import VueI18n from 'vue-i18n';
import router from './router';
import store from './store';
import 'adam.ui-core/dist/umd';
import 'adam.ui-core/utils/scaling.css';
import './registerServiceWorker';
import { VNode } from 'vue/types/umd';
import i18n from '@/utils/i18n';

/* @ts-ignore */
import PebbleUI from '@enerlytics/pebble-ui';
import { LANGUAGES, LocalStorageHelper} from './utils';

import { CoodeSdkPlugin } from '@coode/fe-sdk';
import coodeSdkPluginConfiguration from '@/utils/coode-sdk-plugin-configuration';

Vue.config.productionTip = false;
Vue.use(VueI18n);

const defaultOrCurrentLang = LANGUAGES[LocalStorageHelper.getSelectedLanguage() ?? LocalStorageHelper.determineDefaultLanguage()];

/* @ts-ignore */
Vue.use(PebbleUI, {
    lang: defaultOrCurrentLang,
    dateTimeLocale: defaultOrCurrentLang
});

Vue.use(new CoodeSdkPlugin(), coodeSdkPluginConfiguration);

new Vue({
    i18n,
    router,
    store,
    render: (h): VNode => h(App),
}).$mount('#app');
