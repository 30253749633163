import { EventHeader } from './event-header';

export interface EventInfoModel {
    'eventId': number;
    'eventIdentifier': string;
    'enumEventInfoStatusSid'?: number;
    'availCapacity': number;
    'unAvailCapacity': number;
    'unAvailEnergy': number;
    'unAvailEnergyUnit': number;
    'validFrom'?: string;
    'validTo'?: string;
    'utcStartTime': string;
    'utcEndTime': string;
    'durationHours': number;
    'eventUnitName'?: string;
    'eventPlantName'?: string;
    'minimalCapacityUnit'?: number;
    'nominalCapacityUnit'?: number;
    'budgetYear'?: number;
    'incidentPenaltyName'?: IncidentPenaltyName;
    'incidentPenaltyThreshold'?: number | null;
    'mWhLossMWCapacityThreshold'?: number;
    'incidentPenalty'?: number;
    'isManualCapacityChange'?: boolean;
    'isTractionPower'?: boolean;
    'isStatusChanged': boolean;
    'isDominant'?: boolean;
    'isViaTrip'?: boolean;
    'isMustRun'?: boolean;
    'enumEventLifecycleStatusName'?: string;
    'enumVgbConditionBeforeSid'?: number;
    'enumVgbTimeframeSid'?: number;
    'enumVgbEventTypeSid'?: number;
    'enumVgbMainConsequenceSid'?: number;
    'enumVgbExternalInfluenceDescriptionSid'?: number;
    'enumVgbExternalInfluenceDescriptionName'?: string;
    'enumEventInfoStatusName'?: string;
    'vgbClassificationFlag'?: string;
    'rootCauseAnalysis'?: string;
    'reviewFollowUp'?: string;
    'createdBy'?: string;
    'createdAt'?: string;
    'updatedBy'?: string;
    'updatedAt'?: string;
    'eventHeaderDto': EventHeader;
    'eventIdentifierMspt'?: string;
    'eventSubUnitSid'?: number;
    'eventSapHierarchicalSystem'?: string;
    'eventSapMainSystem'?: string;
    'eventSapSubSystem'?: string;
    'eventTitle'?: string;
    'eventDescription'?: string;
    'preventativeMeasure'?: string;
    'parallelEventDtoCollection'?: any[];
    'splitEventId'?: number;
    'splitEventCollection'?: any[];
    'isAssetReliabilityRelevant'?: boolean;
}

export interface EventInfoTableModel {
    'eventId': number;
    'eventIdentifier': string;
    'eventUnitName': string;
    'eventTitle': string;
    'utcStartTime': string;
    'utcEndTime': string;
    'unAvailCapacity': number;
    'enumEventInfoStatusName': string;
    'enumEventInfoStatusSid': number;
    'enumEventLifecycleStatusName': string;
    'enumEventLifecycleStatusSid': number;
    'parallelEventDtoCollection': any[];
    'splitEventCollection': any[];
}

export interface EventVgbClassificationModel {
    'sid': number;
    'name': string;
    'description': string;
    'idTypeSid': number;
    'isdeleted': boolean;
}

export interface EventVgbClassificationList {
    'enums': Array<EventVgbClassificationModel>;
}

export interface EventAdditionalInformation {
    'isViaTrip'?: boolean;
    'eventDescription'?: string;
    'rootCauseAnalysis'?: string;
    'reviewFollowUp'?: string;
    'preventativeMeasure'?: string;
}
export interface EventInfoList {
    'items': Array<EventInfoTableModel>;
    'totalCount': number;
    'hasPreviousPage': boolean;
    'hasNextPage': boolean;
    'currentPage': number;
    'totalPages': number;
}

export interface EventInfoFilterModel {
    [key: string]: any;
    EventStart?: string | undefined;
    EventEnd?: string | undefined;
    RPMaxFrom?: number | undefined;
    RPMaxTo?: number | undefined;
    EventReason?: number | undefined;
    areaSids?: number[];
    plantSids?: number[];
    machineSids?: number[];
    lifecycleStatus?: number[];
    eventInfoPlusStatus?: number[];
    page?: number;
    size?: number;
    Term?: string | undefined;
    TermToLower?: string | undefined;
}

export interface EventHistoryModel {
    'time': string;
    'author': string;
    'changes': [
        {
            'name': string;
            'oldValue'?: string | undefined;
            'newValue': string;
        }
    ];
}

export interface EventInfoVgbEnums {
    'enumVgbConditionBeforeSid'?: Record<string, any>;
    'enumVgbTimeframeSid'?: Record<string, any>;
    'enumVgbEventTypeSid'?: Record<string, any>;
    'enumVgbMainConsequenceSid'?: Record<string, any>;
    'enumVgbExternalInfluenceDescriptionSid'?: Record<string, any>;
}

export interface CurrentVgbTabModel {
    'enumVgbConditionBeforeSid'?: number | undefined;
    'enumVgbTimeframeSid'?: number | undefined;
    'enumVgbEventTypeSid'?: number | undefined;
    'enumVgbMainConsequenceSid'?: number | undefined;
    'enumVgbExternalInfluenceDescriptionSid'?: number | undefined;
    'vgbClassificationFlag'?: Record<any, string> | undefined;
}

export interface CurrentSystemsTabModel {
    'eventSapHierarchicalSystem'?: string;
    'eventSapMainSystem'?: string;
    'eventSapSubSystem'?: string;
}

export interface CurrentSystemsTabSelectModel {
    'mainSystems': Array<{label: string; value: string}>;
    'subSystems': Array<{label: string; value: string}>;
}

export interface EventInfoStatusModel {
    'enumEventInfoStatusSid': number;
    'eventCount': number;
    'eventTotal': number;
}

export interface IndividualEventInfo {
    isAssetReliabilityRelevant: boolean;
    incidentPenaltyName?: IncidentPenaltyName;
    incidentPenalty?: number;
    incidentPenaltyThreshold?: number | null;
    mWhLossMWCapacityThreshold?: number;
}

export enum IncidentPenaltyName {
    EquivalentOperatingHours = 'Equivalent Operating Hours',
    StartPenalty = 'Start Penalty',
    NotApplicable = 'Not Applicable',
}
