import moment, { MomentInput } from 'moment';
import { LocalStorageHelper } from '@/utils/local-storage-helper';
import { LANGUAGES } from '@/utils/languages';

/**
 * Helper function to convert the given date into a string representation of local time.
 * @param date
 * @returns String with format: YYYY-MM-DDThh:mm:ss+hh:mm
 *
 * FIXME Use moment.js to solve this more elegantly.
 */
export function toLocalizedIsoString (date: Date): string {
    const tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num: number): string {
            const norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
}

export function parseDateForBackend (date: Date): string {
    const timestamp = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
    return timestamp.toISOString()
}

export function parseLocalDateForBackend(date: Date): string {
    const timestamp = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
    return toLocalizedIsoString(timestamp)
}

export function parseBackendUtcTimeAsLocal(timestamp: string): Date {
    if (timestamp.charAt(timestamp.length - 1) === 'Z') {
        timestamp = timestamp.substring(0, timestamp.length - 1)
    }
    const date = new Date(timestamp + '.000Z')
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
}

// only locale-respecting formats are supported, see moment.js docs
export function toDisplayDateString(date: Date | string, locale = 'en', format = 'LLL'): string {
    return moment(date).locale(locale).format(format).toString();
}

// Calculates the difference between 'now' and the provided date in the form of an object that contains
// the diff and the unit as a translation key to be used later like '5 days ago', '2 hours ago', etc.
// while seconds is the last supported measurement. This is also a sane limitation by moments.js.
// By default moment truncates decimal places, so we will allways check for 0 which is falsy in js
// This will mainly be used by the notifications
/* eslint-disable sonarjs/cognitive-complexity */
export function toDisplayDateDifference(date: Date | string, locale = 'en'): { diff: number; unit: string } {
    const daysDiff = moment(date).locale(locale).diff(moment.now(), 'days');

    if(!daysDiff) {
        const hoursDiff = moment(date).locale(locale).diff(moment.now(), 'hours');

        if (!hoursDiff) {
            const minutesDiff = moment(date).locale(locale).diff(moment.now(), 'minutes');

            if (!minutesDiff) {
                const secondsDiff = moment(date).locale(locale).diff(moment.now(), 'seconds');

                return { diff: secondsDiff, unit: secondsDiff * -1 === 1 ? 'notificationTimes.second' : 'notificationTimes.seconds' };
            }

            return { diff: minutesDiff, unit: minutesDiff * -1 === 1 ? 'notificationTimes.minute' : 'notificationTimes.mins' };
        }
        return { diff: hoursDiff, unit: hoursDiff * -1 === 1 ? 'notificationTimes.hour' : 'notificationTimes.hours' };
    }
    return { diff: daysDiff, unit: daysDiff * -1 === 1 ? 'notificationTimes.day' : 'notificationTimes.days' };
}

// A call of format() without a param will use the local timezone.
// moment.js can handle either Date, date strings or milliseconds
export function toLocalIsoString(date: Date | string | number): string {
    return moment(date).format();
}

export const formatWithAutoLocale = ({ datetime, utc, format }: { datetime: MomentInput, utc: boolean, format: string}): string => {
    const currentlySelectedLanguage = LocalStorageHelper.getSelectedLanguage() || '';

    let localeKey = LANGUAGES[currentlySelectedLanguage];

    if (!localeKey) {
        const FALLBACK_LANGUAGE = 'en-gb';
        console.warn(`The language ${currentlySelectedLanguage} could not be found. Defaulting to ${FALLBACK_LANGUAGE}.`);
        localeKey = FALLBACK_LANGUAGE;
    }

    if (utc) {
        return moment.utc(datetime).locale(localeKey).local().format(format);
    }

    return moment(datetime).locale(localeKey).local().format(format);
}
