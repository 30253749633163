interface ItranslationMap {
    field: {
        [key: string]: any;
    };
    sid: {
        [key: number]: any;
    };
    bool: {
        [key: string]: any;
    };

}


export const TranslationMap: ItranslationMap = {
    // translation keys for history table
    // column field
    field:{
        'EnumEventInfoStatusSid': 'info.status',
        'EnumEventLifecycleStatusSid': 'info.lifecycle',
        'EnumEventLifecycleStatusName': 'info.lifecycle',
        'IsDominant': 'info.dominant.dominant',
        'EventId': 'id',
        'EventIdentifier': 'eventIdentifier',
        'EventTitle': 'event-input-title',
        'EventUnitName': 'site',
        'EventPlantName': 'unit',
        'MinimalCapacityUnit': 'minimalCapacity',
        'NominalCapacityUnit': 'nominalCapacity',
        'EventDescription': 'description',
        'UtcStartTime': 'eventStart',
        'UtcEndTime': 'eventEnd',
        'DurationHours': 'duration',
        'AvailCapacity': 'availableCapacity',
        'UnAvailCapacity': 'unavailableCapacity',
        'UnAvailEnergy': 'unavailableEnergy',
        'BudgetYear': 'info.budgetYear',
        'IsTractionPower': 'info.tractionPower',
        'IsMustRun': 'flags.mustRun',
        // vgb tab
        'VgbClassificationFlag': 'vgbFlag.vgbClassificationFlag',
        'EnumVgbConditionBeforeSid': 'vgbFlag.InputFieldTitle.conditionBeforeTitle',
        'EnumVgbTimeframeSid': 'vgbFlag.InputFieldTitle.timeFrameTitle',
        'EnumVgbEventTypeSid': 'vgbFlag.InputFieldTitle.eventTypeTitle',
        'EnumVgbMainConsequenceSid': 'vgbFlag.InputFieldTitle.mainConsequencesTitle',
        'EnumVgbEventCauseSid': 'vgbFlag.InputFieldTitle.eventCauseTitle',
        // main and subsystems tab
        'EventSapMainSystem': 'info.mainSystem',
        'EventSapSubSystem': 'info.subSystem',
        // additional informations tab
        'IsViaTrip': 'info.viaTrip',
        'RootCauseAnalysis': 'info.rootCauseAnalysis',
        'ReviewFollowUp': 'info.reviewAndFollowUp',
        'PreventativeMeasure': 'info.preventiveMeasure',
        // others
        'CreatedBy': 'history.createdBy',
        'CreatedAt': 'history.createdAt'


        // TODO: identify following fields and decideif they are needed here

        // 'eventIdentifierMspt': string,
        // 'unAvailEnergyUnit': number,
        // 'eventSubUnitSid'?: number,
        // 'enumEventLifecycleStatusName'?: string,
        // 'enumEventInfoStatusName'?: string,
        // 'eventHeaderDto': EventHeader,
        // 'splitEventCollection'?: SplitEventModel[],
        // 'updatedBy'?: string,
        // 'updatedAt'?: string,
        // 'validFrom'?: string,
        // 'validTo'?: string
    },
    sid: {
        // EventInfo+ Event Lifecycle
        731: 'eventLifecycleOptions.draft',
        732: 'eventLifecycleOptions.checkedAccepted',
        733: 'eventLifecycleOptions.checkedRejected',
        734: 'eventLifecycleOptions.published',
        735: 'eventLifecycleOptions.completedAndArchived',
        736: 'eventLifecycleOptions.cancelledAndArchived',
        737: 'eventLifecycleOptions.manuallyProcessed',

        // EventInfo+ status
        741: 'eventInfoStatusOption.new',
        742: 'eventInfoStatusOption.pendingFurtherInfo',
        743: 'eventInfoStatusOption.inReview',
        744: 'eventInfoStatusOption.final',
        745: 'eventInfoStatusOption.obsolete',
        746: 'eventInfoStatusOption.locked',
        747: 'eventInfoStatusOption.pendingFinal',
        // vgb condition before
        811: 'vgbFlag.inputfields.conditionBeforeDropDown.overload',
        812: 'vgbFlag.inputfields.conditionBeforeDropDown.fullLoad',
        813: 'vgbFlag.inputfields.conditionBeforeDropDown.partLoad',
        814: 'vgbFlag.inputfields.conditionBeforeDropDown.startUp',
        815: 'vgbFlag.inputfields.conditionBeforeDropDown.shutDown',
        816: 'vgbFlag.inputfields.conditionBeforeDropDown.change',
        817: 'vgbFlag.inputfields.conditionBeforeDropDown.standstill',
        818: 'vgbFlag.inputfields.conditionBeforeDropDown.notKnown',
        // vgb time frame
        821: 'vgbFlag.inputfields.timeFrameDropDown.auto',
        822: 'vgbFlag.inputfields.timeFrameDropDown.manu',
        823: 'vgbFlag.inputfields.timeFrameDropDown.arranged',
        824: 'vgbFlag.inputfields.timeFrameDropDown.restart',
        825: 'vgbFlag.inputfields.timeFrameDropDown.exceedUnplanned',
        826: 'vgbFlag.inputfields.timeFrameDropDown.delay',
        827: 'vgbFlag.inputfields.timeFrameDropDown.extension',
        828: 'vgbFlag.inputfields.timeFrameDropDown.postpone',
        829: 'vgbFlag.inputfields.timeFrameDropDown.fix',
        830: 'vgbFlag.inputfields.timeFrameDropDown.annual',
        831: 'vgbFlag.inputfields.timeFrameDropDown.exceedPlanned',
        832: 'vgbFlag.inputfields.timeFrameDropDown.without',
        // vgb event type
        841: 'vgbFlag.inputfields.eventTypeDropDown.disturbance',
        842: 'vgbFlag.inputfields.eventTypeDropDown.damage',
        843: 'vgbFlag.inputfields.eventTypeDropDown.test',
        844: 'vgbFlag.inputfields.eventTypeDropDown.lubrication',
        845: 'vgbFlag.inputfields.eventTypeDropDown.maintenance',
        846: 'vgbFlag.inputfields.eventTypeDropDown.inspection',
        847: 'vgbFlag.inputfields.eventTypeDropDown.prevRepair',
        848: 'vgbFlag.inputfields.eventTypeDropDown.clean',
        849: 'vgbFlag.inputfields.eventTypeDropDown.revision',
        850: 'vgbFlag.inputfields.eventTypeDropDown.fuelChange',
        851: 'vgbFlag.inputfields.eventTypeDropDown.reconstruction',
        852: 'vgbFlag.inputfields.eventTypeDropDown.externalWithoutDamage',
        853: 'vgbFlag.inputfields.eventTypeDropDown.externalWithoutDamagePreservation',
        854: 'vgbFlag.inputfields.eventTypeDropDown.externalWithoutAmbient',
        855: 'vgbFlag.inputfields.eventTypeDropDown.externalWithoutGridRestr',
        856: 'vgbFlag.inputfields.eventTypeDropDown.externalWithoutLack',
        857: 'vgbFlag.inputfields.eventTypeDropDown.externalWithoutOthers',
        858: 'vgbFlag.inputfields.eventTypeDropDown.functionalTest',
        859: 'vgbFlag.inputfields.eventTypeDropDown.legalRequire',
        861: 'vgbFlag.inputfields.eventTypeDropDown.lackOfReactivity',
        862: 'vgbFlag.inputfields.eventTypeDropDown.other',
        // vgb main consequence
        865: 'vgbFlag.inputfields.mainConsequenceDropDown.withoutRestr',
        866: 'vgbFlag.inputfields.mainConsequenceDropDown.capRestr',
        867: 'vgbFlag.inputfields.mainConsequenceDropDown.island',
        868: 'vgbFlag.inputfields.mainConsequenceDropDown.shutdown',
        // vgb event cause
        871: 'vgbFlag.inputfields.eventCauseDropDown.clarification',
        872: 'vgbFlag.inputfields.eventCauseDropDown.revisionOutage',
        873: 'vgbFlag.inputfields.eventCauseDropDown.planned',
        874: 'vgbFlag.inputfields.eventCauseDropDown.waterRestr',
        875: 'vgbFlag.inputfields.eventCauseDropDown.gridRestr',
        876: 'vgbFlag.inputfields.eventCauseDropDown.legal',
        877: 'vgbFlag.inputfields.eventCauseDropDown.ambient',
        878: 'vgbFlag.inputfields.eventCauseDropDown.outageExt',
        879: 'vgbFlag.inputfields.eventCauseDropDown.strike',
        880: 'vgbFlag.inputfields.eventCauseDropDown.trialRun',
        881: 'vgbFlag.inputfields.eventCauseDropDown.trial'
    },
    bool: {
        'True': 'yes',
        'False': 'no'
    }
}
