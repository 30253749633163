import { Component, Vue } from 'vue-property-decorator';
import UnitInformation from './unit-information/unit-information.vue';
import UnitGeneration from './unit-generation/unit-generation.vue';
import UnitReliability from './uni-reliability/unit-reliability.vue';
import OpDataEntryDetailsTabs from './tabs/op-data-entry-details-tabs/op-data-entry-details-tabs.vue';
import OpDataComandButtons from './op-data-comand-buttons/op-data-comand-buttons.vue'
import store from '@/store';
import { OperationalDataActionsNames } from '@/store/modules/operational-data/operational-data-actions';
import i18n from '@/utils/i18n';

@Component({
    components: {
        UnitInformation,
        UnitGeneration,
        UnitReliability,
        OpDataEntryDetailsTabs,
        OpDataComandButtons
    }
})
export default class OpDataEntryDetails extends Vue {

    isLoading = false;

    async created(): Promise<void> {
        const unitId = this.$route.params.unitId;
        const reportMonth = this.$route.params.date;

        this.isLoading = true;
        try {
            const actionNames = [OperationalDataActionsNames.LoadOperationalDataDetailsModel, OperationalDataActionsNames.LoadStatus];
            await Promise.all([
                ...actionNames.map(actionName => store.dispatch(actionName, { unitId: unitId, reportMonth: reportMonth })),
                store.dispatch(OperationalDataActionsNames.SetOptions)
            ]);
        }
        catch (err) {
            store.commit('setErrorData', {
                errorResponse: `${err}`,
                buttonLabel: i18n.t('operationalData.entryView.opDataTable'),
                buttonLink: '/operationaldata',
                buttonIsVisible: true
            });
        }
        this.isLoading = false;
    }
}
