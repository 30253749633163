import { Component, Vue, Watch } from 'vue-property-decorator';
import { CustomInputsValidator, CustomInputValidationState } from '@/components/operational-data/op-data-entry-details/op-data-comand-buttons/custom-inputs-validator';
import { ModeException } from '@/utils/exceptions/ModeException';

@Component
export default class BaseCustomInputValidation extends Vue {
    protected customInputsValidator = new CustomInputsValidator();

    protected onValidationStateChange!: (inputs: CustomInputValidationState[]) => void;

    @Watch('customInputsValidator.inputs', { deep: true })
    onValidationChange(inputs: CustomInputValidationState[]): void {
        if (!this.onValidationStateChange) {
            console.error(new ModeException('`onValidationStateChange` function is not existing,'
                + ' ensure that it is implemented always when inheriting from `BaseCustomInputValidation` component'
            ));
            
            return;
        }

        this.onValidationStateChange(inputs);
    }

    protected validateCustomInputs(component: Vue): void {
        this.customInputsValidator.validate(component);
    }
}