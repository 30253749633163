import { AutomapperMetadata } from '@/mappers/mapper';
import { OperationalMetadata } from '@/models/operational-data/operational-data-details';
import { OperationalMetadataDTO } from '@/models/operational-data/operational-data-DTO';
import { createMetadataMap } from '@automapper/pojos';

export const createMetadataForOperationalMetadata = (): void => {
    const metadataDto: AutomapperMetadata<OperationalMetadataDTO> = {
        countryName: String,
        countrySid: Number
    }
    createMetadataMap<OperationalMetadataDTO>('OperationalMetadataDto', metadataDto);

    const metadataModel:AutomapperMetadata<OperationalMetadata> = {
        Country: 'Country'
    }
    createMetadataMap<OperationalMetadata>('OperationalMetadata', metadataModel);
};