import Vue from 'vue'
import { EventBus } from '@/utils';
const SidebarButton = Vue.extend({
    name: 'SidebarButton',
    props: {
        displayValue: {
            required: true,
            type: String
        },
        componentName: {
            required: true,
            type: String
        },
        selected: {
            default: false,
            type: Boolean
        },
        eventName: {
            type: String,
            required: true
        },
    },
    data (): {
    } {
        return {
        };
    },
    computed: {
        buttonStyleObject (): Record<string, any> {
            const styleObject = {}
            if (this.selected) {
                (styleObject as any).background = '#00426c';
                (styleObject as any).color = '#fff';
            }

            return styleObject;
        }
    },
    methods: {
        buttonClicked (): void {
            EventBus.$emit(`${this.eventName}`, { componentName: this.componentName });
        }
    }
});
export default SidebarButton;
