import { OperationalDataFiltersOptions } from '@/models/operational-data/operational-data';
import { cloneDeep, isEqual } from 'lodash';
export interface FilterModel {
    startDate: string;
    endDate: string;
    countries: number[];
    sites: number[];
    units: number[];
    statuses: number[];
}

interface ChangedFilters {
    isCountryFilterChange: boolean;
    isSiteFilterChange: boolean;
    isUnitFilterChange: boolean;
    isStatusFilterChange: boolean;
}

export class ActiveOperationalDataFilters implements FilterModel {
    private filtersSnapshot: FilterModel | null = null;

    startDate = '';
    endDate = '';
    countries: number[] = [];
    sites: number[] = [];
    units: number[] = [];
    statuses: number[] = [];

    get activeFilters(): FilterModel {
        return {
            startDate: this.startDate,
            endDate: this.endDate,
            countries: this.countries,
            sites: this.sites,
            units: this.units,
            statuses: this.statuses
        }
    }
    set activeFilters(activeFilters: FilterModel) {
        this.endDate = activeFilters.endDate;
        this.startDate = activeFilters.startDate;
        this.countries = activeFilters.countries;
        this.sites = activeFilters.sites;
        this.units = activeFilters.units;
        this.statuses = activeFilters.statuses;
    }

    formatDatesFilters(): void {
        this.startDate = this.toDateMonthlyFormat(this.startDate);
        this.endDate = this.toDateMonthlyFormat(this.endDate);
    }

    createSnapshot(): void {
        this.filtersSnapshot = cloneDeep(this.activeFilters);
    }

    resetDateFilterToPreviousValue(property: 'startDate' | 'endDate'): void {
        const newValue = this.filtersSnapshot ? this.filtersSnapshot[property] : '';
        this[property] = newValue;
    }

    private toDateMonthlyFormat(date: string): string {
        return date ? date.substring(0, 7) : '';
    }

    updateDependentSelections = (availableOptions: OperationalDataFiltersOptions): void => {
        this.updateSitesSelections(availableOptions);
        this.updateUnitsSelections(availableOptions);
    }

    private updateSitesSelections(availableOptions: OperationalDataFiltersOptions): void {
        if (!this.countries.length)
            return;

        const sitesIdsForSelectedCountries = availableOptions.sites
            .filter(site => this.countries.includes(site.countryId))
            .map(site => site.value);

        this.sites = this.sites.filter(siteId => sitesIdsForSelectedCountries.includes(siteId));
    }

    private updateUnitsSelections(availableOptions: OperationalDataFiltersOptions): void {
        if (this.sites.length) {
            const unitsIdsForSelectedSites = availableOptions.units
                .filter(unit => this.sites.includes(unit.siteId))
                .map(unit => unit.value);

            this.units = this.units.filter(unitId => unitsIdsForSelectedSites.includes(unitId));
        }
        else if (this.countries.length) {
            const unitsIdsForSelectedCountries = availableOptions.units
                .filter(unit => this.countries.includes(unit.countryId))
                .map(unit => unit.value);

            this.units = this.units.filter(unitId => unitsIdsForSelectedCountries.includes(unitId));
        }
    }

    isAnyChange(): boolean {
        return !isEqual(this.filtersSnapshot, this.activeFilters);
    }

    detectChange(): ChangedFilters {
        const isCountryFilterChange = this.filtersSnapshot !== null
            ? !isEqual(this.filtersSnapshot.countries, this.countries)
            : this.countries.length > 0;

        const isSiteFilterChange = this.filtersSnapshot !== null
            ? !isEqual(this.filtersSnapshot.sites, this.sites)
            : this.sites.length > 0;

        const isUnitFilterChange = this.filtersSnapshot !== null
            ? !isEqual(this.filtersSnapshot.units, this.units)
            : this.units.length > 0;

        const isStatusFilterChange = this.filtersSnapshot !== null
            ? !isEqual(this.filtersSnapshot.statuses, this.statuses)
            : this.statuses.length > 0;

        return {
            isCountryFilterChange: isCountryFilterChange,
            isSiteFilterChange: isSiteFilterChange,
            isUnitFilterChange: isUnitFilterChange,
            isStatusFilterChange: isStatusFilterChange,
        }
    }
}