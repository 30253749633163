import CustomInput from '@/components/utils/custom-input/custom-input.vue';
import { ComponentsModel } from '@/models/operational-data/operational-data-details';
import store from '@/store';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
import { ModeSettings } from '@/utils/mode-settings';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        CustomInput
    }
})
export default class ComponentsBlock extends Vue {

    @Prop() data!: ComponentsModel;
    @Prop() index!: number;

    mounted(): void {
        this.hideEmptyLabels();
    }

    readonly componentsPrecision = ModeSettings.InputNumberPrecisionQualityTab;

    isBoiler(): boolean {
        return this.data.sourceBlockName.includes('BO');
    }

    buildingBlockName(): string {
        if (this.data.sourceBlockName.includes('BO')) {
            return this.$t('operationalData.entryView.tabs.componentsTab.bo') + this.data.sourceBlockName.slice(2);
        }
        else if (this.data.sourceBlockName.includes('ST'))
            return this.$t('operationalData.entryView.tabs.componentsTab.st') + this.data.sourceBlockName.slice(2);
        else return this.data.sourceBlockName;

    }

    atInputChange(): void {
        store.commit(OperationalDataMutationsNames.SetActiveComponentsModels, this.data);
    }

    isReadOnly(): boolean {
        const opData = store.state.operationalData.activeStatus;
        return (opData && opData.reportStatusSid) ?
            !store.getters['userAccessManagement/useButtonSave'](opData.reportStatusSid) : true;
    }

    hideEmptyLabels(): void {
        const labels: HTMLCollectionOf<HTMLLabelElement> = document.getElementsByTagName('label');
        for (let i = 0; i < labels.length; i++) {
            if (labels[i].innerText === '')
                labels[i].style.display = 'none';
        }
    }
}
