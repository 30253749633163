import { PuiMultiSelectOption } from '@/contracts';
import { OperationalDataFiltersOptions, SiteOption, UnitOption } from '@/models/operational-data/operational-data';
import { ActiveOperationalDataFilters } from './ActiveOperationalDataFilters';
import OpDataContext from '../op-data-filter';
import { EventBus } from '@/utils/eventbus';
import { DoughnutItem } from '@/utils/doughnut-filter-utils/doughnut-filter';
import { CountriesSid } from '@/utils/evt-mgmt-utils';

export class FiltersOptionsProvider {
    private static readonly StatusTranslationPath = 'operationalData.filters.statuses.';
    countries: PuiMultiSelectOption[] = [];
    sites: PuiMultiSelectOption[] = [];
    units: PuiMultiSelectOption[] = [];
    statuses: PuiMultiSelectOption[] = [];
    private opDataContext!: OpDataContext;
    private availableOptions!: OperationalDataFiltersOptions;
    private activeFilters!: ActiveOperationalDataFilters;
    private mapToPuiMultiselectOption = (value: PuiMultiSelectOption | SiteOption | UnitOption): PuiMultiSelectOption => ({ label: value.label, value: value.value });

    constructor(opDataContext: OpDataContext, availableOptions: OperationalDataFiltersOptions) {
        EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => {
            this.updateStatusesTranslations();
            this.updateCountriesTranslations();
        });
        this.opDataContext = opDataContext;
        this.availableOptions = availableOptions;
        this.initWithAllAvailableOptions();
    }

    private initWithAllAvailableOptions(): void {
        this.countries = this.availableOptions.countries
            .map(country => ({ label: this.opDataContext.$t(CountriesSid[country.value]).toString(), value: country.value }));
        this.sites = this.availableOptions.sites.map(this.mapToPuiMultiselectOption);
        this.units = this.availableOptions.units.map(this.mapToPuiMultiselectOption);
        this.statuses = this.availableOptions.statuses
            .map(status => ({ label: this.getStatusTranslationPath(status.value), value: status.value }))

        this.sortByLabel(this.countries);
        this.sortByLabel(this.sites);
        this.sortByLabel(this.units);
        this.sortByLabel(this.statuses);
    }

    setStatusesFromDoughnutItems(doughnutItems: DoughnutItem[]): void {
        this.statuses = doughnutItems.map(item => ({ label: item.name, value: item.id }));
    }

    updateOptionsOnFilterChange(activeFilters: ActiveOperationalDataFilters): void {
        this.activeFilters = activeFilters;
        const { isCountryFilterChange, isSiteFilterChange, isUnitFilterChange } = this.activeFilters.detectChange();
        const hasCountriesFilter = this.activeFilters.countries.length > 0;
        const hasSitesFilter = this.activeFilters.sites.length > 0;

        if (!hasCountriesFilter && !hasSitesFilter && !isUnitFilterChange) {
            this.initWithAllAvailableOptions();
            return;
        }

        if (isCountryFilterChange) {
            this.updateSitesOptionsForSelectedCountries();
            this.updateUnitsOptions();
        }
        else if (isSiteFilterChange)
            this.updateUnitsOptionsForSelectedSites();
    }

    private updateSitesOptionsForSelectedCountries(): void {
        this.sites = this.activeFilters.countries.length
            ? this.filterSitesByCountries(this.activeFilters.countries)
            : this.availableOptions.sites.map(this.mapToPuiMultiselectOption);
    }

    private updateUnitsOptions(): void {
        if (this.activeFilters.sites.length)
            this.updateUnitsOptionsForSelectedSites();
        else
            this.updateUnitsOptionsForSelectedCountries();
    }

    private updateUnitsOptionsForSelectedSites(): void {
        this.units = this.activeFilters.sites.length
            ? this.filterUnitsBySites(this.activeFilters.sites)
            : this.availableOptions.units.map(this.mapToPuiMultiselectOption);
    }

    private updateUnitsOptionsForSelectedCountries(): void {
        this.units = this.activeFilters.countries.length
            ? this.filterUnitsByCountries(this.activeFilters.countries)
            : this.availableOptions.units.map(this.mapToPuiMultiselectOption);
    }

    private filterSitesByCountries = (countriesId: number[]): PuiMultiSelectOption[] =>
        this.availableOptions.sites.filter(item => countriesId.includes(item.countryId)).map(this.mapToPuiMultiselectOption);

    private filterUnitsByCountries = (countriesId: number[]): PuiMultiSelectOption[] =>
        this.availableOptions.units.filter(item => countriesId.includes(item.countryId)).map(this.mapToPuiMultiselectOption);

    private filterUnitsBySites = (sitesId: number[]): PuiMultiSelectOption[] =>
        this.availableOptions.units.filter(item => sitesId.includes(item.siteId)).map(this.mapToPuiMultiselectOption);

    private sortByLabel(options: PuiMultiSelectOption[]): void {
        options.sort((item1, item2) => {
            const item1Label = item1.label.toUpperCase();
            const item2Label = item2.label.toUpperCase();
            if (item1Label < item2Label)
                return -1;
            if (item1Label > item2Label)
                return 1;
            return 0;
        });
    }

    private updateStatusesTranslations(): void {
        this.statuses.forEach(status => {
            status.label = this.getStatusTranslationPath(status.value);
        });

        this.opDataContext.statusesDropdownRefreshKey += 1;
    }

    private updateCountriesTranslations(): void {
        this.countries.forEach((country) => {
            country.label = this.opDataContext.$t(CountriesSid[country.value]).toString();
        })
        this.opDataContext.countryOptionsRefreshKey = !this.opDataContext.countryOptionsRefreshKey
    }

    private getStatusTranslationPath(statusId: number): string {
        return this.opDataContext.$t(FiltersOptionsProvider.StatusTranslationPath + statusId).toString();
    }
}
