import { EventBus } from '@/utils/eventbus';
import { Unit, MasterApiUnit } from './contracts/Unit';
import { UnitsService } from '../../services/units-service';

type UnitsState = {
    units: Array<Unit>;
    currentUnit: Unit;
    areUnitsAvailable: boolean;
};

export const units = {
    namespaced: true,
    state: (): any => ({
        units: Array<Unit>(),
        currentUnit: undefined, // {} as Unit
        // By default, we assume that all is fine.
        areUnitsAvailable: true
    }),
    getters: {
        getAllUnits: (state: any): Array<Unit> => state.units,
        getCurrentUnit: (state: any): Unit => state.currentUnit,
        areUnitsAvailable: (state: any): boolean => state.areUnitsAvailable,
        noUnitsAvailable: (state: any): boolean => !state.areUnitsAvailable,
        getUnitByMachineSid: (state: UnitsState): any => (machineSid: number): Unit => state.units.filter(x => x.machineSid == machineSid)[0]
    },
    actions: {
        fetchUnits: async ({ commit }: any): Promise<void> => {
            const connection = new UnitsService(334);
            await connection.getUnitsForCurrentUser()
                .then((data: any) => {
                    // Make sure to only proceed if all is well and we have at least one unit available.
                    if (data.result.units.length > 0) {
                        commit('SET_UNITS', data.result.units);
                    } else {
                        // Initiate some error feedback to the user if no unit is available.
                        commit('SET_NO_UNITS_AVAILABLE');
                        EventBus.$emit(EventBus.GLOBAL.NO_UNITS);
                    }
                })
                .catch((err: any) => {
                    commit('SET_NO_UNITS_AVAILABLE');
                    EventBus.$emit(EventBus.GLOBAL.NO_UNITS);
                })
        },
        setCurrentUnit: ({ commit }: any, unit: Unit): void => {
            commit('SET_CURRENT_UNIT', unit);
            commit('poweroutput/setNominalEnergy', unit.pMax, { root: true });
            commit('poweroutput/setMinCapacity', unit.pMin, { root: true });
        }
    },
    mutations: {
        SET_UNITS (state: any, units: MasterApiUnit[]): void {
            state.areUnitsAvailable = true;
            state.units = units.map(u => ({
                pMin: u.minCapacity,
                pMax: u.maxCapacity,
                plantName: u.plantName,
                unitName: u.unitName,
                machineSid: u.machineSid,
                plantSid: u.plantSid,
                countryName: u.countryName,
                countrySid: u.countrySid,
            } as Unit));

            // Make sure to have one unit selected by default. Prevents user to entering garbage bin event data.
            state.currentUnit = state.units[0];
        },
        SET_CURRENT_UNIT (state: any, unit: Unit): void {
            state.currentUnit = unit;
            state.areUnitsAvailable = true;
        },
        // If, for some reason, we don't have any units available, we reset the state and specifically mark the error.
        SET_NO_UNITS_AVAILABLE(state: any): void {
            state.units = Array<Unit>();
            state.currentUnit = {} as Unit;
            // Notify all views watching this, to handle / display an error rg. units not being available.
            state.areUnitsAvailable = false;
        }
    }
};
