/* eslint-disable sonarjs/no-duplicate-string */
import { EventBus } from '@/utils/eventbus';
import { EnumAttributes, EventInfoFilterModel } from '@/models';
import { Unit } from '@/store/modules/contracts/Unit';
import { CountriesSid, EventInfoStatus, EventInfoStatusSid, toLocalizedIsoString } from '@/utils/evt-mgmt-utils';
import { VendorOptions } from '@/components/event/event-table/contracts/table-data';
import { EventInfoDoughnutHelper } from './event-info-doughnut-helper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import store, { LoadingPanel } from '@/store';
import { eventInfoService } from '@/store/modules/event-management';
import { FilterOptions } from './contract/event-info-filter';
import moment from 'moment';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
import { PuiMultiselectOpenCloseEventEmitter } from '@/components/operational-data/op-data-filter/utils/PuiMultiselectOpenCloseEventEmitter';
import { TranslationMap } from '../event-history/translationmap';
import { formatWithAutoLocale }  from '@/utils/date-time-utils';

@Component
export default class EventInfoFilter extends Vue {
    @Prop({ required: true }) vendorOptions!: VendorOptions;

    toLocalizedIsoString = toLocalizedIsoString;

    @Watch('filterValues', { deep: true })
    onFilterValuesChange(value: any): void {
        window.sessionStorage.setItem('eventInfoFilterState', JSON.stringify(value));
    }

    @Watch('$store.state.eventManager.eventFilter')
    onEventFilterChange(): void {
        this.filterValues = this.$store.getters['eventManager/getEventFilter'];
    }

    @Watch('$store.state.eventManager.DoughnutFilterItems')
    onChange(): void {
        this.doughnutHelper?.refreshItems();
    }

    @Watch('localStartCalendarShow')
    onStartCalendarVisibilityChange(isVisible: boolean):void {
        if(isVisible && this.localEndCalendarShow)
            this.localEndCalendarShow = false;
    }

    @Watch('localEndCalendarShow')
    onEndCalendarVisibilityChange(isVisible: boolean):void {
        if(isVisible && this.localStartCalendarShow)
            this.localStartCalendarShow = false;
    }

    localStartCalendarShow = false;
    localEndCalendarShow = false;
    filterValues = this.clearedFilterFields();
    filterOptions: FilterOptions = {
        countries: [], // An other name for country is area.
        sites: [], // An other name for site is plant.
        units: [], // An other name for unit is machine.
        lifecycles: [],
        status: [],
        doughnut: [],
    };
    doughnutHelper: EventInfoDoughnutHelper | undefined = undefined;
    doughnutChartElementId = EventInfoDoughnutHelper.doughnutChartElementId;
    multiselectEventEmitter = new PuiMultiselectOpenCloseEventEmitter();
    multiselectIds = new Map<string, string>([
        ['country', 'countryDropdown'],
        ['site', 'siteDropdown'],
        ['unit', 'unitDropdown'],
        ['lifecycle', 'lifecycleDropdown'],
        ['status', 'statusDropdown'],
    ]);
    apiRequestsLock = false;
    statusesDropdownRefreshKey = 0;
    countriesDropDownRefresKey = 0;
    lifecycleDropDownRefresKey = 0;

    get getAllUnits(): Array<Unit> {
        return store.getters['units/getAllUnits'];
    }

    dateFormat = 'yyyy-MM-DDTHH:mm';
    displayDateFormat = 'L HH:mm';
    previousStartDateValue: string | undefined;
    previousEndDateValue: string | undefined;
    get localTimeEventStart(): any {
        if (this.filterValues.EventStart)
            return formatWithAutoLocale({ utc: true, datetime: this.filterValues.EventStart, format: this.displayDateFormat });
        else
            return undefined;
    }
    set localTimeEventStart(newValue: any) {
        if (newValue !== null) {
            this.filterValues.EventStart = moment(newValue).utc().format(this.dateFormat);
        }
    }
    get localTimeEventEnd(): any {
        if (this.filterValues.EventEnd)
            return formatWithAutoLocale({ utc: true, datetime: this.filterValues.EventEnd, format: this.displayDateFormat });
        else
            return undefined;
    }

    set localTimeEventEnd(newValue: any) {
        if (newValue !== null) {
            this.filterValues.EventEnd = moment(newValue).utc().format(this.dateFormat);
        }
    }

    created(): void {
        EventBus.$on(EventBus.GLOBAL.NO_UNITS, () => {
            (this as any).$pui.toast({
                type: 'error',
                title: 'No units available',
                copy: 'No sites / units are available. Please contact COODE application support for setting up the respective master data.',
            });
        });
    }

    async mounted(): Promise<void> {
        await this.initOptionFields();
        await store.dispatch('eventManager/getDoughnutFilterItems', { obj: this.removeEmptyFieldsFrom(this.filterValues) });

        this.previousStartDateValue = this.localTimeEventStart;
        this.previousEndDateValue = this.localTimeEventEnd;
        this.setupMultiselectObservers();
        EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => {
            this.updateStatusesTranslations();
            this.updateCountriesTranslations();
            this.updateLifecycleTranslation();
        });
    }

    private setupMultiselectObservers(): void {
        this.multiselectIds.forEach(id => {
            this.multiselectEventEmitter.subscribe(id);
            this.multiselectEventEmitter.onOpen(id, () => this.apiRequestsLock = true);
            this.multiselectEventEmitter.onClose(id, () => {
                this.apiRequestsLock = false;
                this.onFilterChange()
            });
        });
    }

    fillUpWithDecimalPlaces(number: string, places: number): string {
        return (number.toString().length < places ? '0' + number : number).toString();
    }

    onDateFilterChange(): void {
        const isAnyDateChange = this.previousStartDateValue !== this.localTimeEventStart ||
            this.previousEndDateValue !== this.localTimeEventEnd;

        if (!isAnyDateChange)
            return;

        this.onFilterChange();
        this.previousStartDateValue = this.localTimeEventStart;
        this.previousEndDateValue = this.localTimeEventEnd;
    }

    onFilterChange(): void {
        if (this.apiRequestsLock) return;
        this.apiRequestsLock = true;

        this.initSiteOptions();
        this.initUnitOptions();

        this.updateSiteSelection();
        this.updateUnitSelection();

        // check if only end date if chosen => set start date somewhere far in the past
        if (!this.filterValues.EventStart && this.filterValues.EventEnd) {
            this.filterValues.EventStart = '2020-01-01T00:00'
        }
        // check if only start date if chosen => set end date to today
        if (!this.filterValues.EventEnd && this.filterValues.EventStart) {
            const today = new Date().toISOString().slice(0, 16)
            //if EventStart is selected in the future, add one day to the eventend by default
            if (this.filterValues.EventStart > today) {
                this.filterValues.EventEnd = moment(this.filterValues.EventStart, this.dateFormat).clone().add(1, 'month').local().format(this.dateFormat);
            } else {
                this.filterValues.EventEnd = today;
            }
        }
        this.$store.dispatch('eventManager/setEventFilter', { obj: this.filterValues });
        EventBus.$emit(EventBus.VIEWS.INFO.FILTER_CHANGED, this.removeEmptyFieldsFrom(this.filterValues));
        this.apiRequestsLock = false;
    }
    removeEmptyFieldsFrom(filterFields: EventInfoFilterModel): EventInfoFilterModel {
        const cleanedFilter: EventInfoFilterModel = {
            page: filterFields.page,
            size: filterFields.size,
        }

        for (const field in filterFields) {
            if (typeof filterFields[field] !== 'undefined') {
                if (typeof filterFields[field] === 'number') {
                    cleanedFilter[field] = filterFields[field];
                }
                if (typeof filterFields[field] === 'string' && filterFields[field]) {
                    cleanedFilter[field] = filterFields[field];
                }
                if (Array.isArray(filterFields[field]) && filterFields[field].length > 0) {
                    cleanedFilter[field] = filterFields[field];
                }
            }
        }

        return cleanedFilter;
    }
    onClearFilters(): void {
        this.apiRequestsLock = true;
        this.filterValues = this.clearedFilterFields();
        this.$store.dispatch('eventManager/setEventFilter', { obj: this.filterValues });
        EventBus.$emit(EventBus.VIEWS.INFO.FILTER_CHANGED, this.removeEmptyFieldsFrom(this.filterValues));
        this.$nextTick(() => this.apiRequestsLock = false);
    }
    clearedFilterFields(): EventInfoFilterModel {
        return {
            EventStart: undefined,
            EventEnd: undefined,
            RPMaxFrom: undefined,
            RPMaxTo: undefined,
            EventReason: undefined,
            areaSids: [],
            plantSids: [],
            machineSids: [],
            lifecycleStatus: [],
            eventInfoPlusStatus: [],
            page: 1,
            size: 20,
            Term: undefined,
            TermToLower: undefined,
        };
    }
    async initOptionFields(): Promise<void> {
        try {
            const promises: Promise<any>[] = [
                this.initEventLivecycleOptions(),
                this.initEventInfoStatusOptions(),
            ];
            if (this.getAllUnits.length === 0) {
                promises.push(this.$store.dispatch('units/fetchUnits'));
            }
            await Promise.all(promises);
            this.initCountryOptions();
            this.initSiteOptions();
            this.initUnitOptions();
            this.preselectFilterValues();
            this.initDoughnutFilter();
        }
        catch (err) {
            if (this.$store.state.sidebar.renderingInProgress)
                store.commit('sidebar/SET_RENDERING_STATE', false);

            console.log(err);
        }
    }
    initDoughnutFilter(): void {
        if (store.state.operationalData.doughnutLoadingPanel)
            store.commit(OperationalDataMutationsNames.SetDoughnutLoadingPanel, false);

        //Get from Backend to store
        this.doughnutHelper = new EventInfoDoughnutHelper(this);

        if (this.$store.state.sidebar.renderingInProgress)
            store.commit('sidebar/SET_RENDERING_STATE', false);
    }
    initCountryOptions(): void {
        this.getAllUnits.forEach((unit: Unit) => {
            const newElement = { label: this.$t(CountriesSid[unit.countrySid]).toString(), value: unit.countrySid };
            const finding = this.filterOptions.countries.findIndex((element) => element.value === newElement.value);
            if (finding === -1) this.filterOptions.countries.push(newElement);
        });
        this.filterOptions.countries = this.sortSelectOptions(this.filterOptions.countries);
    }
    initSiteOptions(): void {
        if (this.filterValues.areaSids?.length) {
            const sites: Array<{ label: string; value: number }> = [];
            this.filterValues.areaSids.forEach((countrySid: number) => {
                this.getAllUnits.forEach((unit: Unit) => {
                    if (unit.countrySid === countrySid) {
                        const newElement = { label: unit.plantName, value: unit.plantSid };
                        const finding = sites.findIndex((element) => element.value === newElement.value);
                        if (finding === -1) sites.push(newElement);
                    }
                });
            });
            this.filterOptions.sites = this.sortSelectOptions(sites);
        }
        else {
            this.getAllUnits.forEach((unit: Unit) => {
                const newElement = { label: unit.plantName, value: unit.plantSid };
                const finding = this.filterOptions.sites.findIndex((element) => element.value === newElement.value);
                if (finding === -1) this.filterOptions.sites.push(newElement);
            });
            this.filterOptions.sites = this.sortSelectOptions(this.filterOptions.sites);
        }
    }
    initUnitOptions(): void {
        if (this.filterValues.plantSids?.length) {
            this.initUnitOptionsRestrictedBySite();
        }
        else if (this.filterValues.areaSids?.length) {
            this.initUnitOptionsRestrictedByCountry();
        }
        else {
            this.getAllUnits.forEach((unit: Unit) => {
                const newElement = { label: unit.unitName, value: unit.machineSid };
                const finding = this.filterOptions.units.findIndex((element) => element.value === newElement.value);
                if (finding === -1) this.filterOptions.units.push(newElement);
            });
            this.filterOptions.units = this.sortSelectOptions(this.filterOptions.units);
        }
    }
    initUnitOptionsRestrictedByCountry(): void {
        const units: Array<{ label: string; value: number }> = [];
        this.filterValues.areaSids?.forEach((areaSid: number) => {
            this.getAllUnits.forEach((unit: Unit) => {
                if (unit.countrySid === areaSid) {
                    const newElement = { label: unit.unitName, value: unit.machineSid };
                    const finding = units.findIndex((element) => element.value === newElement.value);
                    if (finding === -1) units.push(newElement);
                }
            });
        });
        this.filterOptions.units = this.sortSelectOptions(units);
    }
    initUnitOptionsRestrictedBySite(): void {
        const units: Array<{ label: string; value: number }> = [];
        this.filterValues.plantSids?.forEach((siteSid: number) => {
            this.getAllUnits.forEach((unit: Unit) => {
                if (unit.plantSid === siteSid) {
                    const newElement = { label: unit.unitName, value: unit.machineSid };
                    const finding = units.findIndex((element) => element.value === newElement.value);
                    if (finding === -1) units.push(newElement);
                }
            });
        });
        this.filterOptions.units = this.sortSelectOptions(units);
    }
    sortSelectOptions(selectOption: Array<{ label: string; value: number }>): Array<{ label: string; value: number }> {
        return selectOption.map((element: any) => {
            return { label: element.label, value: element.value }
        }).sort((a, b) => {
            return a.label - b.label;
        });
    }
    updateSiteSelection(): void {
        if (!this.filterValues.areaSids?.length || !this.filterValues.plantSids?.length) return;
        const plantSids: Array<number> = [];
        this.filterValues.plantSids?.forEach((plantSid: number) => {
            if (this.getAllUnits.findIndex((unit) => (unit.plantSid === plantSid && this.filterValues.areaSids?.includes(unit.countrySid))) !== -1) {
                plantSids.push(plantSid);
            }
        });
        this.filterValues.plantSids = plantSids;
    }
    updateUnitSelection(): void {
        if (this.filterValues.machineSids?.length && this.filterValues.plantSids?.length) {
            const machineSids: Array<number> = [];
            this.filterValues.machineSids?.forEach((machineSid: number) => {
                if (this.getAllUnits.findIndex((unit) => (unit.machineSid === machineSid && this.filterValues.plantSids?.includes(unit.plantSid))) !== -1) {
                    machineSids.push(machineSid);
                }
            });
            this.filterValues.machineSids = machineSids;
        }
        else if (this.filterValues.machineSids?.length && this.filterValues.areaSids?.length) {
            const machineSids: Array<number> = [];
            this.filterValues.machineSids?.forEach((machineSid: number) => {
                if (this.getAllUnits.findIndex((unit) => (unit.machineSid === machineSid && this.filterValues.areaSids?.includes(unit.countrySid))) !== -1) {
                    machineSids.push(machineSid);
                }
            });
            this.filterValues.machineSids = machineSids;
        }
    }
    async initEventLivecycleOptions(): Promise<void> {
        try {
            const eventLivecycleEnumTypeId = 730;
            const enums = (await eventInfoService.getEnumAttributes(eventLivecycleEnumTypeId)).result.enums
            this.filterOptions.lifecycles = enums.filter((value: EnumAttributes) => {
                return value.idTypeSid === eventLivecycleEnumTypeId;
            }).sort((a, b) => {
                return a.sid - b.sid;
            }).map((value) => (
                { label: this.$t(TranslationMap.sid[value.sid]), value: value.sid }
            )).filter(filterOption => !!filterOption.label);
        } catch (error) {
            console.log(error)
        }
    }
    async initEventInfoStatusOptions(): Promise<void> {
        try {
            const eventStatusInfoEnumTypeId = 740;
            const enums = (await eventInfoService.getEnumAttributes(eventStatusInfoEnumTypeId)).result.enums;
            this.filterOptions.status = enums
                .filter(enumAttributes => enumAttributes.idTypeSid === eventStatusInfoEnumTypeId)
                .sort((a, b) => a.sid - b.sid)
                .map((value) => ({
                    label: this.$t((EventInfoStatus as any)[EventInfoStatusSid[value.sid]]) as string,
                    value: value.sid
                }));
        } catch (error) {
            console.log(error)
        }
    }
    private updateStatusesTranslations(): void {
        this.filterOptions.status.forEach((status: any) => {
            status.label = this.$t((EventInfoStatus as any)[EventInfoStatusSid[status.value]]) as string;
        });

        this.statusesDropdownRefreshKey += 1;
    }

    private updateCountriesTranslations(): void {
        this.filterOptions.countries.forEach((country: any) => {
            country.label = this.$t(CountriesSid[country.value]).toString();
        })
        this.countriesDropDownRefresKey += 1;
    }

    private updateLifecycleTranslation(): void {
        this.filterOptions.lifecycles.forEach((lifecycle: any) => {
            lifecycle.label = this.$t(TranslationMap.sid[lifecycle.value]).toString();
        })
        this.lifecycleDropDownRefresKey += 1;
    }

    preselectFilterValues(): void {
        if (window.sessionStorage.eventInfoFilterState) {
            this.filterValues = { ...this.clearedFilterFields(), ...JSON.parse(window.sessionStorage.getItem('eventInfoFilterState') || '{}') };
            this.$store.dispatch('eventManager/setEventFilter', { obj: this.filterValues });
        }
    }
    get isLoading(): boolean {
        return store.getters.isLoadingPanelVisible(LoadingPanel.EventInfoTable);
    }
    get isFilterEmpty(): boolean {
        if (!this.filterValues.EventStart &&
            !this.filterValues.EventEnd &&
            this.filterValues.areaSids?.length === 0 &&
            this.filterValues.plantSids?.length === 0 &&
            this.filterValues.machineSids?.length === 0 &&
            this.filterValues.lifecycleStatus?.length === 0 &&
            this.filterValues.eventInfoPlusStatus?.length === 0
        ) return true;
        return false;
    }
}
