import { Vue } from 'vue-property-decorator';
import { AxiosRequestConfig } from 'axios';
import { Api } from '@coode/fe-sdk/dist/networking';

export default class ApiHelper {
    private readonly endpoint?: string;
    private readonly api: Api = Vue.prototype.$sdk?.$http?.$generic.request.api;

    constructor(endpoint?: string, api: 'generic' | 'metaData' | 'masterData' | 'userAccessManagement' = 'generic') {
        this.endpoint = endpoint;
        switch (api) {
        case 'generic':
            this.api = Vue.prototype.$sdk?.$http?.$generic.request.api;
            break;
        case 'metaData':
            this.api = Vue.prototype.$sdk?.$http?.$core.metaData.request.api;
            break;
        case 'masterData':
            this.api = Vue.prototype.$sdk?.$http?.$core.masterData.request.api;
            break;
        case 'userAccessManagement':
            this.api = Vue.prototype.$sdk?.$http?.$core.userAccessManagement.request.api;
            break;
        }
    }

    public async get(query?: string, additionalPath?: string): Promise<any> {
        let endpoint = this.mergeEndpoint(additionalPath);
        endpoint = query ? `${endpoint}?${ApiHelper.correctQuery(query)}` : endpoint;
        return Vue.prototype.$sdk?.$http?.$generic.request.api.get(endpoint);
    }

    public async getWithParams(params: Record<string, string | number>): Promise<any> {
        const endpoint = this.mergeEndpoint(undefined);
        return Vue.prototype.$sdk?.$http?.$generic.request.api.get(endpoint, { params: params });
    }

    public async getByPathOrParam(pathParam: string | number): Promise<any> {
        const endpoint = this.mergeEndpoint(pathParam);
        return Vue.prototype.$sdk?.$http?.$generic.request.api.get(endpoint);
    }

    public async post(additionalPath?: string, data?: object, query?: string, config?: AxiosRequestConfig): Promise<any> {
        let endpoint = this.mergeEndpoint(additionalPath);
        endpoint = query ? `${endpoint}?${ApiHelper.correctQuery(query)}` : endpoint;
        return Vue.prototype.$sdk?.$http?.$generic.request.api.post(endpoint, data, config);
    }

    public async put(pathOrParam?: string | number, data?: object): Promise<any> {
        const endpoint = this.mergeEndpoint(pathOrParam);
        return Vue.prototype.$sdk?.$http?.$generic.request.api.put(endpoint, data);
    }

    public async delete(pathOrParam?: string | number, query?: string, config?: AxiosRequestConfig): Promise<any> {
        let endpoint = this.mergeEndpoint(pathOrParam);
        endpoint = query ? `${endpoint}?${ApiHelper.correctQuery(query)}` : endpoint;
        return Vue.prototype.$sdk?.$http?.$generic.request.api.delete(endpoint, config);
    }

    private mergeEndpoint(additionalPath?: string | number): string {
        if (!this.endpoint) {
            return additionalPath
                ? `${additionalPath}`
                : '';
        }

        return additionalPath
            ? `${this.endpoint}/${additionalPath}`
            : this.endpoint;
    }

    private static correctQuery(query?: string): string | undefined {
        if (query && query[0] === '?') {
            return query.substring(1);
        }
        return query;
    }
}

export function extractErrorsFromResponse(errorResponseData: any): string[] {
    if (!errorResponseData) {
        return [];
    }

    if (errorResponseData.errors && Array.isArray(errorResponseData.errors)) {
        return errorResponseData.errors;
    } else if (errorResponseData.message) {
        return [errorResponseData.message];
    }

    return [];
}
