import { OpDataUnitKPI } from '@/models/operational-data/operational-data-details';
import store from '@/store';
import { OperationalDataGettersNames } from '@/store/modules/operational-data/operational-data-getters';
import { Component, Vue } from 'vue-property-decorator';
import CustomInput from '@/components/utils/custom-input/custom-input.vue';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
import { cloneDeep } from 'lodash';
import { ModeSettings } from '@/utils/mode-settings';

@Component({
    components:{
        CustomInput
    }
})
export default class UnitReliability extends Vue {
    min = 0;
    required = true;
    precision = ModeSettings.InputNumberPrecision;

    created(): void {
        if (this.activeUnitCounty === 'Sweden') {
            if (this.activeUnitKPIModel.Starts.FailedCommercial === undefined) {
                this.onInputChange(0, 'Starts.FailedCommercial');
            }
            if (this.activeUnitKPIModel.Starts.FailedTechnical === undefined) {
                this.onInputChange(0, 'Starts.FailedTechnical');
            }
        }
    }

    get isReaderUser(): boolean {
        const opData = store.state.operationalData.activeStatus;
        return (opData && opData.reportStatusSid) ?
            !store.getters['userAccessManagement/useButtonSave'](opData.reportStatusSid) : true;
    }

    get activeUnitKPIModel(): OpDataUnitKPI {
        return cloneDeep(store.getters[OperationalDataGettersNames.GetActiveUnitKPI]);
    }

    get activeUnitCounty(): string {
        return cloneDeep(store.getters[OperationalDataGettersNames.GetActiveDetailsModel]).Metadata.Country.Name;
    }

    onInputChange(value: number, propertyPath: string): void {
        store.commit(OperationalDataMutationsNames.SetUnitKpiProperty, {propertyPath: propertyPath, value});
    }

    onInputChangeSweden(value: number, propertyPath: string): void {
        store.commit(OperationalDataMutationsNames.SetUnitKpiProperty, { propertyPath: propertyPath, value });

        const tempFailedTechnical = this.activeUnitKPIModel.Starts.FailedTechnical ? this.activeUnitKPIModel.Starts.FailedTechnical : 0;
        const tempFailedCommercial = this.activeUnitKPIModel.Starts.FailedCommercial ? this.activeUnitKPIModel.Starts.FailedCommercial : 0;
        const sumFailedstarts = tempFailedTechnical + tempFailedCommercial
        this.onInputChange(sumFailedstarts, 'Starts.Failed')
    }
}
