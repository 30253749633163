import { Component } from 'vue-property-decorator';
import CustomInput from '@/components/utils/custom-input/custom-input.vue';
import store from '@/store';
import { cloneDeep } from 'lodash';
import { OpDataUnitKPI, UnitQualityPctModel, UnitQualityTempModel } from '@/models/operational-data/operational-data-details';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
import { ModeSettings } from '@/utils/mode-settings';
import { OperationalDataGettersNames } from '@/store/modules/operational-data/operational-data-getters';
import { service } from '@/store/modules/operational-data/operational-data-actions';
import moment from 'moment';
import { CustomInputValidationState } from '../../op-data-comand-buttons/custom-inputs-validator';
import { TabsValidationUpdateModel } from '@/models/operational-data/operational-data';
import BaseCustomInputValidation from '@/components/utils/base-custom-input-validation/base-custom-input-validation';

@Component({
    components: {
        CustomInput,
    }
})
export default class QualityLossesTab extends BaseCustomInputValidation {
    readonly qualityLossesPrecision = ModeSettings.InputNumberPrecisionQualityTab;
    sum = 0;
    maxInputLength = 250;

    qualityLossesPct: any = cloneDeep(store.state.operationalData.activeUnitQualityPctModels);
    qualityLossesTemp: any = cloneDeep(store.state.operationalData.activeUnitQualityTempModels);
    prevReportMonthDate: string = moment((store.getters[OperationalDataGettersNames.GetActiveUnitKPI] as OpDataUnitKPI).ReportMonth)
        .subtract(1, 'month').format('YYYY-MM');

    created(): void {
        this.calculateSum();
        this.onValidationStateChange = this.onCustomInputsValidationStateChange;
        this.$nextTick(() => this.validateCustomInputs(this));
    }

    onCustomInputsValidationStateChange(inputs: CustomInputValidationState[]): void {
        const tabValidationUpdateModel: TabsValidationUpdateModel = {
            key: 'isQualityTabValid',
            isValid: inputs.every(i => i.isValid) && this.checkValidity()
        }

        store.commit(OperationalDataMutationsNames.SetTabValidationState, tabValidationUpdateModel);
    }

    get isReadOnly(): boolean {
        const opData = store.state.operationalData.activeStatus;
        return (opData && opData.reportStatusSid) ?
            !store.getters['userAccessManagement/useButtonSave'](opData.reportStatusSid) : true;
    }

    get isGT(): boolean {
        return !!store.state.operationalData.activeComponentsModels.filter(sourceBlock => sourceBlock.sourceBlockName.includes('GT')).length;
    }

    atInputChangePct(item: UnitQualityPctModel): void {
        store.commit(OperationalDataMutationsNames.SetActiveUnitQualityLossesPct, item);
        this.calculateSum();
    }

    atInputChangeTemp(item: UnitQualityTempModel): void {
        store.commit(OperationalDataMutationsNames.SetActiveUnitQualityLossesTemp, item);
        this.calculateSum();
    }

    calculateSum(): void {
        const newSum = this.qualityLossesPct.valueInternal + this.qualityLossesPct.valueExternal + this.qualityLossesPct.valueOther;
        this.sum = parseFloat(newSum.toFixed(this.qualityLossesPrecision));
    }

    checkValidity(): boolean {
        const opInternal = (document.getElementById('customInputOpInternal') as HTMLInputElement).checkValidity();
        const opExternal = (document.getElementById('customInputOpExternal') as HTMLInputElement).checkValidity();
        const other = (document.getElementById('customInputOther') as HTMLInputElement).checkValidity();

        if (opInternal && opExternal && other && this.sum <= 100)
            return true
        return false
    }

    commentLength(comment: string | undefined): number {
        if (comment)
            return comment.length;
        return 0;
    }

    async commentButtonClick(): Promise<void> {
        if (await this.CheckPrevMonthStatus()) {
            this.replaceComments();
        }
    }

    async CheckPrevMonthStatus(): Promise<boolean> {
        try {
            const prevMonthStatus = (await service.getStatus(Number(this.$route.params.unitId), this.prevReportMonthDate)).result.reportStatusSid;
            if (prevMonthStatus === 751 || prevMonthStatus === 752) {

                this.popCommentNotAvailable();
                return false;
            } else {
                return true;
            }
        }
        catch (err) {
            this.popPreviousMonthNotAvailable();
            throw new Error;
        }


    }

    async replaceComments(): Promise<void> {
        try {
            const apiResponse = (await service.getOperationalDataEntry(Number(this.$route.params.unitId), this.prevReportMonthDate)).result;
            this.qualityLossesPct.commentExternal = apiResponse.QualityLossesPct?.commentExternal;
            this.qualityLossesPct.commentInternal = apiResponse.QualityLossesPct?.commentInternal;
            this.qualityLossesPct.commentOther = apiResponse.QualityLossesPct?.commentOther;
        }
        catch (err) {
            this.popCommentNotAvailable();
        }

    }

    popCommentNotAvailable(): void {
        (this as any).$pui.toast({
            title: this.$t('operationalData.entryView.tabs.qualityLossesTab.toastPrevMonthMessage.header').toString(),
            copy: this.$t('operationalData.entryView.tabs.qualityLossesTab.toastPrevMonthMessage.text').toString()
        })
    }
    popPreviousMonthNotAvailable(): void {
        (this as any).$pui.toast({
            title: this.$t('operationalData.entryView.tabs.qualityLossesTab.toastPrevMonthUnavailable.header').toString(),
            copy: this.$t('operationalData.entryView.tabs.qualityLossesTab.toastPrevMonthUnavailable.text').toString()
        })
    }

}
