import { EnumHelper } from '../../../../models/operational-data/operational-data';
import { ColDef, GridOptions } from 'ag-grid-community';
import { OperationalDataStatusColor } from '@/utils/mode-statuses-colors';
import i18n from '@/utils/i18n';

export class OpTableConfigurator {
    constructor() {
        this.setupGridOptions();
    }

    gridOptions!: GridOptions;

    public updateGridOptions(): Array<ColDef>{
        return this.initColumnDefs();
    }

    private initColumnDefs(): Array<ColDef> {
        return [
            { headerName: i18n.t('country').toString() ,field: 'Country', valueGetter: 'data.Country.Name' },
            { headerName: i18n.t('site').toString() ,field: 'Site', valueGetter: 'data.Site.Name' },
            { headerName: i18n.t('unit').toString() ,field: 'Unit', valueGetter: 'data.Unit.Name'},
            { headerName: i18n.t('date').toString() ,field: 'Date', cellRenderer: this.dateRenderer },
            { headerName: i18n.t('status').toString() ,field: 'Status', cellRenderer: this.statusRenderer, comparator: (valueA: {Name: string}, valueB: {Name: string}): number => {
                if (valueA.Name == valueB.Name) return 0;
                return (valueA.Name > valueB.Name) ? 1 : -1;
            }},
        ]
    }
    private setupGridOptions(): void {

        const defaultColDef: ColDef = {
            editable: false,
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 100,
            unSortIcon: true
        };

        this.gridOptions = {
            columnDefs: this.initColumnDefs(),
            defaultColDef: defaultColDef,
            rowSelection: 'single',
            suppressCellSelection: true,
            animateRows: true,
            multiSortKey: 'ctrl'
        };

    }

    private statusRenderer(params: any): string {
        const color =  OperationalDataStatusColor.get(params.value.Id);
        return ` <b><span style="color:${color}">${params.value.Name}<span/></b>`;
    }

    private dateRenderer(params: any): string{
        const unitId = params.node.data.Unit.Id;
        const date = new Date(params.value).toISOString().substring(0, 7);
        return `<a href="#/operationalData/${unitId}/${date}" target="_self">${date}</a>`;
    }
}
