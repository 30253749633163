
import { Component, Vue } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { GridOptions } from 'ag-grid-community';
import { OpTableConfigurator } from './util/OpTableConfigurator';
import store, { StateWithModules } from '@/store';
import { OperationalDataActionsNames } from '@/store/modules/operational-data/operational-data-actions';
import { OperationalDataModel } from '@/models/operational-data/operational-data';
import { EventBus } from '@/utils';
import { CountriesSid } from '@/utils/evt-mgmt-utils';

@Component({
    components: {
        AgGridVue
    }
})
export default class OperationalDataTable extends Vue {
    tableConfigurator!: OpTableConfigurator;

    get gridOptions(): GridOptions {

        return this.tableConfigurator.gridOptions;
    }

    beforeMount(): void {
        this.tableConfigurator = new OpTableConfigurator();
        store.watch(
            (state: StateWithModules) => state.operationalData.filteredTableData,
            this.onTableDataChange
        );
    }
    private onTableDataChange(opData: OperationalDataModel[]): void {
        opData.forEach(entry => {
            entry.Status.Name = this.$t('operationalData.filters.statuses.' + entry.Status.Id) as string
            entry.Country.Name = this.$t(CountriesSid[entry.Country.Id]).toString();
        });
        this.gridOptions.api?.setRowData(opData);
    }

    mounted(): void {
        EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => this.onLanguageChange());
        const tableData = store.state.operationalData.tableData;
        if (!tableData.length) {
            store.dispatch(OperationalDataActionsNames.LoadAvailableStatuses)
                .then(() => store.dispatch(OperationalDataActionsNames.LoadOperationalData));
        }
        else{
            const filteredTableData = store.state.operationalData.filteredTableData;
            this.onTableDataChange(filteredTableData.length ? filteredTableData : tableData);
        }
    }

    onLanguageChange(): void {
        this.gridOptions.api?.forEachNode(node => {
            const opDataModel = node.data as OperationalDataModel;
            opDataModel.Country.Name = this.$t(CountriesSid[opDataModel.Country.Id]).toString();
            opDataModel.Status.Name = this.$t('operationalData.filters.statuses.' + opDataModel.Status.Id) as string;
            node.setData(opDataModel);
        });
        this.gridOptions.api?.setColumnDefs(this.tableConfigurator.updateGridOptions())
    }

    onGridReady(): void {
        this.gridOptions.api?.sizeColumnsToFit();
        this.gridOptions.columnApi?.applyColumnState({
            state: [
                { colId: 'Date', sort: 'desc', sortIndex: 0 },
                { colId: 'Unit', sort: 'asc', sortIndex: 1 },
            ],
        });
    }
}
