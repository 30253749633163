import { Sidebar } from './contracts/Sidebar';

export const sidebar = {
    namespaced: true,
    state: (): any => ({
        renderingInProgress: false,
        currentSidebar: { shouldShowSidebar: false, currentSidebarComponent: '', sidebarComponentProperties: null }
    }),
    getters: {
        getCurrentSidebar: (state: any): Sidebar => state.currentSidebar,
    },
    actions: {
        setCurrentSidebar: ({ commit }: any, sidebar: Sidebar): void => {
            commit('SET_IF_SHOW_SIDEBAR', sidebar.shouldShowSidebar);
            commit('SET_CURRENT_SIDEBAR', sidebar.currentSidebarComponent);
            commit('SET_CURRENT_SIDEBAR_PROPS', sidebar.sidebarComponentProperties);
        }
    },
    mutations: {
        SET_RENDERING_STATE(state: any, isRenderingInProgress: boolean): void {
            state.renderingInProgress = isRenderingInProgress;
        },
        SET_IF_SHOW_SIDEBAR(state: any, shouldShowSidebar: boolean): void {
            state.currentSidebar.shouldShowSidebar = shouldShowSidebar;
        },
        SET_CURRENT_SIDEBAR(state: any, componentName: string): void {
            state.currentSidebar.currentSidebarComponent = componentName;
        },
        SET_CURRENT_SIDEBAR_PROPS(state: any, sidebarComponentProperties: any): void {
            state.currentSidebar.sidebarComponentProperties = sidebarComponentProperties;
        }
    }
};
