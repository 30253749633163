import store from '@/store';
import { EventBus } from '@/utils';
import { DoughnutFilter, DoughnutItem } from '@/utils/doughnut-filter-utils/doughnut-filter';
import { EventInfoStatus, EventInfoStatusColor, EventInfoStatusSid } from '@/utils/evt-mgmt-utils';
import i18n from '@/utils/i18n';

import EventInfoContext from './event-info-filter';

export class EventInfoDoughnutHelper {
    static doughnutChartElementId = 'doughnutEventInfoChart';

    private eventInfoContext!: EventInfoContext;

    constructor(eventInfoContext: EventInfoContext) {
        this.eventInfoContext = eventInfoContext;
        this.initDoughnutFilter();
        EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => this.refreshDoughnut());
    }

    private refreshDoughnut(): void {
        this.doughnutFilter?.setTitle(this.title);
        this.refreshItems();
    }

    doughnutFilter: DoughnutFilter | null = null;
    statuses: DoughnutItem[] = [];

    private get title(): string {
        return this.eventInfoContext.$t('doughnutTitle.eventInfoText') as string;
    }

    refreshItems(): void {
        const statuses = this.prepareStatusesForDoughnutFilter();
        this.doughnutFilter?.setItems(statuses);
    }

    initDoughnutFilter(): void {
        const statuses = this.prepareStatusesForDoughnutFilter();
        this.doughnutFilter = new DoughnutFilter(
            statuses,
            EventInfoDoughnutHelper.doughnutChartElementId,
            this.title
        );

        this.doughnutFilter.chart.on('click', (params: any) => {
            this.updateStatusFilters(params.data);
        });
    }

    private prepareStatusesForDoughnutFilter(): DoughnutItem[] {
        const statuses: DoughnutItem[] = [];
        const counts = this.getDoughnutItems();
        const sortOrderByStatusKey = Object.keys(EventInfoStatusColor);
        sortOrderByStatusKey.forEach(statusKey => {
            const count = counts[statusKey];
            if (!count)
                return;

            statuses.push({
                value: count,
                name: this.eventInfoContext.$t((EventInfoStatus as any)[statusKey]) as string,
                itemStyle: { color: EventInfoStatusColor[statusKey as any] }
            });

        });
        return statuses;
    }

    private getDoughnutItems(): { [key: string]: number }{
        const result = {} as { [key: string]: number };
        const items = store.getters['eventManager/getDoughnutFilterItems'];
        if(items){
            items.forEach((entry: any) => {
                const name = i18n.t((EventInfoStatusSid as any)[entry.enumEventInfoStatusSid]).toString();
                result[name] = entry.eventCount;
            });
        } else {
            console.log('No doughnut filter entries found.');
        }
        return result;
    }

    private updateStatusFilters(doughnutItem: DoughnutItem): void {
        const status = this.eventInfoContext.filterOptions.status.find((status: any) => status.label === doughnutItem.name);
        if (!status)
            return;

        this.onEventInfoStatusClicked((status as any).value);
    }

    private onEventInfoStatusClicked(filterValue: number): void {
        if (this.eventInfoContext.filterValues.eventInfoPlusStatus) {
            const finding = this.eventInfoContext.filterValues.eventInfoPlusStatus.indexOf(filterValue);
            if (finding > -1) {
                this.eventInfoContext.filterValues.eventInfoPlusStatus.splice(finding, 1);
            } else {
                this.eventInfoContext.filterValues.eventInfoPlusStatus.push(filterValue);
            }
        }
    }
}
