import store from '@/store';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class NotFoundPage extends Vue {
    @Prop({ default: 'An error occured' }) errorResponse!: string;
    @Prop({ default: 'Back' }) buttonLabel!: string;
    @Prop({ default: '/' }) buttonLink!: string;
    @Prop({ default: true }) buttonIsVisible!: boolean;
    @Prop() pageReloadTimeout!: number | undefined;

    reloadIn = 0;

    buttonFunction(): void {
        store.commit('setErrorData', undefined);
        const isSameRoute = this.$router.currentRoute.path === this.buttonLink;
        if (isSameRoute)
            this.$router.go(0);
        else
            this.$router.push(this.buttonLink);
    }

    mounted(): void {
        if (this.pageReloadTimeout !== undefined) {
            this.reloadIn = this.pageReloadTimeout;
            this.runTimer(this.pageReloadTimeout);
        }
    }

    async runTimer(seconds: number): Promise<void> {
        for (let i = 0; i < seconds; i++) {
            await this.timerTick();
            this.reloadIn--;
        }

        window.location.reload();
    }

    private timerTick(): Promise<void> {
        return new Promise<void>((resolve) => setTimeout(resolve, 1000));
    }
}
