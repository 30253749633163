import Vue, { PropType } from 'vue';
import BreadcrumbItem from './breadcrumb-item/breadcrumb-item.vue';

const Breadcrumb = Vue.extend({
    name: 'Breadcrumb',
    components: {
        BreadcrumbItem
    },
    props: {
        links: {
            type: Array as PropType<Array<{label: string; href: string; isActive?: boolean}>>,
            default: [],
            required: false
        }
    },
    methods: {
        onActiveRouteChanged(value: {href: string}): void {
            this.$emit('active-route-changed', value.href);
        }
    }
});

export default Breadcrumb;
