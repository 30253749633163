import Vue from 'vue'
import { toLocalizedIsoString } from '@/utils/evt-mgmt-utils';
import {eventInfoService} from '@/store/modules/event-management';

import moment from 'moment';

const EventSplit = Vue.extend({
    name: 'EventSplit',
    components: {
    },
    props: {
        eventId: {
            type: Number,
            required: true
        },
        nextSplitEventId: {
            type: Number,
            required: true
        },
        startDate: {
            type: String,
            required: true
        },
        endDate: {
            type: String,
            required: true
        },
    },
    data(): {
        splitConfirmed: boolean;
        splitDate: string;
        splitDateForReset: string;
        showDateTimePicker: boolean;
        selectableRange: {
            start: string;
            end: string;
        };
        dateFormat: string;
        dataDateFormat: string;
        } {
        return {
            splitConfirmed: false,
            splitDate: '',
            splitDateForReset: '',
            showDateTimePicker: false,
            selectableRange: {
                start: '',
                end: ''
            },
            dateFormat: 'L HH:mm',
            dataDateFormat: 'yyyy-MM-DDTHH:mm',
        }
    },
    created(): void {
        this.selectableRange.start = moment.utc(this.startDate).local().add(1, 'minutes').format(this.dataDateFormat);
        this.selectableRange.end = moment.utc(this.endDate).local().subtract(1, 'minutes').format(this.dataDateFormat);
        this.splitDate = moment(this.startDate).add(1, 'minutes').toString();
    },
    computed: {
        localSplitDate: {
            get: function (): any {
                return moment.utc(this.splitDate).local().format(this.dataDateFormat);
            },
            set: function (newValue: any): void {
                this.splitDate = moment(newValue).utc().format(this.dataDateFormat) + ':00';
            }
        },
        invalidDateMessage(): string {
            return this.$t('split.invalidDate', { start: moment.utc(this.startDate).local().add(1, 'minutes').locale(navigator.language).format(this.dateFormat), end: moment.utc(this.endDate).local().subtract(1, 'minutes').locale(navigator.language).format(this.dateFormat) }).toString()
        },
    },
    methods: {
        toLocalizedIsoString: toLocalizedIsoString,
        closeDialog(): void {
            this.splitConfirmed = false;
            this.splitDate = this.startDate;
            this.localSplitDate = this.selectableRange.start;
            (this.$refs['splitEventModalRef'] as any).close();
        },
        showDateInput(): void {
            this.splitConfirmed = true;
        },
        showDatePicker(): void {
            if(!this.showDateTimePicker)
                this.splitDateForReset = this.localSplitDate;
            this.showDateTimePicker = !this.showDateTimePicker;
        },
        resetSplitDate(): void {
            this.localSplitDate = this.splitDateForReset;
            this.showDateTimePicker = false;
        },
        applySplitDate(): void {
            this.showDateTimePicker = false;
        },
        isDateInvalid(): boolean {
            if (this.localSplitDate < this.selectableRange.start || this.localSplitDate > this.selectableRange.end)
                return true;
            return false;
        },
        showHistory(): void {
            console.log('function not implemented yet');
        },
        async confirmSplitEvent(): Promise<void> {
            const splitDate = this.splitDate;
            (this.$refs['splitEventModalRef'] as any).close();
            this.$store.commit('loading');
            try {
                if (!this.eventId || !this.nextSplitEventId)
                    throw new Error('Invalid Id\'s');

                if(new Date(splitDate) <= new Date(this.startDate) && new Date(splitDate) >= new Date(this.endDate))
                    throw new Error('Invalid spit date');

                const response = (await eventInfoService.postSplitEvent(this.eventId, splitDate));

                if (response.statusCode == 200) {
                    this.$router.push({ path: `/eventinfo/${this.eventId}.${this.nextSplitEventId}` });
                    this.popToastSuccess();
                } else {
                    throw new Error(response.message ? response.message : 'Unknown error');
                }
            } catch (error) {
                this.popToastError();
                console.log(error);
            }
            this.$store.commit('loading');
        },
        popToastSuccess(): void{
            (this as any).$pui.toast({
                title: this.$t('eventSplittingModal.successTitle').toString(),
                copy: this.$t('eventSplittingModal.successText').toString()
            })
        },
        popToastError(): void {
            (this as any).$pui.toast({
                title: this.$t('eventSplittingModal.errorTitle').toString(),
                copy: this.$t('eventSplittingModal.errorText').toString()
            })
        },
    }
})

export default EventSplit;
