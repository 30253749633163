import { PermissionEnum } from '@/models/permissions-matrix';
import { Supervisor } from '@/models/supervisor';
import { UserService } from '@/services/user-service';
import _ from 'lodash';


type UserAccessManagementState = {
    userRole: string | undefined;
    userId: number | undefined;
    supervisor: Supervisor | undefined;
}

const capitalizeFirstLetter = (string: string): string => string.charAt(0).toLocaleUpperCase() + string.slice(1);
function getKeyName(value: number): keyof typeof PermissionEnum {
    return PermissionEnum[value] as keyof typeof PermissionEnum;
}

export const ReaderRoleName = 'Reader';
export const userAccessManagement = {
    namespaced: true,
    state: (): UserAccessManagementState => ({
        userRole: undefined,
        supervisor: undefined,
        userId: undefined
    }),
    getters: {
        isReaderUser: (state: UserAccessManagementState): boolean => state.userRole === ReaderRoleName,
        getUserRole: (state: UserAccessManagementState): string | undefined => state.userRole,
        getUserId: (state: UserAccessManagementState): number | undefined => Number(state.userId),
        useButtonSave: (state: UserAccessManagementState, getters: any, rootState: any, rootGetters: any): (statusSid: number) => boolean =>
            (statusSid: number): boolean => {
                if (statusSid){
                    return !!state.supervisor?.hasAccess('UseButtonSave', getKeyName(statusSid));
                }
                return false;
            },
        useButtonSplit: (state: UserAccessManagementState): (statusSid: number) => boolean =>
            (statusSid: number): boolean => {
                if (statusSid){
                    return !!state.supervisor?.hasAccess('UseButtonSplitEvent', getKeyName(statusSid));
                }
                return false;
            },
        useButtonSubmit: (state: UserAccessManagementState): (statusSid: number) => boolean =>
            (statusSid: number): boolean => {
                if (statusSid){
                    return !!state.supervisor?.hasAccess('UseButtonSubmit', getKeyName(statusSid));
                }
                return false;
            },
        useButtonFinal: (state: UserAccessManagementState): (statusSid: number) => boolean =>
            (statusSid: number): boolean => {
                if (statusSid){
                    return !!state.supervisor?.hasAccess('UseButtonFinal', getKeyName(statusSid));
                }
                return false;
            },
        useButtonRequestInfo: (state: UserAccessManagementState): (statusSid: number) => boolean =>
            (statusSid: number): boolean => {
                if (statusSid){
                    return !!state.supervisor?.hasAccess('UseButtonRequestInfo', getKeyName(statusSid));
                }
                return false;
            },
        useButtonReOpen: (state: UserAccessManagementState): (statusSid: number) => boolean =>
            (statusSid: number): boolean => {
                if (statusSid){
                    return !!state.supervisor?.hasAccess('UseButtonReOpen', getKeyName(statusSid));
                }
                return false;
            },
        useButtonDeleteSplit: (state: UserAccessManagementState): (statusSid: number) => boolean =>
            (statusSid: number): boolean => {
                if (statusSid){
                    return !!state.supervisor?.hasAccess('UseButtonDeleteSplit', getKeyName(statusSid));
                }
                return false;
            }
    },
    actions: {
        async setUserRoleAndPermissions (context: any, { service, userId}: { service: UserService; userId: number}): Promise<void> {
            const response = (await service.getUserRoleById(userId));
            if (response && response.result && response.result.name) {
                const role = _.startCase(_.camelCase(response.result.name)).replace(/ /g, '');
                const supervisor = new Supervisor(role);
                context.commit('SET_USER_ROLE', role);
                context.commit('SET_SUPERVISOR', supervisor);
            } else {
                console.warn('No permissions were assigned to the current user.');
            }
        }
    },
    mutations: {
        SET_USER_ROLE (state: UserAccessManagementState, role: string): void {
            state.userRole = role;
        },
        SET_SUPERVISOR (state: UserAccessManagementState, supervisor: Supervisor): void {
            state.supervisor = supervisor;
        },
        SET_USER_ID (state: UserAccessManagementState, userId: number): void {
            state.userId = userId;
        }
    }
}
