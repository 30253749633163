import { Component, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue';
import { BreadcrumbLink } from '@/contracts';
import { Route } from 'vue-router';
import { EventBus } from '@/utils';
import OpDataEntryDetails from '@/components/operational-data/op-data-entry-details/op-data-entry-details.vue';
import store, { StateWithModules } from '@/store';
import { OperationalDataActionsNames } from '@/store/modules/operational-data/operational-data-actions';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
import { OperationalDataGettersNames } from '@/store/modules/operational-data/operational-data-getters';
import OpDataChart from '@/components/operational-data/op-data-chart/op-data-chart.vue';
import NotFoundPage from '@/components/not-found-page/not-found-page';
import ConfirmationModal from '@/components/confirmation-modal/confirmation-modal.vue';
import { isEqual } from 'lodash';

@Component({
    components: {
        Breadcrumb,
        OpDataEntryDetails,
        OpDataChart,
        NotFoundPage,
        ConfirmationModal
    },
    beforeRouteEnter(to: Route, from: Route, next: any): void {
        store.commit(OperationalDataMutationsNames.SetActiveDetailsModel, null);
        next((vm: any) => vm.isRouteFromeApp = from.matched.length > 0)
    },
    beforeRouteLeave(to: Route, from: Route, next: any): void {
        (this as OpDataEntryView).routerGuard(to, from, next);
    },
    beforeRouteUpdate: function(to: Route, from: Route, next: any): void  {
        (this as OpDataEntryView).routerGuard(to, from, next);
    },
})
export default class OpDataEntryView extends Vue {
    isLoading = false;
    isHidden = false;
    breadcrumbLinks: BreadcrumbLink[] = [];
    isRouteFromeApp = false;
    unwatchTableDataChange!: Function;
    next: any;

    created(): void {
        if(!store.state.operationalData.statuses.length)
            store.dispatch(OperationalDataActionsNames.LoadStatuses);
    }

    mounted(): void {
        EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => this.setupBreadcrumbs());
        if (this.isRouteFromeApp)
            this.setupBreadcrumbs();

        this.watchActiveDetailsModelInit();
    }

    routerGuard(to: Route, from: Route, next: any): void {
        if(!isEqual(store.state.operationalData.originalDetailsModel, store.getters[OperationalDataGettersNames.GetActiveDetailsModel])) {
            this.next = next;
            (this.$refs['ConfirmationModalRef'] as any).openModal();
        } else {
            next();
        }
    }

    ConfirmationModalCancelled(): void {
        this.next(false);
    }

    ConfirmationModalConfirmed(): void {
        this.next();
    }

    private watchActiveDetailsModelInit(): void {
        this.unwatchTableDataChange = store.watch(
            (state: StateWithModules) => state.operationalData.activeUnitKPI,
            () => {
                this.setupBreadcrumbs();
                this.unwatchTableDataChange();
            }
        );
    }

    private setupBreadcrumbs(): void {
        this.breadcrumbLinks = [];
        const tableLink: BreadcrumbLink = {
            label: this.$t('operationalData.entryView.opDataTable') as string,
            href: '#/operationaldata',
            isActive: false
        };

        const entryLink: BreadcrumbLink = {
            label: `${store.state.operationalData.activeUnitKPI?.Unit.Name ?? ''} - ${this.$route.params.date}`,
            href: '',
            isActive: true
        };

        this.breadcrumbLinks.push(tableLink);
        this.breadcrumbLinks.push(entryLink);
    }
}
