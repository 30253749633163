import { GlobalColors, EventLifecycleColors } from './mode-statuses-colors';

export const EVENT_TIME_RESOLUTION = 1 * 60; // in seconds
export const PLOT_TIME_RESOLUTION = 15 * 60;  // in seconds

const zeroPad = (num: number, places: number): string => String(num).padStart(places, '0');

export function emitPlotRangeChangeToEventBus (eventBus: any, startTime: Date, endTime: Date): void {
    eventBus.$emit('plotRangeChange',
        {
            start: startTime,
            end: endTime
        });
}

export function defaultPlottingRangeMin (): number {
    return (
        Math.floor(new Date().getTime() / (900 * 1000)) * 900 * 1000 -
        24 * 60 * 60 * 1000
    );
}

export function defaultPlottingRangeMax (): number {
    return (
        Math.floor(new Date().getTime() / (900 * 1000)) * 900 * 1000 +
        48 * 60 * 60 * 1000
    );
}

export function defaultContextPlottingRangeMin (): number {
    return (
        Math.floor(new Date().getTime() / (900 * 1000)) * 900 * 1000 -
        2 * 24 * 60 * 60 * 1000
    );
}

export function defaultContextPlottingRangeMax (): number {
    return (
        Math.floor(new Date().getTime() / (900 * 1000)) * 900 * 1000 +
        16 * 24 * 60 * 60 * 1000
    );
}

/**
 * Helper funtion to convert a duration (given as seconds) to a duration string
 * as it is used by C#.
 *
 * @param durationSeconds
 */
export function durationToString (durationSeconds: number): string {
    // get minutes and update seconds accordigly
    let minutes = Math.floor(durationSeconds / 60);
    const seconds = durationSeconds - 60 * minutes;

    // get hours and update minutes accordingly
    const hours = Math.floor(minutes / 60);
    minutes = minutes - 60 * hours;

    // get days and update hours
    // const days = Math.floor(hours / 24)
    // hours = hours - 24 * days
    const days = 0;

    const durationString = `${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`;

    if (days === 0) {
        return durationString;
    }
    return `${days}.` + durationString;
}

export function durationToDaysAndHoursAndMinutes (durationMinutes: number): {
    days: number;
    hours: number;
    minutes: number;
} {

    // get hours and update minutes accordingly
    let hours = Math.floor(durationMinutes / 60);
    const minutes = durationMinutes - 60 * hours;

    // get days and update hours
    const days = Math.floor(hours / 24);
    hours = hours - 24 * days;

    return { days: days, hours: hours, minutes: minutes };
}

export function durationToHoursAndMinutes (durationMinutes: number): { hours: number; minutes: number } {
    const temp = durationToDaysAndHoursAndMinutes(durationMinutes);

    return { hours: temp.hours, minutes: temp.minutes };
}

export const hash = (obj: any): number => {

    if (!obj) {
        return -1;
    }

    const stringifiedObject = JSON.stringify(obj);
    let code = 0;

    for (let i = 0; i < stringifiedObject.length; i++) {
        const char = stringifiedObject.charCodeAt(i);
        code = ((code << 5) - code) + char;
        code = code & code; // two-complement conversion
    }
    return code;
}

export const uniquePushToArrayOfObjects = (arr: Array<object>, newEntry: { [key: string]: any }, fieldToCheckUniqueness: string): any => {
    return arr.findIndex((el: any) => el[fieldToCheckUniqueness] === newEntry[fieldToCheckUniqueness]) === -1
        ? arr.push(newEntry)
        : arr;
}
export interface EventFilter {
    reason: Array<EventReason>;
    status: Array<EventStatus>;
    pMax: { min: number | undefined; max: number | undefined };
    startTime: { min: Date | undefined; max: Date | undefined };
    endTime: { min: Date | undefined; max: Date | undefined };
}

export enum EventStatus {
    New = 'eventStatusOption.new',
    Confirmed = 'eventStatusOption.confirmed',
    Rejected = 'eventStatusOption.rejected',
    Edit = 'eventStatusOption.edit'
}

export interface EventInfoStatusSid{
    [key: number]: string;
}

export interface CountriesSid{
    [key: number]: string;
}

/* eslint-disable sonarjs/no-duplicate-string */
export const EventInfoStatusSid: EventInfoStatusSid = {
    741: 'New',
    742: 'Pending further information',
    743: 'In review',
    744: 'Final',
    745: 'Obsolete',
    746: 'Locked',
    747: 'Pending final'
}

export const CountriesSid: CountriesSid = {
    4100: 'countries.germany',
    4101: 'countries.netherlands',
    4104: 'countries.unitedKingdom',
    4109: 'countries.hungary',
    4110: 'countries.sweden',
    4114: 'countries.russia',
    4105: 'countries.austria'
}


export enum EventInfoStatus {
    'New' = 'eventInfoStatusOption.new',
    'Pending further information' = 'eventInfoStatusOption.pendingFurtherInfo',
    'In review' = 'eventInfoStatusOption.inReview',
    'Final' = 'eventInfoStatusOption.final',
    'Obsolete' = 'eventInfoStatusOption.obsolete',
    'Locked' = 'eventInfoStatusOption.locked',
    'Pending final' = 'eventInfoStatusOption.pendingFinal',
    'undefined' = 'eventInfoStatusOption.undefined',
}

export enum EventInfoStatusColor {
    'New' = GlobalColors.Orange,
    'In review' = GlobalColors.Blue,
    'Pending further information' = GlobalColors.Red,
    'Final' = GlobalColors.Green,
    'Obsolete' = GlobalColors.Black,
    'Locked' = GlobalColors.Grey,
    'Pending final' = GlobalColors.DarkerGreen,
    'undefined' = GlobalColors.Grey
}

export const EventLifecycleColor: any = {
    731: EventLifecycleColors.Orange,
    732: EventLifecycleColors.DarkerOrange,
    733: EventLifecycleColors.Red,
    734: EventLifecycleColors.Green,
    735: EventLifecycleColors.DarkerGreen,
    736: EventLifecycleColors.SalmonRed

}

export enum EventLifecycle {
    Draft = 'eventLifecycleOptions.draft',
    Checked = 'eventLifecycleOptions.checkedRejected',
    Published = 'eventLifecycleOptions.published',
    CancelAndArchive = 'eventLifecycleOptions.cancelledAndArchived',
    CompletedAndArchived = 'eventLifecycleOptions.completedAndArchived',
}

export enum EventReason {
    Outage = 'eventReasonOption.outage',
    Maintenance = 'eventReasonOption.maintenance',
    ExternalFactors = 'eventReasonOption.externalFactors',
    Other = 'eventReasonOption.other'
}

/**
 * Helper function to convert the given date into a string representation of local time.
 * @param date
 * @returns String with format: YYYY-MM-DDThh:mm:ss+hh:mm
 *
 * FIXME Use moment.js to solve this more elegantly.
 */
export function toLocalizedIsoString(date: Date): string {
    const tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num: number): string {
            const norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
}

export class PowerplantEvent {
    technicalId: number | null | undefined;
    title: string;
    isExternal: boolean;
    startTime: Date;
    endTime: Date;
    nominal: number;
    nominalPMin: number;
    pMax: number;
    pMin: number;
    reason: EventReason;
    isDominant: boolean;
    comment: string;
    statusSid: number;
    mustRun: boolean;
    testRun: boolean;
    machineSid: number;
    plantSid: number;
    eventIdentifier: string;
    workflowId: string | null | undefined;

    constructor(constructorObject: {
        technicalId: number | null | undefined;
        title: string;
        isExternal: boolean;
        startTime: Date;
        endTime: Date;
        nominal: number;
        nominalPMin: number;
        pMax: number;
        pMin: number;
        reason: EventReason;
        isDominant: boolean;
        comment: string;
        statusSid: number;
        mustRun: boolean;
        testRun: boolean;
        machineSid: number;
        plantSid: number;
        eventIdentifier: string;
    }) {
        this.technicalId = constructorObject.technicalId;
        this.title = constructorObject.title;
        this.isExternal = constructorObject.isExternal;
        this.startTime = constructorObject.startTime;
        this.endTime = constructorObject.endTime;
        this.nominal = constructorObject.nominal;
        this.nominalPMin = constructorObject.nominalPMin;
        this.pMin = constructorObject.pMin;
        this.pMax = constructorObject.pMax;
        this.reason = constructorObject.reason;
        this.isDominant = constructorObject.isDominant;
        this.comment = constructorObject.comment;
        this.statusSid = constructorObject.statusSid;
        this.mustRun = constructorObject.mustRun;
        this.testRun = constructorObject.testRun;
        this.machineSid = constructorObject.machineSid;
        this.plantSid = constructorObject.plantSid;
        this.eventIdentifier = constructorObject.eventIdentifier;
    }

    public durationMinutes (): number {
        return (this.endTime.getTime() - this.startTime.getTime()) / 1000 / 60;
    }
}

export interface D3MouseEvent extends MouseEvent {
    offsetDataCoordinates: { x: number; y: number };
    interpolatedPixelCoordinates: Array<{ x: number; y: number; valid: boolean }>;
    interpolatedDataCoordinates: Array<{ x: number; y: number; valid: boolean }>;
}


export function utcSecondsToString(seconds: number): string {
    const _tempDate = new Date(Number(seconds));
    return _tempDate.toLocaleDateString('de-De', { timeZone: 'UTC' }) + ' ' + _tempDate.toLocaleTimeString('de-De', { timeZone: 'UTC' });
}

export function utcSecondsToISOString(seconds: number): string {
    const _tempDate = new Date(Number(seconds));
    return _tempDate.toISOString();
}

export function round(value: number, decimals: number): string {
    return Number(Math.round(Number(value +'e'+ decimals)) +'e-'+ decimals).toFixed(decimals);
}

/**
  * Helper function to find next smaller data point for given x-val.
  * Returns index of the found data point or -1 if all data points are larger.
  *
  * @param {Array<number>} dataList
  * @param {Number} val
  *
  * @returns {Number}
  */
export function findNextSmallerDataPoint(dataList: Array<number>, val: number): number {
    for (const i in dataList) {
        if (dataList[i] > val) {
            return Number(i) - 1;
        }
    }
    return dataList.length - 1;
}
/**
  * Helper function to find next larger data point for given x-val.
  * Returns index of the found data point or -1 if all data points are smaller.
  *
  * @param {Number} dataList
  * @param {Number} val
  *
  * @returns {Number}
  */
export function findNextLargerDataPoint(dataList: Array<number>, val: number): number {
    for (const i in dataList) {
        if (dataList[i] >= val) {
            return Number(i);
        }
    }
    return -1;
}

/**
  * Helper function to perform linear interpolation between two data points.
  * Returns yVal (in data coordinates!)
  *
  * @param {Number} x1
  * @param {Number} y1
  * @param {Number} x2
  * @param {Number} y2
  * @param {Number} x
  *
  * @returns {Number}
  */
export function linearInterploation(x1: number, y1: number, x2: number, y2: number, x: number): number {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;
    const m = deltaY / deltaX;
    return m * (x - x1) + y1;
}

/**
 * Floor given time-stamp (milliseconds!) to a given target interval, e.g. 15min = 900s
 * 15:16:23 --> 15:15:00
 * Returned timestamp is in milliseconds!
 *
 * @param millisecs
 * @param target
 */
export function floorTime(millisecs: number, target: number): number {
    return Math.floor(millisecs / (target * 1000)) * target * 1000;
}
