<template>
    <div>
        <adam-header-bar
            :username="user.name"
            :emailaddress="user.email"
            @apps-click="clickDrawer"
            :show-notifications.prop="false"
            @view-history-click="redirectToAppStore"
            :class="[
                { 'adam-header-bar--bg-dev': isDevEnvironment },
                { 'adam-header-bar--bg-uat': isUatEnvironment },
            ]"
        >
            <span
                slot="headerContent"
                class="app-logo"
            >
                <img src="~adam.ui-core/assets/brand/logo-coode-white.svg">
            </span>
            <span slot="headerContent">
                <h4>MODE Event Management</h4>
            </span>
            <span slot="profileDetails">
                <select
                    id="language-selection"
                    class="pui-form-type-ahead-input-single-select mb-1"
                    @change="changeSelectedLanguage"
                    v-model="selectedLanguage"
                >
                    <option
                        v-for="(lang, index) of languageOptions"
                        :key="index"
                        :value="lang"
                    >{{ lang }}</option>
                </select>
                <pui-button
                    state="secondary"
                    icon="report"
                    class="profile-action-button"
                    @click="sendFeedback()"
                >
                    {{ $t('sendFeedback') }}
                </pui-button>
                <pui-button
                    state="secondary"
                    icon="warning"
                    class="profile-action-button profile-action-button--important"
                    @click="reportIncident()"
                >
                    {{ $t('reportIncident') }}
                </pui-button>
                <pui-button
                    icon="power"
                    class="profile-action-button"
                    @click="logOut()"
                >
                    {{ $t('signOut') }}
                </pui-button>
            </span>
        </adam-header-bar>

        <pui-navigation-toolbar
            :link-items="navItems"
            :action-items="[]"
            @changed:active-route="handleActiveRoute"
        />

        <app-drawer
            ref="appDrawer"
            :data.prop="applications"
            :labels.prop="getAppDrawerLabels"
            :on-click.prop="handleAppClick"
        >
            <span
                slot="appLogo"
                class="app-drawer-logo"
            >
                <img src="~adam.ui-core/assets/brand/logo-coode-rgb.svg">
            </span>
        </app-drawer>
    </div>
</template>

<script lang="ts">

import { Component, Vue, Watch } from 'vue-property-decorator';
import { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import { Application } from '@/models';
import { EventBus, LANGUAGES, LocalStorageHelper } from '@/utils';
import { AppService } from '@/services/app-service'
import _ from 'lodash';

@Component({
    name: 'header-wrapper',
})
export default class HeaderWrapper extends Vue {
    private applications: MenuEntry[] = [];
    private navItems: any[] = [];
    private user = {
        name: '',
        email: '',
    };
    private appStoreUrl: string | undefined = process.env.VUE_APP_EXTERNAL_APP_APPSTORE_URL;
    private useCaseId: string | undefined = process.env.VUE_APP_USE_CASE_ID;

    private appService: AppService = new AppService();

    private selectedLanguage = LocalStorageHelper.getSelectedLanguage();
    private languageOptions = LocalStorageHelper.getLanguageOptions();

    private async mounted(): Promise<void> {
        await this.init();
    }

    private created(): void {
        EventBus.$on(EventBus.GLOBAL.ROUTE_CHANGED, () => this.setNavItems());
    }

    @Watch('$route')
    onPropertyChange(value: any, oldValue: any): void {
        if (value !== oldValue) {
            this.setNavItems();
        }
    }

    private async init(): Promise<void> {
        EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => this.setNavItems());
        await this.setProfileData();
        await this.loadApps();
        this.setNavItems();
    }

    private async setProfileData(): Promise<void> {
        const profile = LocalStorageHelper.getProfile();
        if (profile) {
            this.user.email = profile.email;
            if (!profile.givenName && !profile.familyName) {
                this.user.name = this.$t('guestUserName').toString();
                return;
            }
            this.user.name = `${profile.givenName} ${profile.familyName}`;
        }
    }

    private async loadApps(): Promise<void> {
        this.$store.commit('loading')
        try {
            const apps = (await this.appService.getApps()).result.items
            this.applications = this.processApps(apps)
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'errorLoadingApplications')
            throw err
        } finally {
            this.$store.commit('loading')
        }
    }

    private processApps(apps: Application[]): MenuEntry[] {
        return apps.map((app: Application): MenuEntry => ({
            id: app.useCaseId,
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconId: this.getLogo(app.logo),
            url: app.url ? app.url : undefined,
        })).sort((a: MenuEntry, b: MenuEntry) => {
            const nameA = a.label.toLowerCase();
            const nameB = b.label.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    private clickDrawer(): void {
        (this.$refs.appDrawer as any).open = true;
    }

    private handleAppClick(app: Application): void {
        if (app.url) {
            window.open(app.url);
        }
    }

    private getLogo(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`);
            return img;
        } catch (e) {
            return 'app-generic';
        }
    }

    private logOut(): void {
        (this as any).$sdk.$authenticator?.logout();
    }

    private sendFeedback(): void {
        window.open(`${this.appStoreUrl}/contact-page/${this.useCaseId}?isFeedback=true`, '_blank');
    }

    private reportIncident(): void {
        window.open(`${this.appStoreUrl}/incident-page/${this.useCaseId}`, '_blank');
    }

    get getAppDrawerLabels(): any {
        return { applications: this.$i18n.t('appsWithAccess'), miscellaneous: this.$i18n.t('appsWithoutAccess') };
    }

    private setNavItems(): void {
        this.navItems = this.$router.options.routes ? _.uniqWith(this.$router.options.routes.map(route => (
            {
                displayName: this.$t(route?.meta?.translationKey),
                href: this.removeParamsFromNavItemPaths(route.path),
                isActive: route.name === this.$router.currentRoute.name
            })
        ), _.isEqual) : [];
    }

    private handleActiveRoute(newRoute: any): void {
        this.$router.push(newRoute.href);
        if (newRoute) {
            this.setNavItems();
        }
    }

    private removeParamsFromNavItemPaths(path: string): string {
        return path.split(':')[0].replace(/\/+$/, '').trimEnd();
    }

    get isDevEnvironment(): boolean {
        return ['dev', 'development'].includes(process.env.VUE_APP_ENVIRONMENT);
    }

    get isUatEnvironment(): boolean {
        return process.env.VUE_APP_ENVIRONMENT === 'uat';
    }

    private redirectToAppStore (): void {
        window.open(`${process.env.VUE_APP_EXTERNAL_APP_APPSTORE_URL}/notifications-overview`);
    }

    private changeSelectedLanguage(): void {
        LocalStorageHelper.setSelectedLanguage(this.selectedLanguage);
        this.$i18n.locale = LANGUAGES[this.selectedLanguage ?? 0];
        this.setNavItems();
        EventBus.$emit(EventBus.GLOBAL.LANGUAGE_CHANGED);
    }

}
</script>

<style scoped lang="less">
@import '../../variables.less';
.app-logo img,
.app-drawer-logo img {
    height: 100%;
}
.app-logo img {
    margin-right: 1rem;
    padding: 6px;
}
.profile-action-button {
    text-transform: none;
    width: 100%;
    border-radius: 0;
    margin-bottom: 2px;
    height: 3.6rem;

    &--important {
        border: 1px solid @contextual-red !important;
        color: @contextual-red !important;
        &:hover {
            color: @dark-red !important;
            border: 1px solid @dark-red !important;
        }
    }
}
.flat-select {
    background-color: darken(#0078dc, 15);
    color: #cfcfcf;
    margin-left: 10px;
    border: 0;
    padding: 5px;
}
.notification-header {
    margin: 1.5rem 0;
    &__context-menu {
        &__item {
            width: 28rem;
            display: flex;
            flex-direction: row-reverse;
            &--icon {
                width: fit-content;
                padding-right: 0;
            }
        }
    }
}
::v-deep .adam-header-notifications__notification__description {
    white-space: pre-line;
}
::v-deep .adam-header-notifications__notification__description::first-line {
    font-weight: bold;
}
/deep/ .adam-header-bar {
    max-width: 100vw;
}

::v-deep .adam-header-bar--bg-dev {
    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @medium-magenta;
        .adam-header-user__icon.adam-header-bar path {
            fill: @medium-magenta;
        }
        .adam-header-user__icon--focused.adam-header-bar {
            background: @medium-magenta;
        }
        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-user__icon--focused.adam-header-bar {
            background: @darker-magenta;
        }
        .adam-header__logo.adam-header-bar:hover {
            --fill: @darker-magenta;
        }
    }
    .adam-header__separator.adam-header-bar {
        background: @white;
    }
}

::v-deep .adam-header-bar--bg-uat {
    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @medium-violet;
        .adam-header-user__icon.adam-header-bar path {
            fill: @medium-violet;
        }
        .adam-header-user__icon--focused.adam-header-bar {
            background: @medium-violet;
        }
        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-user__icon--focused.adam-header-bar {
            background: @darker-violet;
        }
        .adam-header__logo.adam-header-bar:hover {
            --fill: @darker-violet;
        }
    }
    .adam-header__separator.adam-header-bar {
        background: @white;
    }
}
</style>
