import { LocalStorageHelper as SDKLocalStorageHelper } from '@coode/fe-sdk';

export interface UserProfile {
    department: string;
    email: string;
    id: number;
    kid: string;
    name: string;
    familyName: string;
    givenName: string;
}

/**
 * Collection of helper methods for handling local storage items.
 * Inspired by Coode Master Data.
 */
export class LocalStorageHelper {
    static determineDefaultLanguage(): string {
        LocalStorageHelper.getLanguageOptions().find((el: string) => {
            if (el === navigator.language) {
                return el;
            }
        });

        return LocalStorageHelper.getLanguageOptions()[0];
    }

    public static setSelectedLanguage(val: any): void {
        localStorage.setItem(LocalStorageHelper.keys.selectedLanguage, JSON.stringify(val));
    }

    public static setLanguageOptions(options: string[]): void {
        localStorage.setItem(LocalStorageHelper.keys.languageOptions, JSON.stringify(options));
    }

    public static getSelectedLanguage(): string | null {
        const languageString = localStorage.getItem(LocalStorageHelper.keys.selectedLanguage);
        if (languageString !== null) {
            return JSON.parse(languageString);
        }
        return null;
    }

    public static getLanguageOptions(): string[] {
        const languageOptions = localStorage.getItem(LocalStorageHelper.keys.languageOptions);
        if (languageOptions !== null) {
            return JSON.parse(languageOptions);
        }
        return [];
    }

    private static keys = {
        selectedLanguage: 'coode.mode.selectedLanguage',
        languageOptions: 'coode.mode.languageOptions',
    };

    public static getProfile(): UserProfile {
        const permissions: any = SDKLocalStorageHelper.getPermissionsLocalStorage();
        const name = permissions.name.split(',');
        return {
            department: permissions.department as string,
            id: permissions.id as number,
            kid: permissions.kid,
            email: permissions.email,
            name: permissions.name,
            familyName: name[0] as string,
            givenName: name[1] as string,
        }
    }
}
