import Vue from 'vue'
import { EventAdditionalInformation, CurrentVgbTabModel } from '@/models/event-info'
import { EventInfoModel } from '@/models';
import _ from 'lodash';
import store from '@/store';

const AdditionalInfoTab = Vue.extend({
    name: 'AdditionalInfoTab',
    props: {
        hasRequiredFields: {
            type: Boolean,
            default: false
        }
    },
    data(): {
        currentState: EventAdditionalInformation;
        currentVgbSelection: CurrentVgbTabModel | undefined;
        parentEvent: EventInfoModel | undefined;
        maxInputLength: number;
        toggleFlag: boolean;
        vgbEnumA: number;
        vgbEnumB: number;
        } {
        return {
            currentState: {
                'isViaTrip': false,
                'eventDescription': '',
                'rootCauseAnalysis': '',
                'reviewFollowUp': '',
                'preventativeMeasure': ''
            },
            currentVgbSelection: undefined,
            parentEvent: undefined,
            maxInputLength: 300,
            toggleFlag: false,
            vgbEnumA: 821,
            vgbEnumB: 822,
        }
    },
    watch: {
        currentState: {
            handler(newCurrentState): void {
                this.$store.dispatch('eventManager/setcurrentAdditionalInformationTab', {obj: newCurrentState});
            },
            deep: true
        },
    },
    created(): void {
        //get the current state from the vuex store
        //see if viaTrip is already set -> use this value then
        //if not use the value based on the calculation of timeframe
        //if timeframe is A/B and I move back to a additional Information -> check if the value differs from the one I receive from the backend/store
        this.retrieveParentAndCurrentSelection();
        this.initialiseDefaultValuesIfUndefined();
        this.initialiseViaTrip();

    },
    methods: {
        retrieveParentAndCurrentSelection(): void {
            this.parentEvent = this.$store.getters['eventManager/getSingleParentEvent']();
            this.currentVgbSelection = this.$store.getters['eventManager/getVgbTab'];
        },
        initialiseViaTrip(): void {
            if(!this.isParentTimeFrameAorB && this.parentEvent?.isViaTrip === true){
                this.parentEvent.isViaTrip = false;
                this.currentState.isViaTrip = false;
            }
            if(this.parentEvent && this.parentEvent?.enumVgbTimeframeSid !== this.currentVgbSelection?.enumVgbTimeframeSid){
                this.parentEvent.enumVgbTimeframeSid = this.currentVgbSelection?.enumVgbTimeframeSid;
                this.recalculateViaTripValue();
            }

        },
        initialiseDefaultValuesIfUndefined(): void {
            if (!this.parentEvent) {
                return;
            }
            
            const additionalInfoTabState = this.$store.getters['eventManager/getCurrentAdditionalInformationTab'];
            if (additionalInfoTabState) {
                this.currentState = { ...additionalInfoTabState };
            } else {
                this.parentEvent.isViaTrip
                    ?  this.currentState.isViaTrip = this.parentEvent?.isViaTrip
                    :  this.currentState.isViaTrip = false;
                this.parentEvent.eventDescription
                    ?  this.currentState.eventDescription = this.parentEvent.eventDescription
                    : this.currentState.eventDescription = '';
                this.parentEvent.rootCauseAnalysis
                    ? this.currentState.rootCauseAnalysis = this.parentEvent.rootCauseAnalysis
                    : this.currentState.rootCauseAnalysis = '';
                this.parentEvent.reviewFollowUp
                    ? this.currentState.reviewFollowUp = this.parentEvent.reviewFollowUp
                    : this.currentState.reviewFollowUp = '';
                this.parentEvent.preventativeMeasure
                    ? this.currentState.preventativeMeasure = this.parentEvent.preventativeMeasure
                    : this.currentState.preventativeMeasure = '';
            }
        },
        toggleIsViaTrip(): void {
            if (this.isReadOnly) {
                // set back to value before toggle
                this.toggleFlag = !this.toggleFlag;
            }
            else {
                if (this.parentEvent?.enumVgbTimeframeSid !== this.vgbEnumA && this.parentEvent?.enumVgbTimeframeSid !== this.vgbEnumB && !this.currentState.isViaTrip) {
                    // Open popup
                    (this.$refs['forbiddenChoicePopupRef'] as any).open();
                }
                else if (this.currentState.isViaTrip === undefined) {
                    this.currentState.isViaTrip = true;
                }
                else {
                    this.currentState.isViaTrip = !this.currentState.isViaTrip;
                }
            }
        },
        recalculateViaTripValue(): void {
            if(this.currentVgbSelection?.enumVgbTimeframeSid){
                if(this.isCurrentVgbSelectenTimeFrameAorB){
                    this.currentState.isViaTrip = true;
                }
                else {
                    this.currentState.isViaTrip = false;
                }
            }
        },
        async closePopup(): Promise<void> {
            (this.$refs['forbiddenChoicePopupRef'] as any).close();
            this.currentState.isViaTrip = false;
            this.toggleFlag = !this.toggleFlag;
        },
    },
    computed: {
        isParentEvent(): boolean {
            return !this.$route.params.id.includes('.');
        },
        isCurrentStateEmpty(): boolean {
            return (Object.values(this.currentState).every(value => value === null || value === '' ))
        },
        isReadOnly(): boolean {
            const eventInfo = store.getters['eventManager/getSingleParentEvent']();
            return (eventInfo && eventInfo.enumEventInfoStatusSid) ?
                !store.getters['userAccessManagement/useButtonSave'](eventInfo.enumEventInfoStatusSid) : true;
        },
        isParentTimeFrameAorB(): boolean {
            return (this.parentEvent?.enumVgbTimeframeSid === this.vgbEnumA || this.parentEvent?.enumVgbTimeframeSid === this.vgbEnumB) ? true : false;
        },
        isCurrentVgbSelectenTimeFrameAorB(): boolean {
            return (this.currentVgbSelection?.enumVgbTimeframeSid === this.vgbEnumA || this.currentVgbSelection?.enumVgbTimeframeSid === this.vgbEnumB) ? true : false;
        },
        isEventDescriptionValid(): boolean {
            if (!this.hasRequiredFields) return true
            return !!this.currentState.eventDescription && this.currentState.eventDescription.trim().length > 0;
        }
    }
})

export default AdditionalInfoTab;
