import { MasterApiUnit } from '@/store/modules/contracts/Unit';
import { ApiResponse } from '@/models';
import { ModeServiceBase } from '@/services/base-services';

export class UnitsService extends ModeServiceBase {

    private moduleId: number;

    constructor(moduleId: number) {
        super('units');
        this.moduleId = moduleId;
    }

    public async getUnitsForCurrentUser(): Promise<ApiResponse<MasterApiUnit[]>> {
        return (await this.api.getWithParams({ 'ModuleId': this.moduleId })).data;
    }
}
