import { ModeServiceBase } from '@/services/base-services';
import {
    ApiResponse,
    ApiResponseList,
    EventHistoryModel,
} from '@/models';

export class AuditHistoryService extends ModeServiceBase {
    constructor() {
        super('audit-history');
    }

    /**
     * @name getEventHistory
     * @description This method queries from the backend a list of data changes of the event.
     * @param {number} eventId  The id of the parent event.
     * @returns The event history.
     */
    public async getEventHistory(eventId: number): Promise<ApiResponse<ApiResponseList<EventHistoryModel>>> {
        return (await this.api.get(undefined, `${eventId}`)).data;
    }
}
