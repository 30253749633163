import { ModeServiceBase } from '@/services/base-services';
import { ApiEnumerationResponse, ApiResponse, EnumAttributes } from '@/models';

export class EnumerationsService extends ModeServiceBase {
    constructor() {
        super('enumerations');
    }

    /**
     * @name getEnumAttributes
     * @description This method queries from the backend a list of enums with a specific enum type id.
     * @param {number} enumTypeId  The type id of the enum.
     * @returns A List of enum attributes.
     */
    public async getEnumAttributes(enumTypeId: number): Promise<ApiResponse<ApiEnumerationResponse<EnumAttributes>>> {
        return (await this.api.get(`EnumTypeId=${enumTypeId}`)).data;
    }
}
