import Vue from 'vue';

export const EventBus = new Vue({
    data(): any {
        return {
            GLOBAL: {
                SHOW_SNACKBAR: 'global.show-snackbar',
                NO_UNITS: 'global.no-units',
                ROUTE_CHANGED: 'gloabal.route-changed',
                LANGUAGE_CHANGED: 'global.languageChanged',
                NETWORK_ERROR: 'global.networkError'
            },
            VIEWS: {
                LIST: {
                    FILTER_CHANGED: 'view.list.filters',
                    AUDIT_BUTTON_CLICKED: 'view.list.auditButtonClicked',
                    SHOW_WAITING_MODAL: 'view.list.showWaitingModal',
                    CLOSE_WAITING_MODAL: 'view.list.closeWaitingModal',
                },
                CAPTURE: {
                    RANGE_CHANGED: 'view.capture.rangeChanged',
                },
                COMPLIANCE_REPORTING_SETTINGS: {
                    SIDEBAR_BUTTON_CLICKED: 'view.complianceReportingSettings.sidebarButtonClicked'
                },
                INFO: {
                    FILTER_CHANGED: 'view.info.filters',
                }
            }
        };
    },
});
