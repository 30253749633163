
import { Component, Vue } from 'vue-property-decorator';
import OpDataTable from '../../components/operational-data/op-data-table/op-data-table.vue';
import { Route } from 'vue-router';
import store from '@/store';
import { TablePagingMetadata } from '@/models/operational-data/operational-data';
import { cloneDeep } from 'lodash';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
import { OperationalDataActionsNames } from '@/store/modules/operational-data/operational-data-actions';
import NotFoundPage from '@/components/not-found-page/not-found-page.vue';

@Component({
    components: {
        OpDataTable,
        NotFoundPage
    },
    beforeRouteEnter(to: Route, from: Route, next: any): void {
        next((vm: any) => vm.isRouteFromeApp = from.matched.length > 0)
    }
})
export default class OperationalDataView extends Vue {

    isRouteFromeApp = false;

    isUserRoleAssigned = false;

    get pagination(): TablePagingMetadata | null {
        if(!store.state.operationalData.tablePagingMetadata)
            return null;

        return cloneDeep(store.state.operationalData.tablePagingMetadata);
    }

    get isLoading(): boolean {
        return store.state.operationalData.tableLoadingPanel;
    }

    async mounted(): Promise<void> {
        this.isUserRoleAssigned = (store.getters['userAccessManagement/getUserRole']);
        if (this.isUserRoleAssigned) {
            await store.dispatch('sidebar/setCurrentSidebar', {
                shouldShowSidebar: true,
                currentSidebarComponent: 'OpDataFilter'
            });
        }
    }

    onPageChanged(currentPage: any): void {
        store.commit(OperationalDataMutationsNames.SetCurrentPage, currentPage);
        store.dispatch(OperationalDataActionsNames.OnFilterChange);
    }
}
