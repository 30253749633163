import { utcToLocal } from '@/utils';

export function anyEventToEvent (event: any, eventLineSegments: any): any {
    return {
        eventId: event.eventId,
        eventIdentifier:
            event.eventHeaderDto
                ? !event.splitEventId
                    ? event.eventHeaderDto.eventIdentifier
                    : event.eventHeaderDto.eventIdentifier + '.' + event.splitEventId
                : event.eventIdentifier,
        splitEventId:
            event.splitEventId
                ? event.splitEventId
                : undefined,
        data: eventLineSegments,
        utcStartTime: utcToLocal(event.utcStartTime),
        utcEndTime: utcToLocal(event.utcEndTime),
        unAvailCapacity: event.unAvailCapacity,
        availCapacity: event.availCapacity,
        unAvailEnergy: event.unAvailEnergy,
        nominal:
            event.eventHeaderDto
                ? event.eventHeaderDto.startRamp?.nominal
                    ? event.eventHeaderDto.startRamp?.nominal
                    : undefined
                : undefined,
        isDominant: !!event.isDominant
    }
}
