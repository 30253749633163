import { ApiNotificationResponseList, ApiResponse } from '@/models';
import { User, UserFilterModel, UserGroupType } from '@/models/users';
import { ModeServiceBase } from '@/services/base-services';

export class UserService extends ModeServiceBase{
    constructor() {
        super('users')
    }

    /**
     * Get the role (operator, dispatcher, ...) of a mode user.
     * @param userId
     */
    public async getUserRoleById(userId: number): Promise<ApiResponse<UserGroupType>>{
        return (await this.api.get(undefined, `role/${userId}`)).data;
    }
    /**
     * Get a mode user.
     * @param userId
     */
    public async getUserById (userId: number): Promise<ApiResponse<User>> {
        return (await this.api.get(undefined, `${userId}`)).data;
    }

    public async getUsers (filter: UserFilterModel | undefined = undefined): Promise<ApiResponse<ApiNotificationResponseList<User>>> {
        let query = '';
        if (filter) {
            query += '?'
            for (const [key, value] of Object.entries(filter)) {
                query += `${key}=${value}&`
            }
            query.slice(0, query.length - 1);
        }

        // Send request
        return (await this.api.get(query)).data;
    }

    public async getUsersSave (filter: UserFilterModel | undefined = undefined): Promise<Array<User>> {
        let users: Array<User> = [];
        try {
            users = await (await this.getUsers(filter)).result.items;
        }
        catch (e) {
            console.error('Error while retrieving user list from backend: \n' + e)
        }
        return users;
    }


}
