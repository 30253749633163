/* eslint-disable @typescript-eslint/no-unused-vars */
import { ParallelEvent } from '@/components/event/event-parallel/types';
import { EventInfoModel, MainSystem, SplitEventModel, SubSystem } from '@/models'
import { CurrentSystemsTabModel, CurrentSystemsTabSelectModel, CurrentVgbTabModel, EventAdditionalInformation, EventHistoryModel, EventInfoFilterModel, EventInfoStatusModel, EventInfoVgbEnums, IndividualEventInfo} from '@/models/event-info';
import { EnumInfoMasterData, EventInfoService } from '@/services/event-info-service'
import i18n from '@/utils/i18n';
import _, { cloneDeep } from 'lodash';
import store from '..';

type eventManagerState = {
    singleParentEvent:  EventInfoModel | undefined;
    singleChildEvent: SplitEventModel | undefined;
    allSplitEventsForObject: Array<SplitEventModel> | undefined;
    allVgbEnums: EventInfoVgbEnums | undefined;
    currentVgbTab: CurrentVgbTabModel |undefined;
    systemsTabCurrentState: CurrentSystemsTabModel | undefined;
    systemsTabSelectionCurrentState: CurrentSystemsTabSelectModel | undefined;
    currentSplitEvents: Array<SplitEventModel> | undefined;
    currentAdditionalInformationTab: EventAdditionalInformation | undefined;
    currentParallelEvents: { items: ParallelEvent[] } | undefined;
    eventHistory: EventHistoryModel[] | undefined;
    eventFilter: EventInfoFilterModel | undefined;
    singleParentEventPlaceholderCap: number | undefined;
    isUnsavedChanges: boolean;
    DoughnutFilterItems: EventInfoStatusModel[] | undefined;
    individualEventInfo?: IndividualEventInfo;
}

export const eventInfoService = new EventInfoService();

export const eventManager = {
    namespaced: true,
    state: (): eventManagerState => ({
        singleParentEvent: undefined,
        singleChildEvent: undefined,
        allSplitEventsForObject: undefined,
        allVgbEnums: undefined,
        currentVgbTab: undefined,
        systemsTabCurrentState: undefined,
        systemsTabSelectionCurrentState: {
            mainSystems: [],
            subSystems: []
        },
        currentSplitEvents: undefined,
        currentAdditionalInformationTab: undefined,
        currentParallelEvents: undefined,
        eventHistory: undefined,
        eventFilter: undefined,
        singleParentEventPlaceholderCap: undefined,
        isUnsavedChanges: false,
        DoughnutFilterItems: undefined,
        individualEventInfo: undefined
    }),
    getters: {
        // TODO when refactoring check with the guys if each of these getters should have cloneDeep
        // getSingleParentEvent is now queried as a function, use: 'context.getters['getSingleParentEvent']()'
        getSingleParentEvent: (state: eventManagerState) => (): EventInfoModel | undefined => cloneDeep(state.singleParentEvent),
        getSingleChildEvent: (state: eventManagerState) => (): (SplitEventModel | undefined) => cloneDeep(state.singleChildEvent),
        getAllChildEvents: (state: eventManagerState) => (): (Array<SplitEventModel> | undefined) => cloneDeep(state.allSplitEventsForObject),
        getSingleParentEventStatusSid: (state: eventManagerState): number | undefined => cloneDeep(state.singleParentEvent?.enumEventInfoStatusSid),
        getAllVgbEnums: (state: eventManagerState) => (): EventInfoVgbEnums | undefined => cloneDeep(state.allVgbEnums),
        getVgbTab: (state: eventManagerState): CurrentVgbTabModel | undefined => state.currentVgbTab,
        getSystemsTab: (state: eventManagerState): CurrentSystemsTabModel | undefined => cloneDeep(state.systemsTabCurrentState),
        getSystemsTabSelectionCurrentState: (state: eventManagerState): CurrentSystemsTabSelectModel | undefined => cloneDeep(state.systemsTabSelectionCurrentState),
        getCurrentSplitEvent:(state: eventManagerState) => (): Array<SplitEventModel> | undefined => cloneDeep(state.currentSplitEvents),
        getCurrentAdditionalInformationTab: (state: eventManagerState): EventAdditionalInformation | undefined => cloneDeep(state.currentAdditionalInformationTab),
        getCurrentParallelEvents: (state: eventManagerState) => (): { items: ParallelEvent[]} | undefined => cloneDeep(state.currentParallelEvents),
        getEventHistory: (state: eventManagerState): EventHistoryModel[] | undefined => cloneDeep(state.eventHistory),
        getEventFilter: (state: eventManagerState): EventInfoFilterModel | undefined => cloneDeep(state.eventFilter),
        getSingleParentEventPlaceholderCap: (state: eventManagerState): number | undefined => state.singleParentEventPlaceholderCap,
        getDoughnutFilterItems: (state: eventManagerState): EventInfoStatusModel[] | undefined => state.DoughnutFilterItems,
        getIndividualEventInfo: (state: eventManagerState): IndividualEventInfo | undefined => state.individualEventInfo
    },
    actions: {
        // Gets a parent event from backend and saves to store.state
        async getParentEventObject (context: any, {eventId}: {eventId: string}): Promise<void> {
            try{
                const event = (await eventInfoService.getEventInfo(Number(eventId))).result;
                context.commit('SET_SINGLE_PARENT_EVENT', event);
            }
            catch (err) {
                store.commit('setErrorData', {
                    errorResponse: i18n.t('eventLoadErrorMessage'),
                    buttonLabel: i18n.t('eventTable'),
                    buttonLink: '/eventinfo',
                    buttonIsVisible: true
                });
            }
        },

        // Gets a child event from backend and saves to store.state
        async getChildEventObject(context: any, {eventId}: {eventId: string}): Promise<void> {
            const parentId = eventId.split('.')[0];
            const childId = eventId.split('.')[1];
            const event = (await eventInfoService.getSplitEventById(Number(parentId), Number(childId))).result;
            context.commit('SET_SINGLE_SPLIT_EVENT', event);
        },
        // TODO duplicate? the other one is working
        // async getParallelEvents(context: any,
        //     {eventId, utcStartTime, utcEndTime, service}: {eventId: number; utcStartTime: string; utcEndTime: string;}): Promise<void> {
        //     const parallelEvents = (await service.getParallelEvents(eventId, utcStartTime, utcEndTime)).result;
        //     context.commit('SET_CURRENT_PARALLEL_EVENTS', parallelEvents)
        // },
        async getEventHistory(context: any, {eventId}: {eventId: number}): Promise<void> {
            const eventHistory = (await eventInfoService.getEventHistory(eventId)).result.items;
            context.commit('SET_EVENT_HISTORY', eventHistory);
        },
        async getSystemsSelectionMain(context: any, {machineId, functionalLocation}:
                {machineId: number; functionalLocation: string}): Promise<void>{
            const selection = (await eventInfoService.getMainSystems(machineId, functionalLocation)).result.items;
            const mainSelection = selection.map(
                (mainSystem: MainSystem)=>({ label: mainSystem.mainSystemName, value: mainSystem.mainSystemId})
            );
            context.commit('SET_SYSTEM_SELECTION_MAIN_STATE', mainSelection);
        },
        async getSystemsSelectionSub(context: any, {machineId, functionalLocation, mainSystemId}:
                {machineId: number; functionalLocation: string; mainSystemId: string}): Promise<void>{
            const selection = (await eventInfoService.getSubSystems(machineId, functionalLocation, mainSystemId)).result.items;
            const subSelection = selection.map(
                (subSystem: SubSystem)=>({ label: subSystem.subSystemName, value: subSystem.category})
            )
            context.commit('SET_SYSTEM_SELECTION_SUB_STATE', subSelection);
        },
        /**
         * Retrieves All Child event for a parent event from the Backend
         * @param context
         * @param eventId is the Parent Id
         */
        async getAllChildEvents(context: any, {eventId}: {eventId: number}): Promise<void> {
            const temp = (await eventInfoService.getSplitEvents(eventId)).result.items;
            context.commit('SET_ALL_SPLIT_EVENTS', temp);
        },

        /**
         * Deletes all Split events for a parent event
         * @param context
         * @param param1
         */
        async deleteAllSplitEvents(context: any, {eventId}: {eventId: number}): Promise<void> {
            context.commit('DELETE_ALL_CHILD_EVENTS_FROM_STATE');
            const temp = (await eventInfoService.deleteAllSplitEvents(eventId));
        },

        async getParallelEvents(context: any, {eventId, utcStartTime, utcEndTime}: {eventId: string; utcStartTime: string; utcEndTime: string}): Promise<void> {
            const parallelEvents = (await eventInfoService.getParallelEvents(Number(eventId), utcStartTime, utcEndTime)).result;
            context.commit('SET_PARALLEL_EVENTS', parallelEvents)
        },

        /**
         * Sets new EnumStatusSid for Parent Event (only!) and stores it to the store.state
         * Saves the Parent Event (only!) to the backend.
         * @param context
         * @param param1 Takes a getter for this store, an EventInfoserice and the new Value for EnumStatusSid
         */
        async saveStatus(context: any, { getters, newEnumSid }:
            { getters: any; newEnumSid: number }): Promise<void> {
            context.commit('SET_STATUS', newEnumSid);
            /* eslint-disable sonarjs/no-duplicate-string */
            const response = await eventInfoService.setEventInfo(getters['eventManager/getSingleParentEvent']());

            /* catch the case, where the request is "Final", but the BE sets new value to "Awaiting completion" because of REACT status */
            if (response.result.enumEventInfoStatusSid !== newEnumSid) {
                context.commit('SET_STATUS', response.result.enumEventInfoStatusSid);
            }

            context.commit('RESET_STATUS');
        },

        /**
         * Changes the status of the current Parent Event (only!) to the new EnumStatusSid
         * @param context
         * @param param1 takes the new Value for the EnumStatusSid
         */
        changeStatus(context: any, { newEnumSid }: { newEnumSid: number }): void {
            context.commit('SET_STATUS', newEnumSid);
        },

        /**
         * Takes the Parent Event from the Store.state and saves it to the backend
         * @param getters
         * @param service
         */
        async saveParentEventObject(context: any): Promise<boolean> {
            // const event = context.getters.getSingleParentEvent;
            const event = context.getters['getSingleParentEvent']();

            try {
                await eventInfoService.setEventInfo(event);
                return true;
            } catch (error) {
                console.log('An Error occured during saving the event: ', error);
                return false;
            }
        },

        /**
         *  Will take an EventInfoModel-Object, filters out empty/undefined values and assigns the new values to the existing state
         * @param context
         * @param param1
         */
        /* eslint-disable sonarjs/no-duplicate-string */
        updateParentEventObject(context: any, {obj}: {obj: EventInfoModel}): void {
            const passed =  Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== '' && value !== undefined));
            const oldEvent = context.getters['getSingleParentEvent']();
            const newEvent = Object.assign(oldEvent, passed);
            context.commit('SET_SINGLE_PARENT_EVENT', newEvent)
        },

        updateChildEventObject(context: any, {getters, obj}: {getters: any; obj: SplitEventModel}): void {
            const passed =  Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== '' && value !== undefined));
            const oldEvent = getters['eventManager/getSingleChildEvent']();
            const newEvent = Object.assign(oldEvent, passed)
            context.commit('SET_SINGLE_SPLIT_EVENT', newEvent)
        },


        /**
         * Takes the Child Event from the Store.state and saves it to the backend
         * @param getters
         * @param service
         */
        async saveChildEventObject(context: any, {getters}: {getters: any}): Promise<void> {
            try{
                if (!getters['eventManager/getSingleChildEvent']) return;
                const event = getters['eventManager/getSingleChildEvent']();
                // TODO 'An Error occured during saving the event:  TypeError: Cannot read properties of undefined (reading 'putSplitEvent')'
                // this error is thrown when trying to save a value for split event 185.1
                await eventInfoService.putSplitEvent(event);
            }catch(error){
                console.log('An Error occured during saving the event: ', error);
                throw(error);
            }
        },
        async getSplitEvents(context: any, {eventId}: {eventId: number}): Promise<void> {
            const response = await eventInfoService.getSplitEvents(eventId);
            context.commit('SET_ALL_SPLIT_EVENTS', response.result.items);
        },

        /**
         * Retrieve all Enums from Backend and set to state
         */
        getVgbEnums(context: any): any[] {
            let data = {} as EventInfoVgbEnums;
            const allVgbEnums = context.getters.getAllVgbEnums();
            if (allVgbEnums) {
                data = { ...allVgbEnums };
            }

            return ([
                ['enumVgbConditionBeforeSid', EnumInfoMasterData.VgbConditionBefore],
                ['enumVgbExternalInfluenceDescriptionSid', EnumInfoMasterData.VgbExternalInfluenceDescriptionSid],
                ['enumVgbEventTypeSid', EnumInfoMasterData.VgbEventType],
                ['enumVgbMainConsequenceSid', EnumInfoMasterData.VgbMainCons],
                ['enumVgbTimeframeSid', EnumInfoMasterData.VgbTimeFrame],
            ] as [keyof EventInfoVgbEnums, EnumInfoMasterData][])
                .filter(([dataField, masterDataEnum]: [keyof EventInfoVgbEnums, EnumInfoMasterData]) =>
                    !data![dataField]?.length
                )
                .map(([dataField, masterDataEnum]: [keyof EventInfoVgbEnums, EnumInfoMasterData]) => {
                    return new Promise((resolve, reject) => {
                        eventInfoService.getVgbClassificationById(masterDataEnum)
                            .then((response: any) => {
                                data![dataField] = response.result.enums;
                                context.commit('SET_VGB_ENUMS', data);
                                resolve(0);
                            })
                            .catch(reject)
                    });
                })
        },

        /**
         * the following methods will update the respective state
         */
        setVgbState(context: any, {obj}: {obj: CurrentVgbTabModel}): void {
            context.commit('SET_CURRENT_VGB_STATE', obj)
        },

        setSystemsState(context: any, {obj}: {obj: CurrentSystemsTabModel}): void {
            context.commit('SET_CURRENT_SYSTEMS_STATE', obj)
        },

        setSystemsSelectionState(context: any, {obj}: {obj: CurrentSystemsTabSelectModel}): void {
            context.commit('SET_CURRENT_SYSTEM_SELECTION_STATE', obj)
        },

        setCurrentSplitEvents(context: any, {obj}: {obj: Array<SplitEventModel>}): void {
            context.commit('SET_CURRENT_SPLIT_EVENTS', obj)
        },

        setcurrentAdditionalInformationTab(context: any, {obj}: {obj: EventAdditionalInformation}): void {
            context.commit('SET_ADDITIONAL_INFORMATIONTAB', obj)
        },

        async getDoughnutFilterItems(context: any, {obj}: {obj: EventInfoFilterModel}): Promise<void> {
            const itemsArr = (await eventInfoService.getDoughnutfilterStatus(obj)).items;
            context.commit('SET_DOUGHNUT_FILTER_ITEMS', itemsArr);
        },

        setEventFilter(context: any, {obj}: {obj: EventInfoFilterModel}): void {
            context.commit('SET_EVENT_FILTER', obj)
        }
    },
    mutations: {
        SET_SINGLE_PARENT_EVENT (state: eventManagerState, event: EventInfoModel): void {
            state.singleParentEvent = event;
        },
        SET_SINGLE_PARENT_EVENT_PLACEHOLDER_CAP (state: eventManagerState, unAvailCap: number): void {
            state.singleParentEventPlaceholderCap = unAvailCap;
        },
        SET_SINGLE_SPLIT_EVENT (state: eventManagerState, event: SplitEventModel): void {
            state.singleChildEvent = event;
        },
        SET_PARALLEL_EVENTS (state: eventManagerState, events: { items: ParallelEvent[] }): void {
            state.currentParallelEvents = events;
        },
        SET_STATUS (state: eventManagerState, statusSid: number): void {
            //only possible for parent event
            if(state.singleParentEvent){
                state.singleParentEvent.enumEventInfoStatusSid = statusSid;
                state.singleParentEvent.isStatusChanged = true;
            } else {
                console.log('error - setting Status');
            }
        },
        RESET_STATUS (state: eventManagerState): void {
            if (state.singleParentEvent)
                state.singleParentEvent.isStatusChanged = false;
        },
        SET_VGB_ENUMS (state: eventManagerState, obj: EventInfoVgbEnums): void {
            state.allVgbEnums = obj;
        },
        SET_CURRENT_VGB_STATE (state: eventManagerState, obj: CurrentVgbTabModel): void {
            state.currentVgbTab = obj;
        },
        RESET_CURRENT_VGB_STATE(state: eventManagerState): void {
            state.currentVgbTab = undefined;
        },
        SET_CURRENT_SYSTEMS_STATE (state: eventManagerState, obj: CurrentSystemsTabModel): void {
            state.systemsTabCurrentState = obj;
        },
        SET_CURRENT_SYSTEM_SELECTION_STATE (state: eventManagerState, obj: CurrentSystemsTabSelectModel): void {
            state.systemsTabSelectionCurrentState = obj;
        },
        SET_SYSTEM_SELECTION_MAIN_STATE (state: eventManagerState, obj: Array<{label: string; value: string}>): void {
            if(state.systemsTabSelectionCurrentState){
                state.systemsTabSelectionCurrentState.mainSystems = obj;
            }
        },
        SET_SYSTEM_SELECTION_SUB_STATE (state: eventManagerState, obj: Array<{label: string; value: string}>): void {
            if(state.systemsTabSelectionCurrentState){
                state.systemsTabSelectionCurrentState.subSystems = obj;
            }
        },
        RESET_CURRENT_VGB_OPTIONS(state: eventManagerState): void {
            state.systemsTabSelectionCurrentState = {
                mainSystems: [],
                subSystems: [],
            }
        },
        SET_CURRENT_SPLIT_EVENTS (state: eventManagerState, obj: Array<SplitEventModel>): void {
            state.currentSplitEvents = obj;
        },
        SET_ALL_SPLIT_EVENTS (state: eventManagerState, obj: Array<SplitEventModel>): void {
            state.allSplitEventsForObject = obj;
        },
        SET_ADDITIONAL_INFORMATIONTAB (state: eventManagerState, obj: EventAdditionalInformation): void {
            state.currentAdditionalInformationTab = obj;
        },
        SET_CURRENT_PARALLEL_EVENTS (state: eventManagerState, obj: { items: ParallelEvent[]}): void {
            state.currentParallelEvents = obj;
        },
        SET_EVENT_HISTORY(state: eventManagerState, obj: EventHistoryModel[]): void{
            state.eventHistory = obj;
        },
        MAP_SELECTION_MAIN_SYSTEM(state: eventManagerState, obj: Array<MainSystem>): Array<{}> {
            return obj.map(
                (mainSystem: MainSystem)=>({ label: mainSystem.mainSystemName, value: mainSystem.mainSystemId})
            );
        },
        MAP_SELECTION_SUB_SYSTEM(obj: Array<SubSystem>): Array<{}> {
            return obj.map(
                (subSystem: SubSystem)=>({ label: subSystem.subSystemName, value: subSystem.category})
            )
        },
        SET_IS_UNSAVED_CHANGES(state: eventManagerState, value: boolean): void {
            state.isUnsavedChanges = value;
        },
        DELETE_ALL_CHILD_EVENTS_FROM_STATE(state: eventManagerState): void {
            state.allSplitEventsForObject = [];
        },
        SET_DOUGHNUT_FILTER_ITEMS(state: eventManagerState, value: EventInfoStatusModel[]): void {
            state.DoughnutFilterItems = value;
        },
        SET_EVENT_FILTER(state: eventManagerState, value: EventInfoFilterModel): void {
            state.eventFilter = value;
        },
        SET_INDIVIDUAL_EVENT_INFO(state: eventManagerState, value: IndividualEventInfo): void {
            state.individualEventInfo = value;
        }
    }
}
