import CustomInput from '@/components/utils/custom-input/custom-input';
import Vue from 'vue';

type CustomInputComponent = CustomInput & { _uid: number }
export interface CustomInputValidationState {
    id: number;
    isValid: boolean;
}

export class CustomInputsValidator {
    inputs: CustomInputValidationState[] = [];

    private inputsIdsFound: number[] = [];
    validate(component: Vue): void {
        this.searchForCustomInputsInComponentsTree(component);
        this.removeNotFoundInputs();
    }

    private removeNotFoundInputs(): void {
        const idsToRemove = this.inputs
            .filter(input => !this.inputsIdsFound.includes(input.id))
            .map(input => input.id);

        idsToRemove.forEach(id => this.inputs.splice(this.inputs.findIndex(i => i.id === id), 1));
        this.inputsIdsFound = [];
    }

    private searchForCustomInputsInComponentsTree(component: Vue): void {
        component.$children.forEach(child => {
            if (!this.isCustomInputComponent(child)) {
                this.searchForCustomInputsInComponentsTree(child);
                return;
            }

            const customInput = child as CustomInputComponent;
            this.inputsIdsFound.push(customInput._uid);
            const isAlreadyInInputsList = this.inputs.find(i => i.id === customInput._uid);
            if (!isAlreadyInInputsList) {
                this.addEventListeners(customInput);
                this.inputs.push({ id: customInput._uid, isValid: customInput.isValid() });
            }
        });
    }

    private isCustomInputComponent = (component: Vue): boolean => !!component.$vnode?.componentOptions?.tag?.includes('custom-input')

    private addEventListeners(customInput: CustomInputComponent): void {
        customInput.$on('change', () => this.updateValidationStatus(customInput));
        customInput.$watch('required', () => this.updateValidationStatus(customInput));
    }

    private updateValidationStatus(customInput: CustomInputComponent): void {
        const index = this.inputs.findIndex(i => i.id === customInput._uid);
        Vue.set(this.inputs, index, { id: customInput._uid, isValid: customInput.isValid() });
    }
}
