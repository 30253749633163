import { EventInfoModel, IndividualEventInfo } from '@/models';
import Vue, { PropType } from 'vue';
import store from '@/store';
import { IncidentPenaltyName } from '@/models/event-info';

export default Vue.extend({
    name: 'IndividualEventTab',
    components: {},
    props: {
        eventData: {
            type: Object as PropType<EventInfoModel>,
            default: null
        },
        hasRequiredFields: {
            type: Boolean,
            default: false
        },
        singleEventUnavailableEnergy: {
            type: Number,
            default: 0
        }
    },
    data(): {
      currentState: IndividualEventInfo,
      forbiddenChoicePopupRef: string
      } {
        return {
            currentState: {
                isAssetReliabilityRelevant: false,
                incidentPenaltyName: undefined,
                incidentPenalty: undefined,
                incidentPenaltyThreshold: undefined,
                mWhLossMWCapacityThreshold: undefined,
            },
            forbiddenChoicePopupRef: 'forbiddenChoicePopupRef'
        };
    },
    watch: {
        currentState: {
            handler(): void {
                this.$store.commit('eventManager/SET_INDIVIDUAL_EVENT_INFO', this.currentState);
            },
            deep: true
        },
        isEventSevere(newValue: boolean, oldValue: boolean) {
            if (newValue && newValue !== oldValue) {
                this.currentState.isAssetReliabilityRelevant = true;
            }
        }
    },
    created(): void {
        const individualEventState = this.$store.getters['eventManager/getIndividualEventInfo'];
        if (individualEventState) {
            this.currentState = { ...individualEventState };
        } else {
            this.initializeFormValues();
        }
    },
    computed: {
        isAraRelevantDisabled(): boolean {
            return this.isReadOnly || this.isEventSevere;
        },
        isReadOnly(): boolean {
            const eventInfo = store.getters['eventManager/getSingleParentEvent']();
            return (eventInfo && eventInfo.enumEventInfoStatusSid) ?
                !store.getters['userAccessManagement/useButtonSave'](eventInfo.enumEventInfoStatusSid) : true;
        },
        isIncidentPenaltyDisabled(): boolean {
            return this.currentState.incidentPenaltyName === IncidentPenaltyName.NotApplicable;
        },
        isIncidentPenaltyValid(): boolean {
            if (!this.hasRequiredFields || this.currentState.incidentPenalty === 0 || this.isIncidentPenaltyDisabled) return true;
            return !!this.currentState.incidentPenalty;
        },
        isParentEvent(): boolean {
            return !this.$route.params.id.includes('.');
        },
        incidentPenaltyThreshold(): string {
            if (this.isValidNumberValue(this.currentState.incidentPenaltyThreshold)) {
                return this.currentState.incidentPenaltyThreshold!.toString();
            }
            return IncidentPenaltyName.NotApplicable.toString();
        },
        isEventSevere(): any {
            if (
                !this.isParentEvent ||
                !this.currentState.incidentPenaltyName ||
                !this.eventData.nominalCapacityUnit ||
                !this.hasRequiredFields
            ) {
                return false;
            }

            const incidentPenalty = this.currentState.incidentPenalty || 0;
            const incidentPenaltyName = this.currentState.incidentPenaltyName;
            const penaltyThreshold = this.eventData.incidentPenaltyThreshold;
            const mWhLossMWCapacityThreshold = this.eventData.mWhLossMWCapacityThreshold || 0;
            const maxUnavailableEnergy = Math.max(
                this.eventData.eventHeaderDto.unAvailEnergy, // React
                this.singleEventUnavailableEnergy, // Single event
            );
            return (
                (incidentPenaltyName === IncidentPenaltyName.EquivalentOperatingHours && this.isValidNumberValue(penaltyThreshold) && incidentPenalty > penaltyThreshold!) ||
                (incidentPenaltyName === IncidentPenaltyName.StartPenalty && this.isValidNumberValue(penaltyThreshold) && incidentPenalty > penaltyThreshold!) ||
                (maxUnavailableEnergy / this.eventData.nominalCapacityUnit > mWhLossMWCapacityThreshold)
            );
        }
    },
    methods: {
        isValidNumberValue(value?: number | null): boolean {
            return value !== null && value !== undefined;
        },
        initializeFormValues(): void {
            if (!this.eventData) return;

            this.currentState.isAssetReliabilityRelevant = !!this.eventData.isAssetReliabilityRelevant;
            this.currentState.incidentPenaltyName = this.eventData.incidentPenaltyName;
            this.currentState.incidentPenalty = this.eventData.incidentPenalty;
            this.currentState.incidentPenaltyThreshold = this.eventData.incidentPenaltyThreshold;
            this.currentState.mWhLossMWCapacityThreshold = this.eventData.mWhLossMWCapacityThreshold || 0;
        },
        toggleIsAraRelevant(): void {
            if (this.isAraRelevantDisabled) {
                this.openPopup();
            }
            
            this.toggleAraRelevantFlag();
        },
        toggleAraRelevantFlag(): void {
            this.currentState.isAssetReliabilityRelevant = !this.currentState.isAssetReliabilityRelevant;
        },
        openPopup(): void {
            (this.$refs[this.forbiddenChoicePopupRef] as any).open();
        },
        closePopup(): void {
            (this.$refs[this.forbiddenChoicePopupRef] as any).close();
            this.toggleAraRelevantFlag();
        },
    }
})