<template>
    <div
        class="custom-tooltip"
        :style="{ backgroundColor: color }"
    >
        <p><span>{{ header }}:</span></p>
        <p><span>{{ data[key] }}</span></p>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'

const BgTooltip = Vue.extend({
    name: 'BgTooltip',
    data (): {
            color: string | null;
            key: string | null;
            header: string | null;
            data: any;
            } {
        return {
            color: null,
            key: null,
            header: null,
            data: null,
        };
    },
    beforeMount() {
        this.data = (this as any).params.api.getDisplayedRowAtIndex(
            (this as any).params.rowIndex
        ).data;
        this.key = (this as any).params.key;
        this.header = (this as any).params.column.colDef.headerName;
        this.color = (this as any).params.color || 'white';
    }
});
export default BgTooltip;
</script>

<style lang="less">
@import "../../../../variables.less";

.custom-tooltip {
  position: absolute;
  width: 150px;
  height: fit-content;
  border: 1px solid @uniper-blue;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 5px;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}
</style>
