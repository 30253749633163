import { OpDataUnitKPI, OperationalMetadata } from '@/models/operational-data/operational-data-details';
import { OperationalDataUnitKpiDTO, OperationalDataUnitKpiEditDTO, OperationalMetadataDTO } from '@/models/operational-data/operational-data-DTO';
import { convertUsing, ignore, mapFrom, Mapper } from '@automapper/core';
import { CamelToPascalCase, numberConverter, PascalToCamelCase } from '../../common';

export const mapUnitKPI = (mapper: Mapper): void => {
    mapper
        .createMap<OperationalMetadata, OperationalMetadataDTO>('OperationalMetadata', 'OperationalMetadataDTO', { namingConventions: PascalToCamelCase })
        .forMember(d => d.countrySid, mapFrom(s => s.Country.Id))
        .forMember(d => d.countryName, mapFrom(s => s.Country.Name));

    mapper
        .createMap<OperationalMetadataDTO, OperationalMetadata>('OperationalMetadataDTO', 'OperationalMetadata', { namingConventions: PascalToCamelCase })
        .forMember(d => d.Country.Id, mapFrom(s => s.countrySid))
        .forMember(d => d.Country.Name, mapFrom(s => s.countryName))

    mapper
        .createMap<OpDataUnitKPI, OperationalDataUnitKpiDTO>('OpDataUnitKPI', 'OperationalDataUnitKpiDTO', { namingConventions: PascalToCamelCase })
        .forMember(d => d.plantSid, mapFrom(s => s.Site.Id))
        .forMember(d => d.plantName, mapFrom(s => s.Site.Name))
        .forMember(d => d.machineSid, mapFrom(s => s.Unit.Id))
        .forMember(d => d.machineName, mapFrom(s => s.Unit.Name))
        .forMember(d => d.nrCommercialStarts, mapFrom(s => s.Starts.Commercial))
        .forMember(d => d.nrTechnicalStarts, mapFrom(s => s.Starts.Technical))
        .forMember(d => d.nrFailureStarts, mapFrom(s => s.Starts.Failed))
        .forMember(d => d.nrStartsTotal, mapFrom(s => s.Starts.Total))
        .forMember(d => d.nrGoodStartsTotal, mapFrom(s => s.Starts.Good))
        .forMember(d => d.nrFailedCommercialStarts, mapFrom(s => s.Starts.FailedCommercial))
        .forMember(d => d.nrFailedTechnicalStarts, mapFrom(s => s.Starts.FailedTechnical))
        .forMember(d => d.airActual, ignore())
        .forMember(d => d.airForecast, ignore())
        .forMember(d => d.waterActual, ignore())
        .forMember(d => d.waterForecast, ignore())
        .forMember(d => d.commentExternal, ignore())
        .forMember(d => d.commentInternal, ignore())
        .forMember(d => d.commentOther, ignore())
        .forMember(d => d.valueExternal, ignore())
        .forMember(d => d.valueInternal, ignore())
        .forMember(d => d.valueOther, ignore())

    mapper
        .createMap<OperationalDataUnitKpiDTO, OpDataUnitKPI>('OperationalDataUnitKpiDTO', 'OpDataUnitKPI', { namingConventions: CamelToPascalCase })
        .forMember(d => d.Site.Id, mapFrom(s => s.plantSid))
        .forMember(d => d.Site.Name, mapFrom(s => s.plantName))
        .forMember(d => d.Unit.Id, mapFrom(s => s.machineSid))
        .forMember(d => d.Unit.Name, mapFrom(s => s.machineName))
        .forMember(d => d.Starts.Commercial, convertUsing(numberConverter, (s: OperationalDataUnitKpiDTO) => s.nrCommercialStarts))
        .forMember(d => d.Starts.Technical, convertUsing(numberConverter, (s: OperationalDataUnitKpiDTO) => s.nrTechnicalStarts))
        .forMember(d => d.Starts.Failed, convertUsing(numberConverter, (s: OperationalDataUnitKpiDTO) => s.nrFailureStarts))
        .forMember(d => d.Starts.Total, convertUsing(numberConverter, (s: OperationalDataUnitKpiDTO) => s.nrStartsTotal))
        .forMember(d => d.Starts.FailedTechnical, mapFrom(s => s.nrFailedTechnicalStarts))
        .forMember(d => d.Starts.FailedCommercial, mapFrom(s => s.nrFailedCommercialStarts))
        .forMember(d => d.Starts.Good, convertUsing(numberConverter, (s: OperationalDataUnitKpiDTO) => s.nrGoodStartsTotal))
        .forMember(d => d.NrBreakdownEvents, convertUsing(numberConverter, (s: OperationalDataUnitKpiDTO) => s.nrBreakdownEvents))
        .forMember(d => d.NrTrips, convertUsing(numberConverter, (s: OperationalDataUnitKpiDTO) => s.nrTrips))
        .forMember(d => d.OperationHours, convertUsing(numberConverter, (s: OperationalDataUnitKpiDTO) => s.operationHours))
        .forMember(d => d.GeneratedPowerGrossMwh, convertUsing(numberConverter, (s: OperationalDataUnitKpiDTO) => s.generatedPowerGrossMwh))
        .forMember(d => d.GeneratedPowerMwh, convertUsing(numberConverter, (s: OperationalDataUnitKpiDTO) => s.generatedPowerMwh))
        .forMember(d => d.GridFeedVolumeMwh, convertUsing(numberConverter, (s: OperationalDataUnitKpiDTO) => s.gridFeedVolumeMwh))
        .forMember(d => d.AuxiliaryConsumption, convertUsing(numberConverter, (s: OperationalDataUnitKpiDTO) => s.auxiliaryConsumption))
        .forMember(d => d.AuxiliaryPowerOnShutdown, convertUsing(numberConverter, (s: OperationalDataUnitKpiDTO) => s.auxiliaryPowerOnShutdown))

    mapper
        .createMap<OpDataUnitKPI, OperationalDataUnitKpiEditDTO>('OpDataUnitKPI', 'OperationalDataUnitKpiEditDTO', { namingConventions: PascalToCamelCase })
        .forMember(d => d.machineSid, mapFrom(s => s.Unit.Id))
        .forMember(d => d.nrCommercialStarts, mapFrom(s => s.Starts.Commercial))
        .forMember(d => d.nrTechnicalStarts, mapFrom(s => s.Starts.Technical))
        .forMember(d => d.nrFailureStarts, mapFrom(s => s.Starts.Failed))
        .forMember(d => d.nrStartsTotal, mapFrom(s => s.Starts.Total))
        .forMember(d => d.nrGoodStartsTotal, mapFrom(s => s.Starts.Good))
        .forMember(d => d.nrFailedCommercialStarts, mapFrom(s => s.Starts.FailedCommercial))
        .forMember(d => d.nrFailedTechnicalStarts, mapFrom(s => s.Starts.FailedTechnical))
        .forMember(d => d.airActual, ignore())
        .forMember(d => d.airForecast, ignore())
        .forMember(d => d.waterActual, ignore())
        .forMember(d => d.waterForecast, ignore())
        .forMember(d => d.commentExternal, ignore())
        .forMember(d => d.commentInternal, ignore())
        .forMember(d => d.commentOther, ignore())
        .forMember(d => d.valueExternal, ignore())
        .forMember(d => d.valueInternal, ignore())
        .forMember(d => d.valueOther, ignore())
}
