import { render, staticRenderFns } from "./event-table.vue?vue&type=template&id=48bb9d8e&scoped=true"
import script from "./event-table.ts?vue&type=script&lang=ts&external"
export * from "./event-table.ts?vue&type=script&lang=ts&external"
import style0 from "./event-table.less?vue&type=style&index=0&id=48bb9d8e&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48bb9d8e",
  null
  
)

export default component.exports