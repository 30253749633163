var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.isLoading)?_c('div',{staticClass:"event-tab-vgb mt-2"},[_c('pui-grid-container',[_c('pui-grid-row',[_c('pui-grid-column',{attrs:{"cols":12}},[_c('div',{staticClass:"flex-row flex-center event-tab-vgb__header"},[_c('span',{staticClass:"pui-jelly-bean pui-jelly-bean--non-actionable pui-jelly-bean--g",staticStyle:{"background-color":"#ffa100"}},[_vm._v(" "+_vm._s(_vm.currentVgbStateObject.vgbClassificationFlag.text)+" ")]),_c('pui-button',{attrs:{"small":"","disabled":_vm.isClearAllBtnDisabled},on:{"click":_vm.clearAllInputs}},[_vm._v(" "+_vm._s(_vm.$t("info.clearAll"))+" ")])],1)])],1),_c('pui-grid-row',{key:_vm.languageKey},[_c('pui-grid-column',{attrs:{"cols":12}},[_c('pui-form-group',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(12),expression:"12"}],attrs:{"label":this.$t('vgbFlag.InputFieldTitle.conditionBeforeTitle'),"label-for":"conditionBefore","has-info-layer":"","pui-popover-config":{
                        headline: this.$t('vgbFlag.tooltip.conditionBefore.title'),
                        position: 'right'
                    }}},[_c('p',{attrs:{"slot":"pui-popover-content"},slot:"pui-popover-content"},[_vm._v(" "+_vm._s(this.$t('vgbFlag.tooltip.conditionBefore.text'))+" ")]),_c('pui-form-select',{attrs:{"id":"conditionBeforeDropdown","label":this.$t('vgbFlag.InputFieldTitle.conditionBeforeTitle'),"options":_vm.filterOptions.enumVgbConditionBeforeSid,"search-input-placeholder":this.$t('vgbFlag.inputfields.placeholderTextInputField'),"is-disabled":_vm.isReadOnly,"is-valid":_vm.isEnumVgbConditionBeforeSidValid},on:{"change":function($event){return _vm.onSelectionChange()}},model:{value:(_vm.currentVgbStateObject.enumVgbConditionBeforeSid),callback:function ($$v) {_vm.$set(_vm.currentVgbStateObject, "enumVgbConditionBeforeSid", _vm._n($$v))},expression:"currentVgbStateObject.enumVgbConditionBeforeSid"}})],1),_c('pui-form-group',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(12),expression:"12"}],attrs:{"label":this.$t('vgbFlag.InputFieldTitle.timeFrameTitle'),"label-for":"timeFrame","has-info-layer":"","pui-popover-config":{
                        headline: this.$t('vgbFlag.tooltip.timeFrame.title'),
                        position: 'right'
                    }}},[_c('p',{attrs:{"slot":"pui-popover-content"},slot:"pui-popover-content"},[_vm._v(" "+_vm._s(this.$t('vgbFlag.tooltip.timeFrame.text'))+" ")]),_c('pui-form-select',{attrs:{"id":"timeFrameDropdown","label":this.$t('vgbFlag.InputFieldTitle.timeFrameTitle'),"options":_vm.filterOptions.enumVgbTimeframeSid,"search-input-placeholder":this.$t('vgbFlag.inputfields.placeholderTextInputField'),"is-disabled":_vm.isReadOnly,"is-valid":_vm.isEnumVgbTimeframeSidValid},on:{"change":_vm.onSelectionChange},model:{value:(_vm.currentVgbStateObject.enumVgbTimeframeSid),callback:function ($$v) {_vm.$set(_vm.currentVgbStateObject, "enumVgbTimeframeSid", _vm._n($$v))},expression:"currentVgbStateObject.enumVgbTimeframeSid"}})],1),_c('pui-form-group',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(12),expression:"12"}],attrs:{"label":this.$t('vgbFlag.InputFieldTitle.eventTypeTitle'),"label-for":"eventType","has-info-layer":"","pui-popover-config":{
                        headline: this.$t('vgbFlag.tooltip.eventType.title'),
                        position: 'right'
                    }}},[_c('p',{attrs:{"slot":"pui-popover-content"},slot:"pui-popover-content"},[_vm._v(" "+_vm._s(this.$t('vgbFlag.tooltip.eventType.text'))+" ")]),_c('pui-form-select',{attrs:{"id":"eventTypeDropdown","label":this.$t('vgbFlag.InputFieldTitle.eventTypeTitle'),"options":_vm.filterOptions.enumVgbEventTypeSid,"is-disabled":_vm.disableDropdownFields || _vm.isReadOnly,"search-input-placeholder":this.$t('vgbFlag.inputfields.placeholderTextInputField'),"is-valid":_vm.isEnumVgbEventTypeSidValid},model:{value:(_vm.currentVgbStateObject.enumVgbEventTypeSid),callback:function ($$v) {_vm.$set(_vm.currentVgbStateObject, "enumVgbEventTypeSid", _vm._n($$v))},expression:"currentVgbStateObject.enumVgbEventTypeSid"}})],1),_c('pui-form-group',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(12),expression:"12"}],attrs:{"label":this.$t('vgbFlag.InputFieldTitle.mainConsequencesTitle'),"label-for":"mainConsequences","has-info-layer":"","pui-popover-config":{
                        headline: this.$t('vgbFlag.tooltip.mainCons.title'),
                        position: 'right'
                    }}},[_c('p',{attrs:{"slot":"pui-popover-content"},slot:"pui-popover-content"},[_vm._v(" "+_vm._s(this.$t('vgbFlag.tooltip.mainCons.text'))+" ")]),_c('pui-form-select',{attrs:{"id":"mainConDropdown","label":this.$t('vgbFlag.InputFieldTitle.mainConsequencesTitle'),"search-input-id":"mainConDropdownInput","options":_vm.filterOptions.enumVgbMainConsequenceSid,"is-disabled":_vm.disableDropdownFields || _vm.isReadOnly,"search-input-placeholder":this.$t('vgbFlag.inputfields.placeholderTextInputField'),"is-valid":_vm.isEnumVgbMainConsequenceSidValid},model:{value:(_vm.currentVgbStateObject.enumVgbMainConsequenceSid),callback:function ($$v) {_vm.$set(_vm.currentVgbStateObject, "enumVgbMainConsequenceSid", _vm._n($$v))},expression:"currentVgbStateObject.enumVgbMainConsequenceSid"}})],1),_c('pui-form-group',{directives:[{name:"pui-form-grid-column",rawName:"v-pui-form-grid-column",value:(12),expression:"12"}],attrs:{"label":this.$t('vgbFlag.InputFieldTitle.externalInfluenceDescription'),"label-for":"externalInfluenceDescription"}},[_c('pui-form-select',{attrs:{"id":"externalInfluenceDescription","label":this.$t('vgbFlag.InputFieldTitle.externalInfluenceDescription'),"search-input-id":"externalInfluenceDescription","options":_vm.filterOptions.enumVgbExternalInfluenceDescriptionSid,"is-disabled":_vm.externalInfluenceDescriptionFieldDisabled,"search-input-placeholder":this.$t('vgbFlag.inputfields.placeholderTextInputField')},model:{value:(_vm.currentVgbStateObject.enumVgbExternalInfluenceDescriptionSid),callback:function ($$v) {_vm.$set(_vm.currentVgbStateObject, "enumVgbExternalInfluenceDescriptionSid", $$v)},expression:"currentVgbStateObject.enumVgbExternalInfluenceDescriptionSid"}})],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }