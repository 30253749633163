import Vue from 'vue'
import { EnumInfoMasterData, EventInfoService} from '@/services/event-info-service'
import { EventVgbClassificationModel } from '@/models/event-info'
import { EventBus } from '@/utils'

interface ItranslationMap{
    ConditionBefore: {
        [key: number]: any;
    };
    TimeFrame: {
        [key: number]: any;
    };
    EventType: {
        [key: number]: any;
    };
    MainConsequences: {
        [key: number]: any;
    };
    ExternalInfluenceDescription: {
        [key: number]: any;
    };
    FlagTitle: {
        [key: string]: any;
    };
}


export const TranslationMap: ItranslationMap = {
    ConditionBefore:{
        811 : 'vgbFlag.inputfields.conditionBeforeDropDown.overload',
        812 : 'vgbFlag.inputfields.conditionBeforeDropDown.fullLoad',
        813 : 'vgbFlag.inputfields.conditionBeforeDropDown.partLoad',
        814 : 'vgbFlag.inputfields.conditionBeforeDropDown.startUp',
        815 : 'vgbFlag.inputfields.conditionBeforeDropDown.shutDown',
        816 : 'vgbFlag.inputfields.conditionBeforeDropDown.change',
        817 : 'vgbFlag.inputfields.conditionBeforeDropDown.standstill',
        818 : 'vgbFlag.inputfields.conditionBeforeDropDown.notKnown'
    },
    TimeFrame:{
        821: 'vgbFlag.inputfields.timeFrameDropDown.auto',
        822: 'vgbFlag.inputfields.timeFrameDropDown.manu',
        823: 'vgbFlag.inputfields.timeFrameDropDown.arranged',
        824: 'vgbFlag.inputfields.timeFrameDropDown.restart',
        825: 'vgbFlag.inputfields.timeFrameDropDown.exceedUnplanned',
        826: 'vgbFlag.inputfields.timeFrameDropDown.delay',
        827: 'vgbFlag.inputfields.timeFrameDropDown.extension',
        828: 'vgbFlag.inputfields.timeFrameDropDown.postpone',
        829: 'vgbFlag.inputfields.timeFrameDropDown.fix',
        830: 'vgbFlag.inputfields.timeFrameDropDown.annual',
        831: 'vgbFlag.inputfields.timeFrameDropDown.exceedPlanned',
        832: 'vgbFlag.inputfields.timeFrameDropDown.without',
    },
    EventType:{
        841: 'vgbFlag.inputfields.eventTypeDropDown.disturbance',
        842: 'vgbFlag.inputfields.eventTypeDropDown.damage',
        843: 'vgbFlag.inputfields.eventTypeDropDown.test',
        844: 'vgbFlag.inputfields.eventTypeDropDown.lubrication',
        845: 'vgbFlag.inputfields.eventTypeDropDown.maintenance',
        846: 'vgbFlag.inputfields.eventTypeDropDown.inspection',
        847: 'vgbFlag.inputfields.eventTypeDropDown.prevRepair',
        848: 'vgbFlag.inputfields.eventTypeDropDown.clean',
        849: 'vgbFlag.inputfields.eventTypeDropDown.revision',
        850: 'vgbFlag.inputfields.eventTypeDropDown.fuelChange',
        851: 'vgbFlag.inputfields.eventTypeDropDown.reconstruction',
        852: 'vgbFlag.inputfields.eventTypeDropDown.externalWithoutDamage',
        853: 'vgbFlag.inputfields.eventTypeDropDown.externalWithoutDamagePreservation',
        854: 'vgbFlag.inputfields.eventTypeDropDown.externalWithoutAmbient',
        855: 'vgbFlag.inputfields.eventTypeDropDown.externalWithoutGridRestr',
        856: 'vgbFlag.inputfields.eventTypeDropDown.externalWithoutLack',
        857: 'vgbFlag.inputfields.eventTypeDropDown.externalWithoutOthers',
        858: 'vgbFlag.inputfields.eventTypeDropDown.functionalTest',
        859: 'vgbFlag.inputfields.eventTypeDropDown.legalRequire',
        861: 'vgbFlag.inputfields.eventTypeDropDown.lackOfReactivity',
        862: 'vgbFlag.inputfields.eventTypeDropDown.other'
    },
    MainConsequences:{
        865: 'vgbFlag.inputfields.mainConsequenceDropDown.withoutRestr',
        866: 'vgbFlag.inputfields.mainConsequenceDropDown.capRestr',
        867: 'vgbFlag.inputfields.mainConsequenceDropDown.island',
        868: 'vgbFlag.inputfields.mainConsequenceDropDown.shutdown'
    },
    ExternalInfluenceDescription:{
        871: 'vgbFlag.inputfields.externalInfluenceDescription.lowTemperature',
        872: 'vgbFlag.inputfields.externalInfluenceDescription.highTemperature',
        873: 'vgbFlag.inputfields.externalInfluenceDescription.wind',
        874: 'vgbFlag.inputfields.externalInfluenceDescription.floodingHeavyRain',
        875: 'vgbFlag.inputfields.externalInfluenceDescription.floodingSeaRiverLake',
        876: 'vgbFlag.inputfields.externalInfluenceDescription.drought',
        877: 'vgbFlag.inputfields.externalInfluenceDescription.other',
    },
    FlagTitle:{
        OE: 'vgbFlag.flagTitle.OE',
        PUB: 'vgbFlag.flagTitle.PUB',
        PUN: 'vgbFlag.flagTitle.PUN',
        BU: 'vgbFlag.flagTitle.BU',
        OM: 'vgbFlag.flagTitle.OM',
        EXT: 'vgbFlag.flagTitle.Ext',
        ND: 'vgbFlag.flagTitle.ND',
    }

}
