import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';
import { OperationalDataActionsNames } from '@/store/modules/operational-data/operational-data-actions';
import { cloneDeep } from 'lodash';
import { OperationalDataGettersNames } from '@/store/modules/operational-data/operational-data-getters';
import { OperationalDataException } from '@/utils/exceptions/OperationalDataException';
import { OperationalDataStatusDTO } from '@/models/operational-data/operational-data-DTO';
import { VueConstructor } from 'vue';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
import { CustomInputsValidator } from './custom-inputs-validator';

interface MyView {
    $pui: {
        toast: Function;
    };
}
@Component
export default class OpDataComandButtons extends (Vue as VueConstructor<Vue & MyView>) {
    isDisplaySaveButton = false;
    isDisplaySubmitButton = false;
    isDisplayFinalButton = false;
    isDisplayReOpenButton = false;
    isDisplayRequestInfoButton = false;
    inputsValidator = new CustomInputsValidator();
    unwatchStore!: Function;

    created(): void {
        const activeStatus = store.state.operationalData.activeStatus;
        if (!activeStatus) {
            console.error(new OperationalDataException('activeStatus can not be null'));
            return;
        }

        this.setPermissions(activeStatus.reportStatusSid);
    }

    mounted(): void {
        this.$nextTick(() => this.inputsValidator.validate(this.$root));
        this.unwatchStore = store.watch(state => state.operationalData.triggerInputsFinder, this.onInputFinderTrigger)
    }

    beforeDestroy(): void {
        this.unwatchStore();
    }

    onInputFinderTrigger(value: boolean): void {
        if (!value)
            return;

        this.inputsValidator.validate(this.$root);
        store.commit(OperationalDataMutationsNames.TriggerInputsFinder, false);
    }

    get isButtonDisabled(): boolean {
        const hasInvalidInputs = this.inputsValidator.inputs.some(i => !i.isValid);
        return hasInvalidInputs || !store.getters[OperationalDataGettersNames.HasValidTabsData];
    }

    setPermissions(statusSid: number): void {
        this.isDisplaySaveButton = this.$store.getters['userAccessManagement/useButtonSave'](statusSid);
        this.isDisplaySubmitButton = this.$store.getters['userAccessManagement/useButtonSubmit'](statusSid);
        this.isDisplayFinalButton = this.$store.getters['userAccessManagement/useButtonFinal'](statusSid);
        this.isDisplayReOpenButton = this.$store.getters['userAccessManagement/useButtonReOpen'](statusSid);
        this.isDisplayRequestInfoButton = this.$store.getters['userAccessManagement/useButtonRequestInfo'](statusSid);
    }

    async saveEventChanges(): Promise<void> {
        this.$store.commit('loading');
        try {
            await store.dispatch(OperationalDataActionsNames.SaveOperationalDataDetailsModel);
            this.popToastSuccess();
        }
        catch {
            this.popToastError();
        }
        this.$store.commit('loading');
    }

    async changeStatus(statusSid: number): Promise<void> {
        const activeStatus: OperationalDataStatusDTO | null = cloneDeep(store.state.operationalData.activeStatus);
        if (!activeStatus) {
            console.error(new OperationalDataException('activeStatus can not be null'));
            return;
        }
        activeStatus.reportStatusSid = statusSid;
        await store.dispatch(OperationalDataActionsNames.SetStatus, activeStatus);
    }

    saveAndChangeStatus(statusSid: number): void {
        this.changeStatus(statusSid);
        this.saveEventChanges();
        this.setPermissions(statusSid);
    }

    popToastSuccess(): void {
        (this as any).$pui.toast({
            title: this.$t('operationalData.entryView.submittingModal.successTitle').toString(),
            copy: this.$t('operationalData.entryView.submittingModal.successText').toString()
        })
    }

    popToastError(): void {
        (this as any).$pui.toast({
            title: this.$t('operationalData.entryView.submittingModal.errorTitle').toString(),
            copy: this.$t('operationalData.entryView.submittingModal.errorText').toString()
        })
    }

    closeSubmitDialog(): void {
        (this.$refs['submitModalRef'] as any).close();
    }

    confirmSubmit(): void {
        this.saveAndChangeStatus(753);
        this.closeSubmitDialog();
    }

}
