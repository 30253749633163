import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import InfoView from '@/views/info/info-view.vue';
import InfoTableView from '@/views/info-table/info-table-view.vue';
import OperationalDataView from '@/views/operational-data/operational-data-view.vue';
import OpDataEntryView from '@/views/op-data-entry-view/op-data-entry-view.vue';
import store from '@/store';
import i18n from '@/utils/i18n';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/eventinfo/',
        name: 'Event Info +',
        props: true,
        meta: { translationKey: 'navigation.info' },
        component: InfoTableView
    },
    {
        path: '/eventinfo/:id',
        name: 'Event Info +',
        props: true,
        meta: {
            translationKey: 'navigation.info',
            hideNav: true
        },
        component: InfoView,
    },
    {
        path: '/operationaldata/',
        name: 'Operational Data',
        props: true,
        meta: { translationKey: 'navigation.operationalData' },
        component: OperationalDataView,
    },
    {
        path: '/operationaldata/:unitId/:date',
        name: 'Operational Data',
        props: true,
        meta: {
            translationKey: 'navigation.operationalData',
            hideNav: true
        },
        component: OpDataEntryView,
    },
    {
        path: '/powerbi-reporting',
        name: 'PowerbiReporting',
        beforeEnter(): void { window.open(i18n.t('powerBiLink').toString(), '_blank') },
        meta: {
            translationKey: 'navigation.powerbiReporting',
        },
    }
];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    if(to !== from){
        const tempStore = store;
        tempStore.commit('eventManager/RESET_CURRENT_VGB_STATE');
        tempStore.commit('eventManager/RESET_CURRENT_VGB_OPTIONS');
    }
    next();
})

export default router;
