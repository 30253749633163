import Vue from 'vue';
import store from '@/store';
import { EventBus } from '@/utils';
import { VGBType } from '@/models/vgb-enum';
import { mapGetters } from 'vuex';
import { FunctionalLocationService } from '@/services/functional-location-service';
import { FunctionalLocationOption } from '@/models/functional-location';

const functionalLocationService = new FunctionalLocationService();

const EventInfoSystemsTab = Vue.extend({
    name: 'EventInfoSystemsTab',
    components: {},
    props: {
        plantId: {
            type: Number,
            required: true
        },
        machineId: {
            type: Number,
            required: true
        },
        // property required to avoid making multiple requests when this tab is not active
        isTabActive: {
            type: Boolean,
            default: false,
        }
    },
    data(): {
        selectOptions: {
            mainSystems: Array<{label: string; value: string}>;
            subSystems: Array<{label: string; value: string}>;
        };
        currentState: {
            eventSapHierarchicalSystem: string;
            eventSapMainSystem?: string;
            eventSapSubSystem?: string;
        };
        mainSystemSelectRefreshKey: number;
        subSystemSelectRefreshKey: number;

        selectedFunctionalLocationOption: FunctionalLocationOption[];
        functionalLocationOptions: FunctionalLocationOption[];
        functionalLocationSearchTerm: string;
        isLoadingFunctionalLocation: boolean;
        readonly minSearchTermLength: number;
        } {
        return {
            selectOptions: {
                mainSystems: [],
                subSystems: [],
            },
            currentState: {
                eventSapHierarchicalSystem: '',
                eventSapMainSystem: undefined,
                eventSapSubSystem: undefined
            },
            // property needed as pui-form-select doesn't display selected (bound) option after select options load async
            mainSystemSelectRefreshKey: 0,
            // property needed as pui-form-select doesn't display selected (bound) option after select options load async
            subSystemSelectRefreshKey: 0,

            minSearchTermLength: 5,
            selectedFunctionalLocationOption: [],
            functionalLocationOptions: [],
            functionalLocationSearchTerm: '',
            isLoadingFunctionalLocation: false,
        }
    },
    async created() {
        EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => {
            this.translateMainSystemOptions();
            this.translateSubSystemOptions();
        });

        const currentState = store.getters['eventManager/getSystemsTab'];
        this.currentState = {
            eventSapHierarchicalSystem: currentState.eventSapHierarchicalSystem,
            eventSapMainSystem: currentState.eventSapMainSystem,
            eventSapSubSystem: currentState.eventSapSubSystem,
        };

        if (!this.isTabActive) {
            return;
        }

        if (this.currentState.eventSapHierarchicalSystem) {
            this.functionalLocationOptions = [{
                label: this.currentState.eventSapHierarchicalSystem,
                value: this.currentState.eventSapHierarchicalSystem,
            }];
            this.selectedFunctionalLocationOption = [{
                label: this.currentState.eventSapHierarchicalSystem,
                value: this.currentState.eventSapHierarchicalSystem,
            }]
        }
        
        const selectOptions = this.currentVgbTabSystemOptions;
        this.selectOptions = {
            mainSystems: selectOptions.mainSystems,
            subSystems: selectOptions.subSystems,
        };

        if (!this.selectOptions.mainSystems.length) {
            await this.checkSystemCode();
        }
    },
    watch: {
        currentState: {
            handler(newCurrentState): void {
                store.commit('eventManager/SET_CURRENT_SYSTEMS_STATE', newCurrentState);
            },
            deep: true
        },
        selectOptions: {
            handler(newSelectOptions): void {
                store.commit('eventManager/SET_CURRENT_SYSTEM_SELECTION_STATE', newSelectOptions);
            },
            deep: true
        }
    },
    methods: {
        async searchFunctionalLocations(searchTerm: string): Promise<void> {
            const trimmedTerm = searchTerm.trim();
            this.functionalLocationSearchTerm = trimmedTerm;

            if (trimmedTerm.length === 0) {
                this.abortFunctionalLocationSelection();
            }

            if (trimmedTerm.length < this.minSearchTermLength) {
                return;
            }

            try {
                this.isLoadingFunctionalLocation = true;
                const options = (await functionalLocationService.searchFunctionalLocations(this.plantId, trimmedTerm)).result.items;

                // this check is done in case the user does multiple consecutive search requests (with different terms) to show the correct result sets
                if (trimmedTerm !== this.functionalLocationSearchTerm) {
                    return;
                }

                // if the request doesn't return any results show what user typed as selectable option
                if (options.length === 0) {
                    this.functionalLocationOptions = [
                        {
                            value: trimmedTerm,
                            label: trimmedTerm,
                            secondaryLabel: this.$i18n.t('info.customFunctionalLocationHint').toString(),
                        }
                    ];
                } else {
                    this.functionalLocationOptions = options.map(o => ({
                        value: o.name,
                        label: o.name,
                        secondaryLabel: o.description,
                        mainSystemId: o.mainSystemId,
                        mainSystemName: o.mainSystemName,
                        subSystemId: o.subSystemId,
                        subSystemName: o.subSystemName,
                    }))
                }
            } finally {
                this.isLoadingFunctionalLocation = false;
            }
        },
        abortFunctionalLocationSelection(): void {
            this.selectedFunctionalLocationOption = [];
            this.currentState = {
                eventSapHierarchicalSystem: '',
                eventSapMainSystem: undefined,
                eventSapSubSystem: undefined
            };
            this.updateMainSystemOptions();
        },
        selectFunctionalLocation([selectedOption]: FunctionalLocationOption[]): void {
            if (!selectedOption) {
                return;
            }

            this.currentState.eventSapHierarchicalSystem = selectedOption.value;

            if (selectedOption.mainSystemId && selectedOption.subSystemId) {
                this.selectPredefinedSystems(selectedOption);
            } else {
                this.updateMainSystemOptions();
                this.updateSubSystemOptions();
            }
        },
        selectPredefinedSystems(functionalLocation: FunctionalLocationOption): void {
            this.currentState.eventSapMainSystem = functionalLocation.mainSystemId!;
            this.currentState.eventSapSubSystem = functionalLocation.subSystemId!.toString();

            this.selectOptions = {
                mainSystems: [{
                    value: this.currentState.eventSapMainSystem,
                    label: functionalLocation.mainSystemName ?? ''
                }],
                subSystems: [{
                    value: this.currentState.eventSapSubSystem,
                    label: functionalLocation.subSystemName ?? ''
                }],
            };

            this.mainSystemSelectRefreshKey++;
            this.subSystemSelectRefreshKey++;
        },
        translateMainSystemOptions(): void {
            if(this.selectOptions.mainSystems != undefined){
                this.selectOptions.mainSystems.forEach((entry) => {
                    entry.label = this.$t(`mainSystemOptions.${entry.value}`).toString();
                })
            }
        },
        translateSubSystemOptions(): void {
            if(this.selectOptions.subSystems != undefined){
                this.selectOptions.subSystems.forEach((entry) => {
                    const shortened = entry.value.replace('.', '')
                    entry.label = this.$t(`subSystemOptions.${shortened}`).toString();
                })
            }
        },
        async updateMainSystemOptions(): Promise<void> {
            try {
                await store.dispatch('eventManager/getSystemsSelectionMain', {
                    machineId: this.machineId,
                    functionalLocation: this.currentState.eventSapHierarchicalSystem,
                });

                const selectOptions = this.currentVgbTabSystemOptions;
                this.selectOptions.mainSystems = selectOptions != undefined ? selectOptions.mainSystems : [];
                
                this.mainSystemSelectRefreshKey++;

                if (this.selectOptions.mainSystems.length === 1) {
                    // preselect the first option if there is only one item in the list
                    this.currentState.eventSapMainSystem = this.selectOptions.mainSystems[0].value;
                } else if (this.isSystemNotSelectable(this.selectOptions.mainSystems, this.currentState.eventSapMainSystem)) {
                    // if the current option is not available anymore in the new list, then reset the dropdown selection
                    this.currentState.eventSapMainSystem = undefined;
                }

                this.translateMainSystemOptions();
            } catch (error) {
                console.log(error)
            }
        },
        async updateSubSystemOptions(): Promise<void> {
            try {
                await store.dispatch('eventManager/getSystemsSelectionSub', {
                    machineId: this.machineId,
                    mainSystemId: this.currentState.eventSapMainSystem,
                    functionalLocation: this.currentState.eventSapHierarchicalSystem,
                });

                const selectOptions = this.currentVgbTabSystemOptions;
                this.selectOptions.subSystems = selectOptions != undefined ? selectOptions.subSystems : [];
                
                this.subSystemSelectRefreshKey++;

                if (this.selectOptions.subSystems.length === 1) {
                    // preselect the first option if there is only one item in the list
                    this.currentState.eventSapSubSystem = this.selectOptions.subSystems[0].value;
                } else if (this.isSystemNotSelectable(this.selectOptions.subSystems, this.currentState.eventSapSubSystem)) {
                    // if the current option is not available anymore in the new list, then reset the dropdown selection
                    this.currentState.eventSapSubSystem = undefined;
                }

                this.translateSubSystemOptions();
            } catch (error) {
                console.log(error)
            }
        },
        async selectedMainSystemChanged(newMainSystem: string): Promise<void> {
            if (this.currentState.eventSapMainSystem === newMainSystem) return;

            this.currentState.eventSapMainSystem = newMainSystem;
            await this.updateSubSystemOptions();
        },
        async checkSystemCode(): Promise<void> {
            await this.updateMainSystemOptions();
            await this.updateSubSystemOptions();
        },
        isSystemNotSelectable(systems: Array<{label: string; value: string}>, system?: string): boolean {
            return (systems.findIndex((option: {label: string; value: string}) => option.value === system) === -1);
        }
    },
    computed: {
        ...mapGetters({
            currentVgtTabData: 'eventManager/getVgbTab',
            currentVgbTabSystemOptions: 'eventManager/getSystemsTabSelectionCurrentState'
        }),
        isReadOnly(): boolean {
            const eventInfo = store.getters['eventManager/getSingleParentEvent']();
            return (eventInfo && eventInfo.enumEventInfoStatusSid) ?
                !store.getters['userAccessManagement/useButtonSave'](eventInfo.enumEventInfoStatusSid) : true;
        },
        isParentEvent(): boolean {
            return !this.$route.params.id.includes('.');
        },
        isFunctionalLocationValid(): boolean {
            if (!this.currentVgtTabData || !this.isParentEvent) return true;
            const hasRequiredFields = [VGBType.BU.toString(), VGBType.OM.toString()].includes(this.currentVgtTabData.vgbClassificationFlag.value);
            if (!hasRequiredFields) return true;
            return !!this.currentState.eventSapHierarchicalSystem && this.currentState.eventSapHierarchicalSystem.trim().length > 0;
        }
    }
})

export default EventInfoSystemsTab;
