import { EventBus } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';
import UnavailabilityTab from '../unavailability-tab/unavailability-tab.vue';
import ComponentsTab from '../components-tab/components-tab.vue';
import ThermalGenerationTab from '../thermal-generation-tab/thermal-generation-tab.vue';
import MaterialsTab from '../materials-tab/materials-tab.vue';
import QualityLosses from '../quality-losses-tab/quality-losses-tab.vue'
import CHPTab from '../chp-tab/chp-tab.vue'
import store from '@/store';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
import { OperationalDataGettersNames } from '@/store/modules/operational-data/operational-data-getters';
import { OpDataUnitKPI } from '@/models/operational-data/operational-data-details';
import { AvailableUnitsForChp } from '../chp-tab/chp-tab';

@Component({
    components: {
        UnavailabilityTab,
        ComponentsTab,
        ThermalGenerationTab,
        MaterialsTab,
        QualityLosses,
        CHPTab
    }
})
export default class OpDataEntryDetailsTabs extends Vue {

    tabs = [
        'operationalData.entryView.tabs.unavailability.title',
        'operationalData.entryView.tabs.components',
        'operationalData.entryView.tabs.thermalGeneration',
        'operationalData.entryView.tabs.materials',
        'operationalData.entryView.tabs.wasteMaterials',
        'operationalData.entryView.tabs.qualityLossesTab.title'
    ];

    tabsRefreshKey = false;

    mounted(): void {
        EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => this.tabsRefreshKey = !this.tabsRefreshKey);
        store.watch(s => s.operationalData.activeUnitKPI, () => this.tabsRefreshKey = !this.tabsRefreshKey);
        if(this.shouldShowChpTab())
            this.tabs.push('operationalData.entryView.tabs.chpTab.title')
    }

    shouldShowChpTab(): boolean {
        const activeUnitId = (store.getters[OperationalDataGettersNames.GetActiveUnitKPI] as OpDataUnitKPI).Unit.Id;
        return AvailableUnitsForChp.includes(activeUnitId);
    }

    onTabChange(): void {
        this.$nextTick(() => store.commit(OperationalDataMutationsNames.TriggerInputsFinder));
    }

    get invalidTabs(): string {
        let tabs = '';

        if (store.state.operationalData.tabsValidationState) {
            const obj = store.state.operationalData.tabsValidationState;
            let key: keyof typeof obj;
            for (key in obj) {
                if (!obj[key]) {
                    if (tabs.length>0) tabs += ', '
                    tabs += this.tabTranslations[key].toString().toUpperCase();
                }
            }
        }
        return tabs;
    }

    get tabTranslations(): any {
        return {
            isUnavailabilityTabValid: this.$t('operationalData.entryView.tabs.unavailability.title'),
            isComponentTabValid: this.$t('operationalData.entryView.tabs.components'),
            isThermalTabValid: this.$t('operationalData.entryView.tabs.thermalGeneration'),
            isMaterialTabValid: this.$t('operationalData.entryView.tabs.materials'),
            isWasteMaterialTabValid: this.$t('operationalData.entryView.tabs.wasteMaterials'),
            isQualityTabValid: this.$t('operationalData.entryView.tabs.qualityLossesTab.title'),
            isChpTabValid: this.$t('operationalData.entryView.tabs.chpTab.title')
        }
    }
}
