export class PuiMultiselectOpenCloseEventEmitter {
    private static readonly PuiMultiselectOpenClass = 'pui-form-type-ahead--open';
    private static readonly ObserversConfig = {
        attributes: true,
        attributeFilter: ['class'],
        childList: false,
        characterData: false
    }

    private onOpenCallbacks: { targetElementId: string; callback: Function }[] = [];
    private onCloseCallbacks: { targetElementId: string; callback: Function }[] = [];
    private observers: { targetElementId: string; observer: MutationObserver; isOpen: boolean }[] = [];

    onOpen(targetElementId: string, callback: Function): void {
        this.onOpenCallbacks.push({ targetElementId: targetElementId, callback: callback });
    }

    onClose(targetElementId: string, callback: Function): void {
        this.onCloseCallbacks.push({ targetElementId: targetElementId, callback: callback });
    }

    subscribe(targetElementId: string): void {
        const targetElement = document.getElementById(targetElementId);
        if (!targetElement)
            return;

        const observer = new MutationObserver(mutations => this.onMultiSelectClassChange(mutations));
        observer.observe(targetElement, PuiMultiselectOpenCloseEventEmitter.ObserversConfig);
        this.observers.push({ targetElementId, observer: observer, isOpen: false });
    }

    private onMultiSelectClassChange(mutations: MutationRecord[]): void {
        for (let i = 0; i < mutations.length; i++) {
            const mutation = mutations[i];
            if (mutation.attributeName !== 'class')
                continue;

            const mutationTarget = mutation.target as HTMLElement;
            const observer = this.observers.find(o => o.targetElementId === mutationTarget.id);
            if (!observer)
                continue;

            const isCurrentlyOpen = mutationTarget.classList.contains(PuiMultiselectOpenCloseEventEmitter.PuiMultiselectOpenClass);
            this.emitNewStateToSubscribers(observer.isOpen, isCurrentlyOpen, mutationTarget.id);
            observer.isOpen = isCurrentlyOpen;
        }
    }

    private emitNewStateToSubscribers(previouslyOpened: boolean, isCurrentlyOpen: boolean, targetId: string): void {
        if (previouslyOpened && !isCurrentlyOpen){
            this.onCloseCallbacks.filter(closeSubscribsion => closeSubscribsion.targetElementId === targetId)
                .forEach(currentTargetSubscribsion => currentTargetSubscribsion.callback());
        }

        if (!previouslyOpened && isCurrentlyOpen){
            this.onOpenCallbacks.filter(openSubscribsion => openSubscribsion.targetElementId === targetId)
                .forEach(currentTargetSubscribsion => currentTargetSubscribsion.callback());
        }
    }
}