import { ApiResponse } from '@/models';
import { OpDataUnitKPI, OperationalDataDetails, UnavailabilityModel } from '@/models/operational-data/operational-data-details';
import { VGBType } from '@/models/vgb-enum';
import store, { StateWithModules } from '@/store';
import { service } from '@/store/modules/operational-data/operational-data-actions';
import { OperationalDataGettersNames } from '@/store/modules/operational-data/operational-data-getters';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
import { EventBus } from '@/utils';
import { OperationalDataException } from '@/utils/exceptions/OperationalDataException';
import { ModeSettings } from '@/utils/mode-settings';
import * as echarts from 'echarts';
import { EChartsOption, SeriesOption } from 'echarts';
import { BarChart } from 'echarts/charts';
import {
    GridComponent, LegendComponent, TitleComponent, TooltipComponent
} from 'echarts/components';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { colors } from './op-data-chart-colors';
import { DefaultOpDataChartOptions } from './op-data-chart-options';

use([
    CanvasRenderer,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    BarChart,
    GridComponent
]);

@Component
export default class OpDataChart extends Vue {

    chart: echarts.ECharts | null = null;
    option: EChartsOption = cloneDeep(DefaultOpDataChartOptions);
    isLoading = false;

    chartData: any[] = [];
    unavailabilityList: UnavailabilityModel[] = [];
    unitKpiList: (OpDataUnitKPI | null)[] = [];
    unwatchUnavailabilityModels!: Function;
    unsubscribeUnavailabilityModels!: Function;
    unitKPI!: OpDataUnitKPI;
    currentMonthSeriesIndex = 2;
    storeUnavailabilityModels!: UnavailabilityModel[];
    electricNetGenerationVolume!: string;
    electricNetGenerationVolumeSeriesName!: string;
    standby!: string;
    standbySeriesName!: string;
    currentDate = this.$route.params.date;
    oneMonthAgo = moment(this.currentDate).subtract(1, 'month').format('YYYY-MM');
    twoMonthsAgo = moment(this.currentDate).subtract(2, 'month').format('YYYY-MM');
    minSeriesLabelThreshold = 3;

    public datesArray = [
        this.twoMonthsAgo,
        this.oneMonthAgo,
        this.currentDate,
    ]

    created(): void {
        this.isLoading = true;
        this.initTranslations();
        this.initSeriesNames();
        this.watchUnavailabilityModels();
        this.createTooltipFormatter();
        this.createLegendFormatter();
        EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => this.initTranslations());
    }

    mounted(): void {
        this.initChart();
        (this.option.yAxis as any).name = this.$t('operationalData.entryView.chart.percentForPeriod').toString();
        window.addEventListener('resize', () => this.onResizeHandler());
    }

    beforeDestroy(): void {
        if (this.unsubscribeUnavailabilityModels)
            this.unsubscribeUnavailabilityModels();

        if (this.unwatchUnavailabilityModels)
            this.unwatchUnavailabilityModels();

        this.resetOptionData();
        window.removeEventListener('resize', () => this.onResizeHandler());
    }

    private initSeriesNames(): void {
        this.electricNetGenerationVolumeSeriesName = 'operationalData.entryView.unitGeneration.electricNetGeneration';
        this.standbySeriesName = 'operationalData.entryView.chart.standby';
    }

    private initTranslations(): void {
        this.chart?.clear();
        this.electricNetGenerationVolume = this.$t(this.electricNetGenerationVolumeSeriesName).toString();
        if (!this.isLoading)
            (this.option.yAxis as any).name = this.$t('operationalData.entryView.chart.percentForPeriod').toString();
        this.standby = this.$t(this.standbySeriesName).toString();
        this.updateChart();
    }

    private createLegendFormatter(): void {
        (this.option.legend as any).formatter = (data: any): string => {
            return (data in VGBType) ? data : `${this.$t(`${data}`)}`
        }
    }

    private createTooltipFormatter(): void {
        (this.option.tooltip as any).formatter = (params: any): string => {
            let seriesRowStart = '', seriesRowEnd = '', seriesRow = '', seriesFooter = '';

            params.forEach((param: any) => {
                seriesRowStart = `<div style="display: flex; justify-content: space-between;"><div><span style="margin: auto; margin-right: 0.5em; border-radius: 100%; height: 10px; width: 10px; background-color: ${param.color}; display: inline-block;"></span><span style="margin: auto;">`
                seriesRowEnd = `:</span></div><span style="font-weight: 700; padding-left: 4em;">${(param.value).toFixed(ModeSettings.InputNumberPrecision)}%</span></div>`
                const seriesRowFunction = (seriesName: string): string => `${seriesRowStart}${seriesName}${seriesRowEnd}`;
                const seriesRowName = (translationPath: string): string => this.$t(`${translationPath}`).toString();

                Object.values(VGBType).find(type => {
                    if (param.seriesName === type)
                        seriesRow += seriesRowFunction(seriesRowName(`vgbFlag.flagTitle.${param.seriesName}`));
                })

                if (param.seriesName === this.electricNetGenerationVolumeSeriesName) {
                    seriesRow += seriesRowFunction(seriesRowName(this.electricNetGenerationVolumeSeriesName));
                }

                if (param.seriesName === this.standbySeriesName)
                    seriesRow += seriesRowFunction(seriesRowName(this.standbySeriesName));

                seriesFooter = `<div style="text-align: center">${param.name}</div>`;
            });
            return `<div>${seriesRow} ${seriesFooter}</div>`
        }
    }

    private watchUnavailabilityModels(): void {
        this.unwatchUnavailabilityModels = store.watch(
            (state: StateWithModules) => state.operationalData.activeUnavailabilityModels,
            async () => {
                await this.onActiveUnavailabilityModelsInit();
                this.unwatchUnavailabilityModels();
            },
        );
    }

    private subscribeUnavailabilityModels(): void {
        this.unsubscribeUnavailabilityModels = store.subscribe(
            (mutation) => {
                if (mutation.type === OperationalDataMutationsNames.UpdateSingleUnavailabilityModel || mutation.type === OperationalDataMutationsNames.SetUnitKpiProperty) {
                    this.onMutation();
                }
            }
        )
    }

    private async onActiveUnavailabilityModelsInit(): Promise<void> {
        this.unitKPI = await cloneDeep(store.getters[OperationalDataGettersNames.GetActiveUnitKPI]);
        this.storeUnavailabilityModels = cloneDeep(store.state.operationalData.activeUnavailabilityModels);
        await this.getPreviousMonthsData();
        this.chartData.push(cloneDeep(store.state.operationalData.activeUnavailabilityModels));
        if (!store.state.operationalData.activeUnitKPI) {
            console.error(new OperationalDataException('No activeUnitKPI data currently in the Vuex store.'));
            return;
        }
        this.unitKpiList.push(cloneDeep(store.state.operationalData.activeUnitKPI));

        this.getUnavailabilityListValues();
        this.initData();
        this.subscribeUnavailabilityModels();

        this.unwatchUnavailabilityModels();
        this.isLoading = false;
    }


    private async onMutation(): Promise<void> {
        this.unitKPI = cloneDeep(store.getters[OperationalDataGettersNames.GetActiveUnitKPI]);
        const storeUnavailabilityModels = cloneDeep(store.state.operationalData.activeUnavailabilityModels);

        (this.option.series as SeriesOption[]).forEach((series: any) => {
            const unavailabilityModelForCurrentSeries = storeUnavailabilityModels.find(model => model.unavailabilityType === series.name);
            if (unavailabilityModelForCurrentSeries)
                this.setCurrentMonthUnavailabilityValue(series, unavailabilityModelForCurrentSeries);

            this.setCurrentMonthStandby(series, storeUnavailabilityModels);
            this.setCurrentMonthElectricNetGenerationVolume(series);
        });

        (this.option.series as SeriesOption[]).forEach((series: any) => this.seriesDataLabelHandler(series));
        this.updateChart();
    }

    private seriesDataLabelHandler(series: any): void {
        const sum = (this.option.series as any).reduce((p: number, c: any) => {
            return p + c.data[2].value
        }, 0);

        const thresholdValue = (series.data[2].value / sum) * 100;
        series.data[2].label.show = thresholdValue > this.minSeriesLabelThreshold
    }

    private setCurrentMonthUnavailabilityValue(series: any, storeUnavailabilityModel: UnavailabilityModel): void {
        if (series.name === storeUnavailabilityModel.unavailabilityType) {
            series.data[this.currentMonthSeriesIndex].value = Number(storeUnavailabilityModel.valuePct.toFixed(ModeSettings.InputNumberPrecision));
        }
    }

    private setCurrentMonthStandby(series: any, storeUnavailabilityModels: UnavailabilityModel[]): void {
        if (series.name === this.standbySeriesName) {
            series.data[this.currentMonthSeriesIndex].value = this.getCurrentMonthStandby(storeUnavailabilityModels);
        }
    }

    private setCurrentMonthElectricNetGenerationVolume(series: any): void {
        if (series.name === this.electricNetGenerationVolumeSeriesName) {
            if (isNaN(this.getElectricNetGenerationVolumeAsPercent(this.unitKPI?.GeneratedPowerMwh, this.unitKPI?.NetVolumeForPeriodMwh))) {
                series.data[this.currentMonthSeriesIndex].value = 0;
            } else {
                series.data[this.currentMonthSeriesIndex].value = this.getElectricNetGenerationVolumeAsPercent(this.unitKPI?.GeneratedPowerMwh, this.unitKPI?.NetVolumeForPeriodMwh);
            }
        }
    }

    private async getPreviousMonthsData(): Promise<void> {
        const unitID = this.$route.params.unitId;
        const previousReportMonths = [
            this.twoMonthsAgo,
            this.oneMonthAgo
        ];

        let results: ApiResponse<OperationalDataDetails>[];
        try {
            results = await Promise.all(previousReportMonths.map(month => service.getOperationalDataEntry(Number(unitID), month)));
        }
        catch (err) {
            previousReportMonths.forEach((reportMonth) => {
                this.prepopulateUnavailabilityModels(unitID, reportMonth);
            });
            console.warn('OpDataChart: No UnitKPI data for one or both of the first two months of this period. We have prepared charts with 100% standby values.');
            return;
        }

        results.forEach((result, index) => {
            this.getPreviousMonthsUnavailabilityList(result, index, unitID, previousReportMonths);
            this.unitKpiList.push(result.result.UnitKPI);
        });
    }

    private getPreviousMonthsUnavailabilityList(result: any, index: number, unitID: string, reportMonths: string[]): void {
        if (!result.result.UnitKPI || !result.result.UnavailabilityList || result.result.UnavailabilityList.length === 0) {
            this.prepopulateUnavailabilityModels(unitID, reportMonths[index]);
        } else {
            this.chartData.push(result.result.UnavailabilityList);
        }
    }

    private prepopulateUnavailabilityModels(unitID: string, reportMonth: string): void {
        const items: UnavailabilityModel[] = [];
        Object.values(VGBType).forEach(type => {
            const model: UnavailabilityModel = {
                machineSid: Number(unitID),
                reportMonth: reportMonth,
                unavailabilityType: type,
                valueMwh: 0,
                valuePct: 0
            };
            items.push(model);
        });
        this.chartData.push(items);
    }

    private getUnavailabilityListValues(): void {
        this.chartData.forEach((unavailabilityList: UnavailabilityModel[]) => {
            unavailabilityList.forEach(item => {
                this.unavailabilityList.push({
                    unavailabilityType: item.unavailabilityType,
                    reportMonth: item.reportMonth,
                    valueMwh: item.valueMwh,
                    valuePct: item.valuePct,
                    machineSid: item.machineSid
                });
            })
        });
    }

    private getElectricNetGenerationVolumeAsPercent(electricNetGenerationVolume: number, NetVolumeForPeriodMwh: number): number {
        return Number(((electricNetGenerationVolume / NetVolumeForPeriodMwh) * 100).toFixed(ModeSettings.InputNumberPrecision));
    }

    private getStandby(date: string): number {
        let total = 0;

        this.unavailabilityList.forEach(item => {
            if (item.reportMonth === this.dateToFullString(date)) {
                total += item.valuePct;
            }
        });

        this.unitKpiList.forEach(unitKpi => {
            if (!unitKpi)
                return;

            if (unitKpi.ReportMonth === this.dateToFullString(date)) {
                total += this.getElectricNetGenerationVolumeAsPercent(unitKpi.GeneratedPowerMwh, unitKpi.NetVolumeForPeriodMwh)
            }
        });

        return (total > 100) ? 0 : Number((100 - total).toFixed(ModeSettings.InputNumberPrecision));
    }

    private getCurrentMonthStandby(storeUnavailabilityModels: UnavailabilityModel[]): number {
        let total = 0;

        total += storeUnavailabilityModels.reduce((p, c) => p + c.valuePct, 0);

        if (isNaN(this.getElectricNetGenerationVolumeAsPercent(this.unitKPI.GeneratedPowerMwh, this.unitKPI.NetVolumeForPeriodMwh))) {
            total += 0;
        } else {
            total += this.getElectricNetGenerationVolumeAsPercent(this.unitKPI.GeneratedPowerMwh, this.unitKPI.NetVolumeForPeriodMwh);
        }

        return (total > 100) ? 0 : Number((100 - total).toFixed(ModeSettings.InputNumberPrecision));
    }

    private seriesObject(name: string, data: number[]): SeriesOption {
        const dataMap = data.map(data => ({
            value: data,
            itemStyle: { color: colors[name] },
            label: {
                show: this.isSeriesObjectLabel(data)
            }
        }));

        return {
            name: name,
            data: dataMap,
            type: 'bar',
            stack: 'stackBy',
            label: {
                show: true
            },
            emphasis: {
                focus: 'series'
            }
        }
    }

    private isSeriesObjectLabel(data: number): boolean {
        return (data > this.minSeriesLabelThreshold);
    }

    private getUniqueUnavailabilityTypes(): void {
        const uniqueTypes = new Set(this.unavailabilityList.map(item => item.unavailabilityType));

        uniqueTypes.forEach(type => {
            const percentages = this.unavailabilityList
                .filter(unavailiabilityItem => unavailiabilityItem.unavailabilityType === type)
                .map(item => Number((item.valuePct).toFixed(ModeSettings.InputNumberPrecision)));

            (this.option.series as SeriesOption[]).push(this.seriesObject(type, percentages));
        });
    }

    private getEleNetGenVol(): void {
        const netGeneratedElectricVolumeData = this.unitKpiList.map(unitKpi => !unitKpi ? 0 : this.getElectricNetGenerationVolumeAsPercent(unitKpi.GeneratedPowerMwh, unitKpi.NetVolumeForPeriodMwh));
        (this.option.series as SeriesOption[]).push(this.seriesObject(this.electricNetGenerationVolumeSeriesName, netGeneratedElectricVolumeData));
    }

    private getStandbyData(): void {
        const standbyData = this.datesArray.map(date => this.getStandby(date));
        (this.option.series as SeriesOption[]).push(this.seriesObject(this.standbySeriesName, standbyData));
    }

    private addDataToSeries(): void {
        this.getUniqueUnavailabilityTypes();
        this.getEleNetGenVol();
        this.getStandbyData();
    }

    private addDataToXAxis(): void {
        (this.option.xAxis as any).data = this.datesArray;
    }

    private dateToFullString(date: string): string {
        return date + '-01T00:00:00Z';
    }

    private initChart(): void {
        this.chart = echarts.init(this.$refs.myChart as HTMLDivElement);
        this.chart?.setOption(this.option);
    }

    private updateChart(): void {
        this.chart?.setOption(this.option, true);
    }

    private resizeChart(): void {
        this.chart?.resize();
    }

    private onResizeHandler(): void {
        if (this.$refs.myChart !== null && this.$refs.myChart !== undefined) {
            this.resizeChart();
        }
    }

    private resetOptionData(): void {
        this.option.series = [];
        this.unavailabilityList = [];
        this.updateChart();
    }

    private initData(): void {
        this.addDataToSeries();
        this.addDataToXAxis();
        this.updateChart();
    }
}
