import { render, staticRenderFns } from "./event-parallel.vue?vue&type=template&id=c00cc3b2&scoped=true"
import script from "./event-parallel.ts?vue&type=script&lang=ts&external"
export * from "./event-parallel.ts?vue&type=script&lang=ts&external"
import style0 from "./event-parallel.less?vue&type=style&index=0&id=c00cc3b2&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c00cc3b2",
  null
  
)

export default component.exports