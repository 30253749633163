import { ModeServiceBase } from '@/services/base-services';
import { ApiResponse } from '@/models';
import { OperationalDataEnumDTO } from '@/models/operational-data/operational-data-DTO';
import { EventVgbClassificationList } from '@/models/event-info';

export class EnumService extends ModeServiceBase {
    constructor() {
        super('enum');
    }

    public async getEnum(sid: number): Promise<ApiResponse<OperationalDataEnumDTO>> {
        try {
            return (await this.api.get(undefined, `${sid}`)).data;
        }
        catch (err) {
            throw new Error(err as any);
        }
    }

    public async getVgbClassificationById(enumSid: number): Promise<ApiResponse<EventVgbClassificationList>> {
        return (await this.api.get(undefined, `${enumSid}`)).data;
    }
}
