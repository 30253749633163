import { MappingProfile } from '@automapper/core';
import { mapDetails } from '../detailsMapping/DetailsModel/DetailsModelMapping';
import { mapTabs } from '../detailsMapping/Tabs/tabs-mapping';
import { mapUnitKPI } from '../detailsMapping/UnitKpi/UnitKpiMapping';

export const opDataDetailsProfile: MappingProfile = (mapper) => {
    mapUnitKPI(mapper);
    mapTabs(mapper);
    mapDetails(mapper);
};
