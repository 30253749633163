import ApiHelper from '@/utils/api-helper';
import { BaseService } from './service-base';

export class ModeServiceBase implements BaseService {
    protected api: ApiHelper;
    endpoint: string | undefined;

    constructor(endpoint?: string, api: 'generic' | 'metaData' | 'masterData' | 'userAccessManagement' = 'generic') {
        this.api = new ApiHelper(endpoint, api);
        this.endpoint = endpoint;
    }
}
