import { render, staticRenderFns } from "./not-found-page.vue?vue&type=template&id=ee2b9bf2&scoped=true"
import script from "./not-found-page.ts?vue&type=script&lang=ts&external"
export * from "./not-found-page.ts?vue&type=script&lang=ts&external"
import style0 from "./not-found-page.less?vue&type=style&index=0&id=ee2b9bf2&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee2b9bf2",
  null
  
)

export default component.exports