import { ApiResponse, EventStatusListResult } from '@/models';
import _ from 'lodash';
import { ModeServiceBase } from '@/services/base-services';

export enum EnumMasterData {
    EventType = 700,
    EventStatus = 730,
    EventReasonEex = 710,
    EventReason = 720,
    EventNature = 3028,
    OpDataStatus = 750
}

// FIXME: This is temporary until master data API returns this correctly!
export enum EnumEventStatus {
    Draft = 731,
    CheckedAccepted = 732,
    CheckedRejected = 733,
    Published = 734,
    CompletedAndArchived = 735,
    CancelledAndArchived = 736,
}

export class EventStatusCollection {
    Draft: { sid: number; name: string } | undefined;
    CheckedAccepted: { sid: number; name: string } | undefined;
    CheckedRejected: { sid: number; name: string } | undefined;
    Published: { sid: number; name: string } | undefined;
    CompletedAndArchived: { sid: number; name: string } | undefined;
    CancelledAndArchived: { sid: number; name: string } | undefined;

    constructor(constructorObject: {
        Draft: { sid: number; name: string } | undefined;
        CheckedAccepted: { sid: number; name: string } | undefined;
        CheckedRejected: { sid: number; name: string } | undefined;
        Published: { sid: number; name: string } | undefined;
        CompletedAndArchived: { sid: number; name: string } | undefined;
        CancelledAndArchived: { sid: number; name: string } | undefined;
    }) {
        this.Draft = constructorObject.Draft;
        this.CheckedAccepted = constructorObject.CheckedAccepted;
        this.CheckedRejected = constructorObject.CheckedRejected;
        this.Published = constructorObject.Published;
        this.CompletedAndArchived = constructorObject.CompletedAndArchived;
        this.CancelledAndArchived = constructorObject.CancelledAndArchived;
    }

    public getName (sid: number): string {
        for (const entry of Object.entries(this)) {
            if (entry[1].sid === sid) {
                return entry[1].name as string;
            }
        }
        throw `Error: Cannot find name for sid ${sid}`;
    }

    public getSid (name: string): number {
        for (const entry of Object.entries(this)) {
            if (entry[1].name === name) {
                return entry[1].sid as number;
            }
        }
        throw `Error: Cannot find sid for name ${name}`;
    }
}

/**
 * @name EventHeaderEnumerationsService
 * @description This class is used to query the backend server for event (header) enum data.
 */
export class EventHeaderEnumerationsService extends ModeServiceBase {
    constructor() {
        super('enum');
    }

    public async getEventEnumList (enumType: EnumMasterData): Promise<ApiResponse<EventStatusListResult>> {
        // Construct the necessary request to send to the backend
        // const request = `/${enumType}`

        // FIXME: temporary solution for event status
        /*
        if (enumType == EnumMasterData.EventStatus) {
            console.error('Warning: You are still using a temporary solution to retrieve the event status enum!')
            return {
                statusCode: 200,
                message: '',
                result: {
                    items: Object.keys(EnumEventStatus).map(key => {
                        return {
                            sid: (EnumEventStatus as any)[key] as number,
                            name: key,
                            description: '',
                            idTypeSid: 730,
                            isDeleted: false
                        }
                    })
                }
            }
        }
        */

        // Send request
        return (await this.api.get(undefined, `${enumType}`)).data;
    }

    public async getEventReasonNameById (id: number): Promise<string> {
        const statusList = await this.getEventEnumList(EnumMasterData.EventReasonEex)
        return this.getEventEnumNameById(id, statusList, 'reason')
    }

    public async getEventReasonIdByName (name: string): Promise<number> {
        const statusList = await this.getEventEnumList(EnumMasterData.EventReasonEex)
        return this.getEventEnumIdByName(name, statusList, 'reason')
    }

    public async getEventStatusNameById (id: number): Promise<string> {
        const statusList = await this.getEventEnumList(EnumMasterData.EventStatus)
        return this.getEventEnumNameById(id, statusList, 'status')
    }

    public async getEventStatusIdByName (name: string): Promise<number> {
        const statusList = await this.getEventEnumList(EnumMasterData.EventStatus)
        return this.getEventEnumIdByName(name, statusList, 'status')
    }

    private getEventEnumIdByName (name: string,
        statusList: ApiResponse<EventStatusListResult>,
        EnumIdentifier: string): number {
        const id = statusList
            .result
            .items
            .find(el => el.name.replace(/\s/g, '').toLowerCase() === name.replace(/\s/g, '').toLowerCase())?.sid
        if (!id) {
            throw `No Event ${EnumIdentifier} found for name ${name}`
        }
        return id
    }

    private getEventEnumNameById (id: number,
        statusList: ApiResponse<EventStatusListResult>,
        EnumIdentifier: string): string {
        const name = statusList.result.items.find(el => el.sid === id)?.name

        if (!name) {
            console.error(`No Event ${EnumIdentifier} found for id ${id}. Falling back to "Other".`)
            return 'Other'
        }

        // return pascal case
        return _.startCase(_.camelCase(name)).replace(/\s/g, '')
    }
}
