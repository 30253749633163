import CustomInput from '@/components/utils/custom-input/custom-input.vue';
import { OpDataUnitKPI } from '@/models/operational-data/operational-data-details';
import store from '@/store';
import { OperationalDataGettersNames } from '@/store/modules/operational-data/operational-data-getters';
import { OperationalDataStatusColor } from '@/utils/mode-statuses-colors';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        CustomInput
    }
})
export default class UnitInformation extends Vue {
    get reportStatusSid(): number | null {
        const activeStatus = store.state.operationalData.activeStatus;
        return activeStatus ? activeStatus.reportStatusSid : null;
    }

    get statusColor(): string {
        if(!this.reportStatusSid)
            return '';

        return OperationalDataStatusColor.get(this.reportStatusSid) as string;
    }

    get activeUnitKPIModel(): OpDataUnitKPI {
        return store.getters[OperationalDataGettersNames.GetActiveUnitKPI];
    }
}
