import { PuiMultiSelectOption } from '@/contracts';
import { MasterApiUnit } from '@/store/modules/contracts/Unit';
import { mapFrom, Mapper } from '@automapper/core';
import { OperationalDataFiltersOptions, SiteOption, UnitOption } from '@/models/operational-data/operational-data';

export const mapOpDataFiltersOptions = (mapper: Mapper): void => {
    mapper
        .createMap<MasterApiUnit[], OperationalDataFiltersOptions>('MasterApiUnit', 'OperationalDataFiltersOptions')
        .forMember(d => d.countries, mapFrom(s => {
            const uniqueValueMap = new Map<string, number>(s.map(item => ([item.countryName, item.countrySid])));
            const countries: PuiMultiSelectOption[] = [];
            uniqueValueMap.forEach((value, key) => countries.push({ label: key, value: value }));
            return countries;
        }))
        .forMember(d => d.sites, mapFrom(s => {
            const uniqueSitesValueMap = new Map<string, { value: number; countrySid: number }>(
                s.map(item => ([item.plantName, {
                    value: item.plantSid,
                    countrySid: item.countrySid
                }]))
            );
            const sites: SiteOption[] = [];
            uniqueSitesValueMap.forEach((value, key) => sites.push({
                label: key,
                value: value.value,
                countryId: value.countrySid
            }));
            return sites;
        }))
        .forMember(d => d.units, mapFrom(s => {
            const uniqueUnitsValueMap = new Map<string, { value: number; countrySid: number; siteSid: number }>(
                s.map(item => ([item.unitName, {
                    value: item.machineSid,
                    countrySid: item.countrySid,
                    siteSid: item.plantSid
                }]))
            );
            const units: UnitOption[] = [];
            uniqueUnitsValueMap.forEach((value, key) => units.push({
                label: key,
                value: value.value,
                countryId: value.countrySid,
                siteId: value.siteSid
            }));

            return units;
        }))
        .forMember(d => d.statuses, mapFrom(s => []))
}