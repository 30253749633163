import { PermissionEnum, PermissionMatrix, staticPermissionMatrix } from './permissions-matrix';


export class Supervisor {

    private _role = '';
    private _matrix: PermissionMatrix = {} as PermissionMatrix;
    private _permissions: PermissionMatrix = {} as PermissionMatrix;

    constructor(role: string) {
        this.init(role);
    }

    private init(role: string): void {
        this._matrix = staticPermissionMatrix;
        this._role = role;
        this._permissions = this.initPermissions(role);
    }

    public hasAccess (action: string, status: keyof typeof PermissionEnum): boolean {
        const currentLifecyclePermissions = this._permissions[status];
        return !!currentLifecyclePermissions && currentLifecyclePermissions[this._role]?.includes(action);
    }

    // Extract permissions from static permissionmatrix
    private initPermissions(role: string): PermissionMatrix {
        const permissions: PermissionMatrix = {} as PermissionMatrix;

        Object.keys(this._matrix).forEach((key: string) => {
            const currentLifecyclePermissions = this._matrix[key as keyof typeof PermissionEnum];
            if (currentLifecyclePermissions) {
                permissions[key as keyof typeof PermissionEnum] = {
                    [role]: currentLifecyclePermissions[role]
                };
            }
        });

        return permissions;
    }
}
