import {ModeServiceBase} from '@/services/base-services'
import {ApiResponse} from '@/models/api-response'
import {Application} from '@/models/application'
import Vue from 'vue'

/**
 * @name AppService
 * @description This class is used to query the identity server for user related data,
 * which is not related to authentication.
 */
export class AppService extends ModeServiceBase {
    public async getApps(): Promise<ApiResponse<{items: Application[]}>> {
        const api = Vue.prototype.$sdk?.$http?.$core.metaData.request.api
        if (!api) {
            return {
                result: {
                    items: [] as Application[]
                }
            } as unknown as ApiResponse<{items: Application[]}>
        }
        return (await api.get('use-cases')).data as unknown as ApiResponse<{items: Application[]}>
    }
}