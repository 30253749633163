import { UploadValidationItem, UploadValidationResult } from '@/models/manual-upload-validation-model';
import { eventInfoService } from '@/store/modules/event-management';
import { ModeException } from '@/utils/exceptions/ModeException';
import i18n from '@/utils/i18n';
import { GlobalColors } from '@/utils/mode-statuses-colors';

export class ManualEventsUploader {
    readonly htmlDomRefKey = 'manualUploadValidationWindow';

    public response: {
        statusCode: number | null;
        message: string | null;
    } = {
        statusCode: null,
        message: null
    };

    private validationResult: UploadValidationResult | null = null;
    validationItems: UploadValidationItem[] = [];
    itemName: string | undefined = '';

    get canFinalize(): boolean {
        const anyError = !this.validationResult || !!this.validationItems.find(i => i.iconName === 'error');
        return !anyError;
    }

    openUploadWindow(): void {
        this.resetUploadedFiles();
        this.getFileUploadInput().click();
    }

    refreshTranslations(): void {
        this.validationItems = [];
        this.prepareValidationItems(this.validationResult);
    }

    clearValidationResults(): void {
        this.validationItems = [];
        this.validationResult = null;
    }

    private getFileUploadInput(): HTMLInputElement {
        const uploadInput = document.getElementById('uploadInput') as HTMLInputElement | null;
        if (!uploadInput)
            throw new ModeException('Can not open upload file window because element with id `uploadInput` is not existing in DOM');

        return uploadInput;
    }

    async uploadFile(file: File): Promise<void> {
        const response = await eventInfoService.uploadEventsFile(file);

        this.response = {
            statusCode: response.statusCode,
            message: response.message
        }

        if (response.statusCode === 200) {
            this.validationResult = response.result;
            this.prepareValidationItems(response.result);
        }
    }

    private resetUploadedFiles(): void {
        this.getFileUploadInput().value = '';
    }

    private prepareValidationItems(validationResult: UploadValidationResult | null): void {
        if(!validationResult)
        {
            console.error(new ModeException('Can not prepare validation items for manual upload because `validationResult` is not existing'));
            return;
        }

        if (validationResult.successCount) {
            this.validationItems.push({
                iconColor: GlobalColors.Green,
                iconName: 'check',
                message: `${validationResult.successCount} ${i18n.t('manualEventsUpload.successMessage').toString()}`
            });
        }

        if (validationResult.parallelCount) {
            this.validationItems.push({
                iconColor: GlobalColors.Orange,
                iconName: 'info',
                message: `${validationResult.parallelCount} ${i18n.t('manualEventsUpload.paralellMessage').toString()}`
            });
        }

        if (validationResult.duplicatedEntries.length) {
            this.validationItems.push({
                iconColor: GlobalColors.Red,
                iconName: 'error',
                message: validationResult.duplicatedEntries.length
                    + ` ${i18n.t('manualEventsUpload.duplicatedMessage').toString()}: `
                    + validationResult.duplicatedEntries.join(', ')
            });
        }

        if (validationResult.incorrectEntries.length) {
            this.validationItems.push({
                iconColor: GlobalColors.Red,
                iconName: 'error',
                message: validationResult.incorrectEntries.length
                    + ` ${i18n.t('manualEventsUpload.incorrectMessage').toString()}: `
                    + validationResult.incorrectEntries.join(', ')
            });
        }
    }

    async finalizeUpload(): Promise<void> {
        const fileInput = this.getFileUploadInput();

        const fileToUpload = fileInput.files?.item(0);
        if(!fileToUpload)
        {
            console.error(new ModeException('Can not finalize manual upload - there is no files in the #uploadInput element'))
            return;
        }

        await eventInfoService.uploadEventsFile(fileToUpload, true);
        this.resetUploadedFiles();
    }
}
