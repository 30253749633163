import { EChartsOption } from 'echarts';

export const DefaultDoughnutOptions: EChartsOption = {
    tooltip: {
        trigger: 'item',
        position:'bottom'
    },
    legend: {
        icon: 'circle',
        bottom: 5,
        padding: 0,
        left: 'left',
        orient: 'horizontal',
        formatter: undefined,
        selectedMode: false
    },
    series: {
        center: ['48%', '40%'],
        name: 'Status',
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
            borderColor: '#fff',
            borderWidth: 2
        },
        label: {
            show: true,
            position: 'center',
            fontSize: 15,
            formatter: undefined
        },
        labelLine: {
            show: false
        },
        data: []
    }
};
