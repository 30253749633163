import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import EventHistory from '@/components/event/event-history/event-history.vue'
import EventInfo from '@/components/event/event-info/event-info.vue'
import EventParallel from '@/components/event/event-parallel/event-parallel.vue'
import EventTable from '@/components/event/event-table/event-table.vue'
import { EventHistoryModel, EventInfoModel, SplitEventModel } from '@/models'
import {eventInfoService} from '@/store/modules/event-management';
import { EventBus } from '@/utils'
import Vue from 'vue'
import ConfirmationModal from '@/components/confirmation-modal/confirmation-modal.vue';

const InfoView = Vue.extend({
    name: 'InfoView',
    components: {
        EventTable,
        EventInfo,
        EventHistory,
        EventParallel,
        Breadcrumb,
        ConfirmationModal,
    },
    props: {},
    data(): {
        routeId: string;
        isLoading: boolean;
        isHide: boolean;
        eventInfo?: EventInfoModel;
        eventHistoryData?: EventHistoryModel[];
        splitEvents?: SplitEventModel[];
        parallelEvents?: EventInfoModel[];
        breadcrumbLinks: { label: string; href: string; isActive?: boolean }[];
        splitBoolean: boolean;
        updateBoolean: boolean;
        errorResponse: any;
        next: any;
        } {
        return {
            routeId: '',
            isLoading: false,
            isHide: false,
            breadcrumbLinks: [],
            eventInfo: undefined,
            eventHistoryData: undefined,
            splitEvents: undefined,
            parallelEvents: undefined,
            splitBoolean: false,
            updateBoolean: false,
            errorResponse: undefined,
            next: undefined,
        }
    },
    async created(): Promise<void> {
        if ( this.$route.params.id ) {
            try {
                this.setLoading(true);
                this.routeId = this.$route.params.id;

                //TODO: Refactor to remove sessionStorage
                let eventId: number;
                // Check if id is a float number to distinguish parent event from split events.
                if (Number(this.$route.params.id)%1 === 0)
                    eventId = Number(this.$route.params.id);
                else
                    eventId = Number(this.$route.params.id.split('.')[0]);

                //Retrieve Enums for Vgb-Dropdown-options and save to store
                const promises: Promise<any>[] = Array.from(
                    this.$store.dispatch('eventManager/getVgbEnums', {service: eventInfoService}) as unknown as Promise<any>[]
                );

                /**
                * Retrieve ParentEvent and ChildEvent from Backend and set to store
                */
                promises.push(this.$store.dispatch('eventManager/getParentEventObject', {eventId: this.parentId}));
                promises.push(this.$store.dispatch('eventManager/getAllChildEvents', {eventId: this.parentId}));

                if(!this.isParentEvent){
                    promises.push(this.$store.dispatch('eventManager/getChildEventObject', {eventId: this.routeId}))
                }

                // Retrieve EventHistory and set to store
                promises.push(this.getEventHistory(eventId))
                await Promise.all(promises);

                //Retrieve Parallel Events and set to store
                this.eventInfo = this.$store.getters['eventManager/getSingleParentEvent']();
                this.splitEvents = this.$store.getters['eventManager/getAllChildEvents']();
                
                this.checkDataValidity();
                this.setBreadcrumbLinks();
                this.setLoading(false);
            } catch (error) {
                console.log(error);
                this.setLoading(false);
                this.errorResponse = error;
            }
        }
        EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => {this.translateBreadcrumbLinks()})

    },
    beforeRouteUpdate(to, from, next) {
        this.routerGuard(to, from, next);
    },
    beforeRouteLeave(to, from, next) {
        this.routerGuard(to, from, next);
    },
    computed: {
        isParentEvent(): boolean {
            return !this.routeId.includes('.');
        },
        parentId(): number {
            return Number(this.routeId.split('.')[0])
        },
        childId(): number {
            return Number(this.routeId.split('.')[1])
        }
    },
    methods: {
        routerGuard(to: any, from: any, next: any): void {
            if (this.$store.state.eventManager.isUnsavedChanges) {
                this.next = next;
                (this.$refs['ConfirmationModalRef'] as any).openModal();
            } else {
                next();
            }
        },
        ConfirmationModalCancelled(): void {
            this.next(false);
        },
        ConfirmationModalConfirmed(): void {
            this.next();
            this.$store.commit('eventManager/SET_IS_UNSAVED_CHANGES', false);
        },
        async getEventHistory(eventId: number): Promise<void> {
            /* eslint-disable sonarjs/no-duplicate-string */
            await this.$store.dispatch('eventManager/getEventHistory', {eventId: eventId});
            this.eventHistoryData = this.$store.getters['eventManager/getEventHistory'];
        },
        setLoading(loading: boolean): void{
            this.isLoading = loading;
        },
        // hide components while saving && reload history table
        async triggerIsHide(): Promise<void> {
            this.eventHistoryData = (await eventInfoService.getEventHistory(this.parentId)).result.items;
            this.isHide = !this.isHide;
        },
        checkDataValidity(): void {
            // check if received data object has all neccessary values
            if (this.eventInfo) {
                if(!this.checkSingleValueValidity('minimalCapacityUnit', this.eventInfo.minimalCapacityUnit)) this.eventInfo.minimalCapacityUnit = NaN
                if(!this.checkSingleValueValidity('nominalCapacityUnit', this.eventInfo.nominalCapacityUnit)) this.eventInfo.nominalCapacityUnit = NaN
                if(!this.checkSingleValueValidity('durationHours', this.eventInfo.durationHours)) this.eventInfo.durationHours = NaN
                if(!this.checkSingleValueValidity('unAvailCapacity', this.eventInfo.unAvailCapacity)) this.eventInfo.unAvailCapacity = NaN
                if(!this.checkSingleValueValidity('availCapacity', this.eventInfo.availCapacity)) this.eventInfo.availCapacity = NaN
                if(!this.checkSingleValueValidity('unAvailEnergy', this.eventInfo.unAvailEnergy)) this.eventInfo.unAvailEnergy = NaN
            }
        },
        checkSingleValueValidity(input: string, value: number | undefined): boolean {
            if (!value && value != 0) {
                return false
            }
            return true
        },
        translateBreadcrumbLinks(): void {
            this.breadcrumbLinks = [];
            this.setBreadcrumbLinks();
        },
        setBreadcrumbLinks(): void {
            const rootPath = '#/eventinfo';
            this.breadcrumbLinks.push({ label: this.$t('eventTable').toString(), href: rootPath });
            this.addEventToBreadcrumb(rootPath);
            if (this.splitEvents && this.splitEvents.length)
                this.addEventSplitsToBreadcrumb(this.splitEvents, rootPath);
        },
        deleteBreadcrumb(): void {
            for(let i=0; i< this.breadcrumbLinks.length; i++){
                this.breadcrumbLinks.pop();
            }
        },
        addEventToBreadcrumb(rootPath: string): void {
            if (this.eventInfo) {
                this.breadcrumbLinks.push(
                    {
                        label: this.$t('info.id') + ' ' + this.eventInfo.eventHeaderDto.eventIdentifier,
                        href: rootPath + '/' + this.eventInfo.eventId,
                        isActive: (this.eventInfo.eventId === Number(this.$route.params.id)) ? true : false
                    }
                );
            }
        },
        addEventSplitsToBreadcrumb(eventSplitEvent: SplitEventModel[], rootPath: string): void {
            eventSplitEvent.forEach(newSplitEvent => {
                const splitEventId = `${newSplitEvent.parentId}.${newSplitEvent.splitEventId}`;
                this.breadcrumbLinks.push({
                    label: this.$t('info.id') + ' ' + this.eventInfo?.eventHeaderDto.eventIdentifier + '.' + splitEventId.split('.')[1],
                    href: `${rootPath}/${splitEventId}`,
                    isActive: (splitEventId === this.$route.params.id) ? true : false
                });
            });
        },
        handleSplitEventPUT(): void {
            this.splitBoolean = !this.splitBoolean;
        },
        handleUpdateChart(): void {
            this.updateBoolean = !this.updateBoolean;
        }
    }
})

export default InfoView;
