import { PermissionEnum } from '@/models/permissions-matrix';

export enum GlobalColors {
    Orange = '#ffa300',
    Green = '#01b700',
    DarkerGreen = '#137E6D',
    Red = '#ce0000',
    Blue = '#00b2ff',
    Black = '#000000',
    Grey = '#cccccc',
    DarkerOrange = '#FA6639',
}

export enum EventLifecycleColors {
    Green = '#12DB44',
    Orange = '#FF3D00',
    DarkerGreen = '#19C142',
    Red = '#FC0D1B',
    DarkerOrange = '#FA6639',
    SalmonRed = '#ED3E49',
}


export const OperationalDataStatusColor = new Map<number, string>([
    [PermissionEnum.NewForOperationalData, GlobalColors.Orange],
    [PermissionEnum.DraftForOperationalData, GlobalColors.Grey],
    [PermissionEnum.InReviewForOperationalData, GlobalColors.Blue],
    [PermissionEnum.FinalForOperationalData, GlobalColors.Green],
    [PermissionEnum.PendingInfoForOperationalData, GlobalColors.Red],
]);
