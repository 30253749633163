import { Status } from '@/models/operational-data/operational-data';
import {
    OpDataUnitKPI,
    OperationalDataDetails,
    UnitQualityPctModel,
    ThermalHeatSums,
    ThermalGenerationModel
} from '@/models/operational-data/operational-data-details';
import store, { RootState } from '@/store';
import { OperationalDataException } from '@/utils/exceptions/OperationalDataException';
import { Getter, GetterTree } from 'vuex';
import { OperationalDataState } from '../contracts/stateContract/operational-data-state';

export enum OperationalDataGettersNames {
    GetActiveDetailsModel = 'getActiveDetailsModel',
    GetActiveUnitKPI = 'getActiveUnitKPI',
    GetStatusNameById = 'getStatusNameById',
    HasValidTabsData = 'hasValidTabsData',
    GetActiveUnitQualityPct = 'getActiveUnitPct',
    GetThermalHeatSums = 'getThermalHeatSums',
    GetActiveFilters = 'getActiveFilters',
    GetActiveDoughnut = 'getActiveDoughnut',
    GetStatuses = 'getStatuses',
    GetDropDownOptions = 'getDropDownOptions'
}

export class OperationalDataGetters
implements GetterTree<OperationalDataState, RootState> {
    [key: string]: Getter<OperationalDataState, RootState>;

    [OperationalDataGettersNames.GetActiveUnitQualityPct] = (
        state: OperationalDataState
    ): UnitQualityPctModel | null => {
        if (state.activeUnitQualityPctModels) {
            return state.activeUnitQualityPctModels;
        } else {
            return null;
        }
    };

    [OperationalDataGettersNames.GetActiveDetailsModel] = (
        state: OperationalDataState
    ): OperationalDataDetails | null => {
        return {
            Metadata: state.originalDetailsModel
                ? state.originalDetailsModel.Metadata
                : null,
            UnitKPI: state.activeUnitKPI,
            ThermalList: state.activeThermalModels,
            MaterialList: state.activeMaterialsModels,
            UnavailabilityList: state.activeUnavailabilityModels,
            QualityLossesPct: state.activeUnitQualityPctModels,
            QualityLossesTemp: state.activeUnitQualityTempModels,
            ComponentsList: state.activeComponentsModels,
            CHP: state.activeCHP
        };
    };

    [OperationalDataGettersNames.GetActiveUnitKPI] = (
        state: OperationalDataState
    ): OpDataUnitKPI | null => {
        if (!state.activeUnitKPI) {
            console.error(
                new OperationalDataException(
                    'Unit KPI for OperationalData should not be null'
                )
            );
            return null;
        }

        return state.activeUnitKPI;
    };

    [OperationalDataGettersNames.HasValidTabsData] = (state: OperationalDataState): boolean => {
        return !!(state.tabsValidationState && Object.values(state.tabsValidationState).every(v => v));
    };

    [OperationalDataGettersNames.GetStatusNameById] = (
        state: OperationalDataState
    ) => (id: number): string => {
        const status = state.statuses.find(status => status.Id === id);
        return status ? status.Name : '';
    };

    [OperationalDataGettersNames.GetThermalHeatSums] = (
        state: OperationalDataState
    ): ThermalHeatSums => {
        const initialValue = 0;
        const getSumForProperty = (
            a: number,
            b: ThermalGenerationModel,
            key: keyof ThermalGenerationModel
        ): number => a + Number(b[key]);
        return {
            Gross: state.activeThermalModels.reduce(
                (p, c) => getSumForProperty(p, c, 'grossHeatGenerationVolume'),
                initialValue
            ),
            Net: state.activeThermalModels.reduce(
                (p, c) => getSumForProperty(p, c, 'netHeatGenerationVolume'),
                initialValue
            ),
            ElectricEquivalentOfNet: state.activeThermalModels.reduce(
                (p, c) => getSumForProperty(p, c, 'electricEquivalentVolume'),
                initialValue
            )
        };
    };

    [OperationalDataGettersNames.GetActiveFilters] = (
        state: OperationalDataState
    ): any => {
        if (state.activeFilters) {
            return state.activeFilters;
        } else {
            return null;
        }
    };

    [OperationalDataGettersNames.GetActiveDoughnut] = (
        state: OperationalDataState
    ): any => {
        return state.opDataDoughnutData;
    };

    [OperationalDataGettersNames.GetStatuses] = (
        state: OperationalDataState
    ): Status[] | null => {
        return state.statuses;
    };

    [OperationalDataGettersNames.GetDropDownOptions] = (
        state: OperationalDataState
    ): any => {
        return state.dropDownOptions;
    }
}
