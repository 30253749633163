import { ActiveOperationalDataFilters } from '@/components/operational-data/op-data-filter/utils/ActiveOperationalDataFilters';
import { mapper } from '@/mappers/mapper';
import { OperationalDataFilterModel } from '@/models/operational-data/operational-data';
import { MaterialModel, ThermalGenerationModel } from '@/models/operational-data/operational-data-details';
import { OperationalDataDetailsDeleteDTO, OperationalDataUnitMaterialDTO, OperationalDataUnitThermalDTO } from '@/models/operational-data/operational-data-DTO';
import store from '@/store';

export interface OpDataQuery {
    urlParams: string;
    body: any;
}
export class OpDataQueryBuilder {
    buildQueryForOpDataList(activeFilters?: ActiveOperationalDataFilters): OpDataQuery {
        const filter: OperationalDataFilterModel = {
            Page: 1,
            Size: 20
        };

        if(store.state.operationalData.tablePagingMetadata)
            filter.Page = store.state.operationalData.tablePagingMetadata.CurrentPage;

        if (activeFilters)
            this.setupFilters(filter, activeFilters);

        let urlParams = '?';
        const body: any = {};

        for (const key in filter) {
            const value = filter[key as keyof OperationalDataFilterModel];

            if (Array.isArray(value)) {
                if (key === 'OperationalDataStatus')
                    body['operationalDataStatus'] = value;
                else
                    urlParams = urlParams.concat(`${key}=${value.join('&' + key + '=')}&`);
            }
            else {
                if (value)
                    urlParams = urlParams.concat(`${key}=${value}&`);
            }
        }

        if (urlParams.endsWith('&') || urlParams.endsWith('?'))
            urlParams = urlParams.slice(0, -1)

        return { urlParams: urlParams, body: body };
    }

    private setupFilters(filter: OperationalDataFilterModel, activeFilters: ActiveOperationalDataFilters): void {
        if (!activeFilters)
            return;

        if (activeFilters.startDate)
            filter.PeriodStart = activeFilters.startDate;
        if (activeFilters.endDate)
            filter.PeriodEnd = activeFilters.endDate;
        if (activeFilters.countries && activeFilters.countries.length)
            filter.AreaSids = activeFilters.countries;
        if (activeFilters.sites && activeFilters.sites.length)
            filter.PlantIds = activeFilters.sites;
        if (activeFilters.units && activeFilters.units.length)
            filter.UnitIds = activeFilters.units;
        if (activeFilters.statuses && activeFilters.statuses.length)
            filter.OperationalDataStatus = activeFilters.statuses;
    }
    buildQueryForOpDataDelete(model: ThermalGenerationModel | MaterialModel): OpDataQuery {
        const urlParams = 'operational-data'
        const body = this.mapDeletionObject(model);

        return {urlParams: urlParams, body: body}
    }

    private isThermalGenerationModel(model: ThermalGenerationModel | MaterialModel): model is ThermalGenerationModel {
        return ((model as ThermalGenerationModel).grossHeatGenerationVolume !== undefined)
    }

    private mapDeletionObject(model: ThermalGenerationModel | MaterialModel): OperationalDataDetailsDeleteDTO {
        let pObject: OperationalDataDetailsDeleteDTO
        if(this.isThermalGenerationModel(model)){
            const thermalDTO = mapper.map<ThermalGenerationModel, OperationalDataUnitThermalDTO>(model, 'OperationalDataUnitThermalDTO', 'ThermalGenerationModel');
            pObject = {
                operationalDataUnitThermalListDto: [thermalDTO],
                operationalDataUnitMaterialListDto: null
            }
        } else {
            const materialDTO = mapper.map<MaterialModel, OperationalDataUnitMaterialDTO>(model, 'OperationalDataUnitMaterialDTO', 'MaterialModel');
            pObject = {
                operationalDataUnitThermalListDto: null,
                operationalDataUnitMaterialListDto: [materialDTO]
            }
        }
        return pObject
    }
}
