import { TranslationMap } from '@/components/event/event-info/tabs/vgb-tab/translationmap';
import BaseCustomInputValidation from '@/components/utils/base-custom-input-validation/base-custom-input-validation';
import CustomInput from '@/components/utils/custom-input/custom-input.vue';
import { TabsValidationUpdateModel } from '@/models/operational-data/operational-data';
import { OpDataUnitKPI, UnavailabilityModel } from '@/models/operational-data/operational-data-details';
import { VGBType } from '@/models/vgb-enum';
import store from '@/store';
import { OperationalDataGettersNames } from '@/store/modules/operational-data/operational-data-getters';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
import { OperationalDataException } from '@/utils/exceptions/OperationalDataException';
import { ModeSettings } from '@/utils/mode-settings';
import { cloneDeep } from 'lodash';
import { Component } from 'vue-property-decorator';
import { CustomInputValidationState } from '../../op-data-comand-buttons/custom-inputs-validator';

export enum CountriesReportingToReact {
    Germany = 4100,
    Netherlands = 4101,
    Hungaria = 4109
}

interface UnavailabilityTabItem {
    type: VGBType;
    title: string;
    MWh: number;
    percentage: number;
}
@Component({
    components: {
        CustomInput
    }
})
export default class UnavailabilityTab extends BaseCustomInputValidation {
    readonly unavailabilityPrecision = ModeSettings.InputNumberPrecision;
    activeUnavailabilityModels!: UnavailabilityModel[];
    isEditable = true;
    unitId!: number;
    reportMonth!: string;
    min = 0;

   

    get netVolumeForPeriodMwh(): number {
        const unitKpi = store.getters[OperationalDataGettersNames.GetActiveUnitKPI];
        return unitKpi.NetVolumeForPeriodMwh;
    }

    created(): void {
        const unitKPI: OpDataUnitKPI = cloneDeep(store.getters[OperationalDataGettersNames.GetActiveUnitKPI]);
        this.unitId = unitKPI.Unit.Id;
        this.reportMonth = unitKPI.ReportMonth;
        this.activeUnavailabilityModels = cloneDeep(store.state.operationalData.activeUnavailabilityModels);
        this.activeUnavailabilityModels.forEach(model => {
            const item = this.items.find(item => item.type === model.unavailabilityType);
            if (item) {
                item.MWh = model.valueMwh;
                item.percentage = model.valuePct;
            }
        })

        this.setInputsStateForCurrentCountry(store.state.operationalData.originalDetailsModel?.Metadata?.Country.Id);
        this.onValidationStateChange = this.onCustomInputsValidationStateChange;
        this.$nextTick(() => this.validateCustomInputs(this));
    }

    onCustomInputsValidationStateChange(inputs: CustomInputValidationState[]): void {
        const tabValidationUpdateModel: TabsValidationUpdateModel = {
            key: 'isUnavailabilityTabValid',
            isValid: inputs.every(i => i.isValid)
        }
        store.commit(OperationalDataMutationsNames.SetTabValidationState, tabValidationUpdateModel);
    }

    setInputsStateForCurrentCountry(countryId: number | undefined): void {
        this.isEditable = countryId != undefined &&
            countryId != CountriesReportingToReact.Germany &&
            countryId != CountriesReportingToReact.Netherlands &&
            countryId != CountriesReportingToReact.Hungaria &&
            !this.isReadOnly();
    }

    items: UnavailabilityTabItem[] = [
        { type: VGBType.PUB, title: TranslationMap.FlagTitle.PUB, MWh: 0, percentage: 0 },
        { type: VGBType.PUN, title: TranslationMap.FlagTitle.PUN, MWh: 0, percentage: 0 },
        { type: VGBType.BU, title: TranslationMap.FlagTitle.BU, MWh: 0, percentage: 0 },
        { type: VGBType.OM, title: TranslationMap.FlagTitle.OM, MWh: 0, percentage: 0 },
        { type: VGBType.OE, title: TranslationMap.FlagTitle.OE, MWh: 0, percentage: 0 },
        { type: VGBType.EXT, title: TranslationMap.FlagTitle.EXT, MWh: 0, percentage: 0 },
        { type: VGBType.ND, title: TranslationMap.FlagTitle.ND, MWh: 0, percentage: 0 },
    ];

    onInputChange(item: UnavailabilityTabItem): void {
        if (this.netVolumeForPeriodMwh === undefined) {
            console.error(new OperationalDataException('Can not calculate new percentage value when NetVolumeForPeriodMwh is undefined'));
            return;
        }

        item.percentage = this.calculatePercentageFromMWhAndNetPeriod(item.MWh);
        store.commit(OperationalDataMutationsNames.UpdateSingleUnavailabilityModel, this.mapToUnavailabilityModel(item));
    }

    calculatePercentageFromMWhAndNetPeriod(MWh: number | undefined): number {
        return ((MWh ?? 0) / this.netVolumeForPeriodMwh) * 100;
    }

    mapToUnavailabilityModel(item: UnavailabilityTabItem): UnavailabilityModel {
        return {
            machineSid: this.unitId,
            reportMonth: this.reportMonth,
            unavailabilityType: item.type,
            valueMwh: item.MWh ?? 0,
            valuePct: item.percentage ?? 0
        }
    }

    isReadOnly(): boolean {
        const opData = store.state.operationalData.activeStatus;
        return (opData && opData.reportStatusSid) ?
            !store.getters['userAccessManagement/useButtonSave'](opData.reportStatusSid) : true;
    }
}
