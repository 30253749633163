import { AutomapperMetadata } from '@/mappers/mapper';
import { OpDataUnitKPI, OperationalMetadata, UnitStarts } from '@/models/operational-data/operational-data-details';
import { OperationalDataUnitKpiBaseDto, OperationalDataUnitKpiDTO, OperationalDataUnitKpiEditDTO, OperationalMetadataDTO } from '@/models/operational-data/operational-data-DTO';
import { createMetadataMap } from '@automapper/pojos';

export const createMetadataForUnitKPI = (): void => {
    const operationalMetadataDtoMetadata: AutomapperMetadata<OperationalMetadataDTO> = {
        countryName: String,
        countrySid: Number
    };
    createMetadataMap<OperationalMetadataDTO>('OperationalMetadataDTO', operationalMetadataDtoMetadata);
    
    const operationalMetadata: AutomapperMetadata<OperationalMetadata> = {
        Country: 'Country'
    };
    createMetadataMap<OperationalMetadata>('OperationalMetadata', operationalMetadata);

    const unitStartsMetadata: AutomapperMetadata<UnitStarts> = {
        Commercial: Number,
        Technical: Number,
        Failed: Number,
        Total: Number,
        Good: Number,
        FailedCommercial: Number,
        FailedTechnical: Number
    };
    createMetadataMap<UnitStarts>('UnitStarts', unitStartsMetadata);

    const opDataUnitKPI: AutomapperMetadata<OpDataUnitKPI> = {
        Site: 'Site',
        Unit: 'Unit',
        ReportMonth: String,
        NetCapacity: Number,
        Starts: 'UnitStarts',
        GeneratedPowerGrossMwh: Number,
        GeneratedPowerMwh: Number,
        GridFeedVolumeMwh: Number,
        NetVolumeForPeriodMwh: Number,
        NrBreakdownEvents: Number,
        NrTrips: Number,
        OperationHours: Number,
        AuxiliaryConsumption: Number,
        AuxiliaryPowerOnShutdown: Number,
    };
    createMetadataMap<OpDataUnitKPI>('OpDataUnitKPI', opDataUnitKPI);

    const baseUnitKpiDtoMetadata: AutomapperMetadata<OperationalDataUnitKpiBaseDto> = {
        machineSid: Number,
        reportMonth: String,
        netCapacity: Number,
        generatedPowerGrossMwh: Number,
        generatedPowerMwh: Number,
        gridFeedVolumeMwh: Number,
        netVolumeForPeriodMwh: Number,
        nrBreakdownEvents: Number,
        nrTrips: Number,
        operationHours: Number,
        nrStartsTotal: Number,
        nrCommercialStarts: Number,
        nrTechnicalStarts: Number,
        nrFailureStarts: Number,
        nrGoodStartsTotal: Number,
        nrFailedCommercialStarts: Number,
        nrFailedTechnicalStarts: Number,
        valueInternal: Number,
        commentInternal: String,
        valueExternal: Number,
        commentExternal: String,
        valueOther: Number,
        commentOther: String,
        waterActual: Number,
        waterForecast: Number,
        airActual: Number,
        airForecast: Number,
        auxiliaryConsumption: Number,
        auxiliaryPowerOnShutdown: Number
    };
    createMetadataMap<OperationalDataUnitKpiDTO>('OperationalDataUnitKpiDTO', {
        plantSid: Number,
        plantName: String,
        machineName: String,
        ...baseUnitKpiDtoMetadata
    })
    createMetadataMap<OperationalDataUnitKpiEditDTO>('OperationalDataUnitKpiEditDTO', baseUnitKpiDtoMetadata);
}