import { OperationalDataModel } from '@/models/operational-data/operational-data'
import { OperationalDataDTO } from '@/models/operational-data/operational-data-DTO'
import { mapFrom, Mapper } from '@automapper/core'
import { CamelToPascalCase } from '../common'

export const mapOperationalDataModel = (mapper: Mapper): void => {
    mapper
        .createMap<OperationalDataDTO, OperationalDataModel>('OperationalDataDTO', 'OperationalDataModel', { namingConventions: CamelToPascalCase })
        .forMember(d => d.Country.Id, mapFrom(s => s.countrySid))
        .forMember(d => d.Country.Name, mapFrom(s => s.countryName))
        .forMember(d => d.Site.Id, mapFrom(s => s.plantSid))
        .forMember(d => d.Site.Name, mapFrom(s => s.plantName))
        .forMember(d => d.Unit.Id, mapFrom(s => s.machineSid))
        .forMember(d => d.Unit.Name, mapFrom(s => s.unitName))
        .forMember(d => d.Status.Id, mapFrom(s => s.reportStatusSid))
        .forMember(d => d.Status.Name, mapFrom(s => ''))
        .forMember(d => d.Date, mapFrom(s => s.reportMonth.substring(0, 10)))
}