import { CamelCaseNamingConvention, Converter, PascalCaseNamingConvention } from '@automapper/core';

export const PascalToCamelCase = {
    source: new PascalCaseNamingConvention(),
    destination: new CamelCaseNamingConvention(),
};

export const CamelToPascalCase = {
    source: new CamelCaseNamingConvention(),
    destination: new PascalCaseNamingConvention(),
};

export const numberConverter: Converter<any, number> = {
    convert(source: any): number {
        return Number.isFinite(source) ? source : 0;
    },
};