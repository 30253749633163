import { createMapper } from '@automapper/core';
import { pojos } from '@automapper/pojos';
import { opDataMetadataProfile } from './operational-data/profiles/metadata-profile';
import { opDataProfile } from './operational-data/profiles/operational-data-profile';
import { opDataDetailsProfile } from './operational-data/profiles/details-profile';
import { opDataStatusesProfile } from './operational-data/profiles/statuses-profile';
import { opDataFiltersOptionsProfile } from './operational-data/profiles/filters-options-profile';

export type AutomapperMetadata<T> = {
    [key in keyof T]: StringConstructor | DateConstructor | NumberConstructor | BooleanConstructor | typeof File.prototype.constructor | string | null;
};

export const mapper = createMapper({
    name: 'ModeFEModelsMapper',
    pluginInitializer: pojos,
});

mapper
    .addProfile(opDataMetadataProfile)
    .addProfile(opDataProfile)
    .addProfile(opDataDetailsProfile)
    .addProfile(opDataStatusesProfile)
    .addProfile(opDataFiltersOptionsProfile);



