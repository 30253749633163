import { EChartsOption } from 'echarts';

export const DefaultParallelEventChartOptions: EChartsOption = {
    tooltip: {
        trigger: 'axis',
        triggerOn: 'mousemove',
        axisPointer: {
            type: 'line'
        },
        formatter: undefined,
        position: function (pos, params, el, elRect, size): any {
            const obj: { [name: string]: number } = { top: 10 };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
            return obj;
        },
    },
    hover: {
        mode: 'index',
        interfect: false
    },
    legend: {
        // data: []
    },
    xAxis: {
        type: 'time',
        boundaryGap: false,
        min: undefined,
        max: undefined,
        data: []
    },
    yAxis: {
        name: undefined,
        type: 'value',
        nameTextStyle: {
            padding: undefined
        }
    },
    series: []
}
