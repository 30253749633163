import Vue from 'vue';
const ConfirmationModal = Vue.extend({
    name: 'ConfirmationModal',
    components: {
    },
    props: {
        headerLabel: {
            type: String,
            required: false
        },
        messageText: {
            type: String,
            required: true
        },
        confirmLabel: {
            type: String,
            required: false,
            default: 'OK'
        },
        cancelLabel: {
            type: String,
            required: false,
            default: 'Cancel'
        }
    },
    methods: {
        openModal(): void {
            (this.$refs['ConfirmationModalRef'] as any).open();
        },
        closeModal(): void {
            (this.$refs['ConfirmationModalRef'] as any).close();
        },
        onConfirm(): void {
            this.closeModal();
            this.$emit('on-confirm');
        },
        onCancel(): void {
            this.closeModal();
            this.$emit('on-cancel');
        }
    }
});
export default ConfirmationModal;
