import { ActiveOperationalDataFilters } from '@/components/operational-data/op-data-filter/utils/ActiveOperationalDataFilters';
import { EventEnum } from '@/models/event-header-enumeration';
import { MaterialModel, OperationalDataDetails, OperationalDataEnumModel, ThermalGenerationModel } from '@/models/operational-data/operational-data-details';
import { EnumItemDTO, OperationalDataDonutStatusCountDTO, OperationalDataStatusDTO } from '@/models/operational-data/operational-data-DTO';
import { EnumMasterData, EventHeaderEnumerationsService } from '@/services/event-header-enumerations-service';
import { OperationalDataService } from '@/services/operational-data-service';
import { RootState } from '@/store';
import { OperationalDataException } from '@/utils/exceptions/OperationalDataException';
import { cloneDeep, map } from 'lodash';
import { Action, ActionContext, ActionTree } from 'vuex';
import { OperationalDataState } from '../contracts/stateContract/operational-data-state';
import { eraseUnchangedProperties } from './helpers/erasePropertiesHelper';
import { OperationalDataGettersNames } from './operational-data-getters';
import { OperationalDataMutations, OperationalDataMutationsNames } from './operational-data-mutations';
import { isEqual } from 'lodash';
import { DonutStatusCount, OperationalDataDropDownOptions } from '@/models/operational-data/operational-data';
import { UnitsService } from '@/services/units-service';
import { mapper } from '@/mappers/mapper';
import { MasterApiUnit } from '../contracts/Unit';
import { OperationalDataFiltersOptions } from '@/models/operational-data/operational-data';
import { LoadingPanel } from '@/store';

export const service = new OperationalDataService();
export const enumService = new EventHeaderEnumerationsService();
export const unitsService = new UnitsService(338);

export enum OperationalDataActionsNames {
    LoadAvailableStatuses = 'loadAvailableStatuses',
    LoadOperationalData = 'loadOperationalData',
    LoadOperationalDataDetailsModel = 'loadOpDataDetailsModel',
    LoadStatuses = 'loadStatuses',
    LoadStatus = 'loadStatus',
    LoadAvailableFiltersOptions = 'loadAvailableFiltersOptions',
    SaveOperationalDataDetailsModel = 'setOpDataDetailsModel',
    SetStatus = 'setStatus',
    DeleteOperationalDataEntry = 'deleteOperationalDataEntry',
    LoadOperationalDataDoughnutStatus = 'loadOperationalDataDoughnutStatus',
    SetThermOptions = 'setThermOptions',
    OnFilterChange = 'onFilterChange',
    SetOptions = 'setThermOptions'
}

export class OperationalDataActions implements ActionTree<OperationalDataState, RootState> {
    [key: string]: Action<OperationalDataState, RootState>;

    [OperationalDataActionsNames.LoadAvailableStatuses] = async (context: ActionContext<OperationalDataState, RootState>): Promise<void> => {
        await context.dispatch(OperationalDataActionsNames.LoadStatuses);
    };

    [OperationalDataActionsNames.LoadOperationalData] = async (context: ActionContext<OperationalDataState, RootState>): Promise<void> => {
        context.commit('toggleLoadingPanelVisibility', LoadingPanel.OperationalDataTable);
        const activeFilters = context.getters[OperationalDataGettersNames.GetActiveFilters] as ActiveOperationalDataFilters;
        const data = await service.getOperationalData(activeFilters);
        context.commit(OperationalDataMutationsNames.InititializeTableData, data.result);
        context.commit('toggleLoadingPanelVisibility', LoadingPanel.OperationalDataTable);
    };

    [OperationalDataActionsNames.LoadStatuses] = async (context: ActionContext<OperationalDataState, RootState>): Promise<void> => {
        const statuses = context.getters[OperationalDataGettersNames.GetStatuses];
        if (statuses.length > 0) return;
        
        const masterDataEnums = await enumService.getEventEnumList(EnumMasterData.OpDataStatus);
        const opDataEnums = (masterDataEnums.result as any).enums.map((enumItem: EventEnum) => { return { Id: enumItem.sid, Name: enumItem.name } });
        context.commit(OperationalDataMutationsNames.SetStatuses, opDataEnums);
    };

    [OperationalDataActionsNames.LoadAvailableFiltersOptions] = async (context: ActionContext<OperationalDataState, RootState>): Promise<void> => {
        const responses = await Promise.all([unitsService.getUnitsForCurrentUser(), context.dispatch(OperationalDataActionsNames.LoadStatuses)]);
        const availableOptions = mapper.map<MasterApiUnit[], OperationalDataFiltersOptions>((responses[0].result as any).units, 'OperationalDataFiltersOptions', 'MasterApiUnit');
        context.commit(OperationalDataMutationsNames.SetFiltersOptions, availableOptions);
        availableOptions.statuses = context.state.statuses.map(status => ({ label: status.Name, value: status.Id }));
    }

    [OperationalDataActionsNames.OnFilterChange] = async (context: ActionContext<OperationalDataState, RootState>): Promise<void> => {
        context.commit('toggleLoadingPanelVisibility', LoadingPanel.OperationalDataTable);
        context.commit(OperationalDataMutationsNames.SetTableLoadingPanel, true);
        const activeFilters = context.getters[OperationalDataGettersNames.GetActiveFilters] as ActiveOperationalDataFilters;
        const data = await service.getOperationalData(activeFilters);
        context.commit(OperationalDataMutationsNames.UpdateFilteredTableData, data.result);
        context.commit(OperationalDataMutationsNames.SetTableLoadingPanel, false);
        context.commit('toggleLoadingPanelVisibility', LoadingPanel.OperationalDataTable);
    };

    [OperationalDataActionsNames.LoadOperationalDataDoughnutStatus] = async (context: ActionContext<OperationalDataState, RootState>): Promise<void> => {
        const activeFilters = context.getters[OperationalDataGettersNames.GetActiveFilters] as ActiveOperationalDataFilters;
        const data = await service.getOperationalDataDoughnutStatus(activeFilters);
        const statuses: DonutStatusCount[] = []
        data.result.items.forEach(entry => {
            statuses.push(mapper.map<OperationalDataDonutStatusCountDTO, DonutStatusCount>(entry, 'DonutStatusCount', 'OperationalDataDonutStatusCountDTO'))
        })
        context.commit(OperationalDataMutationsNames.UpdateDoughnutData, statuses);
    };

    [OperationalDataActionsNames.LoadOperationalDataDetailsModel] = async (context: ActionContext<OperationalDataState, RootState>, payload: { unitId: string; reportMonth: string }): Promise<void> => {
        const apiResponse = await service.getOperationalDataEntry(Number(payload.unitId), payload.reportMonth);
        context.commit(OperationalDataMutationsNames.SetOriginalDetailsModel, cloneDeep(apiResponse.result));
        context.commit(OperationalDataMutationsNames.SetActiveDetailsModel, apiResponse.result);
    };

    [OperationalDataActionsNames.SaveOperationalDataDetailsModel] = async (context: ActionContext<OperationalDataState, RootState>): Promise<void> => {
        const originalDetailsModel = context.state.originalDetailsModel;
        const activeDetailsModel: OperationalDataDetails | null = cloneDeep(context.getters[OperationalDataGettersNames.GetActiveDetailsModel]);
        if (!originalDetailsModel || !activeDetailsModel)
            throw new OperationalDataException('originalDetailsModel and activeDetailsModel can not be empty');

        const exludedProperties = new Set<keyof OperationalDataDetails>();

        const isUnitKpiChanged = !isEqual(originalDetailsModel.UnitKPI, activeDetailsModel.UnitKPI);
        const isQualityPctChanged = !isEqual(originalDetailsModel.QualityLossesPct, activeDetailsModel.QualityLossesPct);
        const isQualityTempChanged = !isEqual(originalDetailsModel.QualityLossesTemp, activeDetailsModel.QualityLossesTemp);

        if (isUnitKpiChanged || isQualityPctChanged || isQualityTempChanged) {
            exludedProperties.add('UnitKPI');
            exludedProperties.add('QualityLossesPct');
            exludedProperties.add('QualityLossesTemp');
        }

        eraseUnchangedProperties(originalDetailsModel, activeDetailsModel, exludedProperties);

        const isAnyChange = Object.values(activeDetailsModel).some(value => value != null);
        if (isAnyChange && originalDetailsModel.UnitKPI){
            const response = await service.setOperationalDataEntry(originalDetailsModel.UnitKPI.Unit.Id, originalDetailsModel.UnitKPI.ReportMonth.substring(0, 7), activeDetailsModel);
            context.commit(OperationalDataMutationsNames.SetOriginalDetailsModel, cloneDeep(response.result));
            context.commit(OperationalDataMutationsNames.SetActiveDetailsModel, response.result);
        }
    };

    [OperationalDataActionsNames.LoadStatus] = async (context: ActionContext<OperationalDataState, RootState>, payload: { unitId: string; reportMonth: string }): Promise<void> => {
        const apiResponse = await service.getStatus(Number(payload.unitId), payload.reportMonth);
        context.commit(OperationalDataMutationsNames.SetActiveStatus, apiResponse.result);
    };

    [OperationalDataActionsNames.SetStatus] = async (context: ActionContext<OperationalDataState, RootState>, payload: OperationalDataStatusDTO): Promise<void> => {
        const apiResponse = await service.setStatus(payload);
        context.commit(OperationalDataMutationsNames.SetActiveStatus, apiResponse.result);
        await context.dispatch(OperationalDataActionsNames.LoadOperationalData);
    };

    [OperationalDataActionsNames.DeleteOperationalDataEntry] = async (context: ActionContext<OperationalDataState, RootState>, model: ThermalGenerationModel | MaterialModel): Promise<void> => {
        await service.deleteOperationalDataEntry(model);
    };

    [OperationalDataActionsNames.SetOptions] = async (context: ActionContext<OperationalDataState, RootState>): Promise<void> => {
        const obj: OperationalDataDropDownOptions = {
            thermalGenerationOptions: [],
            materialOptions: [],
            wasteMaterialOptions: []
        };

        try {
            const response = await Promise.all([service.getEnum(790), service.getEnum(770), service.getEnum(780)]);

            obj.thermalGenerationOptions = response[0].result.enums
                .map(enumDTO => mapper.map<EnumItemDTO, OperationalDataEnumModel>(enumDTO, 'OperationalDataEnumModel', 'EnumItemDTO'));

            obj.materialOptions = response[1].result.enums
                .map(enumDTO => mapper.map<EnumItemDTO, OperationalDataEnumModel>(enumDTO, 'OperationalDataEnumModel', 'EnumItemDTO'));

            obj.wasteMaterialOptions = response[2].result.enums
                .map(enumDTO => mapper.map<EnumItemDTO, OperationalDataEnumModel>(enumDTO, 'OperationalDataEnumModel', 'EnumItemDTO'));
        }
        catch (err) {
            throw new Error(err as any);
        }
        finally {
            context.commit(OperationalDataMutationsNames.SetDropDownOptions, obj);
        }
    }

}
