import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { LANGUAGES } from './languages';

Vue.use(VueI18n);

// Set the locale based on the language set as default in the user's browser.
export const defaultLang = navigator.language;

function loadLocaleMessages(): any {
    let messages: any = {};
    Object.keys(LANGUAGES).forEach((key: any) => {
        const file = LANGUAGES[key];
        const ft = require(`../locales/${file}.json`);
        messages = {...messages, ...ft};
    });
    return messages;
}

const i18n = new VueI18n({
    locale: defaultLang,
    fallbackLocale: defaultLang,
    messages: loadLocaleMessages(),
});

export default i18n;
