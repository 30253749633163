import { render, staticRenderFns } from "./op-data-table.vue?vue&type=template&id=c5d18aae"
import script from "./op-data-table.ts?vue&type=script&lang=ts&external"
export * from "./op-data-table.ts?vue&type=script&lang=ts&external"
import style0 from "./op-data-table.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports