import { ApiResponse } from '@/models';
import { FunctionalLocation } from '@/models/functional-location';
import { ModeServiceBase } from '@/services/base-services';

export class FunctionalLocationService extends ModeServiceBase {
    constructor() {
        super('master-data/functional-locations')
    }

    /**
     * Gets all functional locations for a plant with a specific term
     * @param plantId
     * @param term
     */
    public async searchFunctionalLocations(plantId: number, term: string): Promise<ApiResponse<{items: FunctionalLocation[]}>>{
        return (await this.api.get(`?plantId=${plantId}&term=${term}`)).data;
    }
}
