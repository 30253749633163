import { EChartsOption } from 'echarts';
import { colors } from './op-data-chart-colors';

const colorsArray: string[] = [];
Object.values(colors).forEach((value: string) => colorsArray.push(value));

export const DefaultOpDataChartOptions: EChartsOption = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        },
        formatter: undefined
    },
    legend: {
        formatter: undefined
    },
    grid: {
        top: '15%',
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis: {
        type: 'category',
        data: []
    },
    yAxis: {
        name: undefined,
        type: 'value'
    },
    series: [],
    color: colorsArray
};
