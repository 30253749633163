import { render, staticRenderFns } from "./op-data-entry-details.vue?vue&type=template&id=365c35d5"
import script from "./op-data-entry-details.ts?vue&type=script&lang=js&external"
export * from "./op-data-entry-details.ts?vue&type=script&lang=js&external"
import style0 from "./op-data-entry-details.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports