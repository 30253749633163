import { OperationalDataState } from '../contracts/stateContract/operational-data-state';
import { OperationalDataActions } from './operational-data-actions';
import { OperationalDataGetters } from './operational-data-getters';
import { OperationalDataMutations } from './operational-data-mutations';

export const operationalData = {
    state: (): OperationalDataState => ({
        tableData: [],
        filteredTableData: [],
        tablePagingMetadata: null,
        statuses: [],
        tableLoadingPanel: false,
        doughnutLoadingPanel: false,
        originalDetailsModel: null,
        activeUnitKPI: null,
        activeThermalModels: [],
        activeMaterialsModels: [],
        activeUnavailabilityModels: [],
        activeComponentsModels: [],
        activeUnitQualityPctModels: null,
        activeUnitQualityTempModels: null,
        activeCHP: null,
        activeStatus: null,
        triggerInputsFinder: false,
        activeFilters: null,
        opDataDoughnutData: [],
        filtersOptions: null,
        dropDownOptions: null,
        tabsValidationState: null
    }),
    getters: new OperationalDataGetters(),
    mutations: new OperationalDataMutations(),
    actions: new OperationalDataActions()
};
