import Vue from 'vue';
import Vuex from 'vuex';
import { eventManager } from './modules/event-management.ts';
import { notifications } from './modules/notifications';
import { sidebar } from './modules/sidebar';
import { units } from './modules/units';
import { userAccessManagement } from './modules/user-access-management';
import { operationalData } from './modules/operational-data/operational-data';
import { OperationalDataState } from './modules/contracts/stateContract/operational-data-state';
import { staticPermissionMatrix } from '@/models/permissions-matrix';
import { ModeErrorModel } from '@/models';

Vue.use(Vuex);

export enum LoadingPanel {
    EventInfoTable,
    OperationalDataTable
}

export interface RootState {
    isLoading: boolean;
    isLoadingPanelVisible: { [key: string]: boolean };
    isWaiting: boolean;
    waitingModalTitle: string;
    waitingModalContent: string;
    isProductionEnv: boolean;
    errorData: ModeErrorModel | undefined;
}

export interface StateWithModules extends RootState {
    operationalData: OperationalDataState;
}

const applicationState: StateWithModules = {
    isLoading: false,
    isLoadingPanelVisible: {
        [LoadingPanel.EventInfoTable]: false,
        [LoadingPanel.OperationalDataTable]: false
    },
    isWaiting: false,
    waitingModalTitle: '',
    waitingModalContent: '',
    operationalData: (null as unknown as OperationalDataState),
    isProductionEnv: process.env.VUE_APP_ENVIRONMENT === 'prd',
    errorData: undefined,
};

const store = new Vuex.Store({
    state: applicationState,
    mutations: {
        loading(state: typeof applicationState): void {
            state.isLoading = !state.isLoading;
        },
        waiting (state: typeof applicationState, waitingModalArgs: { title: string; content: string }): void {
            if (waitingModalArgs) {
                state.waitingModalTitle = waitingModalArgs.title;
                state.waitingModalContent = waitingModalArgs.content;
            } else {
                state.waitingModalTitle = '';
                state.waitingModalContent = '';
            }
            state.isWaiting = !state.isWaiting;
        },
        toggleLoadingPanelVisibility(
            state: typeof applicationState,
            loadingPanel: LoadingPanel.EventInfoTable  // can be extended with "| LoadingPanel. ..." to allow more elements.
        ): void {
            state.isLoadingPanelVisible[loadingPanel] = !state.isLoadingPanelVisible[loadingPanel];
        },
        setErrorData(state: typeof applicationState, payload: ModeErrorModel): void {
            state.errorData = payload;
        }
    },
    actions: {
    },
    modules: {
        sidebar,
        units,
        notifications,
        userAccessManagement,
        eventManager,
        operationalData
    },
    getters: {
        isLoading(state: typeof applicationState): boolean {
            return state.isLoading;
        },
        isWaiting (state: typeof applicationState): boolean {
            return state.isWaiting;
        },
        waitingModalTitle (state: typeof applicationState): string {
            return state.waitingModalTitle;
        },
        waitingModalContent (state: typeof applicationState): string {
            return state.waitingModalContent;
        },
        isLoadingPanelVisible: (state: typeof applicationState) => (loadingPanel: LoadingPanel.EventInfoTable): boolean => {
            return state.isLoadingPanelVisible[loadingPanel];
        },
    },
});

export default store;
