import { MaterialBuildingBlockModel } from '../materials-tab/materials-tab';
import { ThermalBuildingBlockModel } from '../thermal-generation-tab/thermal-generation-tab';

type BuildingBlockModel = ThermalBuildingBlockModel | MaterialBuildingBlockModel;

export class SourceBlockNamesValidator {
    private inputsState: boolean[] = [];
    private validatedInputsCounter = 0;

    allInputsValidated = false;
    get areAllValid():boolean {
        return this.inputsState.every(i => !!i);
    }

    validate<T extends BuildingBlockModel>(
        buildingBlock: T,
        allBuildingBlocks: T[],
        dependentInputs: (keyof T)[]
    ): boolean {
        if(this.allInputsValidated)
        {
            this.allInputsValidated = false;
            this.validatedInputsCounter = 0;
            this.inputsState = [];
        }

        this.validatedInputsCounter++;

        const isEmptyNameForExistingValues = !buildingBlock.sourceBlockNameSid
            && dependentInputs.some(p => buildingBlock[p] !== undefined);

        const isUniqueName = allBuildingBlocks
            .filter(item => item.sourceBlockNameSid === buildingBlock.sourceBlockNameSid).length === 1;

        const isValid = !(allBuildingBlocks.length > 1 && !buildingBlock.sourceBlockNameSid) &&
            !isEmptyNameForExistingValues &&
            isUniqueName;

        this.inputsState.push(isValid);
        if (allBuildingBlocks.length === this.validatedInputsCounter)
            this.allInputsValidated = true;

        return isValid;
    }
}
