import { DonutStatusCount, OperationalDataFiltersOptions, OperationalDataModel, Status, TabsValidationState, TabsValidationUpdateModel } from '@/models/operational-data/operational-data';
import { ComponentsModel, MaterialModel, OpDataUnitKPI, OperationalDataDetails, ThermalGenerationModel, UnavailabilityModel, UnitQualityPctModel, UnitQualityTempModel } from '@/models/operational-data/operational-data-details';
import { Mutation, MutationTree } from 'vuex';
import { OperationalDataState } from '../contracts/stateContract/operational-data-state';
import { PropertyPath, set, cloneDeep } from 'lodash';
import { PagedList } from '@/models/base-models/paged-list';
import { OperationalDataException } from '@/utils/exceptions/OperationalDataException';
import { OperationalDataStatusDTO } from '@/models/operational-data/operational-data-DTO';
import { ActiveOperationalDataFilters } from '@/components/operational-data/op-data-filter/utils/ActiveOperationalDataFilters';
import { CHPTabItem } from '@/components/operational-data/op-data-entry-details/tabs/chp-tab/chp-tab';
import store from '@/store';
import { OperationalDataGettersNames } from './operational-data-getters';

export enum OperationalDataMutationsNames {
    InititializeTableData = 'initializeTableData',
    UpdateFilteredTableData = 'updateFilteredTableData',
    SetStatuses = 'setStatuses',
    SetTableLoadingPanel = 'setTableLoadingPanel',
    SetDoughnutLoadingPanel = 'setDoughnutLoadingPanel',
    SetUnitKpiProperty = 'setUnitKpiProperty',
    SetOriginalDetailsModel = 'setOriginalDetailsModel',
    SetActiveDetailsModel = 'setActiveDetailsModel',
    SetActiveThermalModels = 'setActiveThermalModels',
    SetActiveComponentsModels = 'setActiveComponentsModels',
    SetActiveMaterialModels = 'setActiveMaterialModels',
    SetActiveUnavailabilityModels = 'setActiveUnavailabilityModels',
    UpdateSingleUnavailabilityModel = 'updateSingleUnavailabilityModel',
    SetCurrentPage = 'setCurrentPage',
    SetTabValidationState = 'setTabValidationState',
    SetActiveStatus = 'setActiveStatus',
    SetActiveUnitQualityLossesPct = 'setActiveUnitQualityLossesPct',
    SetActiveUnitQualityLossesTemp = 'SetActiveUnitQualityLossesTemp',
    TriggerInputsFinder = 'triggerInputsFinder',
    DeleteActiveMaterialModelColumn = 'deleteActiveMaterialModelColumn',
    DeleteActiveThermalModelColumn = 'deleteActiveThermalModelColumn',
    SetActiveFilters = 'setActiveFilters',
    UpdateDoughnutData = 'updateDoughnutData',
    SetFiltersOptions = 'setFiltersOptions',
    SetDropDownOptions = 'setDropDownOptions',
    SetActiveChpProperty = 'setActiveChpProperty',
    InitActiveChpProperty = 'initActiveChpProperty'
}

export class OperationalDataMutations implements MutationTree<OperationalDataState> {
    [key: string]: Mutation<OperationalDataState>;

    [OperationalDataMutationsNames.InititializeTableData] = (state: OperationalDataState, payload: PagedList<OperationalDataModel>): void => {
        const tableData = payload.items;
        state.tableData = tableData;
        state.filteredTableData = tableData;
        state.tablePagingMetadata = {
            CurrentPage: payload.currentPage,
            TotalPages: payload.totalPages
        }
    };

    [OperationalDataMutationsNames.SetActiveUnitQualityLossesPct] = (state: OperationalDataState, payload: UnitQualityPctModel): void => {
        state.activeUnitQualityPctModels = payload;
    }

    [OperationalDataMutationsNames.SetActiveUnitQualityLossesTemp] = (state: OperationalDataState, payload: UnitQualityTempModel): void => {
        state.activeUnitQualityTempModels = payload;
    }

    [OperationalDataMutationsNames.UpdateFilteredTableData] = (state: OperationalDataState, payload: PagedList<OperationalDataModel>): void => {
        state.tablePagingMetadata = {
            CurrentPage: payload.currentPage,
            TotalPages: payload.totalPages
        };

        state.filteredTableData = payload.items;
    };

    [OperationalDataMutationsNames.UpdateDoughnutData] = (state: OperationalDataState, payload: DonutStatusCount[]): void => {
        state.opDataDoughnutData = payload;
    };

    [OperationalDataMutationsNames.SetStatuses] = (state: OperationalDataState, payload: Status[]): void => {
        payload.sort((status1, status2) => status1.Name.length - status2.Name.length);
        state.statuses = payload;
    };

    [OperationalDataMutationsNames.SetTableLoadingPanel] = (state: OperationalDataState, payload: boolean): void => {
        state.tableLoadingPanel = payload;
    };

    [OperationalDataMutationsNames.SetDoughnutLoadingPanel] = (state: OperationalDataState, payload: boolean): void => {
        state.doughnutLoadingPanel = payload;
    };

    [OperationalDataMutationsNames.SetUnitKpiProperty] = (state: OperationalDataState, payload: { propertyPath: PropertyPath; value: any }): void => {
        if (!state.activeUnitKPI) {
            console.error(new OperationalDataException(
                `Can not set property for null activeUnitKPI model.
                 Mutation : ${OperationalDataMutationsNames.SetUnitKpiProperty}`
            ));
            return;
        }

        set(state.activeUnitKPI, payload.propertyPath, payload.value);
    };

    [OperationalDataMutationsNames.SetOriginalDetailsModel] = (state: OperationalDataState, payload: OperationalDataDetails | null): void => {
        state.originalDetailsModel = payload;
    }

    [OperationalDataMutationsNames.SetActiveDetailsModel] = (state: OperationalDataState, payload: OperationalDataDetails | null): void => {
        if (!payload) {
            state.activeUnitKPI = null;
            state.activeThermalModels = [];
            state.activeMaterialsModels = [];
            state.activeComponentsModels = [];
            state.activeUnavailabilityModels = [];
            state.activeUnitQualityPctModels = null;
            state.activeUnitQualityTempModels = null;
            return;
        }

        state.activeUnitKPI = payload.UnitKPI;
        state.activeThermalModels = payload.ThermalList ?? [];
        state.activeMaterialsModels = payload.MaterialList ?? [];
        state.activeComponentsModels = payload.ComponentsList ?? [];
        state.activeUnavailabilityModels = payload.UnavailabilityList ?? [];
        state.activeUnitQualityPctModels = payload.QualityLossesPct;
        state.activeUnitQualityTempModels = payload.QualityLossesTemp;
        state.activeCHP = payload.CHP;

        state.tabsValidationState = {
            isUnavailabilityTabValid: true,
            isComponentTabValid: true,
            isThermalTabValid: true,
            isMaterialTabValid: true,
            isWasteMaterialTabValid: true,
            isQualityTabValid: true,
            isChpTabValid: true
        }
    };

    [OperationalDataMutationsNames.SetActiveThermalModels] = (state: OperationalDataState, payload: ThermalGenerationModel[]): void => {
        const isInitialBuildingBlock = state.activeThermalModels.length === 0 && payload.length === 1;
        if (isInitialBuildingBlock && state.originalDetailsModel?.ThermalList?.length === 0)
            state.originalDetailsModel.ThermalList = payload;

        state.activeThermalModels = payload;
    };

    [OperationalDataMutationsNames.SetActiveMaterialModels] = (state: OperationalDataState, payload: MaterialModel[]): void => {

        const prepareNewMaterialsForType = (materialType: string, existingMaterials: MaterialModel[], newMaterials: MaterialModel[]): MaterialModel[] => {
            return cloneDeep(existingMaterials)
                .filter(item => item.materialType !== materialType)
                .concat(newMaterials);
        }

        const materialType = payload.length > 0 ? payload[0].materialType : '';
        const isInitialBuildingBlock = payload.length === 1;
        if (isInitialBuildingBlock && state.originalDetailsModel?.MaterialList?.filter(item => item.materialType === materialType)?.length === 0)
            state.originalDetailsModel.MaterialList = prepareNewMaterialsForType(materialType, state.originalDetailsModel.MaterialList ?? [], payload)

        state.activeMaterialsModels = prepareNewMaterialsForType(materialType, state.activeMaterialsModels, payload);
    };

    [OperationalDataMutationsNames.SetActiveUnavailabilityModels] = (state: OperationalDataState, payload: UnavailabilityModel[]): void => {
        state.activeUnavailabilityModels = payload;
    };

    [OperationalDataMutationsNames.SetActiveComponentsModels] = (state: OperationalDataState, payload: ComponentsModel): void => {
        let index = 0;
        for (let i = 0; i < state.activeComponentsModels.length; i++) {
            if (state.activeComponentsModels[i].sourceBlockCounter === payload.sourceBlockCounter) {
                index = i;
            }
        }
        state.activeComponentsModels[index] = payload;
    };

    [OperationalDataMutationsNames.InitActiveChpProperty] = (state: OperationalDataState): void => {
        const unitKPI = store.getters[OperationalDataGettersNames.GetActiveUnitKPI] as OpDataUnitKPI;

        state.activeCHP = {
            machineSid: unitKPI.Unit.Id,
            reportMonth: unitKPI.ReportMonth,
            Heat: {},
            Power: {}
        }
    };

    [OperationalDataMutationsNames.SetActiveChpProperty] = (state: OperationalDataState, payload: CHPTabItem): void => {
        if (!state.activeCHP) {
            console.error(new OperationalDataException('can not update `state.activeCHP` property because it is not existing'));
            return;
        }

        set(state.activeCHP, payload.propertyPath, payload.value);
    };


    [OperationalDataMutationsNames.UpdateSingleUnavailabilityModel] = (state: OperationalDataState, payload: UnavailabilityModel): void => {
        const unavailabilityModel = state.activeUnavailabilityModels.find(model => model.unavailabilityType === payload.unavailabilityType);
        if (!unavailabilityModel) {
            console.error(new OperationalDataException(
                `Trying to update unavailability model in store, but model does not exist in current state.
                 Mutation : ${OperationalDataMutationsNames.UpdateSingleUnavailabilityModel}`
            ));
            return;
        }

        unavailabilityModel.valueMwh = payload.valueMwh;
        unavailabilityModel.valuePct = payload.valuePct;
    };

    [OperationalDataMutationsNames.SetCurrentPage] = (state: OperationalDataState, payload: number): void => {
        if (!state.tablePagingMetadata) {
            console.error(new OperationalDataException(
                `Can not set paging for empty metadata object.
                 Mutation : ${OperationalDataMutationsNames.SetCurrentPage}`
            ));
            return;
        }

        state.tablePagingMetadata.CurrentPage = payload;
    };

    [OperationalDataMutationsNames.SetTabValidationState] = (state: OperationalDataState, payload: TabsValidationUpdateModel): void => {
        if (!state.tabsValidationState) {
            console.error(new OperationalDataException('Cannot update `tabsValidationState` in store because it is not existing'))
            return;
        }

        state.tabsValidationState[payload.key] = payload.isValid;
    };

    [OperationalDataMutationsNames.SetActiveStatus] = (state: OperationalDataState, payload: OperationalDataStatusDTO): void => {
        state.activeStatus = payload;
    };

    [OperationalDataMutationsNames.TriggerInputsFinder] = (state: OperationalDataState, payload = true): void => {
        state.triggerInputsFinder = payload;
    };

    [OperationalDataMutationsNames.DeleteActiveThermalModelColumn] = (state: OperationalDataState, payload: number): void => {
        state.activeThermalModels.splice(payload, 1);
    };

    [OperationalDataMutationsNames.DeleteActiveMaterialModelColumn] = (state: OperationalDataState, payload: number): void => {
        state.activeMaterialsModels.splice(payload, 1);
    };

    [OperationalDataMutationsNames.SetActiveFilters] = (state: OperationalDataState, payload: ActiveOperationalDataFilters): void => {
        state.activeFilters = payload;
    };

    [OperationalDataMutationsNames.SetFiltersOptions] = (state: OperationalDataState, payload: OperationalDataFiltersOptions): void => {
        state.filtersOptions = payload;
    };

    [OperationalDataMutationsNames.SetDropDownOptions] = (state: OperationalDataState, payload: any): void => {
        state.dropDownOptions = payload;
    }
}
