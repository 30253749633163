import { EventHeader, EventInfoModel, SplitEventModel } from '@/models';
import store from '@/store';
import { EventBus, utcToLocal } from '@/utils';
import { ModeSettings } from '@/utils/mode-settings';
import { LineSeriesOption } from 'echarts';
import { LineChart } from 'echarts/charts';
import {
    GridComponent, LegendComponent, TitleComponent,
    TooltipComponent
} from 'echarts/components';
import { ECharts, use } from 'echarts/core';
import * as echarts from 'echarts/lib/echarts';
import { CanvasRenderer } from 'echarts/renderers';
import { LegendComponentOption } from 'echarts/types/dist/shared';
import { isEqual } from 'lodash';
import moment from 'moment';
import { TranslateResult } from 'vue-i18n';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DefaultParallelEventChartOptions } from './event-parallel-options';
import { ChartEventModel, ParallelEvent, TooltipItem } from './types';
import { anyEventToEvent } from './utils/anyEventToEvent';

use([
    CanvasRenderer,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    LineChart,
    GridComponent
]);

@Component
export default class EventParallel extends Vue {

    @Prop({ required: true }) data!: EventInfoModel;
    @Prop({ required: true }) updateBoolean!: boolean;

    chart: ECharts | null = null;
    option = DefaultParallelEventChartOptions;
    isHide = false;
    firstChange = false;
    additionalPaddingDivisor = 10;

    eventId = this.data.eventId;
    newEvents: ChartEventModel[] = [];
    eventRampSegments: any[] = [];
    dominantEvents: EventInfoModel[] = [];
    parallelEvents: { items: ParallelEvent[] } = { items: [] };
    parallelEventsNaN: ParallelEvent[] = [];
    splitEvents: SplitEventModel[] = [];
    totalLineSeries: ((string | number)[])[] = [];
    totalLineSeriesName = '';
    links: (string | number | undefined)[][] = [];
    isParallel = false;

    async created(): Promise<void> {
        this.initTranslations();
        await this.initData();
        this.getEventLinks();
        EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => {
            this.initTranslations();
            this.resetOptionData();
        });
    }

    mounted(): void {
        this.initChart();
        this.defineXAxis();
        this.defineYAxis();
        this.createTooltipFormatter();
        window.addEventListener('resize', () => this.onResizeHandler());
    }

    beforeDestroy(): void {
        this.clearOptionData();
        window.removeEventListener('resize', () => this.onResizeHandler());
    }

    @Watch('updateBoolean')
    async onPropertyChanged(value: boolean, oldValue: boolean): Promise<void> {
        if (value !== oldValue) {
            this.resetOptionData();
        }
    }

    createTooltipFormatter(): void {
        (this.option.tooltip as any).formatter = (params: any): string => {
            let eventContent = '';
            let totalContent = '';

            this.newEvents.forEach(event => {
                for (const item of params) {
                    const result = this.generateTooltipContent(event, item, eventContent, totalContent);
                    if (result.totalContent)
                        totalContent = result.totalContent;

                    eventContent = result.eventContent;

                    if (eventContent)
                        break;
                }
            });
            return `<div style="padding: 0.5em 1em;">${totalContent}${eventContent}${this.generateTooltipContentForFooter(params[0].data[0])}</div>`;
        }
    }

    generateTooltipContent(event: ChartEventModel, item: any, eventContent: string, totalContent: string): { totalContent: string; eventContent: string } {
        let currentIndex = this.findCurrentTooltipIndex(event, item.data);

        if (currentIndex < 0)
            currentIndex = 0;

        if (item.seriesName === this.totalLineSeriesName)
            totalContent = this.generateTooltipContentForTotal(item.seriesName, item.data[1]);

        const isNameAndDate = event.eventIdentifier === item.seriesName && event.data[currentIndex][0] === item.data[0];
        if (isNameAndDate)
            eventContent = eventContent + this.generateTooltipContentForEvent(this.prepareEventTooltipData(event, item));

        return { totalContent: totalContent, eventContent: eventContent }
    }

    findCurrentTooltipIndex(event: ChartEventModel, item: (string | number)[]): number {
        return event.data.indexOf(event.data.find((data: (string | number)[]) => isEqual(data, item)));
    }

    prepareEventTooltipData(event: any, item: TooltipItem): any {
        return {
            eventIdentifier:
                (!event.splitEventId)
                    ? event.eventIdentifier
                    : `${event.eventIdentifier}.${event.splitEventId}`,
            availCapacity:
                (event.nominal !== undefined)
                    ? event.nominal - item.data[1]
                    : event.availCapacity,
            unAvailCapacity: Number(item.data[1]),
            unAvailEnergy: event.unAvailEnergy,
            color: item.color
        }
    }

    generateTooltipContentForFooter(date: string): string {
        return `<div style="margin-top: 0.25em; display: flex; justify-content: center;">${moment(date).locale(navigator.language).format('L HH:mm')}</div>`;
    }

    generateTooltipContentForTotal(seriesName: string, totalUnavailableCapacity: number): string {
        return `
            <div style="margin-bottom: 0.25em">
                ${this.tooltipContentHeader(seriesName, '#000')}
                ${this.tooltipContentRow(this.$t('unavailableCapacity'), totalUnavailableCapacity,'MW')}
            </div>
        `;
    }

    generateTooltipContentForEvent(event: any): string {
        return `
        <div>
            ${this.tooltipContentHeader(event.eventIdentifier, event.color)}
            ${this.tooltipContentRow(this.$t('availableCapacity'), event.availCapacity,'MW')}
            ${this.tooltipContentRow(this.$t('unavailableCapacity'), event.unAvailCapacity,'MW')}
            ${this.tooltipContentRow(this.$t('unavailableEnergy'), event.unAvailEnergy, 'MWh')}
        </div>
        `;
    }

    tooltipContentHeader(name: string, color: string): string {
        return `<div style="font-weight: 600;">Event ${name} <span class="dot" style="background-color: ${color}"></span></div>`
    }

    tooltipContentRow(name: TranslateResult, value: number,measure:string): string {
        return `<div style="text-align: right;">${name}: <span style="font-weight: 600">${value?.toFixed(ModeSettings.InputNumberPrecision)} ${measure}</span></div>`
    }

    parallelEventNaNHTML(): string {
        let events = '';

        this.parallelEventsNaN.forEach((event: any, index) => {
            if (this.parallelEventsNaN.length === 1) {
                events += `${event.eventIdentifier}.`
            } else if (index < this.parallelEventsNaN.length - 1) {
                events += `${event.eventIdentifier},&nbsp;`
            } else if (index === this.parallelEventsNaN.length - 1) {
                events += `${event.eventIdentifier}.`
            }
        });

        return `
        <div class="NaN-value-container">
            <div>${this.$t('no-unavail-value-header')}: <span style="font-weight: 600">${events}</span></div>
            <div>${this.$t('no-unavail-value-message')}.</div>
        </div>
        `
    }

    getCurrentEvent(): any {
        let placeholderCapacity = '';
        if (store.getters['eventManager/getSingleParentEventPlaceholderCap'])
            placeholderCapacity = store.getters['eventManager/getSingleParentEventPlaceholderCap'].unAvailCap;

        const currentEvent = store.getters['eventManager/getSingleParentEvent']();
        if (placeholderCapacity !== '')
            currentEvent.unAvailCapacity = placeholderCapacity;

        return currentEvent;
    }

    async getParallelEvents(): Promise<void> {
        return await store.dispatch('eventManager/getParallelEvents', {
            eventId: this.eventId,
            utcStartTime: this.$props.data.utcStartTime,
            utcEndTime: this.$props.data.utcEndTime,
        });
    }

    async addSplitEvents(): Promise<void> {
        this.splitEvents = store.getters['eventManager/getAllChildEvents']();
        this.splitEvents.forEach((event: any) => {
            this.addEventLineSegments(event);
        });
    }

    addCurrentEvent(): void {
        if (!this.splitEvents.length) {
            const currentEvent = this.getCurrentEvent();
            this.addEventLineSegments(currentEvent);
        }
    }

    async addParallelEvents(): Promise<void> {
        await this.getParallelEvents();
        this.parallelEvents = store.getters['eventManager/getCurrentParallelEvents']();

        if (this.parallelEvents.items.length)
            this.isParallel = true;

        this.parallelEvents.items.forEach((event: any) => {
            this.addEventLineSegments(event);
        });
    }

    addEventLineSegments(event: any): void {
        const eventLineSegments = [
            [utcToLocal(event.utcStartTime), event.unAvailCapacity],
            [utcToLocal(event.utcEndTime), event.unAvailCapacity]
        ];
        this.newEvents.push(anyEventToEvent(event, eventLineSegments));
    }

    addCurrentEventRamp(): void {
        const event: EventInfoModel = store.getters['eventManager/getSingleParentEvent']();
        this.createRamp(event.eventHeaderDto, 'startRamp');
        this.createRamp(event.eventHeaderDto, 'endRamp');
        this.newEvents.push(anyEventToEvent(event, this.eventRampSegments));
    }

    addRampSegment(ramp: {}[], nominal: number): void {
        this.eventRampSegments.push(...ramp.map((item: any) => [
            utcToLocal(item.utcEndTime),
            nominal - item.capacityTotal
        ]));
    }

    createRamp(eventHeaderDto: EventHeader, rampType: 'startRamp' | 'endRamp'): void {
        const ramp = eventHeaderDto[rampType];
        let rampStartTime: string, rampEndTime: string, rampValue: number;

        if (ramp) {
            rampStartTime = rampEndTime = ramp.rampingPointSegmentList[0].utcStartTime;
            rampValue = (rampType === 'startRamp') ? 0 : eventHeaderDto.unAvailCapacityUnit;
        } else {
            rampStartTime = eventHeaderDto.utcStartTime;
            rampEndTime = eventHeaderDto.utcEndTime;
            rampValue = eventHeaderDto.unAvailCapacityUnit
        }

        const rampTime = rampType === 'startRamp' ? rampStartTime : rampEndTime;
        this.eventRampSegments.push([
            utcToLocal(rampTime),
            rampValue
        ]);

        if (ramp)
            this.addRampSegment(ramp.rampingPointSegmentList, ramp.nominal);
    }

    prepareAllEventDates(): string[] {
        const allDates: string[] = [];
        this.newEvents.map(event => {
            allDates.push(event.utcStartTime);
            allDates.push(event.utcEndTime);
        });
        return allDates
    }

    getUniqueDates(): Set<string> {
        const allDates = this.prepareAllEventDates();
        return new Set(allDates)
    }

    getEventsForDate(date: string): ChartEventModel[] {
        return this.newEvents.filter(event => {
            return moment(event.utcStartTime).isSame(date) ||
            moment(event.utcEndTime).isSame(date) ||
            moment(date).isBetween(event.utcStartTime, event.utcEndTime)
        })
    }

    getEventsStartingOrOccuringOnDate(events: any, date: string): ChartEventModel[] {
        return events.filter((event: ChartEventModel) => {
            return moment(event.utcStartTime).isSame(date) ||
                        moment(date).isBetween(event.utcStartTime, event.utcEndTime);
        })
    }

    prepareTotalLineSeries(): any {
        const uniqueDates = this.getUniqueDates();

        uniqueDates.forEach(date => {
            const eventsForDate = this.getEventsForDate(date);
            const dominantEvents = eventsForDate.filter(event => event.isDominant);

            if (dominantEvents.length) {
                const filteredDominantEvents = this.getEventsStartingOrOccuringOnDate(dominantEvents, date);

                if (filteredDominantEvents.length) {
                    this.totalLineSeries.push(this.preparePointForTotalLineSeries(date, filteredDominantEvents));
                } else {
                    this.calculateTotalNonDominantMWForDate(eventsForDate, date);
                }
            } else {
                this.calculateTotalNonDominantMWForDate(eventsForDate, date);
            }
        });
    }

    preparePointForTotalLineSeries(date: string, events: ChartEventModel[]): (string | number)[] {
        return [date, events.reduce((p,c ) => p + c.unAvailCapacity, 0)];
    }

    calculateTotalNonDominantMWForDate(eventsForDate: ChartEventModel[], date: string): void {
        const nonDominantEvents = eventsForDate.filter(event => !event.isDominant);
        const filteredNonDominantEvents = this.getEventsStartingOrOccuringOnDate(nonDominantEvents, date);
        this.totalLineSeries.push(this.preparePointForTotalLineSeries(date, filteredNonDominantEvents));
    }

    sortEventsByDate(array: ((string | number)[])[]): void {
        array?.sort((a, b) => {
            return Date.parse(a[0] as string) - Date.parse(b[0] as string);
        })
    }

    createTotalLine(): void {
        this.addEventToSeries(null, this.totalLineSeries, 'end');
        (this.option?.legend as LegendComponentOption).data?.push(this.totalLineSeriesName);
    }

    populateOptionData(): void {
        this.newEvents.forEach(event => {
            this.addEventToSeries(event, event.data, undefined);
        })
    }

    addEventToSeries(event: ChartEventModel | null, data: {}[], step: LineSeriesOption['step']): void {
        if (!Array.isArray(this.option.series)) return;
        this.option.series.push({
            name:
                event
                    ? event.eventIdentifier
                    : this.totalLineSeriesName,
            data: data,
            type: 'line',
            step: step,
            lineStyle: {
                color:
                    event
                        ? undefined
                        : '#000',
                width:
                    event
                        ? this.isCurrentSplit(event)
                        : 1,
                type:
                    event
                        ? event.isDominant ? 'solid' : 'dashed'
                        : 'dotted'
            }
        });
    }

    isCurrentSplit(event: any): number {
        return (Number(event.eventId + '.' + event.splitEventId) === Number(this.data.eventId + '.' + this.data.splitEventId)) ? 7 : 3;
    }

    graphStartTime(): string {
        return utcToLocal(moment.utc(this.$props.data.utcStartTime)
            .subtract(moment(this.$props.data.utcEndTime)
                .diff(moment(this.$props.data.utcStartTime)) / this.additionalPaddingDivisor).toString())
    }
    graphEndTime(): string {
        return utcToLocal(moment.utc(this.$props.data.utcEndTime)
            .add(moment(this.$props.data.utcEndTime)
                .diff(moment(this.$props.data.utcStartTime)) / this.additionalPaddingDivisor).toString())
    }

    getEventLinks(): void {
        const allIds = this.newEvents.reduce((p, c) => {
            if (c.eventIdentifier !== this.$props.data.eventIdentifier)
                p.push(c.eventId);
            return p;
        }, [] as number[]);

        const uniqueEventIds = Array.from(new Set(allIds));
        const uniqueIdsAndIdentifiers = uniqueEventIds.map((id: number) => [id, (this.newEvents.find((event: ChartEventModel) => event.eventId === id)?.eventIdentifier)]);
        this.links = uniqueIdsAndIdentifiers;
    }

    goToLink(payload: any): void {
        const routerPath = this.$router.resolve({path: `/eventinfo/${payload}`});
        window.open(routerPath.href, '_blank');
    }

    onResizeHandler(): void {
        if (this.$refs.myChart !== null && this.$refs.myChart !== undefined) {
            this.resizeChart();
        }
    }

    defineYAxis(): void {
        (this.option as any).yAxis.name = this.totalLineSeriesName;
        (this.option as any).yAxis.nameTextStyle.padding = [0, 0, 0, this.totalLineSeriesName.length * 3];
    }

    defineXAxis(): void {
        (this.option as any).xAxis.min = this.graphStartTime();
        (this.option as any).xAxis.max = this.graphEndTime();
    }

    initTranslations(): void {
        this.chart?.clear();
        this.totalLineSeriesName = this.$t('total-MW-unavailable').toString();
        this.defineYAxis();
    }

    initChart(): void {
        this.chart = echarts.init(this.$refs.myChart as HTMLDivElement);
        this.chart?.setOption(this.option);
    }

    updateChart(): void {
        this.chart?.setOption(this.option, true);
    }

    resizeChart(): void {
        this.chart?.resize();
    }

    async triggerIsHide(): Promise<void> {
        this.isHide = !this.isHide;
    }

    clearOptionData(): void {
        this.newEvents = [];
        this.totalLineSeries = [];
        this.option.series = [];
    }

    async resetOptionData(): Promise<void> {
        this.newEvents = [];
        this.totalLineSeries = [];
        this.option.series = [];
        await this.initData();
    }

    async initData(): Promise<void> {
        await this.addSplitEvents();
        await this.addParallelEvents();

        if (!this.splitEvents.length && !this.parallelEvents.items.length) {
            this.addCurrentEventRamp();
        } else if (!this.splitEvents.length) {
            this.addCurrentEvent();
        }

        this.populateOptionData();

        if (this.newEvents.find(event => event.eventIdentifier !== this.newEvents[0].eventIdentifier)) {
            this.prepareTotalLineSeries();
            this.sortEventsByDate(this.totalLineSeries);
            this.createTotalLine();
        }

        this.updateChart();
    }
}
