import { Component, Watch } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';
import store from '@/store';
import { OperationalDataMutationsNames } from '@/store/modules/operational-data/operational-data-mutations';
import { OperationalDataEnumModel, OperationalDataExtendedThermal, ThermalGenerationModel } from '@/models/operational-data/operational-data-details';
import { OperationalDataGettersNames } from '@/store/modules/operational-data/operational-data-getters';
import CustomInput from '@/components/utils/custom-input/custom-input.vue';
import { ModeSettings } from '@/utils/mode-settings';
import { OperationalDataActionsNames } from '@/store/modules/operational-data/operational-data-actions';
import { SourceBlockNamesValidator } from '../tabs-utils/SourceBlockNamesValidator';
import { CustomInputValidationState } from '../../op-data-comand-buttons/custom-inputs-validator';
import { BuildingBlockTabValidation } from '../materials-tab/materials-tab';
import { TabsValidationUpdateModel } from '@/models/operational-data/operational-data';
import BaseCustomInputValidation from '@/components/utils/base-custom-input-validation/base-custom-input-validation';

export interface ThermalBuildingBlockModel {
    sourceBlockCounter: number | undefined;
    sourceBlockNameSid: number | undefined;
    grossHeatGenerationVolume: number | undefined;
    netHeatGenerationVolume: number | undefined;
    powerFactor: number | undefined;
    electricEquivalentVolume: number | undefined;
}
@Component({
    components: {
        CustomInput
    }
})
export default class ThermalGenerationTab extends BaseCustomInputValidation {
    readonly maxBuildingBlocksCount = 10;
    precision = ModeSettings.InputNumberPrecision;
    data: ThermalBuildingBlockModel[] = [];
    flagSourceThermalGeneration = false;
    rowText: string[] = [];
    units: string[] = [
        'MWh(th)',
        'MWh(th)',
        'MWh(el)/MWh(th)',
        'MWh(el)'
    ];
    options: OperationalDataExtendedThermal[] = [];
    sourceBlockNamesValidator!: SourceBlockNamesValidator;

    buildingBlocksValidationState: BuildingBlockTabValidation = {
        allSourceBlockNamesValid: true,
        allBlockCustomInputsValid: true
    }

    shortenedOptionsArr: OperationalDataEnumModel[] = [];

    @Watch('buildingBlocksValidationState', { deep: true })
    onBuildingBlockInputsValidationStateChange(validationState: BuildingBlockTabValidation): void {
        const tabValidationUpdateModel: TabsValidationUpdateModel = {
            key: 'isThermalTabValid',
            isValid: validationState.allSourceBlockNamesValid && validationState.allBlockCustomInputsValid
        }
        store.commit(OperationalDataMutationsNames.SetTabValidationState, tabValidationUpdateModel);
    }

    get isReadOnly(): boolean {
        const opData = store.state.operationalData.activeStatus;
        return (opData && opData.reportStatusSid) ?
            !store.getters['userAccessManagement/useButtonSave'](opData.reportStatusSid) : true;
    }

    created(): void {
        {
            this.getThermalOptions();
            this.onValidationStateChange = (inputs: CustomInputValidationState[]): void => {
                this.buildingBlocksValidationState.allBlockCustomInputsValid = inputs.every(i => i.isValid);
            };

            const thermals = cloneDeep(store.state.operationalData.activeThermalModels);
            this.data = thermals.map(thermal => this.prepareBuildingBlockFromThermalModel(thermal));
            if (this.data.length == 0)
                this.addColumn();
            else
                this.$nextTick(() => this.validateCustomInputs(this));
        }
    }

    private prepareBuildingBlockFromThermalModel(thermalModel: ThermalGenerationModel): ThermalBuildingBlockModel {
        const getNumberInputValue = (value: number): number | undefined => !thermalModel.sourceBlockNameSid && !value ? undefined : value;

        return {
            sourceBlockCounter: thermalModel.sourceBlockCounter,
            sourceBlockNameSid: thermalModel.sourceBlockNameSid,
            electricEquivalentVolume: getNumberInputValue(thermalModel.electricEquivalentVolume),
            grossHeatGenerationVolume: getNumberInputValue(thermalModel.grossHeatGenerationVolume),
            netHeatGenerationVolume: getNumberInputValue(thermalModel.netHeatGenerationVolume),
            powerFactor: getNumberInputValue(thermalModel.powerFactor)
        }
    }

    mounted(): void {
        this.initializeTranslations();
        this.sourceBlockNamesValidator = new SourceBlockNamesValidator();
    }

    private initializeTranslations(): void {
        this.rowText = [
            '',
            this.$t('operationalData.entryView.tabs.thermalGen.sourceOfThermalGen').toString(),
            this.$t('operationalData.entryView.tabs.thermalGen.grossHeatGenVol').toString(),
            this.$t('operationalData.entryView.tabs.thermalGen.netHeatGenVol').toString(),
            this.$t('operationalData.entryView.tabs.thermalGen.powerFactor').toString(),
            this.$t('operationalData.entryView.tabs.thermalGen.electricEquivalentVol').toString(),
        ];
    }

    getThermalOptions(): void {
        const test = store.getters[OperationalDataGettersNames.GetDropDownOptions];
        this.options = test.thermalGenerationOptions;
        this.translateLabels();
    }

    translateLabels(): void {
        this.options.forEach(element => {
            element.label = this.$t(`operationalData.entryView.tabs.dropDownOptions.${element.value}`).toString(),
            element.shortenedLabel = this.$t(`operationalData.entryView.tabs.shortenedDropDownOptions.${element.value}`).toString()
        })
    }

    isRequired(index: number): boolean {
        return !!this.data[index].sourceBlockNameSid;
    }

    isValidName(buildingBlock: ThermalBuildingBlockModel): boolean {
        const isValid = this.sourceBlockNamesValidator.validate(
            buildingBlock,
            this.data,
            ['grossHeatGenerationVolume', 'netHeatGenerationVolume', 'powerFactor']
        );

        if (this.sourceBlockNamesValidator.allInputsValidated)
            this.buildingBlocksValidationState.allSourceBlockNamesValid = this.sourceBlockNamesValidator.areAllValid;

        return isValid;
    }

    deleteColumn(column: number): void {
        this.queryColumnDeletion(column, store.state.operationalData.activeThermalModels)
        this.data.splice(column, 1);
        this.closeConfirmDeletionPopup(column);
        this.$nextTick(() => this.validateCustomInputs(this));

    }
    queryColumnDeletion(column: number, activeThermalList: ThermalGenerationModel[]): void {
        if (activeThermalList[column].sourceBlockCounter !== undefined)
            store.dispatch(OperationalDataActionsNames.DeleteOperationalDataEntry, activeThermalList[column]);

        store.commit(OperationalDataMutationsNames.DeleteActiveThermalModelColumn, column);
    }
    closeConfirmDeletionPopup(column: number): void {
        (this.$refs['confirmDeletionModalRef'] as any)[column - 1].close();
    }

    openConfirmDeletionPopup(column: number): void {
        (this.$refs['confirmDeletionModalRef'] as any)[column - 1].open();
    }

    addColumn(): void {
        this.data.push({
            sourceBlockCounter: undefined,
            sourceBlockNameSid: undefined,
            grossHeatGenerationVolume: undefined,
            netHeatGenerationVolume: undefined,
            powerFactor: undefined,
            electricEquivalentVolume: undefined,
        })

        const thermalModels = this.mapBuildingBlocksToThermalModels();
        store.commit(OperationalDataMutationsNames.SetActiveThermalModels, thermalModels);
        this.$nextTick(() => this.validateCustomInputs(this));
    }

    atInputChange(column: number, row: number): void {
        if (row > 0) {
            const net = this.data[column].netHeatGenerationVolume;
            const powerFactor = this.data[column].powerFactor;
            if (net !== undefined && powerFactor !== undefined)
                this.data[column].electricEquivalentVolume = net * powerFactor;
            else {
                this.data[column].electricEquivalentVolume = undefined;
            }
        }

        const thermalModels = this.mapBuildingBlocksToThermalModels();
        store.commit(OperationalDataMutationsNames.SetActiveThermalModels, thermalModels);
    }

    mapBuildingBlocksToThermalModels(): ThermalGenerationModel[] {
        const unitKPI = cloneDeep(store.getters[OperationalDataGettersNames.GetActiveUnitKPI]);
        return this.data.map(buildingBlock => {
            return {
                machineSid: unitKPI.Unit.Id,
                reportMonth: unitKPI.ReportMonth,
                sourceBlockNameSid: buildingBlock.sourceBlockNameSid,
                sourceBlockCounter: buildingBlock.sourceBlockCounter,
                grossHeatGenerationVolume: buildingBlock.grossHeatGenerationVolume ?? 0,
                netHeatGenerationVolume: buildingBlock.netHeatGenerationVolume ?? 0,
                electricEquivalentVolume: buildingBlock.electricEquivalentVolume ? parseFloat(buildingBlock.electricEquivalentVolume.toFixed(3)) : 0,
                powerFactor: buildingBlock.powerFactor ?? 0
            }
        });
    }
}
