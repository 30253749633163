import Vue from 'vue';
import { mapGetters } from 'vuex';
const WaitingModal = Vue.extend({
    name: 'WaitingModal',
    components: {
    },
    props: {},
    data (): {
    } {
        return {
        }
    },
    watch: {
        isOpen: {
            handler (val: boolean): void {
                if (val) {
                    (this.$refs['globalWaitingModalRef'] as any).open();
                }
                else {
                    (this.$refs['globalWaitingModalRef'] as any).close();
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            isOpen: 'isWaiting',
            waitingModalTitle: 'waitingModalTitle',
            waitingModalContent: 'waitingModalContent',
        }),
    },
});
export default WaitingModal;
