import Vue from 'vue'
import moment from 'moment';
import { ColumnDef, VendorOptions } from '@/components/event/event-table/contracts/table-data'
import { TranslationMap } from './translationmap'
import EventTable from '@/components/event/event-table/event-table.vue'
import { EventHistoryModel } from '@/models';
import { EventBus } from '@/utils';
import _ from 'lodash';
import { ModeSettings } from '@/utils/mode-settings';

const EventHistory = Vue.extend({
    name: 'EventHistory',
    components: {
        EventTable
    },
    props: {
        historyData: {
            type: Array as () => EventHistoryModel[],
            required: true,
        },
    },
    data(): {
        vendorOptions: VendorOptions;
        showMore: boolean;
        buttonLabel: string;
        dateFormat: string;
        showButton: boolean;
        } {
        return {
            vendorOptions: {
                data: [],
                rowSelection: 'single',
                columnDefs: [],
                paginationPageSize: 20
            },
            showMore: false,
            buttonLabel: '',
            dateFormat: 'L HH:mm',
            showButton: true
        }
    },
    watch: {
        '$store.state.selectedLanguage': function (): void {
            this.vendorOptions.columnDefs = this.setColumns();
            this.vendorOptions.data = this.setTableData();
        }
    },
    created(): void {
        this.vendorOptions.columnDefs = this.setColumns();
        this.vendorOptions.data = this.setTableData();


        EventBus.$on(EventBus.GLOBAL.LANGUAGE_CHANGED, () => {
            this.vendorOptions.columnDefs = this.setColumns();
            this.vendorOptions.data = this.setTableData();
        });
    },
    methods: {
        setColumns(): Array<ColumnDef> {
            return [
                { headerName: this.$t('history.field').toString(), field: 'col-1', sortable: false },       // Field – which data within the Event was changed
                { headerName: this.$t('history.new').toString(), field: 'col-2', sortable: false },         // New – what is the new data that has changed to
                { headerName: this.$t('history.old').toString(), field: 'col-3', sortable: false },         // Old – what was the old data prior to the change
                { headerName: this.$t('history.timestamp').toString(), field: 'col-4', sortable: false },   // Timestamp – dd/mm/yyyy hh:mm
                { headerName: this.$t('history.name').toString(), field: 'col-5', sortable: false }         // KID / Name - Person who last updated
            ]
        },
        shortenValue(changeString: string): string {
            if(changeString != undefined && changeString.includes('.') ){
                return this.$t(`subSystemOptions.${changeString.replace('.', '')}`).toString();
            } else {
                return '-'
            }
        },
        /* eslint-disable sonarjs/cognitive-complexity */
        setRowData(item: any, change: any): any { // TODO: Set classes
            // translating SIDs to strings
            if(change.name === 'EventSapMainSystem'){
                return {
                    'col-1': this.$t(TranslationMap.field[change.name]) || change.name,
                    'col-2': this.$t(`mainSystemOptions.${change.newValue}`) || '-',
                    'col-3': this.$t(`mainSystemOptions.${change.oldValue}`) || '-',
                    'col-4': item.time || '',
                    'col-5': item.author || ''
                }
            }

            if(change.name === 'EventSapSubSystem'){
                return {
                    'col-1': this.$t(TranslationMap.field[change.name]) || change.name,
                    'col-2': this.shortenValue(change.newValue),
                    'col-3': this.shortenValue(change.oldValue),
                    'col-4': item.time || '',
                    'col-5': item.author || ''
                }
            }
            if(change.name === 'VgbClassificationFlag'){
                const newValue = (change.newValue === '-' || !change.newValue) ? '-' : this.$t(`vgbFlag.flagTitle.${change.newValue}`);
                const oldValue = (change.oldValue === '-' || !change.oldValue) ? '-' : this.$t(`vgbFlag.flagTitle.${change.oldValue}`);
                return {
                    'col-1': this.$t(`vgbFlag.vgbClassificationFlag`) || change.name,
                    'col-2': newValue,
                    'col-3': oldValue,
                    'col-4': item.time || '',
                    'col-5': item.author || ''
                }
            }

            if (change.name === 'EnumEventInfoStatusSid'
                || change.name === 'EnumVgbConditionBeforeSid'
                || change.name === 'EnumVgbTimeframeSid'
                || change.name === 'EnumVgbEventTypeSid'
                || change.name === 'EnumVgbMainConsequenceSid'
                || change.name === 'EnumVgbEventCauseSid'
                || change.name === 'EnumEventLifecycleStatusSid'
            ) {
                return {
                    'col-1': this.$t(TranslationMap.field[change.name]) || change.name,
                    'col-2': this.$t(TranslationMap.sid[change.newValue]) || '-',
                    'col-3': this.$t(TranslationMap.sid[change.oldValue]) || '-',
                    'col-4': item.time || '',
                    'col-5': item.author || ''
                }
            }
            // setting time format
            else if (change.name === 'UtcStartTime'
                || change.name === 'UtcEndTime'
                || change.name === 'CreatedAt'
            ) {

                return {
                    'col-1': this.$t(TranslationMap.field[change.name]) || change.name,
                    'col-2': moment.utc(change.newValue).local().locale(navigator.language).format(this.dateFormat) || '-',
                    'col-3': moment.utc(change.oldValue).local().locale(navigator.language).format(this.dateFormat) || '-',
                    'col-4': item.time || '',
                    'col-5': item.author || ''
                }
            }
            else if (change.name === 'IsTractionPower'
                || change.name === 'IsViaTrip'
                || change.name === 'IsMustRun'
                || change.name === 'IsDominant'
            ) {
                return {
                    'col-1': this.$t(TranslationMap.field[change.name]) || change.name,
                    'col-2': this.$t(TranslationMap.bool[change.newValue]) || '-',
                    'col-3': this.$t(TranslationMap.bool[change.oldValue]) || '-',
                    'col-4': item.time || '',
                    'col-5': item.author || ''
                }
            }
            else if (change.name.includes('Capacity')
                || change.name.includes('Energy')
            ) {
                return {
                    'col-1': this.$t(TranslationMap.field[change.name]) || change.name,
                    'col-2': Number(Number(change.newValue).toFixed(ModeSettings.InputNumberPrecision)).toString() || '-',
                    'col-3': Number(Number(change.oldValue).toFixed(ModeSettings.InputNumberPrecision)).toString() || '-',
                    'col-4': item.time || '',
                    'col-5': item.author || ''
                }
            }
            // in other cases return backend value
            else {
                return {
                    'col-1': this.$t(TranslationMap.field[change.name]) || change.name,
                    'col-2': change.newValue || '-',
                    'col-3': change.oldValue || '-',
                    'col-4': item.time || '',
                    'col-5': item.author || ''
                }
            }
        },
        setTableData(): any {
            let tableContent = []
            for (const item of this.historyData) {
                for (const change of item.changes) {
                    tableContent.push(this.setRowData(item, change));
                }
            }

            // sort by date - latest updates will be shown first
            tableContent.sort((a,b)=>Number(new Date(b['col-4']))-Number(new Date(a['col-4'])));

            // set date format
            for (let i = 0; i < tableContent.length; i++) {
                const date = moment(tableContent[i]['col-4']);
                tableContent[i]['col-4'] = moment.utc(date).local().locale(navigator.language).format(this.dateFormat);
            }
            if (tableContent.length <= 3) {
                this.showButton = false
            }
            else if (!this.showMore) {
                // show less: only show 3 latest updates
                this.buttonLabel = this.$t('history.showMore').toString();
                tableContent = tableContent.slice(0, 3)
            }
            else {
                // show more: show all updated values
                this.buttonLabel = this.$t('history.showLess').toString();
            }
            return (tableContent);
        },
        showMoreFunction(): void {
            this.showMore = !this.showMore;
            this.vendorOptions.data = this.setTableData();
        }
    }
})

export default EventHistory;
