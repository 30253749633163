import { AutomapperMetadata } from '@/mappers/mapper';
import { BasicDescription, Country, OperationalDataModel, Site, Status, Unit } from '@/models/operational-data/operational-data';
import { OperationalDataDTO } from '@/models/operational-data/operational-data-DTO';
import { createMetadataMap } from '@automapper/pojos';

export const createMetadataForOpDataModel = (): void => {
    const basicDescriptionMetadata: AutomapperMetadata<BasicDescription> = {
        Id: String,
        Name: String
    }

    createMetadataMap<BasicDescription>('BasicDescription', basicDescriptionMetadata);
    createMetadataMap<Country>('Country', basicDescriptionMetadata);
    createMetadataMap<Site>('Site', basicDescriptionMetadata);
    createMetadataMap<Unit>('Unit', basicDescriptionMetadata);
    createMetadataMap<Status>('Status', basicDescriptionMetadata);
    createMetadataMap<OperationalDataModel>('OperationalDataModel', {
        Country: 'Country',
        Site: 'Site',
        Unit: 'Unit',
        Date: String,
        Status: 'Status'
    });
    createMetadataMap<OperationalDataDTO>('OperationalDataDTO', {
        countryName: String,
        countrySid: Number,
        machineSid: Number,
        unitName: String,
        plantName: String,
        plantSid: Number,
        reportMonth: String,
        reportStatusSid: Number
    });
}