import { AutomapperMetadata } from '@/mappers/mapper';
import { UnitQualityPctModel, UnitQualityTempModel } from '@/models/operational-data/operational-data-details';
import { createMetadataMap } from '@automapper/pojos';

export const createMetadataForQualityLosses = (): void => {
    const qualityPctMetadata: AutomapperMetadata<UnitQualityPctModel> = {
        machineSid: Number,
        reportMonth: String,
        commentExternal: String,
        commentInternal: String,
        commentOther: String,
        valueExternal: Number,
        valueInternal: Number,
        valueOther: Number
    };
    createMetadataMap<UnitQualityPctModel>('UnitQualityPctModel', qualityPctMetadata);

    const qualityTempMetadata: AutomapperMetadata<UnitQualityTempModel> = {
        machineSid: Number,
        reportMonth: String,
        airActual: Number,
        airForecast: Number,
        waterActual: Number,
        waterForecast: Number
    };
    createMetadataMap<UnitQualityTempModel>('UnitQualityTempModel', qualityTempMetadata);
}