import { OperationalDataDetails } from '@/models/operational-data/operational-data-details';
import { OperationalDataDetailsDTO, OperationalDataDetailsEditDTO } from '@/models/operational-data/operational-data-DTO';
import { createMetadataMap } from '@automapper/pojos';
import { AutomapperMetadata} from '@/mappers/mapper';

export const createMetadataForDetails = (): void => {

    const dtoMetadata : AutomapperMetadata<OperationalDataDetailsDTO> = {
        operationalDataUnitKpiDto: 'OperationalDataUnitKpiDTO',
        operationalDataUnitMaterialListDto: 'OperationalDataUnitMaterialDTO',
        operationalDataUnitThermalListDto: 'OperationalDataUnitThermalDTO',
        operationalDataUnitUnavailabilityListDto: 'OperationalDataUnitUnavailabilityDTO',
        operationalDataUnitChpDto: 'OperationalDataUnitChpDto',
        operationalDataComponentKpiListDto: 'OperationalDataUnitComponentsDto',
        operationalMetadataDto: ''
    }

    createMetadataMap<OperationalDataDetailsDTO>('OperationalDataDetailsDTO', dtoMetadata);

    createMetadataMap<OperationalDataDetails>('OperationalDataDetails', {
        UnitKPI: 'OpDataUnitKPI',
        ThermalList: 'ThermalGenerationModel',
        MaterialList: 'MaterialModel',
        UnavailabilityList: 'UnavailabilityModel',
        CHP: 'ChpModel'
    });

    createMetadataMap<OperationalDataDetailsEditDTO>('OperationalDataDetailsEditDTO', {
        operationalDataUnitKpiDto: 'OperationalDataUnitKpiEditDTO',
        operationalDataUnitMaterialListDto: 'OperationalDataUnitMaterialDTO',
        operationalDataUnitThermalListDto: 'OperationalDataUnitThermalDTO',
        operationalDataUnitChpDto: 'OperationalDataUnitChpDto',
    });
};