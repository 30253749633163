import { MasterApiUnit } from '@/store/modules/contracts/Unit';
import { createMetadataMap } from '@automapper/pojos';
import { OperationalDataFiltersOptions } from '@/models/operational-data/operational-data';
import { AutomapperMetadata } from '@/mappers/mapper';

export const createMetadataForMasterUnits = (): void => {
    const masterApiUnitMetadata : AutomapperMetadata<Partial<MasterApiUnit>> = {
        countryName: String,
        countrySid: Number,
        plantName: String,
        plantSid: Number,
        unitName: String,
        machineSid: Number
    };
    createMetadataMap<MasterApiUnit>('MasterApiUnit', masterApiUnitMetadata);
};

export const createMetadataForFiltersOptions = (): void => {
    const dataFiltersOptionsMetadata: AutomapperMetadata<OperationalDataFiltersOptions> = {
        countries: Array,
        sites: Array,
        units: Array,
        statuses: Array
    };
    createMetadataMap<OperationalDataFiltersOptions>('OperationalDataFiltersOptions', dataFiltersOptionsMetadata);
};